import { useMemo, useState } from 'react';
import { sortAlphabetical } from '@biggby-coffee/percomatic-typescript/dist/src/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Connection as ConnectionComponent,
	Error,
	Loading
} from '../../components';
import { ConnectionsQuery, useConnectionsQuery } from '../../graphql';
import {
	faArrowDown,
	faArrowUp,
	faMinus,
	faPlus
} from '@fortawesome/free-solid-svg-icons';

export type Connection = ConnectionsQuery['connections'][number];

const keys = [
	'key',
	'storeId',
	'termId',
	'connectionId',
	'disconnected',
	'value',
	'time'
] as const;

type Key = typeof keys[number];

type SortDirection = 'asc' | 'desc';

type Sort = {
	key: Key;
	direction: SortDirection;
};

type SorterProps = {
	sort: Sort;
	sorts: Sort[];
	setSorts: React.Dispatch<Sort[]>;
	index: number;
};

const Sorter: React.FC<SorterProps> = ({ sort, sorts, setSorts, index }) => {
	return (
		<div
			className="d-flex justify-content-between align-items-center border border-primary text-primary rounded-1 p-1"
			style={{ marginBottom: index === sorts.length - 1 ? 0 : 5 }}
		>
			<div style={{ width: 25 }}>
				{index !== 0 && <FontAwesomeIcon icon={faArrowUp} />}
			</div>

			<div className="w-100 text-center">
				{sort.key}{' '}
				<span className="text-secondary cursor-pointer">
					{sort.direction === 'asc' ? (
						<FontAwesomeIcon
							icon={faArrowUp}
							onClick={() =>
								setSorts(
									sorts.map(currentSort => {
										if (currentSort.key !== sort.key) {
											return currentSort;
										}

										return {
											...currentSort,
											direction: 'desc'
										};
									})
								)
							}
						/>
					) : (
						<FontAwesomeIcon
							icon={faArrowDown}
							onClick={() =>
								setSorts(
									sorts.map(currentSort => {
										if (currentSort.key !== sort.key) {
											return currentSort;
										}

										return {
											...currentSort,
											direction: 'asc'
										};
									})
								)
							}
						/>
					)}
				</span>{' '}
				<span className="text-danger cursor-pointer">
					<FontAwesomeIcon
						icon={faMinus}
						onClick={() =>
							setSorts(
								sorts.filter(({ key }) => sort.key !== key)
							)
						}
					/>
				</span>
			</div>

			<div style={{ width: 25 }}>
				{index !== sorts.length - 1 && (
					<FontAwesomeIcon icon={faArrowDown} />
				)}
			</div>
		</div>
	);
};

type SortersProps = {
	sorts: Sort[];
	setSorts: React.Dispatch<Sort[]>;
};

const Sorters: React.FC<SortersProps> = ({ sorts, setSorts }) => {
	const availableKeys = useMemo(() => {
		const sortsKeys = sorts.map(sort => sort.key);

		return keys.filter(key => !sortsKeys.includes(key));
	}, [sorts]);

	return (
		<div className="d-flex justify-content-between">
			<div className="flex-shrink-1" style={{ minWidth: 200 }}>
				{sorts.map((sort, key) => (
					<Sorter
						sort={sort}
						sorts={sorts}
						setSorts={setSorts}
						index={key}
						key={key}
					/>
				))}
			</div>

			<div className="grid-150-gap-10 flex-grow-1">
				{availableKeys.map(key => (
					<button
						className="btn btn-outline-success"
						onClick={() =>
							setSorts([...sorts, { key, direction: 'asc' }])
						}
						key={key}
					>
						<div className="d-flex justify-content-between align-items-center text-upper">
							<div className="w-100 text-center">{key}</div>
							<div>
								<FontAwesomeIcon icon={faPlus} />
							</div>
						</div>
					</button>
				))}
			</div>
		</div>
	);
};

const sortConnectionAlphabetical =
	(key: Key) => (a: Connection, b: Connection) =>
		sortAlphabetical(a[key], b[key]);

const sortConnectionNumeric = (key: Key) => (a: Connection, b: Connection) =>
	a[key] - b[key];

const sortFunctions = {
	key: sortConnectionAlphabetical('key'),
	storeId: sortConnectionNumeric('storeId'),
	termId: sortConnectionNumeric('termId'),
	connectionId: sortConnectionAlphabetical('connectionId'),
	disconnected: (a: Connection, b: Connection) =>
		Number(a.disconnected) - Number(b.disconnected),
	value: sortConnectionAlphabetical('value'),
	time: (a: Connection, b: Connection) =>
		new Date(a.time)?.getTime() - new Date(b.time)?.getTime()
} as const;

export const Connections: React.FC = () => {
	const { data, error } = useConnectionsQuery();
	const [sorts, setSorts] = useState<Sort[]>([
		{ key: 'time', direction: 'desc' }
	]);

	const connections = useMemo(() => {
		if (!data) return [];

		const connections = data.connections.sort((a, b) => {
			let result = 0;

			for (const sort of sorts) {
				result = sortFunctions[sort.key](a, b);

				if (sort.direction === 'desc') {
					result *= -1;
				}

				if (result !== 0) return result;
			}

			return result;
		});

		return connections;
	}, [data, sorts]);

	if (error) return <Error error={error.message} />;
	if (!data) return <Loading />;

	return (
		<div className="my-5">
			<div className="p-1">
				<Sorters sorts={sorts} setSorts={setSorts} />
			</div>

			<hr />

			<div className="mt-1 grid-300-gap-5">
				{connections.map((connection, key) => (
					<ConnectionComponent key={key} connection={connection} />
				))}
			</div>
		</div>
	);
};

type Milliseconds = number;

const LOOK_AHEAD_MINUTES = Number(
	process.env.REACT_APP_LOOK_AHEAD_MINUTES ?? 5
);

const ITEM_QUANTITY_MULTIPLIER = Number(
	process.env.REACT_APP_ITEM_QUANTITY_MULTIPLIER ?? 1
);

/**
 * How far in the future to look when deciding if a given order should be sent
 * or not. Units in milliseconds.
 */
const LOOK_AHEAD: Milliseconds = 1000 * 60 * LOOK_AHEAD_MINUTES;

/**
 * Number of minutes to add to look ahead per item in order.
 */
const LOOK_AHEAD_MULTIPLIER = 1000 * 60 * ITEM_QUANTITY_MULTIPLIER;

/**
 * Determines if an order was manually pushed past the "ORDER_SCHEDULED" status
 * before the time it was scheduled for (by a store employee).
 * @param sentStatusTime time (in ms) that the order was sent
 * @param scheduledTime time (in ms) that the customer scheduled the order for
 * @param orderQuantity the number of items in the order
 * @returns a boolean indicating if this order was sent BEFORE the scheduled
 * time
 */
export const wasOrderSentEarly = (
	sentStatusTime: Milliseconds,
	scheduledTime: Milliseconds,
	orderQuantity: number
): boolean =>
	sentStatusTime <
	scheduledTime - (LOOK_AHEAD + orderQuantity * LOOK_AHEAD_MULTIPLIER);

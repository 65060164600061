import React, { useEffect, useState } from 'react';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import {
	Drawer,
	Header,
	Loader,
	PercomaticLoading,
	Search
} from '../components';

import { RecipeBookProductGroup } from '../components/RecipeBook';

import api from '../worker';
import UI from '../domains/UI';

const Body: React.FC = () => {
	const [isActive, setIsActive] = useState(false);

	const [productGroups, setProductGroups] = useState<
		Percomatic.Serialized.ProductGroup[]
	>([]);
	const [products, setProducts] = useState<Percomatic.Serialized.Product[]>(
		[]
	);

	useEffect(() => {
		(async () => {
			const products = await api.getProducts();

			setProducts(
				products.filter(
					product => product.isVisible && product.itemIds.length > 0
				)
			);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			const productGroups = await api.getProductGroups();

			setProductGroups(
				productGroups.filter(
					group =>
						group.visibleProducts.length > 0 &&
						group.isVisible &&
						group.products.some(
							product => product.itemIds.length > 0
						)
				)
			);
		})();
	}, []);

	return (
		<>
			<div className="position-relative">
				<Loader
					loaded={products.length > 0 && productGroups.length > 0}
				>
					<Search products={products} productGroups={productGroups} />
				</Loader>
			</div>

			<div className="position-relative">
				<Loader loaded={productGroups.length > 0}>
					<Drawer isActive={isActive}>
						<Header
							isActive={isActive}
							onClick={() => setIsActive(!isActive)}
							label="Browse"
							tag="h1"
							color="secondary"
						/>

						{productGroups?.map((group, key) => (
							<RecipeBookProductGroup group={group} key={key} />
						))}
					</Drawer>

					<hr className="m-0 p-0" />
				</Loader>
			</div>
		</>
	);
};

export function RecipeBook(): JSX.Element {
	UI.actions.useSetOrientation('portrait');

	return (
		<div className="overflow-auto pb-3 h-100 text-light">
			<PercomaticLoading redirect="/recipe-book" children={<Body />} />
		</div>
	);
}

import { Moment } from 'moment';
import { useMemo } from 'react';

import { PendingOrderQuery } from '../../../graphql';
import { useWasOrderSentEarly } from './useWasOrderSentEarly';

export type TitleProps = {
	scheduledTimeRelative: string;
	scheduledTime: Moment;
	status: PendingOrderQuery['pendingOrder']['status'];
	statuses: PendingOrderQuery['pendingOrder']['statuses'];
	items: PendingOrderQuery['pendingOrder']['items'];
};

export const Title: React.FC<TitleProps> = ({
	scheduledTimeRelative,
	status,
	statuses,
	scheduledTime,
	items
}) => {
	const message = useMemo(
		() =>
			`Scheduled For: ${scheduledTimeRelative} at ${scheduledTime.format(
				'h:mm a'
			)}`,
		[scheduledTime, scheduledTimeRelative]
	);

	const early = useWasOrderSentEarly(scheduledTime, items, status, statuses);

	if (early)
		return (
			<>
				<del>{message}</del> ASAP
			</>
		);

	return <>{message}</>;
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
	ComponentFields,
	ItemComponentGroups,
	PercomaticLoading
} from '../../components';

import ProductManagement from '../../domains/ProductManagement';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import api from '../../worker';

interface ParamProps {
	groupId: string;
	productId: string;
	itemId: string;
	componentId: string;
}

function Body() {
	const params = useParams<ParamProps>();

	const [component, setComponent] =
		useState<Percomatic.Serialized.Component>();

	useEffect(() => {
		(async () => {
			const component = await api.getComponent(
				Number(params.componentId)
			);
			setComponent(component);
		})();
	}, [params.componentId]);

	const updateHeader = ProductManagement.actions.useUpdateHeader();

	useEffect(() => {
		updateHeader({
			label: component?.name ?? '',
			showButton: true,
			buttonTitle: 'Create New Component',
			linkTo: '/product-management/new/component'
		});
	}, [component?.name, updateHeader]);

	return (
		<>
			{component && <ComponentFields {...component} />}
			<ItemComponentGroups
				params={params}
				componentGroups={component?.componentGroups}
			/>
		</>
	);
}

export default function Component(): JSX.Element {
	return (
		<div>
			<PercomaticLoading loaded children={<Body />} />
		</div>
	);
}

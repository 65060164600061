import React, { useRef, useEffect } from 'react';

export interface CaptureOuterClickProps {
	children: React.ReactNode;
	handler: (event?: MouseEvent) => void;
}

/**
 * Hook that detects clicks outside of the passed ref
 */
function useOutsideClick(
	ref: React.MutableRefObject<HTMLElement | null>,
	handler: (event?: MouseEvent) => void
) {
	useEffect(() => {
		/**
		 * Call handler if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				handler(event);
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, handler]);
}

export const CaptureOuterClick: React.FC<CaptureOuterClickProps> = (
	props: CaptureOuterClickProps
) => {
	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, props.handler);

	return <div ref={wrapperRef}>{props.children}</div>;
};

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CreditCardPaymentsFieldFragment } from '../../../graphql';
import { GenericDrawer } from '../..';

export const CreditCardInfo: React.FC<CreditCardPaymentsFieldFragment> =
	props => {
		const [showTransferInfo, setShowTransferInfo] =
			useState<boolean>(false);
		return (
			<div className="order-info-container px-3">
				<div className="order-info">
					<h3>Charge ID</h3>
					<p className="text-break">{props.chargeId}</p>
				</div>
				<div className="order-info">
					<h3>Card Type</h3>
					<p>
						{`${props.cardType
							?.charAt(0)
							.toUpperCase()}${props.cardType?.slice(1)}`}
					</p>
				</div>
				<div className="order-info">
					<h3>Card Ending With</h3>
					<p>{props.lastFour}</p>
				</div>
				<div className="order-info">
					<h3>Amount Charged</h3>
					<p>${(props.amount / 100).toFixed(2)}</p>
				</div>
				<div className="order-info">
					<h3>Status</h3>
					<p>{props.charge.status}</p>
				</div>
				<div className="order-info">
					<h3>Charge Description</h3>
					<p className="text-wrap">{props.charge.description}</p>
				</div>
				<GenericDrawer isActive={showTransferInfo}>
					<div
						className="order-info d-flex flex-row align-items-center"
						onClick={() => setShowTransferInfo(!showTransferInfo)}
					>
						<h3 className="m-0">Transfer Information</h3>
						<FontAwesomeIcon
							icon={
								showTransferInfo ? faChevronUp : faChevronDown
							}
							className="ml-4"
						/>
					</div>
					<div className="order-info-container d-flex flex-wrap">
						<div className="order-info">
							<h3>Transfer Destination Id</h3>
							<p className="text-break">
								{props.charge.transfer?.destination.id ?? 'N/A'}
							</p>
						</div>
						<div className="order-info">
							<h3>Transfer Id</h3>
							<p className="text-break">
								{props.charge.transfer?.id ?? 'N/A'}
							</p>
						</div>
						<div className="order-info">
							<h3>Transfer Group</h3>
							<p className="text-break">
								{props.charge.transfer?.group ?? 'N/A'}
							</p>
						</div>
						<div className="order-info">
							<h3>Transfer Destination Account Id</h3>
							<p className="text-break">
								{props.charge.transfer?.destination.accountId ??
									'N/A'}
							</p>
						</div>
						<div className="order-info">
							<h3>Transfer Destination Charges Enabled</h3>
							<p>
								{props.charge.transfer?.destination
									.chargesEnabled === true
									? 'Yes'
									: 'No'}
							</p>
						</div>
					</div>
				</GenericDrawer>
			</div>
		);
	};

import { pullAt } from 'lodash';

import { OLOItem, PERCItem, ItemPair } from './types';

export const getItemPairs = (
	oloItems: OLOItem[],
	percItems: PERCItem[]
): {
	itemPairs: ItemPair[];
	orphans: { oloItems: OLOItem[]; percItems: PERCItem[] };
} => {
	const itemPairs: ItemPair[] = [];
	const oloItemOrphans: OLOItem[] = [];
	const availablePercItems = [...percItems];

	for (const oloItem of oloItems) {
		const percItemIndex = availablePercItems.findIndex(
			percItem => percItem.itemId === oloItem.itemId
		);

		if (percItemIndex === -1) {
			oloItemOrphans.push(oloItem);
			continue;
		}

		const percItem = { ...availablePercItems[percItemIndex] };

		pullAt(availablePercItems, percItemIndex);

		itemPairs.push({ oloItem, percItem });
	}

	return {
		itemPairs,
		orphans: { oloItems: oloItemOrphans, percItems: availablePercItems }
	};
};

import React, { useState, useEffect } from 'react';
import { Modal } from '../../Generic';
import { ActiveDefaultForm } from '../ActiveDefaultForm/ActiveDefaultForm';
import {
	BrandStandardEdgeFieldsFragment,
	DefaultNoticeEdgeFieldsFragment,
	useEditDefaultNoticeMutation,
	useDeleteDefaultNoticeMutation
} from '../../../graphql';
import actions from '../../../domains/UI/actions';

export type ActiveDefaultProps = {
	edit: boolean;
	active: DefaultNoticeEdgeFieldsFragment;
	brandStandards: BrandStandardEdgeFieldsFragment[];
};

export const ActiveDefault: React.FC<ActiveDefaultProps> = ({
	active,
	edit,
	brandStandards
}) => {
	const toggleModal = actions.useToggleModal();

	const [activeDefault, setActiveDefault] = useState({
		id: active.node.id,
		description: active.node.description,
		defaultDate: String(active.node.defaultDate).slice(0, 10),
		notificationMethod: active.node.notificationMethod,
		brandStandardsViolated: String(active.node.brandStandardViolated),
		reportedBy: active.node.reportedBy,
		resolved: active.node.resolved,
		resolvedDate: new Date().toISOString().slice(0, 10)
	});

	const [brandStandardResolution, setBrandStandardResolution] = useState<{
		description: string;
		resolution: string;
	}>({
		description: '',
		resolution: ''
	});

	const [saveModal, setSaveModal] = useState<boolean>(false);

	const [saveStatusModal, setSaveStatusModal] = useState<boolean>(false);

	const [deleteModal, setDeleteModal] = useState<boolean>(false);

	const [deleteStatusModal, setDeleteStatusModal] = useState<boolean>(false);

	const [brandStandardsModal, setBrandStandardsModal] =
		useState<boolean>(false);

	const [editDefaultNotice] = useEditDefaultNoticeMutation();

	const [deleteDefaultNotice] = useDeleteDefaultNoticeMutation();

	useEffect(() => {
		const resolution = brandStandards.find(
			brandStandard =>
				brandStandard.node.brandStandardId ===
				Number(activeDefault.brandStandardsViolated)
		);

		if (resolution) {
			setBrandStandardResolution({
				description: resolution.node.description ?? '',
				resolution: resolution.node.resolution ?? ''
			});
		}
	}, [brandStandards, activeDefault]);

	const handleChange = (e: {
		target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
	}) => {
		const { name, value } = e.target;

		if (name === 'resolved') {
			const val = value === '1' ? true : false;
			setActiveDefault({
				...activeDefault,
				[name]: val
			});
			return;
		}

		setActiveDefault({
			...activeDefault,
			[name]: [value]
		});
	};

	const handleSaveClick = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setSaveModal(true);
		toggleModal();
	};

	const handleSave = async () => {
		const response = await editDefaultNotice({
			variables: {
				defaultNotice: {
					id: active.node.id,
					description: String(activeDefault.description),
					defaultDate: active.node.defaultDate,
					notificationMethod: String(
						activeDefault.notificationMethod
					),
					brandStandardViolated: Number(
						activeDefault.brandStandardsViolated
					),
					reportedBy: active.node.reportedBy,
					resolved: activeDefault.resolved,
					resolutionDate: new Date(activeDefault.resolvedDate)
				}
			}
		});

		if (response) {
			setSaveModal(false);
			setSaveStatusModal(true);
			toggleModal();
		}
	};

	const handleDeleteClick = () => {
		toggleModal();
		setDeleteModal(true);
	};

	const handleDelete = async () => {
		const response = await deleteDefaultNotice({
			variables: {
				id: activeDefault.id
			}
		});

		if (response) {
			setDeleteModal(false);
			setDeleteStatusModal(true);
			toggleModal();
		}
	};

	return (
		<div>
			<ActiveDefaultForm
				submit={handleSaveClick}
				change={handleChange}
				edit={edit}
				activeDefault={activeDefault}
				brandStandardsModal={brandStandardsModal}
				setBrandStandardsModal={setBrandStandardsModal}
				brandStandards={brandStandards}
				onDelete={handleDeleteClick}
				toggleModal={toggleModal}
			/>

			{saveModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Save"
					confirmationButtonHandler={handleSave}
					closeMessage="Cancel"
					closeButtonHandler={() => setSaveModal(false)}
				>
					<>
						<h1 className="text-dark text-center">
							Are you sure you want to save?
						</h1>
						<p className="text-dark">
							The default for{' '}
							<em>'{activeDefault.description}'</em>&nbsp; will be
							saved.
						</p>
					</>
				</Modal>
			)}

			{saveStatusModal && (
				<Modal
					closeButtonHandler={() => {
						setSaveStatusModal(false);
						if (activeDefault.resolved) {
							window.location.reload();
						}
					}}
				>
					<>
						<h1 className="text-dark">Changes Saved!</h1>
						<p className="text-dark">
							The default for{' '}
							<em>'{activeDefault.description}'</em>&nbsp; has
							been updated.
						</p>
					</>
				</Modal>
			)}

			{deleteModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Delete"
					confirmationButtonHandler={handleDelete}
					closeMessage="Cancel"
					closeButtonHandler={() => setDeleteModal(false)}
				>
					<>
						<h1 className="text-dark text-center">
							Are you sure you want to permanently delete?
						</h1>
						<p className="text-dark">
							The default for{' '}
							<em>'{activeDefault.description}'</em>&nbsp; will be
							deleted.
						</p>
					</>
				</Modal>
			)}

			{deleteStatusModal && (
				<Modal
					closeButtonHandler={() => {
						setDeleteStatusModal(false);
						window.location.reload();
					}}
				>
					<>
						<h1 className="text-dark">Successfully Deleted!</h1>
						<p className="text-dark">
							The default for{' '}
							<em>'{activeDefault.description}'</em>&nbsp; has
							been deleted.
						</p>
					</>
				</Modal>
			)}

			{brandStandardsModal && (
				<Modal closeButtonHandler={() => setBrandStandardsModal(false)}>
					<div>
						<h3 className="mb-3">
							{brandStandardResolution.description}
						</h3>
						<p className="mb-1 font-italic">Resolution:</p>
						<p>{brandStandardResolution.resolution}</p>
					</div>
				</Modal>
			)}
		</div>
	);
};

import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';

/* eslint-disable import/no-webpack-loader-syntax */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import qrScannerWorkerSource from '!!raw-loader!../../../../node_modules/qr-scanner/qr-scanner-worker.min.js';

import { CancelButton } from '..';

QrScanner.WORKER_PATH = URL.createObjectURL(new Blob([qrScannerWorkerSource]));

export type QRCodeProps = {
	cancelAction: () => void;
	successAction: (result: string) => void;
	targetRes?: number;
};

export const QRCode: React.FC<QRCodeProps> = ({
	cancelAction,
	successAction,
	targetRes = 400
}) => {
	const videoRef = useRef<HTMLVideoElement | null>(null);

	const [result, setResult] = useState<string>();
	const [error, setError] = useState<string>();

	useEffect(() => {
		if (videoRef.current && result === undefined) {
			const qrScanner = new QrScanner(
				videoRef.current,
				result => {
					console.log('decoded qr code:', result);
					setResult(result);
					successAction(result);
					qrScanner.stop();
					qrScanner.destroy();
					videoRef.current?.pause();
				},
				setError,
				targetRes,
				'environment'
			);

			qrScanner.start();
		}
	}, [successAction, result, targetRes]);

	const width = videoRef.current?.clientWidth;
	const height = videoRef.current?.clientHeight;
	const hRes = videoRef.current?.videoWidth;
	let scaleFactor = 0;

	if (hRes && width) {
		scaleFactor = width / hRes;
	}

	return (
		<div className="text-light">
			<div className="d-flex align-items-center justify-content-between">
				<h1 className="mb-0 text-primary">Scan QR Code</h1>

				<CancelButton onClick={() => cancelAction()} />
			</div>

			{result && <p>Result: {result}</p>}
			{error && <p>{error}</p>}

			<div
				style={{ position: 'relative', width, height }}
				className="mx-auto"
			>
				<video
					style={{ maxWidth: '100%', maxHeight: '74vh' }}
					ref={videoRef}
				/>

				<div
					style={{
						position: 'absolute',
						width: targetRes * scaleFactor,
						height: targetRes * scaleFactor,
						top: '50%',
						left: '50%',
						transform: 'translate3d(-50%, -50%, 0)',
						border: '1px solid red'
					}}
				/>
			</div>
		</div>
	);
};

import React from 'react';
import { Link } from 'react-router-dom';

import Session from '../domains/Session';
import UI from '../domains/UI';

const adminRoles = [1, 7];
// brandStandardsRoles: to view/edit brand standards
const brandStandardsRoles = [1, 14];
const bossRoles = [1, 10];

export default function HomeOffice(): JSX.Element {
	UI.actions.useSetOrientation('any');

	const employee = Session.selectors.useSelectEmployee();

	return (
		<div className="employee overflow-auto h-100 mx-auto mx-sm-0 d-sm-flex align-items-center justify-content-center">
			<div className="w-75">
				<div className="grid-200">
					{employee && (
						<>
							{employee.roles.find(({ roleId }) =>
								adminRoles.includes(roleId)
							) && (
								<>
									<Link
										className="btn btn-info btn-lg p-sm-4"
										to="/admin"
									>
										Admin
									</Link>

									<Link
										className="btn btn-info btn-lg p-sm-4"
										to="/product-management"
									>
										Product Management
									</Link>

									<Link
										className="btn btn-info btn-lg p-sm-4"
										to="/lyla-questions"
									>
										Lyla Questions
									</Link>
								</>
							)}

							{employee.roles.find(({ roleId }) =>
								brandStandardsRoles.includes(roleId)
							) && (
								<Link
									className="btn btn-info btn-lg p-sm-4"
									to="/brand-standards"
								>
									Brand Standards
								</Link>
							)}

							{employee.roles.find(({ roleId }) =>
								bossRoles.includes(roleId)
							) && (
								<Link
									className="btn btn-info btn-lg p-sm-4"
									to="/create-cards"
								>
									Create Cards
								</Link>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}

import { head, pick, slice } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
	Dropdown,
	LinkedTableItem,
	SingleRelationshipLabel,
	TableHead,
	TableItem
} from '..';
import { determineSlug } from '../../../../utils';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import { Tooltip } from '../../..';

export type TableItemType = {
	id?: number | string;
	containerId?: number;
	name?: string;
	unitName?: string;
	component?: Percomatic.Serialized.Component;
};

export type TableDropdownProps<T> = {
	state: T[];
	onDelete: (item: T, index: number) => void;
	onAdd: (item: T, index: number) => void;
	dropdownItems: T[];
	label: string;
	showDropdownButton?: boolean;
	singleRelationship?: boolean;
	content?: React.ReactNode;
};

export const TableDropdown = <T extends TableItemType>({
	state,
	onDelete,
	dropdownItems,
	label,
	showDropdownButton,
	singleRelationship,
	onAdd,
	content,
	...props
}: TableDropdownProps<T>): React.ReactElement => {
	const location = window.location.pathname;

	return (
		<>
			<div className="d-flex flex-wrap justify-content-between mt-5">
				<div className="d-flex align-items-center col-lg-3 col-sm-5">
					<h2 className="text-light font-weight-bold pr-4">
						{`${label}${singleRelationship ? '' : 's'}`}
					</h2>
					{content && (
						<Tooltip content={content}>
							{' '}
							<FontAwesomeIcon
								icon={faQuestionCircle}
								color="white"
							/>
						</Tooltip>
					)}
				</div>
				<div className="col-lg-3 col-sm-5 ">
					<Dropdown
						showDropdownButton={showDropdownButton}
						label={label}
						clickHandler={onAdd}
						items={dropdownItems}
						state={state}
					/>
				</div>
			</div>

			{singleRelationship && <SingleRelationshipLabel />}

			<table className="table table-striped table-hover table-dark">
				<TableHead dropdownItems={dropdownItems} />
				<tbody>
					{state.map((item, key) => (
						<tr key={key}>
							<LinkedTableItem
								key={key}
								val={head(
									Object.values(
										pick(item, ['unitName', 'name', 'id'])
									)
								)}
								to={determineSlug(
									label,
									item.id ?? item.containerId ?? 0,
									location
								)}
							/>

							{slice(
								Object.values(
									pick(item ?? {}, ['unitName', 'name', 'id'])
								),
								1
							).map((val, key) => (
								<TableItem val={val} key={key} />
							))}

							<td
								onClick={() => onDelete(item, key)}
								className="text-center"
							>
								<FontAwesomeIcon icon={faTimes} color="red" />
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
};

import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { useInactivitySpring } from '../../hooks/useInactivitySpring';

import Terminals from './Terminals';
import Stores from './Stores';
import LiveOrders from './LiveOrders';
import Logs from './Logs';
import Nav from './Nav';
import { Connections } from './Connections';
import { Orders } from './Orders';
import { Order } from './Order';

import AdminDomain from '../../domains/Admin';

const Router = React.memo(() => (
	<Switch>
		<Route exact path="/admin/orders">
			<Orders />
		</Route>

		<Route path="/admin/orders/:id">
			<Order />
		</Route>

		<Route path="/admin/live">
			<LiveOrders />
		</Route>

		<Route path="/admin/terminals">
			<Terminals />
		</Route>

		<Route path="/admin/logs">
			<Logs />
		</Route>

		<Route path="/admin/stores">
			<Stores />
		</Route>

		<Route path="/admin/connections">
			<Connections />
		</Route>
	</Switch>
));

const Admin: React.FC = () => {
	const { push } = useHistory();
	const { pathname } = useLocation();

	const navHeight = useInactivitySpring(50);

	AdminDomain.actions.useLoadTerminals();
	AdminDomain.actions.useSubscribeToOrders();
	AdminDomain.actions.useSubscribeToTerminals();

	useEffect(() => {
		if (pathname === '/admin') {
			push('/admin/stores');
		}
	}, [push, pathname]);

	return (
		<div className="admin overflow-auto" style={{ marginTop: navHeight }}>
			<Nav height={navHeight} />

			<div className="p-1 overflow-auto">
				<Router />
			</div>
		</div>
	);
};

export default Admin;

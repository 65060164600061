/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import useSound from 'use-sound';

import selectors from './selectors';

//@ts-ignore
import alertSound1 from '../../assets/sounds/alert.mp3';
//@ts-ignore
import alertSound2 from '../../assets/sounds/alert2.mp3';
//@ts-ignore
import alertSound3 from '../../assets/sounds/alert3.mp3';

import {
	useTerminalsQuery,
	usePendingOrderIdsQuery,
	usePendingOrderQuery,
	useOrderReadyMutation,
	useOrderCompleteMutation,
	useSendOrderMutation,
	useTerminalsUpdatesSubscription,
	useOrderStatusSubscription,
	usePendingOrdersSubscription,
	useAllOrdersSubscription,
	OrderStatusEnum,
	TerminalStatus
} from '../../graphql';

const actions: AdminDomain.AdminDomainActions = {
	useTerminalSounds(storeId, termId) {
		// console.log(`Admin: actions: useTerminalSounds: S${storeId}T${termId}`);
		const [play1] = useSound(alertSound1);
		const [play2] = useSound(alertSound2);

		const [oldStatus, setOldStatus] = useState<TerminalStatus | null>(null);

		const { status } = selectors.useSelectTerminal(storeId, termId);

		useEffect(() => {
			console.group(`Store: ${storeId}\tTerm: ${termId}`);
			console.debug(oldStatus, status);
			if (oldStatus === null) {
				console.debug('0000000000000000000000000000000000');
				play1();
				setOldStatus(status);
			} else if (status !== oldStatus) {
				if (oldStatus === 'CONNECTED' && status === 'DISCONNECTED') {
					console.debug('111111111111111111111111111111111111111');
					play1();
				}

				if (oldStatus === 'DISCONNECTED' && status === 'CONNECTED') {
					console.debug('222222222222222222222222222222222222222222');
					play2();
				}

				setOldStatus(status);
			}

			console.groupEnd();
		}, [oldStatus, status, play1, play2, storeId, termId]);
	},
	useLoadTerminals() {
		const dispatch = useDispatch();
		const { data, error } = useTerminalsQuery();

		useEffect(() => {
			if (error) throw error;

			if (data) {
				const action: AdminDomain.Actions.TerminalsUpdated = {
					type: 'TERMINALS_UPDATED',
					payload: data.terminals
				};

				dispatch(action);
			}
		}, [data, error, dispatch]);
	},
	useSubscribeToTerminals() {
		const dispatch = useDispatch();

		const { data, error } = useTerminalsUpdatesSubscription();

		useEffect(() => {
			if (error) throw error;

			if (data) {
				const action: AdminDomain.Actions.TerminalsUpdated = {
					type: 'TERMINALS_UPDATED',
					payload: data.terminals
				};

				dispatch(action);
			}
		}, [data, error, dispatch]);
	},
	useSubscribeToOrders() {
		const dispatch = useDispatch();
		const { data, error } = useAllOrdersSubscription();

		useEffect(() => {
			if (error) throw error;
			if (data && data.allOrders) {
				const action: AdminDomain.Actions.OrdersUpdated = {
					type: 'ORDERS_UPDATED',
					payload: data.allOrders
				};
				dispatch(action);
			}
		}, [data, error, dispatch]);
	},
	useToggleDisplayAllTerminals() {
		const dispatch = useDispatch();

		return () => {
			const action: AdminDomain.Actions.ToggleDisplayAllTerminals = {
				type: 'TOGGLE_DISPLAY_ALL_TERMINALS'
			};

			dispatch(action);
		};
	},
	useLoadPendingOrderIds() {
		const dispatch = useDispatch();
		const [play] = useSound(alertSound2);
		const { pendingOrderIds, completedIds, sortMostRecent } = useSelector(
			(state: RootState) => ({
				pendingOrderIds: state.admin.pendingOrders.ids,
				completedIds: state.admin.pendingOrders.completedIds,
				sortMostRecent: state.admin.pendingOrders.sortMostRecent
			})
		);
		const { data, error } = usePendingOrderIdsQuery();
		const { data: newOrder, error: newOrderError } =
			usePendingOrdersSubscription();

		useEffect(() => {
			if (error) throw error;
			console.log('Admin: actions: useLoadPendingOrderIds: useEffect: 1');

			if (data) {
				const action: AdminDomain.Actions.PendingOrdersUpdated = {
					type: 'PENDING_ORDERS_UPDATED',
					payload: data.pendingOrderIds
				};

				dispatch(action);
			}
		}, [data, dispatch, error]);

		useEffect(() => {
			if (newOrderError) throw newOrderError;
			console.log('Admin: actions: useLoadPendingOrderIds: useEffect: 2');

			if (
				newOrder?.newOrder &&
				!pendingOrderIds.includes(newOrder.newOrder.id) &&
				!completedIds.includes(newOrder.newOrder.id)
			) {
				let updatedPendingOrderIds: string[];

				if (sortMostRecent) {
					updatedPendingOrderIds = [
						newOrder.newOrder.id,
						...pendingOrderIds
					];
				} else {
					updatedPendingOrderIds = [
						...pendingOrderIds,
						newOrder.newOrder.id
					];
				}

				play();

				const action: AdminDomain.Actions.PendingOrdersUpdated = {
					type: 'PENDING_ORDERS_UPDATED',
					payload: updatedPendingOrderIds
				};

				dispatch(action);
			}
		}, [
			newOrder,
			pendingOrderIds,
			completedIds,
			newOrderError,
			sortMostRecent,
			dispatch,
			play
		]);
	},
	usePendingOrder(orderId) {
		const [play] = useSound(alertSound3);

		const dispatch = useDispatch();

		console.log('Admin: actions: usePendingOrder: ' + orderId);

		const [status, setStatus] = useState<OrderStatusEnum | undefined>(
			undefined
		);

		const [orderReady, { error: orderReadyError }] =
			useOrderReadyMutation();

		const [orderComplete, { error: orderCompleteError }] =
			useOrderCompleteMutation();

		const [sendOrder, { error: publishOrderError }] =
			useSendOrderMutation();

		const { data, error, refetch } = usePendingOrderQuery({
			variables: { id: orderId },
			fetchPolicy: 'cache-and-network'
		});

		const { data: statusData, error: statusError } =
			useOrderStatusSubscription({
				variables: { id: orderId },
				async onSubscriptionData({ client, subscriptionData }) {
					if (subscriptionData.data?.orderStatus) {
						client.writeData({
							data: omit(subscriptionData.data.orderStatus, 'id'),
							id: `$Order:${subscriptionData.data.orderStatus.orderId}.status`
						});

						refetch();
					}
				}
			});

		if (orderReadyError) throw orderReadyError;
		if (orderCompleteError) throw orderCompleteError;
		if (publishOrderError) throw publishOrderError;
		if (statusError) throw statusError;
		if (error) throw error;

		useEffect(() => {
			if (data) {
				setStatus(data.pendingOrder.status.status);
			}
		}, [data]);

		useEffect(() => {
			console.log(
				'Admin: actions: usePendingOrder: ' + orderId + ': useEffect'
			);

			if (
				statusData &&
				statusData.orderStatus &&
				statusData.orderStatus.orderId === orderId
			) {
				if (statusData.orderStatus.status === 'ORDER_RECEIVED') {
					play();
				}

				if (statusData.orderStatus.status === 'ORDER_COMPLETE') {
					console.log(
						'Admin: actions: usePendingOrder: ' +
							orderId +
							': useEffect: ORDER_COMPLETE'
					);

					const action: AdminDomain.Actions.PendingOrderComplete = {
						type: 'PENDING_ORDER_COMPLETE',
						payload: orderId
					};

					dispatch(action);
				} else {
					console.log(
						'Admin: actions: usePendingOrder: ' +
							orderId +
							': useEffect: setStatus',
						statusData.orderStatus.status
					);
					setStatus(statusData.orderStatus.status);
				}
			}
		}, [statusData, orderId, dispatch, play]);

		return {
			order: data?.pendingOrder,
			status,
			orderReady: () => {
				console.log(
					'Admin: actions: usePendingOrder: ' +
						orderId +
						': orderReady'
				);

				orderReady({ variables: { id: orderId } });
			},
			orderComplete: (forced?: boolean) => {
				console.log(
					'Admin: actions: usePendingOrder: ' +
						orderId +
						': orderReady'
				);

				orderComplete({ variables: { id: orderId, forced } });
			},
			sendOrder: () => {
				console.log(
					`Admin: actions: usePendingOrder ${orderId}: publishOrder`
				);

				sendOrder({ variables: { id: orderId } });
			}
		};
	},
	useTogglePendingOrdersSortOrder() {
		const dispatch = useDispatch();

		return () => {
			const action: AdminDomain.Actions.TogglePendingOrdersSortOrder = {
				type: 'TOGGLE_PENDING_ORDERS_SORT_ORDER'
			};

			dispatch(action);
		};
	},
	useSetFilterPendingOrders() {
		const dispatch = useDispatch();

		return payload => {
			const action: AdminDomain.Actions.SetFilterPendingOrders = {
				type: 'SET_FILTER_PENDING_ORDERS',
				payload
			};

			dispatch(action);
		};
	}
};

export default actions;

import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ComponentGroupFields, PercomaticLoading } from '../../components';

import ProductManagement from '../../domains/ProductManagement';
import api from '../../worker';
interface ParamProps {
	groupId: string;
	productId: string;
	itemId: string;
	componentGroupId: string;
}

function Body() {
	const params = useParams<ParamProps>();

	const [componentGroup, setComponentGroup] =
		useState<Percomatic.Serialized.ComponentGroup>();

	useEffect(() => {
		(async () => {
			const group = await api.getComponentGroup(
				Number(params.componentGroupId)
			);
			setComponentGroup(group);
		})();
	}, [params.componentGroupId]);

	const updateHeader = ProductManagement.actions.useUpdateHeader();

	useEffect(() => {
		updateHeader({
			label: componentGroup?.name ?? '',
			showButton: true,
			buttonTitle: 'Create New Component Group',
			linkTo: '/product-management/new/component-group'
		});
	}, [componentGroup?.name, updateHeader]);

	useEffect(() => {
		updateHeader({
			label: componentGroup?.name ?? '',
			showButton: true,
			buttonTitle: 'Create New Component Group',
			linkTo: '/product-management/new/component-group'
		});
	}, [componentGroup?.name, updateHeader]);

	return (
		<>{componentGroup && <ComponentGroupFields {...componentGroup} />}</>
	);
}

export default function editComponentGroup(): JSX.Element {
	return (
		<div>
			<PercomaticLoading loaded children={<Body />} />
		</div>
	);
}

import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useMeasure, useThrottleFn } from 'react-use';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type HeaderProps = {
	isActive: boolean;
	onClick: React.EventHandler<React.MouseEvent>;
	color: 'primary' | 'secondary' | 'light' | 'dark';
	label?: string | React.ReactNode;
	tag?: keyof JSX.IntrinsicElements;
	paddingX?: 0 | 1 | 2 | 3 | 4 | 5;
	paddingY?: 0 | 1 | 2 | 3 | 4 | 5;
	noHr?: boolean;
};

export const Header: React.FC<
	HeaderProps & React.HTMLAttributes<HTMLDivElement>
> = ({
	color,
	children,
	tag: Tag = 'div',
	label = children,
	paddingX = 3,
	paddingY = 2,
	onClick,
	isActive,
	noHr = false,
	...otherProps
}) => {
	return (
		<>
			{!noHr && <hr className="m-0 p-0" />}

			<div
				{...otherProps}
				className={`text-${color} cursor-pointer d-flex align-items-center px-${paddingX} py-${paddingY}`}
				onClick={onClick}
			>
				<Tag className="mb-0 mr-auto">{label}</Tag>

				<FontAwesomeIcon
					icon={isActive ? faChevronUp : faChevronDown}
				/>
			</div>
		</>
	);
};

export type DrawerProps = {
	isActive: boolean;
	expandDuration?: number;
	tag?: keyof JSX.IntrinsicElements;
	showOverflow?: boolean;
	onRest?: (...args: unknown[]) => unknown;
	overflow?: string;
	footer?: boolean;
};

export const Drawer: React.FC<
	DrawerProps & React.HTMLAttributes<HTMLOrSVGElement>
> = ({
	children,
	tag: Tag = 'div',
	isActive,
	expandDuration = 200,
	showOverflow,
	onRest,
	footer: hasFooter = false,
	...otherProps
}) => {
	const [contentHeight, setContentHeight] = useState(0);
	const [ref, { height }] = useMeasure<HTMLDivElement>();
	const throttledIsActive = useThrottleFn<boolean, [boolean]>(
		isActive => isActive,
		expandDuration,
		[isActive]
	);
	const expand = useSpring({
		height: throttledIsActive ? `${contentHeight}px` : '0px',
		onRest
	});

	useEffect(() => {
		const resizeAction = () => setContentHeight(height);

		// Sets initial height
		setContentHeight(height);

		// Adds resize event listener
		window.addEventListener('resize', resizeAction);

		// Clean-up
		return window.removeEventListener('resize', resizeAction);
	}, [height]);

	let footer;

	const childArray = React.Children.toArray(children);
	const header = childArray.shift();

	if (hasFooter) {
		footer = childArray.pop();
	}

	const others = childArray;

	return (
		<Tag {...otherProps} style={otherProps.style}>
			{header}

			<animated.div
				style={{
					...expand,
					overflow: showOverflow ? 'auto' : 'hidden',
					paddingBottom: 0
				}}
			>
				<div ref={ref}>{others}</div>
			</animated.div>

			<div>{hasFooter && footer}</div>
		</Tag>
	);
};

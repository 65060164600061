import React, { useState, useEffect, useRef } from 'react';

import actions from '../../../../domains/UI/actions';

import { BooleanInput, Table, TableDropdown, TextInput } from '../..';
import { Modal } from '../../../Generic';

import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import api from '../../../../worker';
import { PercomaticLoading, SaveButton } from '../../..';

// import {
// 	ticketNameTooltip,
// 	servingsTooltip,
// 	suppressNameFromRecipeTooltip,
// 	isVariableQuantityTooltip,
// 	staticTooltip,
// 	unitTooltip,
// 	baseComponentTooltip,
// 	componentModifierTooltip,
// 	componentVersionTooltip,
// 	inventoryItemTooltip
// } from '../../tooltips';

function Body() {
	const toggleModal = actions.useToggleModal();

	const [componentId, setComponentId] = useState('');

	const [name, setName] = useState('');

	const [, setSuppressNameFromRecipe] = useState(false);

	const [ticketName, setTicketName] = useState('');

	const [componentUnits, setComponentUnits] = useState<
		Percomatic.Serialized.Unit[]
	>([]);
	const [units, setUnits] = useState<Percomatic.Serialized.Unit[]>([]);

	const [componentInventoryItem, setComponentInventoryItem] = useState<
		Percomatic.Serialized.InventoryItem[]
	>([]);
	const [inventoryItems, setInventoryItems] = useState<
		Percomatic.Serialized.InventoryItem[]
	>([]);

	const [componentVersions, setComponentVersions] = useState<
		Percomatic.Serialized.ComponentVersion[]
	>([]);

	const [componentVersion, setComponentVersion] = useState<
		Percomatic.Serialized.ComponentVersion[]
	>([]);

	const [componentModifier, setComponentModifier] = useState<
		Percomatic.Serialized.Modifier[]
	>([]);

	const [components, setComponents] = useState<
		Percomatic.Serialized.Component[]
	>([]);

	const [baseComponent, setBaseComponent] = useState<
		Percomatic.Serialized.Component[]
	>([]);

	const [modifiers, setModifiers] = useState<
		Percomatic.Serialized.Modifier[]
	>([]);

	const [servings, setServings] = useState('');
	const [, setIsVariableQuantity] = useState(false);
	const [, setIsStatic] = useState(false);

	useEffect(() => {
		(async () => {
			const units = await api.getUnits();
			const modifiers = await api.getModifiers();
			const inventoryItems = await api.getInventoryItems();
			const components = await api.getComponents();
			const componentVersions = await api.getComponentVersions();

			setUnits(units);
			setModifiers(modifiers);
			setInventoryItems(inventoryItems);
			setComponents(components);
			setComponentVersions(componentVersions);
			setComponentId(String(components[components.length - 2].id + 1));
		})();
	}, []);

	// const newComponentData = {
	// 	componentId: Number(componentId),
	// 	name: name,
	// 	suppressNameFromRecipe,
	// 	ticketName: ticketName,
	// 	unitId: Number(componentUnits[0]?.id),
	// 	inventoryItemId: Number(componentInventoryItem[0]),
	// 	servings: Number(servings),
	// 	componentVersionId: componentVersion[0]
	// 		? componentVersion[0].id
	// 		: undefined,
	// 	baseComponentId: baseComponent[0] ? baseComponent[0].id : undefined,
	// 	isVariableQuantity,
	// 	static: isStatic,
	// 	modifier: 1
	// };

	const preventDuplicates = (
		array: (
			| string
			| Percomatic.Serialized.Unit
			| Percomatic.Serialized.InventoryItem
		)[]
	) => {
		const valuesSoFar = [];
		for (let i = 0; i < array.length; ++i) {
			const value = array[i];
			if (valuesSoFar.indexOf(value) !== -1) {
				array.pop();
			}
			valuesSoFar.push(value);
		}
		return false;
	};

	const validated = useRef(false);

	useEffect(() => {
		isNaN(Number(servings))
			? (validated.current = false)
			: (validated.current = true);
	}, [servings]);

	preventDuplicates(componentUnits);
	preventDuplicates(componentInventoryItem);

	// const [addComponent] = useAddComponentMutation({
	// 	variables: { component: newComponentData }
	// });

	const submitNewComponent = () => {
		// addComponent();
		setComponentId('');
		setName('');
		// setSuppressNameFromRecipe(false);
		setTicketName('');
		setComponentUnits([]);
		setComponentInventoryItem([]);
		setServings('');
		setComponentVersion([]);
		setBaseComponent([]);
		setIsVariableQuantity(false);
		setIsStatic(false);
	};

	return (
		<>
			<Table>
				<TextInput
					label="Id"
					state={componentId}
					setState={setComponentId}
					readOnly={true}
				/>

				<TextInput label="Name" state={name} setState={setName} />
				<TextInput
					label="Ticket Name"
					state={ticketName}
					setState={setTicketName}
					content={'ticketNameTooltip'}
				/>
				<TextInput
					label="Servings"
					state={servings}
					setState={setServings}
					content={'servingsTooltip'}
				/>

				<BooleanInput
					label="Suppress Name From Recipe"
					setState={setSuppressNameFromRecipe}
					content={'suppressNameFromRecipeTooltip'}
				/>
				<BooleanInput
					label="Is Variable Quantity"
					setState={setIsVariableQuantity}
					content={'isVariableQuantityTooltip'}
				/>
				<BooleanInput
					label="Static"
					setState={setIsStatic}
					content={'staticTooltip'}
				/>
			</Table>
			<TableDropdown
				state={componentUnits}
				dropdownItems={units}
				label="Unit"
				showDropdownButton={false}
				singleRelationship={true}
				onDelete={(_, key) =>
					setComponentUnits(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setComponentUnits([item])}
				content={'unitTooltip'}
			/>

			<TableDropdown
				singleRelationship={true}
				state={baseComponent}
				dropdownItems={components}
				label="Base Component"
				onDelete={(_, key) =>
					setBaseComponent(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setBaseComponent([item])}
				content={'baseComponentTooltip'}
			/>

			<TableDropdown
				singleRelationship={true}
				state={componentModifier}
				dropdownItems={modifiers}
				label="Component Modifier"
				onDelete={(_, key) =>
					setComponentModifier(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setComponentModifier([item])}
				content={'componentModifierTooltip'}
			/>

			<TableDropdown
				singleRelationship={true}
				state={componentVersion}
				dropdownItems={componentVersions}
				showDropdownButton={false}
				label="Component Version"
				onDelete={(_, key) =>
					setComponentVersion(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setComponentVersion([item])}
				content={'componentVersionTooltip'}
			/>

			<TableDropdown
				state={componentInventoryItem}
				dropdownItems={inventoryItems}
				label="Inventory Item"
				showDropdownButton={true}
				singleRelationship={true}
				onDelete={(_, key) =>
					setComponentInventoryItem(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setComponentInventoryItem([item])}
				content={'inventoryItemTooltip'}
			/>

			<Modal
				showConfirmationButton={true}
				confirmationMessage="Yes"
				confirmationButtonHandler={submitNewComponent}
			>
				{validated.current === false ? (
					<>
						<h1 className="mb-4 text-danger">
							There was a problem
						</h1>
						<p>The following properties must be a number:</p>
						<ul>
							<li>Servings</li>
						</ul>
					</>
				) : (
					<>
						<h1>Are you sure?</h1>
						<p>This action will create a new component.</p>
					</>
				)}
			</Modal>
			<SaveButton onClick={() => toggleModal()} />
		</>
	);
}

export const Component: React.FC = () => {
	return (
		<div>
			<PercomaticLoading loaded children={<Body />} />
		</div>
	);
};

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Percomatic, utils } from '@biggby-coffee/percomatic-typescript';
import { v4 } from 'uuid';

import { useConfigQuery } from '../../../graphql';

import Product from '../../../domains/Product';
import Session from '../../../domains/Session';
type JSONTables = Omit<
	Percomatic.Mutable<Percomatic.Database.Tables>,
	'config'
>;

type OutputLocation = {
	tableName: keyof JSONTables;
	hash: string;
	fileName: string;
};

export const useFetchAllProductData = (): void => {
	const dispatch = useDispatch();
	const [startedInitAndLink, setStartedInitAndLink] = useState(false);

	const [tables, setTables] = useState<JSONTables>();
	const initAndLink = Product.actions.useInitAndLink();

	const store = Session.selectors.useSelectStore();
	const { data, loading, error } = useConfigQuery({
		fetchPolicy: 'no-cache'
	});

	useEffect(() => {
		const fetchJson = async () => {
			// append uuid as query param to force refetch every time
			const tableNames = (await (
				await fetch('/product-data/tableNames.json?v=' + v4())
			).json()) as OutputLocation[];

			const output: Partial<JSONTables> = {};

			for (let i = 0; i < tableNames.length; ++i) {
				const { tableName: name, fileName } = tableNames[i];

				const action: ProductDomain.Actions.UpdateLoadingQueue = {
					type: 'UPDATE_LOADING_QUEUE',
					payload: {
						initAndLinkProgress: Math.floor(
							(i / tableNames.length) * 50
						),
						initAndLinkProgressMessage: `Downloading ${name} data...`
					}
				};

				dispatch(action);

				const tableJson = await (
					await fetch('/product-data/' + fileName)
				).json();

				output[name] = tableJson;
			}

			setTables(output as JSONTables);
		};

		fetchJson();
	}, [dispatch]);

	useEffect(() => {
		console.log('useFetchAllProductData: useEffect (2)');

		if (
			tables !== undefined &&
			data &&
			data.tables &&
			!startedInitAndLink
		) {
			initAndLink(
				{
					...utils.mapTables({
						...tables,
						config: [],
						itemPrices: data.tables.itemPrices,
						modifierPrices: data.tables.modifierPrices
					}),
					config: data.tables.config
				},
				store?.storeId ?? 995
			);
			setStartedInitAndLink(true);
		}
	}, [data, store, startedInitAndLink, initAndLink, tables]);

	useEffect(() => {
		if (error) throw error;

		if (loading) {
			dispatch({
				type: 'STARTED_LOADING_ALL_PRODUCT_DATA'
			});
		} else if (data) {
			dispatch({
				type: 'ALL_PRODUCT_DATA_LOADED',
				payload: data
			});
		}
	}, [dispatch, loading, data, error]);
};

import React, { useState, useEffect } from 'react';

import actions from '../../../../domains/UI/actions';

import { BooleanInput, TableDropdown, Table, TextInput } from '../../';
import { Modal } from '../../../Generic';

import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import api from '../../../../worker';
import { PercomaticLoading, SaveButton } from '../../..';

// import {
// 	suppressNameFromRecipeTooltip,
// 	unitTooltip,
// 	componentGroupTooltip
// } from '../../tooltips';

function Body() {
	const toggleModal = actions.useToggleModal();

	const [name, setName] = useState('');
	const [suppressNameFromRecipe, setSuppressNameFromRecipe] = useState(false);
	const [id, setId] = useState('');

	const [componentGroupUnits, setComponentGroupUnits] = useState<
		Percomatic.Serialized.Unit[]
	>([]);

	const [componentGroups, setComponentGroups] = useState<
		Percomatic.Serialized.ComponentGroup[]
	>([]);

	const [parentGroup, setParentGroup] = useState<
		Percomatic.Serialized.ComponentGroup[]
	>([]);

	const [units, setUnits] = useState<Percomatic.Serialized.Unit[]>([]);

	useEffect(() => {
		(async () => {
			const units = await api.getUnits();
			const componentGroups = await api.getComponentGroups();

			setUnits(units);
			setId(String(componentGroups[componentGroups.length - 2].id + 1));
			setComponentGroups(componentGroups);
		})();
	}, []);

	// const newComponentGroupData = {
	// 	name: name,
	// 	parentGroupId: parentGroup[0] ? parentGroup[0].id : undefined,
	// 	suppressNameFromRecipe,
	// 	componentGroupId: Number(id),
	// 	unitId: Number(componentGroupUnits[0]?.id)
	// };

	// const [addComponentGroup] = useAddComponentGroupMutation({
	// 	variables: { componentGroup: newComponentGroupData }
	// });

	const submitNewComponentGroup = () => {
		// addComponentGroup();
		setName('');
		setParentGroup([]);
		setSuppressNameFromRecipe(false);
		setComponentGroupUnits([]);
		setId('');
	};

	return (
		<>
			<Table>
				<TextInput
					label="Id"
					state={id}
					setState={setId}
					readOnly={true}
				/>

				<TextInput label="Name" state={name} setState={setName} />

				<BooleanInput
					label="Suppress Name From Recipe"
					setState={setSuppressNameFromRecipe}
					selected={suppressNameFromRecipe}
					content={'suppressNameFromRecipeTooltip'}
				/>
			</Table>

			<TableDropdown
				dropdownItems={units}
				state={componentGroupUnits}
				label="Unit"
				showDropdownButton={false}
				singleRelationship={true}
				onDelete={(_, key) =>
					setComponentGroupUnits(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setComponentGroupUnits([item])}
				content={'unitTooltip'}
			/>

			<TableDropdown
				dropdownItems={componentGroups}
				state={parentGroup}
				label="Parent Group"
				singleRelationship={true}
				showDropdownButton={false}
				onDelete={(_, key) =>
					setParentGroup(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setParentGroup([item])}
				content={'componentGroupTooltip'}
			/>

			<Modal
				showConfirmationButton={true}
				confirmationMessage="Yes"
				confirmationButtonHandler={submitNewComponentGroup}
			>
				{
					<>
						<h1>Are you sure?</h1>
						<p>This action will create a new component group.</p>
					</>
				}
			</Modal>
			<SaveButton onClick={() => toggleModal()} />
		</>
	);
}

export const ComponentGroup: React.FC = () => {
	return (
		<div>
			<PercomaticLoading loaded children={<Body />} />
		</div>
	);
};

import { ChangeEvent } from 'react';
import {
	OrderCreditCardPaymentFieldsFragment,
	OrderGiftCardPaymentFieldsFragment,
	RefundDestinationInput
} from '../../graphql';

export type DestinationProps = RefundDestinationInput[];

const destinations: DestinationProps = [];

export const handleRefundDestinationInput = (
	e: ChangeEvent<HTMLInputElement>,
	payment:
		| OrderCreditCardPaymentFieldsFragment
		| OrderGiftCardPaymentFieldsFragment,
	setRefundDestinations: (setDestinations: DestinationProps) => void
): void => {
	if (
		destinations.some(destination => destination.paymentId === payment.id)
	) {
		for (let i = 0; i < destinations.length; i++) {
			if (destinations[i].paymentId === payment.id) {
				if (e.target.value) {
					destinations[i] = {
						...destinations[i],
						amount: Number(
							(Number(e.target.value.slice(1)) * 100).toFixed(0)
						)
					};
				} else {
					// Deletes destination if 'Amount to refund' input is deleted completely
					destinations.splice(i, 1);
				}
			}
		}
	} else {
		destinations.push({
			amount: Number((Number(e.target.value.slice(1)) * 100).toFixed(0)),
			paymentId: payment.id,
			paymentType:
				payment.__typename === 'OrderCreditCardPayment'
					? 'CREDIT_CARD'
					: 'GIFT_CARD'
		});
	}
	// TODO: Making sure all fields are present on destination
	setRefundDestinations(destinations);
};

import { Dispatch, SetStateAction /* , useRef */, useState } from 'react';
import { captureException } from '@sentry/react';

import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '../../Generic';

import {
	LylaQuestionFieldsFragment,
	useDeleteLylaQuestionMutation,
	useEditLylaQuestionMutation
} from '../../../graphql';

import actions from '../../../domains/UI/actions';
import { LylaQuestionInput } from '../LylaQuestionInput';

export type LylaQuestionProps = React.HTMLAttributes<HTMLDivElement> & {
	question:
		| ({
				__typename?: 'LylaQuestion' | undefined;
		  } & LylaQuestionFieldsFragment)
		| undefined;
	setLylaQuestions: Dispatch<
		SetStateAction<
			| ({
					__typename?: 'LylaQuestion' | undefined;
			  } & LylaQuestionFieldsFragment)[]
			| undefined
		>
	>;
	lylaQuestions:
		| ({
				__typename?: 'LylaQuestion' | undefined;
		  } & LylaQuestionFieldsFragment)[]
		| undefined;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	whereToShowOptions: { value: string; label: string }[];
};

export const LylaQuestion: React.FC<LylaQuestionProps> = ({
	question,
	lylaQuestions,
	setLylaQuestions,
	setIsLoading,
	whereToShowOptions
}) => {
	const toggleModal = actions.useToggleModal();

	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const [editModal, setEditModal] = useState<boolean>(false);

	const [deleteLylaQuestion] = useDeleteLylaQuestionMutation();
	const [editLylaQuestion] = useEditLylaQuestionMutation();

	console.log(question);

	const [questionInput, setQuestionInput] = useState<string>(
		question?.question || ''
	);

	const [isActive, setIsActive] = useState<boolean>(
		question?.isActive || false
	);
	const [askAgainIfSkipped, setAskAgainIfSkipped] = useState<boolean>(
		question?.askAgainIfSkipped || false
	);

	const [startDate, setStartDate] = useState<Date | undefined>(
		new Date(question?.startDate) || undefined
	);

	const [endDate, setEndDate] = useState<Date | undefined>(
		new Date(question?.endDate) || undefined
	);
	// const [endDate, setEndDate] = useState<Date | undefined>();

	const [whereToShow, setWhereToShow] = useState<number | undefined>(
		question?.whereToShow
	);

	const [idleTime, setIdleTime] = useState<number | undefined>(
		question?.idleTime
	);

	const [askFrequency, setAskFrequency] = useState<number | undefined>(
		question?.askFrequency || 0
	);

	const setLylaQuestionValues = () => {
		setQuestionInput(question?.question || '');
		setIsActive(question?.isActive || false);
		setAskAgainIfSkipped(question?.askAgainIfSkipped || false);
		setStartDate(
			question?.startDate ? new Date(question?.startDate) : undefined
		);
		setEndDate(question?.endDate ? new Date(question?.endDate) : undefined);
		setWhereToShow(question?.whereToShow);
		setIdleTime(question?.idleTime);
		setAskFrequency(question?.askFrequency);
	};

	/*
		Note: Left here for reference
	*/
	// const editQuestion = useRef(Object.create(question || null));

	return (
		<div className="lyla-question pl-5" key={question?.questionId}>
			{/*<FontAwesomeIcon
				className="text-danger icon"
				icon={faTimes}
				onClick={() => {
					toggleModal();
					setDeleteModal(true);
				}}
			/>*/}
			<FontAwesomeIcon
				className="text-warning icon"
				icon={faEdit}
				onClick={() => {
					toggleModal();
					setEditModal(true);
				}}
			/>
			<p className="question">{question?.question}</p>

			{deleteModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Yes"
					confirmationButtonHandler={async () => {
						setIsLoading(true);
						await deleteLylaQuestion({
							variables: {
								id: question?.questionId || ''
							}
						})
							.then(() => {
								setLylaQuestions(
									lylaQuestions?.filter(
										lylaQuestion =>
											lylaQuestion?.questionId !==
											question?.questionId
									)
								);
							})
							.catch(err => captureException(err));
						setIsLoading(false);
						setDeleteModal(false);
					}}
					closeMessage="No"
					closeButtonHandler={() => setDeleteModal(false)}
				>
					<>
						<h1 className="text-dark text-center">
							Are you sure you want to permanently delete this
							question?
						</h1>
					</>
				</Modal>
			)}

			{editModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Save Question"
					confirmationButtonHandler={async () => {
						if (question) {
							setIsLoading(true);
							const lylaQuestionInput = {
								questionId: question.questionId,
								question: questionInput,
								isActive,
								askAgainIfSkipped,
								askFrequency:
									Number(askFrequency) === 0
										? undefined
										: Number(askFrequency),
								idleTime:
									Number(idleTime) === 0
										? undefined
										: Number(idleTime),
								startDate: startDate ? startDate : undefined,
								endDate: endDate ? endDate : undefined,
								whereToShow:
									Number(whereToShow) === 0
										? undefined
										: Number(whereToShow)
							};

							console.log(lylaQuestionInput);

							editLylaQuestion({
								variables: {
									question: lylaQuestionInput
								}
							})
								.then(() => {
									/* Note: setLylaQuestions is real picky about how you
										update the data, so be careful in changing this */
									const questions = lylaQuestions?.filter(
										lylaQuestion =>
											lylaQuestion?.questionId !==
											question?.questionId
									);
									questions?.push(lylaQuestionInput);
									setLylaQuestions(questions);
									setIsLoading(false);
									setEditModal(false);
								})
								.catch(err => {
									console.error(err);
									captureException(err);
									setIsLoading(false);
									setEditModal(false);
								});
						}
					}}
					closeMessage="Cancel"
					closeButtonHandler={() => {
						setEditModal(false);
						setLylaQuestionValues();
					}}
				>
					<>
						<h1 className="text-dark text-center">
							Edit Lyla Question
						</h1>
						<LylaQuestionInput
							question={questionInput}
							setQuestion={setQuestionInput}
							isActive={isActive}
							setIsActive={setIsActive}
							askAgainIfSkipped={askAgainIfSkipped}
							setAskAgainIfSkipped={setAskAgainIfSkipped}
							idleTime={idleTime}
							setIdleTime={setIdleTime}
							askFrequency={askFrequency}
							setAskFrequency={setAskFrequency}
							whereToShow={whereToShow}
							setWhereToShow={setWhereToShow}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							whereToShowOptions={whereToShowOptions}
						/>
					</>
				</Modal>
			)}
		</div>
	);
};

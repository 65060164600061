import React, { useState } from 'react';

import { Error, Loading } from '..';

import Network from '../../domains/Network';
import Session from '../../domains/Session';

import {
	EmployeeStoresFieldsFragment,
	useEmployeeStoresQuery
} from '../../graphql';

type ClickHandler = (store: EmployeeStoresFieldsFragment) => void;

export type StoresListProps = {
	onClick?: ClickHandler;
	redirect?: string;
};

export const StoresList: React.FC<StoresListProps> = ({
	onClick,
	redirect
}) => {
	const setStoreId = Session.actions.useSetStoreId(redirect);
	const [storeSelected, setStoreSelected] = useState(false);
	const { data, error } = useEmployeeStoresQuery();
	const settingStoreId = Network.selectors.useSelectSettingStoreId();

	if (error) {
		return <Error error={error.message} />;
	}

	if (!data || settingStoreId || storeSelected) {
		return <Loading />;
	}

	const stores = data.session.employee?.stores;

	if (!stores) {
		return <Error error="Not authenticated." />;
	}

	if (stores.length <= 0) {
		return <Error error="No stores found." />;
	}

	const defaultClickHandler: ClickHandler = store => {
		setStoreId(store.storeId);
		setStoreSelected(true);
	};

	return (
		<div className="stores-list overflow-auto h-100">
			<div className="grid-100">
				{stores.map((store, key) => (
					<button
						className="btn btn-info"
						onClick={() =>
							onClick
								? onClick(store)
								: defaultClickHandler(store)
						}
						key={key}
					>
						{store.storeId}
					</button>
				))}
			</div>
		</div>
	);
};

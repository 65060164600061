import { useParams } from 'react-router-dom';

import { PercomaticLoading, ProductBody } from '../components';

import Product from '../domains/Product';

import '../sass/Product.scss';

export default function ProductView(): JSX.Element {
	const params = useParams<{ groupId: string; productId: string }>();

	const loaded = Product.actions.useSelectProduct(params.productId);

	return (
		<PercomaticLoading
			redirect={`/menu/${params.groupId}/${params.productId}`}
			loaded={loaded}
			className="product-view"
		>
			{loaded && <ProductBody />}
		</PercomaticLoading>
	);
}

import React, { useState } from 'react';
import {
	BrandStandardEdgeFieldsFragment,
	useCreateDefaultNoticeMutation
} from '../../../graphql';
import { Modal, TextArea, Input, Select } from '../../Generic';
import actions from '../../../domains/UI/actions';

export type AddDefaultNoticeProps = {
	brandStandards: BrandStandardEdgeFieldsFragment[];
	storeId: string;
	defaultDate?: Date;
};

export const AddDefaultNotice: React.FC<AddDefaultNoticeProps> = ({
	brandStandards,
	storeId,
	defaultDate = new Date()
}) => {
	const toggleModal = actions.useToggleModal();

	const [newDefaultNotices, setNewDefaultNotices] = useState<{
		description: string;
		defaultDate: string;
		notificationMethod: string;
		brandStandardViolated: string;
		reportedBy: string;
		resolved: boolean;
	}>({
		description: '',
		defaultDate: defaultDate.toISOString().slice(0, 10),
		notificationMethod: '',
		brandStandardViolated: '',
		reportedBy: '',
		resolved: false
	});

	const [saveModal, setSaveModal] = useState<boolean>(false);

	const [resetModal, setResetModal] = useState<boolean>(false);

	const [createDefaultNotice] = useCreateDefaultNoticeMutation();

	const handleChange = (e: {
		target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
	}) => {
		const { name, value } = e.target;

		setNewDefaultNotices({
			...newDefaultNotices,
			[name]: [value]
		});
	};

	const handleReset = () => {
		setNewDefaultNotices({
			description: '',
			defaultDate: defaultDate.toISOString().slice(0, 10),
			notificationMethod: '',
			brandStandardViolated: '',
			reportedBy: '',
			resolved: false
		});
		setResetModal(false);
	};

	const handleSaveClick = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		toggleModal();
		setSaveModal(true);
	};

	const handleSave = () => {
		createDefaultNotice({
			variables: {
				defaultNotice: {
					description: String(newDefaultNotices.description),
					defaultDate: new Date(newDefaultNotices.defaultDate),
					notificationMethod: String(
						newDefaultNotices.notificationMethod
					),
					brandStandardViolated: Number(
						newDefaultNotices.brandStandardViolated
					),
					reportedBy: String(newDefaultNotices.reportedBy),
					resolved: newDefaultNotices.resolved,
					storeId: Number(storeId)
				}
			}
		});
		setNewDefaultNotices({
			description: '',
			defaultDate: '',
			notificationMethod: '',
			brandStandardViolated: '',
			reportedBy: '',
			resolved: false
		});
		window.location.reload();
	};

	return (
		<div className="px-3">
			<h3 className="my-0">Add Default Notices</h3>
			<hr />
			<form className="default-notices-form" onSubmit={handleSaveClick}>
				<fieldset className="default-notices-fieldset">
					<div className="default-notices-row-70">
						<TextArea
							classname="default-notices-item"
							label="Description"
							name="description"
							value={newDefaultNotices.description}
							change={handleChange}
							required={true}
						/>
						<Input
							classname="default-notices-item"
							label="Date of Default"
							type="date"
							name="defaultDate"
							value={newDefaultNotices.defaultDate}
							change={handleChange}
							required={true}
						/>
					</div>

					<div className="default-notices-row">
						<Input
							classname="default-notices-item mr-3"
							label="Reported By"
							type="text"
							name="reportedBy"
							value={newDefaultNotices.reportedBy}
							change={handleChange}
							required={true}
						/>
						<Select
							classname="default-notices-item"
							label="Method of Notification"
							name="notificationMethod"
							value={newDefaultNotices.notificationMethod}
							change={handleChange}
							required={true}
							defaultOptions={true}
							options={[
								{
									optValue: 'Store Visit',
									optName: 'Store Visit'
								},
								{
									optValue: 'Customer Feedback',
									optName: 'Customer Feedback'
								},
								{
									optValue: 'Social Media',
									optName: 'Social Media'
								},
								{
									optValue: 'Others',
									optName: 'Others'
								}
							]}
						/>
					</div>

					<div className="default-notices-row-100">
						<div className="default-notices-item">
							<label>Brand Standard Violated</label>
							<select
								className="brand-standard-select"
								name="brandStandardViolated"
								value={newDefaultNotices.brandStandardViolated}
								onChange={handleChange}
								required
							>
								<option disabled value="">
									-- select an option --{' '}
								</option>
								{brandStandards.map(brandStandard => (
									<option
										key={brandStandard.node.id}
										value={
											brandStandard.node.brandStandardId
										}
									>
										{brandStandard.node.description}
									</option>
								))}
							</select>
						</div>
					</div>

					<hr />

					<div className="default-notices-submit">
						<button
							type="reset"
							className="btn btn-primary"
							onClick={() => {
								toggleModal();
								setResetModal(true);
							}}
						>
							Reset
						</button>
						<button type="submit" className="btn btn-success mx-3">
							Save
						</button>
					</div>
				</fieldset>
			</form>

			{saveModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Save"
					confirmationButtonHandler={handleSave}
					closeMessage="Cancel"
					closeButtonHandler={() => setSaveModal(false)}
				>
					<>
						<h1 className="text-dark text-center">
							Are you sure you want to save?
						</h1>
						<p className="text-dark">
							The default{' '}
							<em>"{newDefaultNotices.description}"</em>&nbsp;
							will be save.
						</p>
					</>
				</Modal>
			)}

			{resetModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Yes"
					confirmationButtonHandler={handleReset}
					closeMessage="No"
					closeButtonHandler={() => setResetModal(false)}
				>
					<>
						<h1 className="text-dark text-center">
							Are you sure you want to reset?
						</h1>
						<p className="text-dark">The default will be reset.</p>
					</>
				</Modal>
			)}
		</div>
	);
};

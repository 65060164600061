import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { proxy } from 'comlink';
import { addBreadcrumb, captureException, Severity } from '@sentry/react';

import { useInterval } from '../../domains/Product/services';

import Order from '../../domains/Order';
import Product from '../../domains/Product';
import UI from '../../domains/UI';

import api from '../../worker';
import { OrderItemInput } from '../../graphql';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

const mapTransItem = (
	transItem: Percomatic.Serialized.TransItem
): OrderItemInput => ({
	id: transItem.id,
	itemId: transItem.item.id,
	price: Number((transItem.price * 100).toFixed(0)),
	taxRate: transItem.taxRate,
	taxAmount: 0,
	modifiers: transItem.modifiers.map(transModifier => ({
		id: transModifier.id,
		modifierId: transModifier.modifier.id,
		price: Number((transModifier.price * 100).toFixed(0))
	}))
});

const actions: ProductDomain.ProductDomainActions = {
	useResetProduct: () => {
		const dispatch = useDispatch();

		return () => {
			const action: ProductDomain.Actions.ResetProduct = {
				type: 'RESET_PRODUCT'
			};

			dispatch(action);

			addBreadcrumb({
				level: 'info' as Severity.Info,
				timestamp: Date.now(),
				category: 'product',
				message: 'resetProduct'
			});
		};
	},
	useAddToCart: () => {
		const addToCart = Order.actions.useAddToCart();
		const showAlert = UI.actions.useAddAlert();

		const transItem = useSelector(
			(state: RootState) => state.product.percomatic.transItem
		);

		return (quantity: number, redirect: string) => {
			addBreadcrumb({
				level: 'info' as Severity.Info,
				timestamp: Date.now(),
				category: 'product',
				message: 'addToCart',
				data: {
					quantity,
					redirect,
					transItem
				}
			});

			if (!transItem) {
				throw new Error('No transItem to add to cart.');
			}

			const alert: UIDomain.NewAlert = {
				type: 'success',
				autoDismiss: true,
				message: 'Added product to cart.'
			};

			showAlert(alert);
			addToCart(mapTransItem(transItem), redirect);
		};
	},
	useInitAndLink: () => {
		const initProgress = useSelector<RootState, number>(
			state => state.product.initAndLinkProgress
		);

		const loadingQueue = useSelector<
			RootState,
			Pick<
				ProductDomain.State,
				'initAndLinkProgress' | 'initAndLinkProgressMessage'
			>[]
		>(({ product: { loadingQueue } }) => loadingQueue);

		const dispatch = useDispatch();

		const clearer = useInterval(() => {
			if (loadingQueue.length > 0) {
				const action: ProductDomain.Actions.UpdateInitProgress = {
					type: 'UPDATE_INIT_PROGRESS'
				};

				dispatch(action);
			}
		}, 50);

		useEffect(() => {
			if (clearer && initProgress === 100) {
				clearInterval(clearer);
			}
		}, [initProgress, clearer]);

		return (tables, storeId) => {
			addBreadcrumb({
				level: 'info' as Severity.Info,
				timestamp: Date.now(),
				category: 'product',
				message: 'initAndLink started'
			});

			api.initAndLink(
				tables,
				storeId,
				proxy(
					(
						payload: Pick<
							ProductDomain.State,
							'initAndLinkProgress' | 'initAndLinkProgressMessage'
						>
					) => {
						const action: ProductDomain.Actions.UpdateLoadingQueue =
							{
								type: 'UPDATE_LOADING_QUEUE',
								payload: {
									...payload,
									initAndLinkProgress:
										50 +
										Math.floor(
											payload.initAndLinkProgress / 2
										)
								}
							};

						dispatch(action);

						addBreadcrumb({
							level: 'info' as Severity.Info,
							timestamp: Date.now(),
							category: 'product',
							message: 'initAndLink progress update',
							data: { action }
						});
					}
				)
			)
				.then(() => {
					const action: ProductDomain.Actions.InitAndLink = {
						type: 'INIT_AND_LINK'
					};

					dispatch(action);

					addBreadcrumb({
						level: 'info' as Severity.Info,
						timestamp: Date.now(),
						category: 'product',
						message: 'initAndLink complete'
					});
				})
				.catch(err => captureException(err));
		};
	},
	useSelectProduct: (productId: string | number) => {
		const { initAndLinkProgress } =
			Product.selectors.useSelectInitAndLinkProgress();

		const dispatch = useDispatch();

		useEffect(() => {
			if (initAndLinkProgress === 100) {
				addBreadcrumb({
					level: 'info' as Severity.Info,
					timestamp: Date.now(),
					category: 'product',
					message: 'selectProduct',
					data: {
						productId
					}
				});

				api.selectProduct(Number(productId))
					.then(payload => {
						const action: ProductDomain.Actions.SelectProduct = {
							type: 'SELECT_PRODUCT',
							payload
						};

						dispatch(action);
					})
					.catch(err => captureException(err));
			}
		}, [initAndLinkProgress, dispatch, productId]);

		return initAndLinkProgress === 100;
	},
	useSelectModifier: () => {
		const dispatch = useDispatch();
		return (modifierGroupIndex, modifierIndex) => {
			console.log('Product: actions: useChangeSize: changeSize');

			dispatch({ type: 'START_MODIFYING' });

			addBreadcrumb({
				level: 'info' as Severity.Info,
				timestamp: Date.now(),
				category: 'product',
				message: 'selectModifier',
				data: {
					modifierGroupIndex,
					modifierIndex
				}
			});

			api.selectModifier(modifierGroupIndex, modifierIndex)
				.then(payload => {
					const action: ProductDomain.Actions.SelectModifier = {
						type: 'SELECT_MODIFIER',
						payload
					};

					dispatch(action);
				})
				.catch(err => captureException(err));
		};
	},
	useDeselectModifier: () => {
		const dispatch = useDispatch();
		return (modifierGroupIndex, modifierIndex) => {
			console.log('Product: actions: useChangeSize: changeSize');

			dispatch({ type: 'START_MODIFYING' });

			addBreadcrumb({
				level: 'info' as Severity.Info,
				timestamp: Date.now(),
				category: 'product',
				message: 'deselectModifier',
				data: {
					modifierGroupIndex,
					modifierIndex
				}
			});

			api.deselectModifier(modifierGroupIndex, modifierIndex)
				.then(payload => {
					const action: ProductDomain.Actions.DeselectModifier = {
						type: 'DESELECT_MODIFIER',
						payload
					};

					dispatch(action);
				})
				.catch(err => captureException(err));
		};
	},
	useIncreaseModifierLevel: () => {
		const dispatch = useDispatch();
		return (times, modifierScaleIndex) => {
			console.log('Product: actions: useChangeSize: changeSize');

			dispatch({ type: 'START_MODIFYING' });

			addBreadcrumb({
				level: 'info' as Severity.Info,
				timestamp: Date.now(),
				category: 'product',
				message: 'increaseModifierLevel',
				data: {
					times,
					modifierScaleIndex
				}
			});

			api.increaseModifierLevel(times, modifierScaleIndex)
				.then(payload => {
					const action: ProductDomain.Actions.IncreaseModifierLevel =
						{
							type: 'INCREASE_MODIFIER_LEVEL',
							payload
						};

					dispatch(action);
				})
				.catch(err => captureException(err));
		};
	},
	useDecreaseModifierLevel: () => {
		const dispatch = useDispatch();
		return (times, modifierScaleIndex) => {
			console.log('Product: actions: useChangeSize: changeSize');

			dispatch({ type: 'START_MODIFYING' });

			addBreadcrumb({
				level: 'info' as Severity.Info,
				timestamp: Date.now(),
				category: 'product',
				message: 'decreaseModifierLevel',
				data: {
					times,
					modifierScaleIndex
				}
			});

			api.decreaseModifierLevel(times, modifierScaleIndex)
				.then(payload => {
					const action: ProductDomain.Actions.DecreaseModifierLevel =
						{
							type: 'DECREASE_MODIFIER_LEVEL',
							payload
						};

					dispatch(action);
				})
				.catch(err => captureException(err));
		};
	},
	useChangeSize: () => {
		const dispatch = useDispatch();

		return index => {
			console.log('Product: actions: useChangeSize: changeSize');
			dispatch({ type: 'START_MODIFYING' });

			addBreadcrumb({
				level: 'info' as Severity.Info,
				timestamp: Date.now(),
				category: 'product',
				message: 'changeSize',
				data: {
					index
				}
			});

			api.changeSize(index)
				.then(payload => {
					const action: ProductDomain.Actions.ChangeSize = {
						type: 'CHANGE_SIZE',
						payload
					};

					dispatch(action);
				})
				.catch(err => captureException(err));
		};
	},
	useChangeVersion: () => {
		const dispatch = useDispatch();

		return index => {
			console.log('Product: actions: useChangeVersion: changeVersion');
			dispatch({ type: 'START_MODIFYING' });

			addBreadcrumb({
				level: 'info' as Severity.Info,
				timestamp: Date.now(),
				category: 'product',
				message: 'changeVersion',
				data: {
					index
				}
			});

			api.changeVersion(index)
				.then(payload => {
					const action: ProductDomain.Actions.ChangeVersion = {
						type: 'CHANGE_VERSION',
						payload
					};

					dispatch(action);
				})
				.catch(err => captureException(err));
		};
	}
};

export default actions;

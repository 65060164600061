import { RefundFieldsFragment, RefundDestinationInput } from '../../graphql';

// Returns all refund destinations and amount already refunded (if requested) for a particular payment id
export const orderRefundsToDestinations = (
	orderRefunds: RefundFieldsFragment[] | undefined,
	paymentId: string
): [RefundDestinationInput[], number] => {
	const destinations: RefundDestinationInput[] = [];

	if (orderRefunds) {
		for (let i = 0; i < orderRefunds.length; i++) {
			const refund = orderRefunds[i];
			for (let j = 0; j < refund.destinations.length; j++) {
				if (refund.destinations[j].payment.id === paymentId) {
					const paymentType =
						refund.destinations[j].payment.__typename;
					destinations.push({
						paymentId: refund.destinations[j].payment.id,
						paymentType:
							paymentType === 'OrderCreditCardPayment'
								? 'CREDIT_CARD'
								: 'GIFT_CARD',
						amount: refund.destinations[j].amount
					});
				}
			}
		}
	}

	// Add up amount already refunded for this destination's payment id
	const amountRefunded = destinations.reduce(
		(sum, refundDestination) => sum + refundDestination.amount,
		0
	);

	return [destinations, amountRefunded];
};

import React, { useState, useEffect } from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import UI from '../domains/UI';
import {
	BrandStandardEdgeFieldsFragment,
	useBrandStandardsLazyQuery,
	useBrandStandardsQuery
} from '../graphql';
import { Error, Loading, WrapApollo } from '../components';
import {
	BrandStandardsTable,
	BrandStandardDetails,
	AddBrandStandard
} from '../components/BrandStandards';

const BrandStandardsInformation: React.FC = () => {
	UI.actions.useSetOrientation('landscape');

	const [brandStandards, setBrandStandards] = useState<
		BrandStandardEdgeFieldsFragment[]
	>([]);

	const [currentOffset, setCurrentOffset] = useState<string>();

	const [hasLoadMore, setHasLoadMore] = useState<boolean>(false);

	const { data, loading, error } = useBrandStandardsQuery({
		variables: {
			first: 10,
			orderBy: 'brandStandardId',
			orderDirection: 'asc'
		}
	});

	const [
		brandStandardQuery,
		{ data: lazyData, loading: lazyLoading, error: lazyError }
	] = useBrandStandardsLazyQuery();

	useEffect(() => {
		setCurrentOffset(data?.brandStandards.pageInfo.endCursor);
		if (data?.brandStandards) {
			setBrandStandards(data.brandStandards.edges);
		}

		if (data?.brandStandards.pageInfo) {
			setHasLoadMore(data.brandStandards.pageInfo.hasNextPage);
		}
	}, [data]);

	useEffect(() => {
		setCurrentOffset(lazyData?.brandStandards.pageInfo.endCursor);

		setBrandStandards(brandStandards => {
			if (lazyData?.brandStandards) {
				return [...brandStandards, ...lazyData.brandStandards.edges];
			}
			return brandStandards;
		});

		if (lazyData?.brandStandards.pageInfo) {
			setHasLoadMore(lazyData.brandStandards.pageInfo.hasNextPage);
		}
	}, [lazyData]);

	if (error) {
		return <Error error={error.message} />;
	}

	if (lazyError) {
		return <Error error={lazyError.message} />;
	}

	return (
		<div className="text-light py-3">
			<div className="d-flex justify-content-between mb-2">
				<h1 className="px-3 m-0">Brand Standards</h1>
				<Link
					className="btn btn-secondary mr-3 align-self-center"
					to="/brand-standards/add-brand-standard"
				>
					Add Brand Standard
				</Link>
			</div>

			<WrapApollo
				data={brandStandards}
				loading={loading}
				component={BrandStandardsTable}
			/>

			{lazyLoading && <Loading />}
			{!lazyLoading && !loading && hasLoadMore && (
				<div className="d-flex justify-content-center">
					<button
						className="btn btn-lg bg-primary text-light"
						onClick={() =>
							brandStandardQuery({
								variables: {
									first: 10,
									after: currentOffset,
									orderBy: 'brandStandardId',
									orderDirection: 'asc'
								}
							})
						}
					>
						Load More
					</button>
				</div>
			)}
		</div>
	);
};

export const BrandStandards = React.memo(() => {
	return (
		<div className="brand-standard overflow-auto h-100">
			<Switch>
				<Route
					path="/brand-standards/add-brand-standard"
					children={<AddBrandStandard />}
				/>

				<Route
					path="/brand-standards/:brandStandardId"
					children={<BrandStandardDetails />}
				/>

				<Route
					path="/brand-standards"
					children={<BrandStandardsInformation />}
				/>
			</Switch>
		</div>
	);
});

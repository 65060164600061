import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';

import { Total } from '../../Cart/Cart';

import { updateTotalPrices } from '../../../utils';

import { StripeOrderQuery, useOrderRefundsQuery } from '../../../graphql';
import Refund from '../../../domains/Refund';

export const RefundOrderItems: React.FC<StripeOrderQuery> = memo(
	(props: StripeOrderQuery) => {
		const order = props?.order;

		const { data, error } = useOrderRefundsQuery({
			variables: {
				id: order?.id ?? ''
			}
		});

		const updateRefundItems = Refund.actions.useUpdateRefundItems();
		const setPrices = Refund.actions.useSetPrices();

		const refunds = data?.order.refunds;

		const [checkedState, setCheckedState] = useState(
			new Array(order?.items.length).fill(false)
		);

		const [subTotal, setSubTotal] = useState(0);
		const [tax, setTax] = useState(0);
		const [total, setTotal] = useState(0);

		if (error) {
			return (
				<div className="p-3">
					<div className="alert alert-danger">
						Please select your store to see refund information.
						<Link to="/" className="text-danger">
							{' '}
							Go back.
						</Link>
					</div>
				</div>
			);
		}

		setPrices(subTotal, tax);

		const handleChange = (position: number) => {
			order && updateRefundItems(order?.items[position]);
			// Update checked state array
			const updateCheckedState = checkedState.map((check, index) =>
				index === position ? !check : check
			);

			setCheckedState(updateCheckedState);

			// Update prices
			let newSubTotal = subTotal;
			let newTax = tax;
			if (order) {
				[newSubTotal, newTax] = updateTotalPrices(
					order,
					updateCheckedState
				);
			}
			setSubTotal(newSubTotal);
			setTax(newTax);
			setTotal(newSubTotal + newTax);
		};

		return (
			<>
				<h3 className="px-3">REFUND ITEMS</h3>
				<p className="px-3 order-number">
					{order?.items.length} Item
					{order && order.items.length === 1 ? '' : 's'}
				</p>
				<hr />
				<div>
					<table className="table table-dark">
						<thead>
							<tr>
								<th>Product Name</th>
								<th>Unit Price</th>
								<th>Quantity</th>
								<th>Total</th>
								<th>Refund?</th>
							</tr>
						</thead>
						<tbody>
							{order?.items.map((item, key) => (
								<tr key={key}>
									<td>{item.item.product?.name}</td>
									<td>${(item.price / 100).toFixed(2)}</td>
									<td>{item.quantity}</td>
									<td>
										$
										{(
											(item.price / 100) *
											item.quantity
										).toFixed(2)}
									</td>
									<td>
										{refunds?.some(refund =>
											refund?.items.some(
												refundItem =>
													refundItem.id === item.id
											)
										) ? (
											'Refunded'
										) : (
											<input
												type="checkbox"
												name="refund-item"
												onChange={() =>
													handleChange(key)
												}
												style={{ zoom: 2.0 }}
											/>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<Total
						subtotal={(subTotal / 100).toFixed(2)}
						tax={(tax / 100).toFixed(2)}
						total={(total / 100).toFixed(2)}
					/>
				</div>
			</>
		);
	}
);

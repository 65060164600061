import { useSelector } from 'react-redux';

const selectors: OrderDomain.OrderDomainSelectors = {
	useSelectCart() {
		const items = useSelector((state: RootState) =>
			state.order.cart.items.sort(
				(a, b) => Number(a.created) - Number(b.created)
			)
		);

		return items;
	},
	useSelectCartItem(id) {
		const item = useSelector((state: RootState) => {
			const item = state.order.cart.items.find(item => item.id === id);

			if (!item) {
				console.log('cart id', state.order.cart.id);
				console.log('cart items', state.order.cart.items);

				throw new Error('Could not find cart item with id ' + id);
			}

			return item;
		});

		return item;
	},
	useSelectCartQuantity() {
		const quantity = useSelector((state: RootState) =>
			state.order.cart.items.reduce((acc, item) => acc + item.quantity, 0)
		);

		return quantity;
	},
	useSelectOrder() {
		const sentOrder = useSelector((state: RootState) =>
			state.network.session.loaded
				? state.order.order
					? state.order.order
					: null
				: undefined
		);

		return sentOrder;
	}
};

export default selectors;

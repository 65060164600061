import { useEffect, useState } from 'react';
import { useInterval, useEvent, useCounter } from 'react-use';
import useSpring from 'react-use/lib/useSpring';

export const useInactivitySpring = (
	activeValue: number,
	inactiveValue = 0,
	secondsUntilInactive = 5,
	pollInterval = 1
): number => {
	const [isActive, setIsActive] = useState(true);
	const value = useSpring(isActive ? activeValue : inactiveValue, 100, 15);
	const [counter, { dec, reset }] = useCounter(
		secondsUntilInactive,
		secondsUntilInactive,
		0
	);

	useInterval(dec, pollInterval * 1000);

	useEffect(() => {
		if (counter === 0 && isActive) {
			setIsActive(false);
		}
		if (counter !== 0 && !isActive) {
			setIsActive(true);
		}
	}, [isActive, counter]);

	useEvent('mousemove', () => reset());
	useEvent('touchstart', () => reset());

	return value;
};

export type TextFieldProps = {
	label: string;
	value: string | number | null | undefined;
};

export const TextField: React.FC<TextFieldProps> = ({ label, value }) => (
	<div className="d-inline-flex align-items-center" style={{ gap: '0.5rem' }}>
		<div className="text-primary font-weight-bold">{label}:</div>
		<div className="text-light font-weight-normal">{value ?? ''}</div>
	</div>
);

import React, { useState, useRef, useEffect } from 'react';

import {
	BooleanInput,
	ProductVersionSelector,
	Table,
	TableDropdown,
	TextInput
} from '../../ProductManagement';
import { SaveButton } from '../..';
import { Modal } from '../../Generic';

// import { useEditProductMutation } from '../../graphql';

import actions from '../../../domains/UI/actions';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import api from '../../../worker';
import { defined } from '@biggby-coffee/percomatic-typescript/dist/src/utils';

// import {
// 	ticketNameTooltip,
// 	otherNamesTooltip,
// 	imageUrlTooltip,
// 	printableTooltip,
// 	autoModifyTooltip,
// 	includeInSalesTooltip,
// 	productVersionTooltip,
// 	defaultSizeTooltip,
// 	tokenProductTooltip,
// 	productGroupTooltip
// } from '../tooltips';

export interface ProductFieldsFormProps {
	productData: Percomatic.Serialized.Product;
}

export const ProductFieldsForm: React.FC<Percomatic.Serialized.Product> =
	productData => {
		useEffect(() => {
			(async () => {
				if (productData.defaultSize) {
					setDefaultSize([productData.defaultSize]);
				}
				const allProductGroups = await api.getProductGroups();
				const sizes = await api.getSizes();
				const products = await api.getProducts();

				setSizes(sizes);
				setProducts(products);

				if (allProductGroups) {
					setAllProductGroups(allProductGroups);
				}

				setTokenProduct(
					products.filter(
						product => product.id === productData.tokenProductId
					)
				);

				const productProductGroupsPromises =
					productData.productGroupIds.map(id =>
						api.getProductGroup(id)
					);
				const productProductGroups = Promise.all(
					productProductGroupsPromises
				);

				const groups = (await productProductGroups)
					.filter(defined)
					.map(group => ({
						id: group.id,
						name: group.name,
						isVisible: group.isVisible,
						priority: group.priority,
						parentId: group.parentId,
						childProductGroupIds: group.childProductGroupIds,
						visibleChildProductGroupIds:
							group.visibleChildProductGroupIds,
						sizes: group.sizes,
						products: group.products,
						visibleProducts: group.visibleProducts,
						priorityProducts: group.priorityProducts,
						visiblePriorityProducts: group.visiblePriorityProducts,
						nonPriorityProducts: group.nonPriorityProducts,
						visibleNonPriorityProducts:
							group.visibleNonPriorityProducts
					}));
				setProductProductGroups(groups);
			})();
		}, [
			productData.defaultSize,
			productData.productGroupIds,
			productData.tokenProductId
		]);

		const toggleModal = actions.useToggleModal();

		const [productProductGroups, setProductProductGroups] = useState<
			Percomatic.Serialized.ProductGroup[]
		>([]);

		const [sizes, setSizes] = useState<Percomatic.Serialized.Size[]>([]);

		const [allProductGroups, setAllProductGroups] = useState<
			Percomatic.Serialized.ProductGroup[]
		>([]);

		const [products, setProducts] = useState<
			Percomatic.Serialized.Product[]
		>([]);

		const [tokenProduct, setTokenProduct] = useState<
			Percomatic.Serialized.Product[]
		>([]);

		const [name, setName] = useState(productData.name ?? '');
		const [ticketName, setTicketName] = useState(
			productData.ticketName ?? ''
		);

		const [defaultSize, setDefaultSize] = useState<
			Percomatic.Serialized.Size[]
		>([]);

		const [, setVersionId] = useState<number>(
			productData.productVersion?.name === 'HOT'
				? 1
				: productData.productVersion?.name === 'ICED'
				? 2
				: 3
		);
		const [, setPrintable] = useState(productData.printable ?? false);
		const [, setAutoModify] = useState(productData.autoModify);
		const [includeInSales, setIncludeInSales] = useState(true);
		// const [imageUrl, setImageUrl] = useState('');
		const [productId, setProductId] = useState(String(productData.id));

		// const productProductGroupIds = productProductGroups.map(group => group.id);

		// const editedProductData = {
		// 	productId: Number(productId),
		// 	name: name,
		// 	defaultSizeId: Number(defaultSize[0]?.id) ?? 1,
		// 	productVersionId: Number(versionId),
		// 	// image: imageUrl,
		// 	printable: printable ?? false,
		// 	autoModify: autoModify,
		// 	includeInSales: includeInSales ?? false,
		// 	tokenProductId: tokenProduct[0] ? tokenProduct[0].id : undefined,
		// 	productGroupIds: productProductGroupIds
		// };

		// const [editProduct] = useEditProductMutation({
		// 	variables: { product: editedProductData }
		// });

		// const submitEditedProduct = () => {
		// 	editProduct();
		// };

		const validated = useRef(false);

		useEffect(() => {
			isNaN(Number(defaultSize))
				? (validated.current = false)
				: (validated.current = true);
		}, [defaultSize]);

		return (
			<div>
				<h2 className="text-light font-weight-bold">Product Fields</h2>
				<Table>
					<TextInput
						label="Name"
						name="name"
						state={name}
						setState={setName}
					/>
					<TextInput
						label="Ticket Name"
						state={ticketName}
						setState={setTicketName}
						content={'ticketNameTooltip'}
					/>

					<TextInput
						label="Product Id"
						readOnly={true}
						state={productId}
						setState={setProductId}
					/>

					<ProductVersionSelector
						setState={setVersionId}
						value={productData.productVersion?.name ?? ''}
						content={'productVersionTooltip'}
					/>
					<BooleanInput
						label="Auto Modify"
						selected={productData.autoModify}
						setState={setAutoModify}
						content={'autoModifyTooltip'}
					/>
					<BooleanInput
						label="Include in Sales"
						setState={setIncludeInSales}
						selected={includeInSales}
						content={'includeInSalesTooltip'}
					/>
					<BooleanInput
						label="Printable"
						selected={productData.printable}
						setState={setPrintable}
						content={'printableTooltip'}
					/>
				</Table>
				<TableDropdown
					state={defaultSize}
					dropdownItems={sizes}
					label="Default Size"
					singleRelationship={true}
					onDelete={(_, key) =>
						setDefaultSize(items =>
							items.filter((_, index) => index !== key)
						)
					}
					onAdd={(item, key) => setDefaultSize([item])}
					content={'defaultSizeTooltip'}
				/>
				<TableDropdown
					state={tokenProduct}
					dropdownItems={products}
					label="Token Product"
					showDropdownButton={true}
					singleRelationship={true}
					onDelete={(_, key) =>
						setTokenProduct(items =>
							items.filter((_, index) => index !== key)
						)
					}
					onAdd={(item, key) => setTokenProduct([item])}
					content={'tokenProductTooltip'}
				/>

				<TableDropdown
					state={productProductGroups}
					dropdownItems={allProductGroups}
					label="Product Group"
					showDropdownButton={true}
					onDelete={(_, key) =>
						setProductProductGroups(items =>
							items.filter((_, index) => index !== key)
						)
					}
					onAdd={(item, key) => {
						setProductProductGroups(items => [...items, item]);
					}}
					content={'productGroupTooltip'}
				/>
				<Modal
					showConfirmationButton={validated.current ? true : false}
					confirmationMessage="Yes"
					confirmationButtonHandler={console.log}
				>
					{validated.current === false ? (
						<>
							<h1 className="mb-4 text-danger">
								There was a problem
							</h1>
							<p>The following properties must be a number:</p>
							<ul>
								<li>Token Product Id</li>
								<li>Default Size</li>
							</ul>
						</>
					) : (
						<>
							<h1>Are you sure?</h1>
							<p>
								This action will affect the {productData.name}{' '}
								product.
							</p>
						</>
					)}
				</Modal>
				<SaveButton onClick={() => toggleModal()} />
			</div>
		);
	};

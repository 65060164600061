interface InpuBoxtProps {
	name?: string;
	label?: string;
	inputType?: string;
	inputMode?: string;
	defaultValue?: string;
	size?: string;
	value?: string | number;
	placeholder?: string;
	error?: string | null;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	maxLength?: number;
	pattern?: string;
}

const TextInputBox: React.FC<InpuBoxtProps> = ({
	inputType,
	name,
	placeholder,
	label,
	onChange,
	value,
	pattern,
	maxLength,
	error
}) => {
	return (
		<div className="mb-4">
			<div className="form-group">
				<label htmlFor={`${name}`} className="text-light">
					{label}
				</label>
				<input
					type={inputType}
					className="form-control"
					id={`${name}`}
					name={`${name}`}
					aria-describedby={name}
					placeholder={placeholder}
					onChange={onChange}
					value={value}
					pattern={pattern}
					maxLength={maxLength}
				/>
				{error && (
					<small id="error" className="form-text text-danger">
						{error}
					</small>
				)}
			</div>
		</div>
	);
};

export default TextInputBox;

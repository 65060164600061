import React from 'react';

export const SaveButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = ({
	...otherProps
}) => {
	return (
		<button
			{...otherProps}
			className="btn btn-lg btn-success text-white mb-3"
		>
			Save
		</button>
	);
};

import React from 'react';

import { round } from '../../../utils';
import Product from '../../../domains/Product';
import { ProductNutrition } from '../..';

type RowProps = {
	label: string;
	value: string;
};

const Row: React.FC<RowProps> = ({ label, value }) => (
	<div className="d-flex align-items-between pb-1">
		<p className="mb-0">{label}</p>

		<p className="mb-0 ml-auto">{value}</p>
	</div>
);

export type IngredientDetailsProps = {
	ingredientNutritionFacts: ProductDomain.UI.IngredientNutritionFacts;
};

export const IngredientDetails: React.FC<IngredientDetailsProps> = ({
	ingredientNutritionFacts
}) => {
	const {
		component: { name, recipeUnits },
		quantity,
		servings
	} = ingredientNutritionFacts;

	const nutritionRows = Product.selectors.useSelectNutrition([
		ingredientNutritionFacts
	]);

	return (
		<div className="pt-1">
			<div className="px-3">
				<h6 className="text-primary mb-0">Component Details</h6>

				<Row label="Name" value={name} />
				<Row label="Recipe Units" value={recipeUnits} />
				<Row label="Quantity" value={round(quantity)} />
				<Row label="Servings" value={round(servings, 3)} />
			</div>

			<ProductNutrition
				tag="h6"
				title="Contributing Values"
				nutritionRows={nutritionRows}
			/>
		</div>
	);
};

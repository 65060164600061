import React from 'react';
import FadeIn from 'react-fade-in';

import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import Product from '../../domains/Product';

export const OtherVersions: React.FC = () => {
	const changeVersion = Product.actions.useChangeVersion();
	const modifying = Product.selectors.useSelectModifying();

	const { selectedIndex, productVersions } =
		Product.selectors.useSelectProductVersions();

	const selected: Percomatic.Serialized.ProductVersion = productVersions[
		selectedIndex
	] ?? {
		id: 0,
		name: ''
	};

	if (selected && productVersions.length > 0) {
		let body = [];

		body = productVersions.map((otherVersion, index) => (
			<FadeIn
				className={`col-3 p-0 m-0 border border-dark rounded `}
				key={index}
			>
				<button
					className={`btn rounded text-dark btn-block w-100 ${
						// TODO get color for selected button
						selectedIndex === index
							? 'product-version-modifier'
							: 'product-version-modifier'
					}`}
					onClick={() => {
						if (!modifying) {
							changeVersion(index);
						}
					}}
				>
					{otherVersion.name ?? ''}
				</button>
			</FadeIn>
		));

		return (
			<div className="option option-active versions">
				<div className="modifier-group row justify-content-center">
					{body}
				</div>
			</div>
		);
	} else {
		return <></>;
	}
};

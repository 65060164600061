import { SubscriptionClient } from 'subscriptions-transport-ws';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';

const GRAPHQL_WS_ENDPOINT =
	process.env.NODE_ENV === 'production'
		? `wss://${window.location.host}/graphql`
		: process.env.REACT_APP_GRAPHQL_WS_ENDPOINT ?? '';

const client = new SubscriptionClient(GRAPHQL_WS_ENDPOINT, {
	reconnect: true,
	timeout: 60000,
	lazy: true
});

client.on('connecting', () => {
	console.log('subscription-client: connecting');
});

client.on('connected', () => {
	console.log('subscription-client: connected');
});

client.on('reconnecting', () => {
	console.log('subscription-client: reconnecting');
});

client.on('reconnected', () => {
	console.log('subscription-client: reconnected');
});

client.on('disconnected', () => {
	console.log('subscription-client: disconnected');
});

export default new ApolloClient({
	link: new WebSocketLink(client),
	cache: new InMemoryCache()
});

import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

export interface createRouteProps {
	base?: string;
}

const makePath = (
	base?: string,
	extension?: RouteProps['path']
): RouteProps['path'] => {
	if (!extension) {
		return base;
	}

	if (Array.isArray(extension)) {
		return extension.map(path => `${base}${path}`);
	}

	return `${base}${extension}`;
};

export const createRoute: (base?: string) => React.FC<RouteProps> =
	base =>
	({ path, ...props }) =>
		<Route {...props} path={makePath(base, path)} />;

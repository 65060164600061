/* eslint-disable no-unreachable */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	useSetSessionStoreIdMutation,
	useLogoutMutation,
	useSessionLazyQuery
} from '../../graphql';

import Session from '../../domains/Session';

const actions: SessionDomain.SessionDomainActions = {
	useUpdateSession() {
		const dispatch = useDispatch();
		const [sessionQuery, { called, data }] = useSessionLazyQuery();

		useEffect(() => {
			if (called && data) {
				const action: SessionDomain.Actions.SessionLoaded = {
					type: 'SESSION_LOADED',
					payload: data.session
				};

				dispatch(action);
			}
		}, [called, data, dispatch]);

		return () => {
			sessionQuery();
		};
	},
	useSetStoreId: (redirect = '/employee') => {
		const { push } = useHistory();
		const dispatch = useDispatch();

		const [setStoreId, { data, loading, error }] =
			useSetSessionStoreIdMutation();
		// const fetchPrices = Network.actions.useFetchPrices();

		useEffect(() => {
			if (error) throw error;

			if (!loading && data) {
				const action: SessionDomain.Actions.StoreIdSet = {
					type: 'STORE_SET',
					payload: data.setSessionStoreId
				};

				dispatch(action);
				push(redirect);
			}
		}, [loading, data, error, dispatch, push, redirect]);

		return (storeId: number) => {
			const action: SessionDomain.Actions.SetStoreId = {
				type: 'SET_STORE',
				payload: storeId
			};

			setStoreId({ variables: { storeId } });
			// fetchPrices(storeId, tierId);
			dispatch(action);
		};
	},
	useLogout: () => {
		const { push } = useHistory();
		const dispatch = useDispatch();

		const [logout, { data, loading, error }] = useLogoutMutation();

		useEffect(() => {
			if (error) throw error;

			if (!loading) {
				if (data) {
					if (data.logout) {
						const action: SessionDomain.Actions.LoggedOut = {
							type: 'LOGGED_OUT'
						};

						dispatch(action);
					}
				}
			}
		}, [data, loading, error, dispatch]);

		return () => {
			const action: SessionDomain.Actions.StartedLoggingOut = {
				type: 'STARTED_LOGGING_OUT'
			};

			logout();
			dispatch(action);
			push('/');
		};
	},
	useProtectAdmin(): void {
		const { push } = useHistory();
		const authenticated = Session.selectors.useSelectAuthenticated();
		const roles = Session.selectors.useSelectRoles();

		useEffect(() => {
			if (
				authenticated !== null &&
				(!authenticated || roles.length === 0)
			) {
				push('/');
			}
		}, [authenticated, roles, push]);
	}
};

export default actions;

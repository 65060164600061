import { Dispatch, SetStateAction, useState } from 'react';
import { captureException } from '@sentry/react';

import { Modal } from '../../Generic';
import { LylaQuestionInput } from '../LylaQuestionInput';

import actions from '../../../domains/UI/actions';
import {
	LylaQuestionFieldsFragment,
	useAddLylaQuestionMutation
} from '../../../graphql/';

export type AddLylaQuestionButtonProps =
	React.HTMLAttributes<HTMLDivElement> & {
		largestId: string;
		setLylaQuestions: Dispatch<
			SetStateAction<
				| ({
						__typename?: 'LylaQuestion' | undefined;
				  } & LylaQuestionFieldsFragment)[]
				| undefined
			>
		>;
		lylaQuestions:
			| ({
					__typename?: 'LylaQuestion' | undefined;
			  } & LylaQuestionFieldsFragment)[]
			| undefined;
		setIsLoading: Dispatch<SetStateAction<boolean>>;
		whereToShowOptions: { value: string; label: string }[];
	};

export const AddLylaQuestionButton: React.FC<AddLylaQuestionButtonProps> = ({
	largestId,
	setLylaQuestions,
	lylaQuestions,
	setIsLoading,
	whereToShowOptions
}) => {
	const toggleModal = actions.useToggleModal();

	const [addQuestionModal, setAddQuestionModal] = useState<boolean>(false);

	const [addLylaQuestion] = useAddLylaQuestionMutation();

	const [question, setQuestion] = useState<string>('');
	const [isActive, setIsActive] = useState<boolean>(true);
	const [askAgainIfSkipped, setAskAgainIfSkipped] = useState<boolean>(true);
	const [startDate, setStartDate] = useState<Date | undefined>();
	const [endDate, setEndDate] = useState<Date | undefined>();
	const [whereToShow, setWhereToShow] = useState<number | undefined>();
	const [idleTime, setIdleTime] = useState<number | undefined>();
	const [askFrequency, setAskFrequency] = useState<number | undefined>();

	const resetInputs = () => {
		setQuestion('');
		setIsActive(true);
		setAskAgainIfSkipped(true);
		setStartDate(undefined);
		setEndDate(undefined);
		setWhereToShow(undefined);
		setIdleTime(undefined);
		setAskFrequency(undefined);
	};

	return (
		<>
			<button
				className="btn btn-secondary mr-3"
				onClick={() => {
					toggleModal();
					setAddQuestionModal(true);
				}}
			>
				Add Lyla Question
			</button>

			{addQuestionModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Add Question"
					confirmationButtonHandler={async () => {
						setIsLoading(true);

						const variables = {
							variables: {
								question: {
									questionId: (
										Number(largestId) + 1
									).toString(),
									question,
									isActive,
									askAgainIfSkipped,
									idleTime,
									askFrequency,
									startDate,
									endDate,
									whereToShow
								}
							}
						};

						await addLylaQuestion(variables)
							.then(res => {
								if (
									res.data?.addLylaQuestion &&
									lylaQuestions
								) {
									setLylaQuestions([
										...lylaQuestions,
										res.data?.addLylaQuestion
									]);
									resetInputs();
									setIsLoading(false);
									setAddQuestionModal(false);
								}
							})
							.catch(err => {
								captureException(err);
							});
						setIsLoading(false);
					}}
					closeMessage="Cancel"
					closeButtonHandler={() => {
						resetInputs();
						setAddQuestionModal(false);
					}}
				>
					<>
						<h1 className="text-dark text-center">
							Add a Lyla Question
						</h1>
						<LylaQuestionInput
							question={question}
							setQuestion={setQuestion}
							isActive={isActive}
							setIsActive={setIsActive}
							askAgainIfSkipped={askAgainIfSkipped}
							setAskAgainIfSkipped={setAskAgainIfSkipped}
							idleTime={idleTime}
							setIdleTime={setIdleTime}
							askFrequency={askFrequency}
							setAskFrequency={setAskFrequency}
							whereToShow={whereToShow}
							setWhereToShow={setWhereToShow}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							whereToShowOptions={whereToShowOptions}
						/>
					</>
				</Modal>
			)}
		</>
	);
};

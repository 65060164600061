import React, { Key, useState } from 'react';
import moment from 'moment';

import Admin from '../../domains/Admin';
import { TerminalOrder } from '..';
import { useInterval } from '../../hooks/useInterval';

export interface TerminalProps {
	storeId: number;
	termId: number;
}

const useMoment = (time: string): moment.Moment => {
	const [date, setDate] = useState(moment(time));

	useInterval(() => {
		setDate(moment(time));
	}, 1000);

	return date;
};

export const Terminal: React.FC<TerminalProps> = ({
	storeId,
	termId
}: TerminalProps) => {
	Admin.actions.useTerminalSounds(storeId, termId);

	const { status, time, connectionId } = Admin.selectors.useSelectTerminal(
		storeId,
		termId
	);

	const date = useMoment(time);

	const termOrders = Admin.selectors.useSelectTerminalOrders(storeId, termId);

	const [show, setShow] = useState(false);

	return (
		<div className="terminal" onClick={() => setShow(!show)}>
			<div
				className={`rounded p-1 ${
					status === 'CONNECTED' ? 'bg-success' : 'bg-danger'
				}`}
			>
				<div className="d-flex justify-content-between">
					<div className="font-weight-bold">
						{storeId} T{termId}
					</div>

					<div>{date.fromNow()}</div>
				</div>

				<div className="font-weight-bold">
					{date.format('M/D/YY hh:mm:ss A')}
				</div>

				<div>{connectionId ?? 'DISCONNECTED'}</div>
			</div>

			{show && (
				<div className="bg-white list-group">
					{termOrders.map((order: AdminDomain.OrderId, key: Key) => (
						<div>
							<TerminalOrder
								orderId={order.orderId}
								storeId={order.storeId}
								termId={order.termId}
								key={key}
							/>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

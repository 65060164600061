import React, { useState } from 'react';
import { useFullscreen } from 'react-use';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowLeft,
	faRedo,
	faExpandAlt,
	faCompressAlt
} from '@fortawesome/free-solid-svg-icons';

import { Clock } from '..';

import Session from '../../domains/Session';

import Logo from '../../assets/imgs/logo.png';

export type NavBarProps = {
	layoutRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const NavBar: React.FC<NavBarProps> = ({ layoutRef }) => {
	const [fullscreen, toggle] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const authenticated = Session.selectors.useSelectAuthenticated();
	const store = Session.selectors.useSelectStore();
	const employee = Session.selectors.useSelectEmployee();

	useFullscreen(layoutRef, fullscreen);

	return (
		<nav className="bg-light-gray px-1 px-sm-3 py-0 d-flex align-items-center">
			{location.pathname !== '/' ? (
				<FontAwesomeIcon
					icon={faArrowLeft}
					onClick={() => history.goBack()}
					className="text-primary"
				/>
			) : (
				<>&nbsp;&nbsp;&nbsp;</>
			)}

			{authenticated && employee && (
				<span className="ml-2">
					{employee.firstName}
					{store && ` - ${store.storeId}`}
					{employee.roles[0] && ` - ${employee.roles[0].role.name}`}
				</span>
			)}

			<FontAwesomeIcon
				icon={faRedo}
				className="ml-2"
				onClick={() => window.location.reload()}
			/>

			<Link className="nav-logo" to="/">
				<img src={Logo} alt="BIGGBY COFFEE" className="mh-100"></img>
			</Link>

			<h6 className="nav-date ml-auto mb-0">
				<Clock />
			</h6>

			<FontAwesomeIcon
				icon={fullscreen ? faCompressAlt : faExpandAlt}
				className="ml-2"
				onClick={() => toggle(!fullscreen)}
			/>
		</nav>
	);
};

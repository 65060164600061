import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ItemFields, PercomaticLoading } from '../../components';

import ProductManagement from '../../domains/ProductManagement';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import api from '../../worker';
import Product from '../../domains/Product';
interface ParamProps {
	groupId?: string;
	productId?: string;
	itemId?: string;
}

function Body() {
	const { productId, itemId } = useParams<ParamProps>();

	if (!productId || !itemId) {
		throw new Error('Missing product id for item.');
	}

	const [product, setProduct] = useState<Percomatic.Serialized.Product>();
	const [item, setItem] = useState<Percomatic.Serialized.Item>();
	const progress = Product.selectors.useSelectInitAndLinkProgress();

	useEffect(() => {
		(async () => {
			if (productId && itemId && progress.initAndLinkProgress === 100) {
				const product = await api.getProduct(Number(productId));
				const item = await api.getItem(Number(itemId));

				setProduct(product);
				setItem(item);
			}
		})();
	}, [itemId, productId, progress.initAndLinkProgress]);

	const updateHeader = ProductManagement.actions.useUpdateHeader();

	useEffect(() => {
		updateHeader({
			label: `${item?.size?.name} ${item?.product.name}`,
			showButton: true,
			buttonTitle: 'Create New Item',
			linkTo: `/product-management/new/item/product-id/${product?.id}`
		});
	}, [item?.product.name, updateHeader, item?.size?.name, product?.id]);

	return <>{item && item.product && <ItemFields {...item} />}</>;
}

export default function Item(): JSX.Element {
	return (
		<div>
			<PercomaticLoading loaded children={<Body />} />
		</div>
	);
}

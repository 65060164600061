import React, { useRef } from 'react';

import Router from './Router';
import { NavBar } from './components';

import UI from './domains/UI';

const Layout: React.FC = () => {
	const orientation = UI.selectors.useSelectOrientation();
	const layout = 'app'; // TODO: add to UI domain
	const ref = useRef<HTMLDivElement | null>(null);

	return (
		<div ref={ref} className={`${layout} ${orientation}`}>
			<div className="main">
				<Router />
			</div>

			<NavBar layoutRef={ref} />
		</div>
	);
};

export default Layout;

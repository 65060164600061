import React, { useState } from 'react';

import { Table, TextInput } from '../../..';

export const CustomerDetails: React.FC = () => {
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [address, setAddress] = useState('');

	return (
		<>
			<h3 className="px-3 dp-h3">Customer Details</h3>
			<div className="px-3">
				<Table>
					<TextInput
						label="Email"
						readOnly={false}
						state={email}
						setState={setEmail}
					/>
					<TextInput
						label="Customer Name"
						readOnly={false}
						state={name}
						setState={setName}
					/>
					<TextInput
						label="Billing Address"
						readOnly={false}
						state={address}
						setState={setAddress}
					/>
				</Table>
			</div>
		</>
	);
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { InventoryItemFields, PercomaticLoading } from '../../components';
import ProductManagement from '../../domains/ProductManagement';

import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import api from '../../worker';
interface ParamProps {
	groupId: string;
	productId: string;
	itemId: string;
	componentId: string;
	inventoryItemId: string;
}

function Body() {
	const params = useParams<ParamProps>();

	const [components, setComponents] = useState<
		Percomatic.Serialized.Component[]
	>([]);

	useEffect(() => {
		(async () => {
			const components = await api.getComponents();
			setComponents(components);
		})();
	}, []);

	const currentInventoryItem = components.find(
		item => item?.inventoryItem?.id === Number(params.inventoryItemId)
	)?.inventoryItem;

	const updateHeader = ProductManagement.actions.useUpdateHeader();

	useEffect(() => {
		updateHeader({
			label: currentInventoryItem?.name ?? '',
			showButton: true,
			buttonTitle: 'Create New Inventory Item',
			linkTo: '/product-management/new/inventory-item'
		});
	}, [currentInventoryItem?.name, updateHeader]);

	return (
		<>
			{currentInventoryItem && (
				<InventoryItemFields {...currentInventoryItem} />
			)}
		</>
	);
}

export default function InventoryItem(): JSX.Element {
	return (
		<div>
			<PercomaticLoading loaded children={<Body />} />
		</div>
	);
}

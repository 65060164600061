// https://overreacted.io/making-setinterval-declarative-with-react-hooks/

import { useState, useEffect, useRef } from 'react';

export function useInterval<T extends () => void>(
	callback: T,
	delay: number
): NodeJS.Timeout | undefined {
	const [clearer, setClearer] = useState<NodeJS.Timeout>();
	const savedCallback = useRef<T>();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			if (savedCallback.current) {
				savedCallback.current();
			}
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			setClearer(id);
			return () => clearInterval(id);
		}
	}, [delay]);

	return clearer;
}

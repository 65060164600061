import React, { useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { OrderStatus } from '../components';

import Order from '../domains/Order';

export default function Success(): JSX.Element | null {
	const history = useHistory();

	const order = Order.selectors.useSelectOrder();

	useEffect(() => {
		if (order === null) {
			history.push('/checkout');
		}
	}, [order, history]);

	let body: unknown;

	if (order) {
		switch (order.status.status) {
			case 'ORDER_PROCESSING':
				body = (
					<OrderStatus
						title="Processing Your Order... Please Wait."
						body="We're just checking to make sure everything looks OK."
						id={order.id}
						showLoading={true}
					/>
				);

				break;
			case 'ORDER_SENT':
				body = (
					<OrderStatus
						title="Order Sent!"
						body="Waiting for the terminal to process the order."
						id={order.id}
						showLoading={true}
					/>
				);

				break;
			case 'ORDER_RECEIVED':
				body = (
					<OrderStatus
						title="Order Received!"
						body="Your order has been received by the terminal."
						id={order.id}
						showLoading={false}
					/>
				);

				break;
			default:
				body = (
					<OrderStatus
						title="Order Loading..."
						body="Checking on the status of your order... please wait."
						id=""
						showLoading={true}
					/>
				);

				break;
		}

		return (
			<div className="success transition-page transition-item">
				<div className="px-3 mt-5">
					{body}

					<Link
						className="btn btn-info btn-block w-25 btn-lg mx-auto mt-4"
						to="/menu"
					>
						Menu
					</Link>
				</div>
			</div>
		);
	} else {
		return null;
	}
}

import React from 'react';
// import tooltipJSON from '../../ProductManagement/assets/tooltips.json';
import { Tooltip } from '../..';

import Session from '../../../domains/Session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export interface BooleanInputProps {
	label: string;
	selected?: boolean;
	setState: React.Dispatch<React.SetStateAction<boolean>>;
	content?: React.ReactNode;
}

const canViewIsVisibleIds = [1, 2, 5, 7, 9, 10, 12];

export const BooleanInput: React.FC<BooleanInputProps> = ({
	label,
	selected,
	setState,
	content
}) => {
	const roles = Session.selectors.useSelectRoles();

	const selectBody = (
		<>
			<div className="d-flex align-items-center">
				<th className="border-0 py-3">{label}</th>
				{content && (
					<Tooltip content={content}>
						{' '}
						<FontAwesomeIcon
							icon={faQuestionCircle}
							color="white"
						/>
					</Tooltip>
				)}
			</div>
			<td className="border-0">
				<select
					onChange={e =>
						setState(e.target.value === 'true' ? true : false)
					}
					name={label}
					className="form-control"
				>
					<option disabled selected>
						{' '}
						-- select an option --{' '}
					</option>
					<option
						value="true"
						selected={selected === true ? true : undefined}
					>
						True
					</option>
					<option
						value="false"
						selected={selected === false ? true : undefined}
					>
						False
					</option>
				</select>
			</td>
		</>
	);

	let body;

	if (label === 'Is Visible') {
		if (roles.find(role => canViewIsVisibleIds.includes(role.roleId))) {
			body = selectBody;
		} else {
			body = null;
		}
	} else {
		body = selectBody;
	}

	return <>{body}</>;
};

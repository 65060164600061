import { ChangeEvent, useEffect, memo } from 'react';

import { CurrencyInput } from '../CurrencyInput';

import Refund from '../../../domains/Refund';
import {
	handleRefundDestinationInput,
	isFullyRefunded,
	orderRefundsToDestinations
} from '../../../utils';

import { StripeOrderQuery } from '../../../graphql';

export type RefundDestinationProps = React.HTMLAttributes<HTMLDivElement> &
	StripeOrderQuery;

export const RefundDestination = memo(
	(props: RefundDestinationProps): React.ReactElement => {
		const order = props.order;
		const creditCardPayments = order?.creditCardPayments;
		const giftCardPayments = order?.giftCardPayments;

		let numberOfPayments = 0;
		if (creditCardPayments) {
			numberOfPayments += creditCardPayments.length;
		}
		if (giftCardPayments) {
			numberOfPayments += giftCardPayments.length;
		}

		const refundItems = Refund.selectors.useSelectRefundItems();
		const prices = Refund.selectors.useSelectPrices();
		const formattedPrice = Number(
			((prices.subtotal + prices.tax) / 100).toFixed(2)
		);

		const setRefundDestinations = Refund.actions.useSetRefundDestinations();

		useEffect(() => {
			// If refund items are unchecked, it won't carry over the last item checked in refund destinations
			if (refundItems.length === 0) {
				setRefundDestinations([]);
			} else {
				const totalPrice = prices.subtotal + prices.tax;

				if (numberOfPayments === 1 && totalPrice > 0) {
					if (creditCardPayments?.length === 1) {
						setRefundDestinations([
							{
								amount: totalPrice,
								paymentId: creditCardPayments[0].id,
								paymentType: 'CREDIT_CARD'
							}
						]);
					}

					if (giftCardPayments?.length === 1) {
						setRefundDestinations([
							{
								amount: totalPrice,
								paymentId: giftCardPayments[0].id,
								paymentType: 'GIFT_CARD'
							}
						]);
					}
				}
			}
		}, [
			creditCardPayments,
			giftCardPayments,
			prices,
			setRefundDestinations,
			numberOfPayments,
			refundItems
		]);

		return (
			<>
				<h3 className="px-3">REFUND DESTINATION</h3>
				{creditCardPayments && creditCardPayments[0] && (
					<div>
						<p className="px-3 order-number">
							Credit card refund option
							{creditCardPayments.length > 1 ? 's' : ''}:
						</p>
						<table className="table table-dark">
							<thead>
								<tr>
									<th>Credit Card Last Four</th>
									<th>Refundable Amount</th>
									<th>Amount to Refund</th>
								</tr>
							</thead>
							<tbody>
								{creditCardPayments.map((ccp, key) => {
									const [
										orderRefundDestinations,
										amountRefunded
									] = orderRefundsToDestinations(
										order?.refunds,
										ccp.id
									);

									return (
										!isFullyRefunded(
											ccp,
											orderRefundDestinations
										) && (
											<tr key={key}>
												<td>{ccp.lastFour}</td>
												<td>
													$
													{(
														(ccp.amount -
															amountRefunded) /
														100
													).toFixed(2)}
												</td>
												<td>
													{refundItems.length ===
													0 ? null : numberOfPayments ===
													  1 ? (
														`$${formattedPrice}`
													) : (
														<CurrencyInput
															placeholder="$0.00"
															onChange={(
																e: ChangeEvent<HTMLInputElement>
															) =>
																handleRefundDestinationInput(
																	e,
																	ccp,
																	setRefundDestinations
																)
															}
														/>
													)}
												</td>
											</tr>
										)
									);
								})}
							</tbody>
						</table>
					</div>
				)}

				{giftCardPayments && giftCardPayments[0] && (
					<div>
						<p className="px-3 order-number">
							Gift card refund option
							{giftCardPayments.length > 1 ? 's' : ''}:
						</p>
						<table className="table table-dark">
							<thead>
								<tr>
									<th>Gift Card Number</th>
									<th>Refundable Amount</th>
									<th>Amount to Refund</th>
								</tr>
							</thead>
							<tbody>
								{giftCardPayments.map((gcp, key) => {
									const [
										orderRefundDestinations,
										amountRefunded
									] = orderRefundsToDestinations(
										order?.refunds,
										gcp.id
									);

									return (
										!isFullyRefunded(
											gcp,
											orderRefundDestinations
										) && (
											<tr key={key}>
												<td>{gcp.cardNumber}</td>
												<td>
													$
													{(
														(gcp.amount -
															amountRefunded) /
														100
													).toFixed(2)}
												</td>
												<td>
													{refundItems.length ===
													0 ? null : numberOfPayments ===
													  1 ? (
														`$${formattedPrice}`
													) : (
														<CurrencyInput
															placeholder="$0.00"
															onChange={(
																e: ChangeEvent<HTMLInputElement>
															) =>
																handleRefundDestinationInput(
																	e,
																	gcp,
																	setRefundDestinations
																)
															}
														/>
													)}
												</td>
											</tr>
										)
									);
								})}
							</tbody>
						</table>
					</div>
				)}
			</>
		);
	}
);

import React from 'react';
import { GiftCardPaymentsFieldFragment } from '../../../graphql';

export const GiftCardInfo: React.FC<GiftCardPaymentsFieldFragment> = ({
	cardId,
	cardNumber,
	amount
}) => {
	return (
		<div className="order-info-container px-3">
			<div className="order-info">
				<h3>Card ID</h3>
				<p>{cardId}</p>
			</div>
			<div className="order-info">
				<h3>Card Type</h3>
				<p>Gift Card</p>
			</div>
			<div className="order-info">
				<h3>Card Ending With</h3>
				<p>{cardNumber.slice(-4)}</p>
			</div>
			<div className="order-info">
				<h3>Amount Charged</h3>
				<p>${(amount / 100).toFixed(2)}</p>
			</div>
		</div>
	);
};

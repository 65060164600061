import React from 'react';

import { createRoute } from '../../components';

import Menu from './Menu';
import Product from './Product';
import Item from './Item';
import Component from './Component';
import EditComponentGroup from './EditComponentGroup';
import InventoryItem from './InventoryItem';

import { New } from './New';

const Route = createRoute('/product-management');

export const Routes: React.FC = () => {
	return (
		<>
			<Route
				exact
				path={[
					// '/nutrition/inventory-item/:inventoryItemId',
					'/component/:componentId/inventory-item/:inventoryItemId',
					'/product-group/:groupId/product/:productId/item/:itemId/component-group/:componentGroupId/component/:componentId/inventory-item/:inventoryItemId',
					'/product-group/:productGroupId/product/:productId/item/:itemId/component/:componentId/inventory-item/:inventoryItemId'
				]}
			>
				<InventoryItem />
			</Route>

			<Route
				exact
				path={[
					'/component/:componentId',
					'/product-group/:groupId/product/:productId/item/:itemId/component/:componentId',
					'/product-group/:groupId/product/:productId/item/:itemId/component-group/:componentGroupId/component/:componentId'
				]}
			>
				<Component />
			</Route>

			<Route
				exact
				path="/product-group/:groupId/product/:productId/item/:itemId/component-group/:componentGroupId"
			>
				<EditComponentGroup />
			</Route>

			<Route
				exact
				path="/product-group/:groupId/product/:productId/item/:itemId"
			>
				<Item />
			</Route>

			<Route
				exact
				path={[
					'/product/:productId',
					'/product-group/:groupId/product/:productId'
				]}
			>
				<Product />
			</Route>

			<Route exact path="/product-group/:groupId">
				<Menu />
			</Route>

			<Route path="/new">
				<New />
			</Route>

			<Route exact path="/">
				<Menu />
			</Route>
		</>
	);
};

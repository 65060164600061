import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import { OwnerOperator } from './OwnerOperator';
import { Stores } from './Stores';
import { Store } from './Store';
import { OrderInformation } from './OrderInformation';
import { DisputesEvidence } from './DisputesEvidence';

import Session from '../../domains/Session';
import Disputes from './Disputes';
import { Refund } from '.';

const ooRoles = [1, 2, 5, 7, 9];
const managerRoles = [1, 2, 3, 5, 7, 9];

const Nav: React.FC = () => {
	const employee = Session.selectors.useSelectEmployee();

	const store = Session.selectors.useSelectStore();

	return (
		<div className="d-flex h-100 justify-content-center align-items-center">
			<div className="w-75 grid-200">
				{employee?.roles.find(({ roleId }) =>
					ooRoles.includes(roleId)
				) && (
					<Link className="btn btn-info btn-lg p-4" to="/stripe/oo">
						Onboarding
					</Link>
				)}
				{employee?.roles.find(({ roleId }) =>
					managerRoles.includes(roleId)
				) &&
					store && (
						<>
							<Link
								className="btn btn-info btn-lg p-4"
								to={`/stripe/store/${store.storeId}`}
							>
								Orders
							</Link>
							<Link
								className="btn btn-info btn-lg p-4"
								to={`/stripe/store/${store.storeId}/disputes`}
							>
								Disputes
							</Link>
						</>
					)}
			</div>
		</div>
	);
};

const Stripe = React.memo(() => {
	return (
		<div className="stripe overflow-auto h-100">
			<Switch>
				<Route path="/stripe/oo">
					<OwnerOperator />
				</Route>

				<Route path="/stripe/store/:storeId/:orderId/dispute-evidence">
					<DisputesEvidence />
				</Route>

				<Route path="/stripe/store/:storeId/disputes">
					<Disputes />
				</Route>

				<Route
					path="/stripe/store/:storeId/:orderId/refund"
					component={Refund}
				/>

				<Route path="/stripe/store/:storeId/:orderId">
					<OrderInformation />
				</Route>

				<Route path="/stripe/store/:storeId">
					<Store />
				</Route>

				<Route path="/stripe/stores">
					<Stores />
				</Route>

				<Route path="/stripe">
					<Nav />
				</Route>
			</Switch>
		</div>
	);
});

export default Stripe;

import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

// import { useAddItemMutation } from '../../../graphql';

import actions from '../../../../domains/UI/actions';

import { BooleanInput, Table, TableDropdown, TextInput } from '../..';
import { Modal } from '../../../Generic';

import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import api from '../../../../worker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { PercomaticLoading, SaveButton } from '../../..';

// import {
// 	capacityTooltip,
// 	barcodeTooltip,
// 	defaultPriceTooltip,
// 	isVariablePriceTooltip,
// 	productTooltip,
// 	componentTooltip
// } from '../../tooltips';

function Body() {
	const toggleModal = actions.useToggleModal();

	const [itemProduct, setItemProduct] = useState<
		Percomatic.Serialized.Product[]
	>([]);
	const [, setItemSize] = useState<Percomatic.Serialized.Size[]>([]);
	const [itemId, setItemId] = useState('');
	const [containerId, setContainerId] = useState('');
	const [capacity, setCapacity] = useState('');
	const [isVariablePrice, setIsVariablePrice] = useState(true);
	const [showQuantityCheat, setShowQuantityCheat] = useState<boolean>(false);

	const params = useParams<{ productId: string }>();

	const [products, setProducts] = useState<Percomatic.Serialized.Product[]>(
		[]
	);
	const [, setAllSizes] = useState<Percomatic.Serialized.Size[]>([]);

	const [, setAllComponents] = useState<Percomatic.Serialized.Component[]>(
		[]
	);

	const [itemBarcode, setItemBarcode] = useState<string>('');

	const [defaultPrice, setDefaultPrice] = useState<string>('');

	const [, setContainers] = useState<Percomatic.Serialized.Container[]>([]);

	// const [itemContainer, setItemContainer] = useState<Percomatic.Serialized.Container[]>([]);

	useEffect(() => {
		(async () => {
			const products = await api.getProducts();
			const sizes = await api.getSizes();
			const components = await api.getComponents();
			const product = await api.getProduct(Number(params.productId));
			const items = await api.getItems();
			const containers = await api.getContainers();

			if (product) {
				setItemProduct([product]);
			}
			setContainers(containers);
			setProducts(products);
			setAllSizes(sizes);
			setAllComponents(components);
			setItemId(String(items[items.length - 2].id + 1));
		})();
	}, [params.productId]);

	const [itemRecipe] = useState<Percomatic.Serialized.Ingredient[]>([]);

	const formattedIngredients: {
		componentId: number;
		quantity: number;
		relevance: number;
		itemId: number;
	}[] = [];

	const formatIngredients = (
		ingredients: Percomatic.Serialized.Ingredient[]
	) => {
		for (const { component, quantity, relevance } of ingredients) {
			formattedIngredients.push({
				componentId: component?.id ?? 0,
				quantity,
				relevance,
				itemId: Number(itemId)
			});
		}
	};

	formatIngredients(itemRecipe);

	// const recipeNames = itemRecipe.map(item => item.component?.name);

	// const newItemData = {
	// 	itemId: Number(itemId),
	// 	productId: Number(itemProduct[0]?.id),
	// 	sizeId: Number(itemSize[0]?.id),
	// 	isVariablePrice,
	// 	containerId: itemContainer[0]
	// 		? itemContainer[0].containerId
	// 		: undefined,
	// 	capacity: Number(capacity),
	// 	barcode: { itemId: Number(itemId), barcode: itemBarcode },
	// 	defaultPrice: { price: Number(defaultPrice) / 100 }
	// };

	// const [addRecipe] = useAddRecipeMutation({
	// 	variables: { ingredients: formattedIngredients }
	// });

	// const [addItem] = useAddItemMutation({
	// 	variables: { item: newItemData }
	// });

	const submitNewItem = () => {
		// addItem();
		//addRecipe();
		setItemProduct([]);
		setItemSize([]);
		setItemId('');
		setContainerId('');
		setCapacity('');
		setIsVariablePrice(false);
	};

	const validated = useRef(false);

	useEffect(() => {
		isNaN(Number(containerId)) ||
		isNaN(Number(capacity)) ||
		isNaN(Number(itemBarcode))
			? (validated.current = false)
			: (validated.current = true);
	}, [containerId, capacity, itemBarcode]);

	return (
		<>
			<Table>
				<TextInput
					label="Id"
					state={itemId}
					setState={setItemId}
					readOnly={true}
				/>
				<TextInput
					label="Capacity"
					state={capacity}
					setState={setCapacity}
					content={'capacityTooltip'}
				/>
				<TextInput
					state={itemBarcode}
					setState={setItemBarcode}
					label="Barcode"
					content={'barcodeTooltip'}
				/>
				<TextInput
					state={defaultPrice}
					setState={setDefaultPrice}
					label="Default Price (in cents)"
					content={'defaultPriceTooltip'}
				/>
				<BooleanInput
					label="Is Variable Price"
					setState={setIsVariablePrice}
					selected={isVariablePrice}
					content={'isVariablePriceTooltip'}
				/>
			</Table>

			<TableDropdown
				state={itemProduct}
				dropdownItems={products}
				label="Product"
				showDropdownButton={true}
				singleRelationship={true}
				onDelete={(_, key) =>
					setItemProduct(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setItemProduct([item])}
				content={'productTooltip'}
			/>

			<div className="m-3 mt-5">
				<div
					className="d-flex align-items-center"
					onClick={() => setShowQuantityCheat(!showQuantityCheat)}
				>
					<h4 className="text-primary font-weight-bold pr-3">
						Quantity Cheat Sheet
					</h4>
					<FontAwesomeIcon
						icon={showQuantityCheat ? faChevronUp : faChevronDown}
						color={'white'}
					/>
				</div>
				{showQuantityCheat && (
					<p className="text-light">
						You are typically given quantities for the tall, super,
						and grande sizes. To calculate the amount for other
						sizes, use these rules:
						<ul>
							<li>Short - half the grande amount</li>
							<li> 32oz - double the tall amount</li>
							<li> 64oz - double the 32oz amount</li>
							<li> 100oz - 5 times the grande amount</li>
							<li> 50oz - half the 100oz amount</li>
						</ul>
					</p>
				)}
			</div>

			{/* <ItemRecipeTable
				state={itemRecipe}
				dropdownItems={allComponents}
				label="Component"
				showDropdownButton={true}
				onAdd={item => {
					if (!recipeNames.includes(item.name)) {
						setItemRecipe(items => [
							...items,
							{ component: item, quantity: 0, relevance: 0 }
						]);
					}
				}}
				onDelete={(_, key) =>
					setItemRecipe(items =>
						items.filter((_, index) => index !== key)
					)
				}
				quantityOnChange={(e, id) => {
					const updatedRecipe = itemRecipe.map(item =>
						item.component?.id === id
							? { ...item, quantity: Number(e.target.value) }
							: item
					);

					setItemRecipe(updatedRecipe);
				}}
				relevanceOnChange={(e, id) => {
					const updatedRecipe = itemRecipe.map(item =>
						item.component?.id === id
							? { ...item, relevance: Number(e.target.value) }
							: item
					);

					setItemRecipe(updatedRecipe);
				}}
				content={componentTooltip}
			/> */}

			<Modal
				showConfirmationButton={true}
				confirmationMessage="Yes"
				confirmationButtonHandler={submitNewItem}
			>
				{validated.current === false ? (
					<>
						<h1 className="mb-4 text-danger">
							There was a problem
						</h1>
						<p>The following properties must be a number:</p>
						<ul>
							<li>Container Id</li>
							<li>Capacity</li>
							<li>Barcode</li>
						</ul>
					</>
				) : (
					<>
						<h1>Are you sure?</h1>
						<p>This action will create a new item.</p>
						<p>
							Make sure to create versions for tall, grande,
							super, 32oz, 64oz, 100oz, and 50oz sizes. Capacity
							will be the same for all.{' '}
						</p>
					</>
				)}
			</Modal>
			<SaveButton onClick={() => toggleModal()} />
		</>
	);
}

export const Item: React.FC = () => {
	return (
		<div>
			<PercomaticLoading loaded children={<Body />} />
		</div>
	);
};

const initialState: OrderDomain.State = {
	cart: { id: '', items: [] }
};

const reducer = (
	state: OrderDomain.State = initialState,
	action: OrderDomain.Action
): OrderDomain.State => {
	switch (action.type) {
		case 'LOGGED_OUT':
			return {
				...initialState
			};
		case 'SESSION_LOADED':
			return {
				...state,
				cart: action.payload.cart,
				order: action.payload.order ? action.payload.order : undefined
			};
		case 'CART_UPDATED':
			return {
				...state,
				cart: action.payload
			};
		case 'ORDER_SENT': {
			return {
				...state,
				cart: action.payload.session.cart,
				order: action.payload
			};
		}
		case 'ORDER_STATUS_UPDATE': {
			if (!state.order) {
				return state;
			}

			return {
				...state,
				order: {
					...state.order,
					status: action.payload
				}
			};
		}
		case 'STORE_SET':
			return {
				...state,
				cart: action.payload.cart
			};
		default:
			return state;
	}
};

export default reducer;

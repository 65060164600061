import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import {
	useAddCartItemMutation,
	useUpdateCartItemMutation,
	useDeleteCartItemMutation,
	useSendEmployeeOrderMutation,
	OrderItemInput,
	SessionQuery,
	SendEmployeeOrderMutation
} from '../../graphql';

import socket from '../../subscription-client';
import selectors from './selectors';

const mapItem = (
	item: SessionQuery['session']['cart']['items'][number]
): OrderItemInput => ({
	itemId: Number(item.item.id),
	id: item.id,
	price: item.price,
	taxAmount: item.taxAmount,
	taxRate: item.taxRate,
	modifiers: item.modifiers.map(modifier => ({
		id: modifier.id,
		price: modifier.price,
		modifierId: Number(modifier.modifier.id)
	}))
});

const actions: OrderDomain.OrderDomainActions = {
	useAddToCart() {
		console.log('Order: actions: useAddToCart');
		const dispatch = useDispatch();
		const { push } = useHistory();
		const [redirect, setRedirect] = useState('/menu');
		const [addToCart, { data, loading, error }] = useAddCartItemMutation();

		useEffect(() => {
			if (error) throw error;

			if (!loading && data) {
				if (data.addCartItem) {
					const action: OrderDomain.Actions.CartUpdated = {
						type: 'CART_UPDATED',
						payload: data.addCartItem
					};

					dispatch(action);

					console.log(
						'Order: actions: useAddToCart: useEffect: redirecting to ',
						redirect
					);

					push(redirect);
				} else {
					console.error('failed to add cart item');
					// TODO: dispatch alert about failing to add cart item
				}
			}
		}, [data, loading, error, dispatch, redirect, push]);

		return (item, redirect: string) => {
			console.log('Order: actions: useAddToCart: addToCart');
			console.log('Order: actions: useAddToCart: addToCart: item', item);
			console.log(
				'Order: actions: useAddToCart: return function: setting redirect to ',
				redirect
			);
			setRedirect(redirect);
			addToCart({
				variables: {
					item
				}
			});
		};
	},
	useIncrementItemQuantity(id) {
		console.log('Order: actions: useIncrementItemQuantity');
		const updateCartItem = actions.useUpdateCartItem();
		const item = selectors.useSelectCartItem(id);

		return () => {
			console.log(
				'Order: actions: useIncrementItemQuantity: incrementItemQuantity'
			);
			updateCartItem({ ...mapItem(item) });
		};
	},
	useDecrementItemQuantity(id) {
		console.log('Order: actions: useDecrementItemQuantity');
		const updateCartItem = actions.useUpdateCartItem();
		const deleteCartItem = actions.useDeleteCartItem();
		const item = selectors.useSelectCartItem(id);

		return () => {
			console.log(
				'Order: actions: useDecrementItemQuantity: decrementItemQuantity'
			);
			if (item.quantity === 1) {
				deleteCartItem(item.id);
			} else if (item.quantity > 1) {
				updateCartItem({
					...mapItem(item)
				});
			} else {
				throw new Error('Invalid item quantity');
			}
		};
	},
	useUpdateCartItem() {
		console.log('Order: actions: useUpdateCartItem');
		const dispatch = useDispatch();
		const [updateCartItem, { data, loading, error }] =
			useUpdateCartItemMutation();

		useEffect(() => {
			if (error) throw error;

			if (!loading && data) {
				if (data.updateCartItem) {
					const action: OrderDomain.Actions.CartUpdated = {
						type: 'CART_UPDATED',
						payload: data.updateCartItem
					};

					dispatch(action);
				} else {
					// TODO: dispatch alert about failing to add cart item
				}
			}
		}, [data, loading, error, dispatch]);

		return item => {
			console.log('Order: actions: useUpdateCartItem: updateCartItem');
			console.log(
				'Order: actions: useUpdateCartItem: updateCartItem: item',
				item
			);
			updateCartItem({
				variables: {
					item
				}
			});
		};
	},
	useDeleteCartItem() {
		console.log('Order: actions: useDeleteCartItem');
		const dispatch = useDispatch();
		const [itemId, setItemId] = useState<string>();
		const [deleteCartItem, { data, loading, error }] =
			useDeleteCartItemMutation();

		useEffect(() => {
			if (error) throw error;

			if (!loading && data) {
				if (data.deleteCartItem) {
					const action: OrderDomain.Actions.CartUpdated = {
						type: 'CART_UPDATED',
						payload: data.deleteCartItem
					};

					dispatch(action);
				} else {
					// TODO: dispatch alert about failing to add cart item
				}
			}
		}, [data, loading, error, dispatch, itemId]);

		return (id: string) => {
			console.log('Order: actions: useDeleteCartItem: deleteCartItem');
			deleteCartItem({ variables: { id } });
			setItemId(id);
		};
	},
	useSendOrder() {
		const dispatch = useDispatch();
		const { push } = useHistory();
		const items = useSelector((state: RootState) => state.order.cart.items);
		const [sendOrder, { data, loading, error, client }] =
			useSendEmployeeOrderMutation();

		useEffect(() => {
			if (error) throw error;

			if (!loading && data) {
				if (data.sendEmployeeOrder !== null) {
					console.log('order sent success!');
					console.log('data', data);

					if (data.sendEmployeeOrder) {
						const action: OrderDomain.Actions.OrderSent = {
							type: 'ORDER_SENT',
							payload: data.sendEmployeeOrder
						};

						dispatch(action);
						push('/success');

						console.log('executing subscription in useSendOrder');

						interface SubscriptionData {
							data: {
								orderStatus: SendEmployeeOrderMutation['sendEmployeeOrder']['status'];
							};
						}

						socket
							.subscribe({
								query: gql`
									subscription OrderStatusSubscription(
										$id: ID!
									) {
										orderStatus(id: $id) {
											id
											orderId
											status
											details
											time
										}
									}
								`,
								variables: { id: data.sendEmployeeOrder.id },
								fetchPolicy: 'network-only'
							})
							.subscribe({
								next: ({ data }: SubscriptionData) => {
									console.log(
										'useSendOrder: subscription.subscribe: next: data',
										data
									);

									if (data && data.orderStatus) {
										const action: OrderDomain.Actions.OrderStatusUpdate =
											{
												type: 'ORDER_STATUS_UPDATE',
												payload: data.orderStatus
											};
										dispatch(action);
									}
								}
							});
					} else {
						console.log(
							'order sent but data.sendOrder.id is undefined'
						);
					}
				} else {
					console.log('order sent but data.sendOrder was null');
				}
			}
		}, [data, loading, error, dispatch, client, push]);

		return (termId: number) => {
			sendOrder({
				variables: {
					order: {
						termId,
						items: items.map(mapItem)
					}
				}
			});
		};
	}

	// useSubscribeToOrder(orderId: string) {
	// 	const dispatch = useDispatch();
	// 	console.log('useSubscribeToOrder', orderId);
	// 	useSubscription(orderStatusSubscription, {
	// 		variables: {
	// 			id: orderId,
	// 		},
	// 		fetchPolicy: 'network-only',
	// 		onSubscriptionData(subscription) {
	// 			console.log('onSubscriptionData', subscription);
	// 			const { data, error } = subscription.subscriptionData;
	// 			if (error) throw error;
	// 			if (data && data.orderStatus) {
	// 				const action: OrderDomain.Actions.OrderStatusUpdate = {
	// 					type: 'ORDER_STATUS_UPDATE',
	// 					payload: data.orderStatus,
	// 				};
	// 				dispatch(action);
	// 			}
	// 		},
	// 	});
	// },
};

export default actions;

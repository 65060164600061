/* eslint-disable @typescript-eslint/no-non-null-assertion */
const initialState: AdminDomain.State = {
	terminalIds: [],
	terminals: {},
	orderIds: [],
	orders: {},
	displayAllTerminals: true,
	pendingOrders: {
		ids: [],
		completedIds: [],
		sortMostRecent: true,
		sortLeastRecent: false
	},
	filterPendingOrders: 'ALL'
};

const reducer = (
	state: AdminDomain.State = initialState,
	action: AdminDomain.Action
): AdminDomain.State => {
	let terminalIds: AdminDomain.TerminalId[];
	let terminals: AdminDomain.Terminal;
	let orderIds: AdminDomain.OrderId[];
	let orders: AdminDomain.Orders;
	let storeId: number;
	let termId: number;
	let id: number;
	let filteredIds: string[];
	switch (action.type) {
		case 'TERMINALS_UPDATED':
			terminalIds = [];
			terminals = {};

			for (const term of action.payload) {
				const { termId, storeId } = term;

				terminalIds.push({ termId, storeId });
				terminals[storeId] = { ...terminals[storeId] };
				terminals[storeId][termId] = term;
			}

			return {
				...state,
				terminalIds,
				terminals
			};
		case 'ORDERS_UPDATED':
			orderIds = [...state.orderIds];
			orders = { ...state.orders };

			storeId = action.payload.storeId || 0;
			termId = action.payload.termId || 0;
			id = action.payload.id;

			orderIds.push({ storeId, termId, orderId: String(id) });
			if (orders[storeId]) {
				if (orders[storeId]![termId]) {
					orders[storeId]![termId]![id] = action.payload;
				} else {
					orders = {
						...orders,
						[storeId]: {
							...orders[storeId],
							[termId]: { [id]: action.payload }
						}
					};
				}
			} else {
				orders[storeId] = { ...orders[storeId] };
				orders[storeId]![termId] = { [id]: action.payload };
			}

			return {
				...state,
				orderIds,
				orders
			};
		case 'TOGGLE_DISPLAY_ALL_TERMINALS':
			return {
				...state,
				displayAllTerminals: !state.displayAllTerminals
			};
		case 'TOGGLE_PENDING_ORDERS_SORT_ORDER':
			return {
				...state,
				pendingOrders: {
					...state.pendingOrders,
					ids: state.pendingOrders.ids.reverse(),
					sortMostRecent: !state.pendingOrders.sortMostRecent,
					sortLeastRecent: !state.pendingOrders.sortLeastRecent
				}
			};
		case 'PENDING_ORDERS_UPDATED':
			return {
				...state,
				pendingOrders: {
					...state.pendingOrders,
					ids: action.payload
				}
			};
		case 'PENDING_ORDER_COMPLETE':
			console.log('Admin: reducer: PENDING_ORDER_COMPLETE');
			console.log(
				'Admin: reducer: PENDING_ORDER_COMPLETE: pendingOrders.ids pre-filter',
				state.pendingOrders.ids
			);

			filteredIds = state.pendingOrders.ids.filter(
				id => id !== action.payload
			);

			console.log(
				'Admin: reducer: PENDING_ORDER_COMPLETE: filteredIds',
				filteredIds
			);

			return {
				...state,
				pendingOrders: {
					...state.pendingOrders,
					ids: filteredIds,
					completedIds: [
						...state.pendingOrders.completedIds,
						action.payload
					]
				}
			};
		case 'SET_FILTER_PENDING_ORDERS':
			return { ...state, filterPendingOrders: action.payload };
		default:
			return state;
	}
};

export default reducer;

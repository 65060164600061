import React, { useState, useRef, useEffect } from 'react';

import actions from '../../../domains/UI/actions';

import { BooleanInput, Table, TableDropdown, TextInput } from '..';
import { Modal } from '../../Generic';
import { SaveButton } from '../..';

import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import api from '../../../worker';

// import {
// 	capacityTooltip,
// 	barcodeTooltip,
// 	defaultPriceTooltip,
// 	isVariablePriceTooltip,
// 	sizeTooltip,
// 	componentTooltip,
// 	productTooltip,
// 	containerTooltip
// } from '../tooltips';

export interface ItemFieldsProps {
	itemData: Percomatic.Serialized.Item;
}

export const ItemFields: React.FC<Percomatic.Serialized.Item> = itemData => {
	// const params: { itemId: string } = useParams();

	// const { data } = useItemPricesQuery({
	// 	variables: { storeId: 0, tierId: 0 }
	// });

	//const { data: itemsData } = useItemsQuery();

	// useEffect(() => {
	// 	if (data) {
	// 		const itemPrice = data?.itemPrices.find(
	// 			item =>
	// 				item.itemId === Number(params.itemId) &&
	// 				item.storeId === 0 &&
	// 				item.tierId === 0
	// 		)?.price;
	// 		setDefaultPrice(String(itemPrice));
	// 	}
	// }, [data, params.itemId]);

	useEffect(() => {
		(async () => {
			const components = await api.getComponents();
			const sizes = await api.getSizes();
			const products = await api.getProducts();
			const containers = await api.getContainers();

			setItemContainer(
				containers.filter(
					container => container.containerId === itemData.containerId
				)
			);
			// setItemBarcode(
			// 	itemsData?.items.find(
			// 		item => Number(item.id) === itemData.itemId
			// 	)?.barcode?.barcode || 'No Barcode Found'
			// );

			setContainers(containers);
			setProducts(products);
			setComponents(components);
			setSizes(sizes);
			if (itemData.size) {
				setItemSize([itemData.size]);
			}
		})();
	}, [
		itemData.containerId,
		// itemData.itemId,
		itemData.size
		// itemsData?.items
	]);

	const toggleModal = actions.useToggleModal();

	const [, setComponents] = useState<Percomatic.Serialized.Component[]>([]);

	const [products, setProducts] = useState<Percomatic.Serialized.Product[]>(
		[]
	);

	const [containers, setContainers] = useState<
		Percomatic.Serialized.Container[]
	>([]);

	const [itemContainer, setItemContainer] = useState<
		Percomatic.Serialized.Container[]
	>([]);

	const [itemBarcode, setItemBarcode] = useState<string>('loading...');

	const [itemProduct, setItemProduct] = useState<
		Percomatic.Serialized.Product[]
	>([itemData.product] ?? []);

	const [itemSize, setItemSize] = useState<Percomatic.Serialized.Size[]>([]);

	const [sizes, setSizes] = useState<Percomatic.Serialized.Size[]>([]);

	const [itemId, setItemId] = useState(String(itemData.id) ?? '');

	// const [defaultPrice, setDefaultPrice] = useState<string>('loading...');

	const [capacity, setCapacity] = useState(String(itemData.capacity) ?? '0');
	const [isVariablePrice, setIsVariablePrice] = useState(
		itemData.isVariablePrice
	);

	const [itemRecipe] = useState<Percomatic.Serialized.Ingredient[]>(
		itemData.recipe ?? []
	);

	const formattedIngredients: {
		componentId: number;
		quantity: number;
		relevance: number;
		itemId: number;
		componentGroup: Percomatic.Serialized.ComponentGroup | undefined;
	}[] = [];

	const formatIngredients = (
		ingredients: Percomatic.Serialized.Ingredient[]
	) => {
		for (const {
			component,
			quantity,
			relevance,
			componentGroup
		} of ingredients) {
			formattedIngredients.push({
				componentId: component?.id ?? 0,
				quantity,
				relevance,
				itemId: itemData.id,
				componentGroup
			});
		}
	};

	formatIngredients(itemRecipe);

	// const editedItemData = {
	// 	itemId: Number(itemId),
	// 	productId: Number(itemProduct),
	// 	sizeId: Number(itemSize[0]?.id),
	// 	isVariablePrice,
	// 	containerId: itemContainer[0]
	// 		? itemContainer[0].containerId
	// 		: undefined,
	// 	capacity: Number(capacity),
	// 	barcode: { itemId: itemData.id, barcode: itemBarcode },
	// 	defaultPrice: { price: Number(defaultPrice) / 100 }
	// };

	// const [editItem] = useEditItemMutation({
	// 	variables: { item: editedItemData }
	// });

	// const [editRecipe] = useEditRecipeMutation({
	// 	variables: { ingredients: formattedIngredients }
	// });

	const submitEditedItem = () => {
		// editItem();
		//editRecipe();
	};

	const validated = useRef(false);

	useEffect(() => {
		isNaN(Number(capacity)) ||
		isNaN(Number(itemProduct)) ||
		isNaN(Number(itemBarcode))
			? (validated.current = false)
			: (validated.current = true);
	}, [capacity, itemProduct, itemBarcode]);

	return (
		<div>
			<h2 className="text-light font-weight-bold">Item Fields</h2>
			<Table>
				<TextInput
					state={itemId}
					setState={setItemId}
					label="Item Id"
					readOnly={true}
				/>
				<TextInput
					label="Capacity"
					state={capacity}
					setState={setCapacity}
					content={'capacityTooltip'}
				/>
				{/* <TextInput
					state={defaultPrice}
					setState={setDefaultPrice}
					label="Default Price (in cents)"
					content={'defaultPriceTooltip'}
				/> */}
				<TextInput
					state={itemBarcode}
					setState={setItemBarcode}
					label="Barcode"
					content={'barcodeTooltip'}
				/>
				<BooleanInput
					label="Is Variable Price"
					setState={setIsVariablePrice}
					selected={isVariablePrice}
					content={'isVariablePriceTooltip'}
				/>
			</Table>

			<TableDropdown
				state={itemSize}
				dropdownItems={sizes}
				label="Size"
				singleRelationship={true}
				onDelete={(_, key) =>
					setItemSize(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setItemSize([item])}
				content={'sizeTooltip'}
			/>

			<TableDropdown
				state={itemContainer}
				dropdownItems={containers}
				label="Container"
				singleRelationship={true}
				onDelete={(_, key) =>
					setItemContainer(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setItemContainer([item])}
				content={'containerTooltip'}
			/>

			<TableDropdown
				state={itemProduct}
				dropdownItems={products}
				label="Product"
				singleRelationship={true}
				onDelete={(_, key) =>
					setItemProduct(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setItemProduct([item])}
				content={'productTooltip'}
			/>

			{/* <ItemRecipeTable
				state={itemRecipe}
				dropdownItems={components}
				label="Component"
				showDropdownButton={true}
				onAdd={item => {
					const recipeNames = itemRecipe.map(
						item => item.component?.name
					);

					if (!recipeNames.includes(item.name)) {
						setItemRecipe(items => [
							...items,
							{ component: item, quantity: 0, relevance: 0 }
						]);
					}
				}}
				onDelete={(_, key) =>
					setItemRecipe(items =>
						items.filter((_, index) => index !== key)
					)
				}
				quantityOnChange={(e, id) => {
					const updatedRecipe = itemRecipe.map(item =>
						item.component?.id === id
							? { ...item, quantity: Number(e.target.value) }
							: item
					);

					setItemRecipe(updatedRecipe);
				}}
				relevanceOnChange={(e, id) => {
					const updatedRecipe = itemRecipe.map(item =>
						item.component?.id === id
							? { ...item, relevance: Number(e.target.value) }
							: item
					);

					setItemRecipe(updatedRecipe);
				}}
				content={componentTooltip}
			/> */}

			<Modal
				showConfirmationButton={validated.current ? true : false}
				confirmationMessage="Yes"
				confirmationButtonHandler={submitEditedItem}
			>
				{validated.current === false ? (
					<>
						<h1 className="mb-4 text-danger">
							There was a problem
						</h1>
						<p>The following properties must be a number:</p>
						<ul>
							<li>Container Id</li>
							<li>Capacity</li>
							<li>Product Id</li>
							<li>Barcode</li>
						</ul>
					</>
				) : (
					<>
						<h1>Are you sure?</h1>
						<p>
							This action will affect the {itemData.size?.name}{' '}
							{itemData.product?.name} item.
						</p>
					</>
				)}
			</Modal>

			<SaveButton onClick={() => toggleModal()} />
		</div>
	);
};

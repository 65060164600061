import React from 'react';
import { DefaultNoticeEdgeFieldsFragment } from '../../../graphql';

export type DefaultNoticesHistoryProps = {
	histories: DefaultNoticeEdgeFieldsFragment[];
};

export const DefaultNoticesHistory: React.FC<DefaultNoticesHistoryProps> = ({
	histories
}) => {
	return (
		<div className="px-3 mb-5">
			<h3 className="my-0">History</h3>
			<hr />
			{histories.length === 0 && (
				<div className="default-notice-message">
					<p>There are no historic defaults.</p>
				</div>
			)}
			{histories.length > 0 && (
				<table className="table table-dark">
					<thead>
						<tr>
							<th>Description</th>
							<th>Default Date</th>
							<th>Resolution Date</th>
						</tr>
					</thead>
					<tbody>
						{histories.map(history => {
							const defaultDate = String(
								history.node.defaultDate
							);
							const resolutionDate = String(
								history.node.resolutionDate
							);
							return (
								<tr key={history.node.id}>
									<td>{history.node.description}</td>
									<td>
										{defaultDate.slice(5, 7)}-
										{defaultDate.slice(8, 10)}-
										{defaultDate.slice(0, 4)}
									</td>
									<td>
										{resolutionDate.slice(5, 7)}-
										{resolutionDate.slice(8, 10)}-
										{resolutionDate.slice(0, 4)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
		</div>
	);
};

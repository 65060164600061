import React from 'react';

export interface InputProps {
	classname?: string;
	label: string;
	name?: string;
	type: string;
	readOnly?: boolean;
	value: string | number;
	change: React.ChangeEventHandler<HTMLInputElement>;
	disabled?: boolean;
	required?: boolean;
}

export const Input: React.FC<InputProps> = ({
	classname = '',
	label,
	name = '',
	type,
	readOnly = false,
	value,
	change,
	disabled = false,
	required = false
}: InputProps) => {
	return (
		<div className={classname}>
			<label>{label}</label>
			<input
				readOnly={readOnly}
				name={name}
				type={type}
				value={value}
				onChange={change}
				disabled={disabled}
				required={required}
			/>
		</div>
	);
};

import React from 'react';

export interface DisputeStatusButtonProps {
	disputeStatus: string;
}

export const DisputeStatusButton: React.FC<DisputeStatusButtonProps> = ({
	disputeStatus
}) => {
	return (
		<button disabled={true} className="btn btn-secondary mr-3">
			{disputeStatus.toUpperCase()}
		</button>
	);
};

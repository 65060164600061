import { OrderItemFieldsFragment } from '../../graphql';

const initialState: RefundDomain.State = {
	returnReasonId: 0,
	items: [],
	destinations: [],
	prices: {
		subtotal: 0,
		tax: 0
	}
};

const reducer = (
	state: RefundDomain.State = initialState,
	action: RefundDomain.Action
): RefundDomain.State => {
	switch (action.type) {
		case 'SET_RETURN_REASON_ID':
			return {
				...state,
				returnReasonId: action.payload
			};
		case 'UPDATE_REFUND_ITEMS':
			return state.items.some(
				(item: Partial<OrderItemFieldsFragment>) =>
					item.id === action.payload.id
			)
				? {
						...state,
						items: state.items.filter(
							(item: Partial<OrderItemFieldsFragment>) =>
								item.id !== action.payload.id
						)
				  }
				: {
						...state,
						items: [...state.items, action.payload]
				  };
		case 'CLEAR_REFUND_ITEMS':
			return {
				...state,
				items: []
			};
		case 'SET_REFUND_DESTINATIONS':
			return {
				...state,
				destinations: action.payload
			};
		case 'SET_PRICES':
			return {
				...state,
				prices: {
					subtotal: action.payload.subtotal,
					tax: action.payload.tax
				}
			};
		default:
			return state;
	}
};

export default reducer;

import { Fragment, useMemo } from 'react';

import {
	TextField,
	DateField,
	MoneyField,
	CentsField,
	PercentField,
	makeTableComponent
} from '../../../components';
import { formatTime, formatMoney } from '../../../utils';
import { TransItemComparisonField, ItemPair, OLOItem } from './types';
import { ItemDetails } from './ItemDetails';
import { getModifierPairs } from './getModifierPairs';
import { TransModifierComparison } from './TransModifierComparison';

export type TransItemComparisonProps = ItemPair & {
	storeId: number;
	priceTierId: number;
};

const ItemPricesTable = makeTableComponent<OLOItem['item']['prices'][number]>(
	['id', 'storeId', 'tierId', 'price', 'lastModified', 'serverLastModified'],
	{
		formatColumnFunctions: {
			price: formatMoney,
			lastModified: formatTime,
			serverLastModified: formatTime
		}
	}
);

const transItemComparisonFields: TransItemComparisonField[] = [
	{
		label: 'Price',
		olo: 'price',
		perc: 'price',
		oloComp: CentsField,
		percComp: MoneyField
	},
	{
		label: 'Tax Amount',
		olo: 'taxAmount',
		perc: 'taxAmount',
		oloComp: CentsField,
		percComp: MoneyField
	},
	{ label: 'Tax Rate', olo: 'taxRate', perc: 'taxRate', comp: PercentField },
	{
		olo: 'quantity',
		oloLabel: 'Quantity',
		perc: 'discountAmount',
		percLabel: 'Discount Amount',
		percComp: MoneyField
	}
];

export const TransItemComparison: React.FC<TransItemComparisonProps> = ({
	storeId,
	priceTierId,
	oloItem,
	percItem
}) => {
	const { modifierPairs, orphans } = useMemo(
		() => getModifierPairs(oloItem.modifiers, percItem.modifiers),
		[oloItem.modifiers, percItem.modifiers]
	);

	return (
		<div className="border border-light rounded py-2 mb-2">
			<div className="row">
				<div className="col-12">
					<h3 className="text-info mb-0">{percItem.ticketName}</h3>
				</div>

				<div className="col-12 mb-2">
					<ItemDetails item={oloItem.item} />
				</div>

				<ItemPricesTable
					className="mb-1"
					data={oloItem.item.prices.filter(
						price =>
							(price.storeId === storeId ||
								price.storeId === 0) &&
							(price.tierId === priceTierId || price.tierId === 0)
					)}
				/>

				<div className="col-6">
					<h4 className="text-danger mb-0">OLO</h4>
				</div>

				<div className="col-6">
					<h4 className="text-danger mb-0">PERC</h4>
				</div>

				<div className="col-6">
					<TextField label="OLO Item ID" value={oloItem.id} />
				</div>

				<div className="col-6">
					<DateField
						label="PERC Item Add Time"
						value={percItem.itemAddTime}
					/>
				</div>

				{transItemComparisonFields.map(
					(
						{
							olo,
							perc,
							label = '',
							oloLabel = label,
							percLabel = label,
							comp = TextField,
							oloComp: OloComp = comp,
							percComp: PercComp = comp
						},
						key
					) => (
						<Fragment key={key}>
							<div className="col-6">
								{olo && (
									<OloComp
										label={oloLabel}
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										value={oloItem[olo] as any}
									/>
								)}
							</div>

							<div className="col-6">
								{perc && (
									<PercComp
										label={percLabel}
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										value={percItem[perc] as any}
									/>
								)}
							</div>
						</Fragment>
					)
				)}

				<div className="col-12">
					<h3 className="text-success mb-0">Modifiers</h3>
				</div>

				{modifierPairs.map(({ oloModifier, percModifier }, key) => (
					<TransModifierComparison
						storeId={storeId}
						priceTierId={priceTierId}
						oloModifier={oloModifier}
						percModifier={percModifier}
						key={key}
					/>
				))}

				{(orphans.oloModifiers.length > 0 ||
					orphans.percModifiers.length > 0) && (
					<div className="col-12">
						<h4 className="text-light">Orphans</h4>

						<pre>{JSON.stringify(orphans, null, '\t')}</pre>
					</div>
				)}
			</div>
		</div>
	);
};

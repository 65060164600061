import { useDispatch } from 'react-redux';
import { OrderItemFieldsFragment, RefundDestinationInput } from '../../graphql';

const actions: RefundDomain.RefundDomainActions = {
	useSetReturnReasonId() {
		const dispatch = useDispatch();

		return returnReasonId => {
			const action: RefundDomain.Actions.SetReturnReason = {
				type: 'SET_RETURN_REASON_ID',
				payload: returnReasonId
			};

			dispatch(action);
		};
	},
	useUpdateRefundItems() {
		const dispatch = useDispatch();

		return (item: OrderItemFieldsFragment) => {
			const action: RefundDomain.Actions.UpdateRefundItems = {
				type: 'UPDATE_REFUND_ITEMS',
				payload: item
			};

			dispatch(action);
		};
	},
	useClearRefundItems() {
		const dispatch = useDispatch();

		return () => {
			const action: RefundDomain.Actions.ClearRefundItems = {
				type: 'CLEAR_REFUND_ITEMS'
			};

			dispatch(action);
		};
	},
	useSetPrices() {
		const dispatch = useDispatch();

		return (subtotal: number, tax: number) => {
			const action: RefundDomain.Actions.SetPrices = {
				type: 'SET_PRICES',
				payload: { subtotal, tax }
			};

			dispatch(action);
		};
	},
	useSetRefundDestinations() {
		const dispatch = useDispatch();

		return (destinations: RefundDestinationInput[]) => {
			const action: RefundDomain.Actions.SetRefundDestinations = {
				type: 'SET_REFUND_DESTINATIONS',
				payload: destinations
			};

			dispatch(action);
		};
	}
};

export default actions;

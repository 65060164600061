import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';

import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

import apollo from './graphql-client';
import store from './store';
import App from './App';

if (process.env.NODE_ENV === 'production') {
	switch (window.location.hostname) {
		case 'perc-orders.biggby.com':
			if (process.env.REACT_APP_SENTRY_DSN_PROD) {
				Sentry.init({
					dsn: process.env.REACT_APP_SENTRY_DSN_PROD
				});
			}

			if (process.env.REACT_APP_LOGROCKET_ORG_ID_PROD) {
				LogRocket.init(process.env.REACT_APP_LOGROCKET_ORG_ID_PROD);
			}

			break;
		case 'perc-orders-preview.biggby.com':
			if (process.env.REACT_APP_SENTRY_DSN_PREVIEW) {
				Sentry.init({
					dsn: process.env.REACT_APP_SENTRY_DSN_PREVIEW
				});
			}

			if (process.env.REACT_APP_LOGROCKET_ORG_ID_PREVIEW) {
				LogRocket.init(process.env.REACT_APP_LOGROCKET_ORG_ID_PREVIEW);
			}

			break;
		case 'perc-orders-staging.biggby.com':
			if (process.env.REACT_APP_SENTRY_DSN_STAGING) {
				Sentry.init({
					dsn: process.env.REACT_APP_SENTRY_DSN_STAGING
				});
			}

			if (process.env.REACT_APP_LOGROCKET_ORG_ID_STAGING) {
				LogRocket.init(process.env.REACT_APP_LOGROCKET_ORG_ID_STAGING);
			}

			break;
	}
}

ReactDOM.render(
	<Provider store={store}>
		<ApolloProvider client={apollo}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ApolloProvider>
	</Provider>,
	document.getElementById('root')
);

import React from 'react';
import * as Sentry from '@sentry/react';

import apollo from '../../graphql-client';
import { LogoutDocument } from '../../graphql';

export const ErrorFallback: React.FC = () => {
	return (
		<div className="container mt-5 mx-auto">
			<h1 className="text-danger display-1">There was an error.</h1>

			<button
				className="btn btn-primary btn-lg my-3"
				onClick={() => {
					apollo
						.mutate({ mutation: LogoutDocument })
						.then(data => {
							if (data.errors) {
								for (const graphqlError of data.errors) {
									Sentry.withScope(scope => {
										scope.setExtra(
											'in-error-boundary',
											true
										);
										scope.setExtra(
											'in-error-boundary-logout-mutation',
											true
										);

										scope.setExtra(
											'graphql-error',
											JSON.stringify(graphqlError)
										);

										Sentry.captureException(
											graphqlError.originalError ||
												graphqlError
										);
									});
								}
							}
						})
						.catch(err => {
							console.error(err);
							Sentry.withScope(scope => {
								scope.setExtra('in-error-boundary', true);

								Sentry.captureException(err);
							});
						})
						.finally(() => {
							// eslint-disable-next-line no-restricted-globals
							location.reload();
						});
				}}
			>
				Start Over
			</button>
		</div>
	);
};

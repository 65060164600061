import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
	CreditCardPaymentsFieldFragment,
	GiftCardPaymentsFieldFragment,
	useOrderRefundsQuery,
	useRefundMutation,
	useRefundProcessedMutation
} from '../../../graphql';

import {
	gcRefundStatus,
	refundStatus,
	validateRefundInput
} from '../../../utils';

import { useRefundButtonDisabled } from '../../../hooks/';

import Refund from '../../../domains/Refund';
import { Loading } from '../../Loading';
import { Error } from '../../Generic/Error';

export interface RefundButtonProps {
	creditCardPayments?: CreditCardPaymentsFieldFragment[];
	giftCardPayments?: GiftCardPaymentsFieldFragment[];
	orderId: string;
}

export const RefundButton: React.FC<RefundButtonProps> = ({
	creditCardPayments,
	giftCardPayments,
	orderId
}) => {
	const params = useParams<{ storeId: string }>();
	const { push } = useHistory();

	const { data, error } = useOrderRefundsQuery({
		variables: { id: orderId }
	});

	const refundItems = Refund.selectors.useSelectRefundItems();
	const refundDestinations = Refund.selectors.useSelectRefundDestinations();
	const returnReasonId = Refund.selectors.useSelectReturnReasonId();
	const prices = Refund.selectors.useSelectPrices();

	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	useEffect(() => {
		setErrorMessages([]);
	}, [refundDestinations, refundItems]);

	const [refundProcessed] = useRefundProcessedMutation();

	const [refund, { loading }] = useRefundMutation({
		// onCompleted: accepted
		onCompleted(refundMutationValues) {
			const { orderId, id } = refundMutationValues.refund;
			const refundId = parseInt(id);

			refundProcessed({
				variables: {
					orderId: orderId,
					refundId
				}
			});
			push(`/stripe/store/${params.storeId}/${orderId}`);
		},
		onError(err) {
			alert(err.message);
		}
	});

	const [isRefunded, setIsRefunded] = useState<boolean>(false);
	useEffect(() => {
		let areCreditCardPaymentsRefunded = true;
		let areGiftCardPaymentsRefunded = true;

		if (creditCardPayments) {
			areCreditCardPaymentsRefunded = refundStatus(creditCardPayments);
		}

		if (giftCardPayments) {
			areGiftCardPaymentsRefunded = gcRefundStatus(
				giftCardPayments,
				data?.order?.refunds
			);
		}

		setIsRefunded(
			areCreditCardPaymentsRefunded && areGiftCardPaymentsRefunded
		);
	}, [creditCardPayments, giftCardPayments, data?.order?.refunds]);

	const isRefundButtonDisabled = useRefundButtonDisabled(isRefunded);

	const handleRefund = () => {
		console.log('RefundButton: handleRefund');
		console.log('RefundButton: handleRefund: data?.order', data?.order);

		if (data?.order) {
			const [isRefundInputValid, errorMessages] = validateRefundInput(
				[
					...data.order.creditCardPayments,
					...data.order.giftCardPayments
				],
				refundDestinations,
				prices,
				data.order.refunds
			);

			console.log(
				'RefundButton: handleRefund: isRefundInputValid',
				isRefundInputValid
			);

			if (isRefundInputValid) {
				refund({
					variables: {
						orderId: data.order.id,
						returnReasonId,
						refundDestinations,
						orderItemIds: refundItems.map(
							refundItem => refundItem.id
						)
					}
				});
			}
			if (errorMessages.length > 0) {
				setErrorMessages(errorMessages);
			}
		}
	};

	if (!data) return <Loading />;
	if (error) return <Error error={error.message} />;

	return (
		<>
			<button
				disabled={isRefundButtonDisabled || loading}
				className="btn btn-secondary mr-3"
				onClick={() => handleRefund()}
			>
				{!isRefunded ? (
					loading ? (
						<Loading />
					) : (
						'Refund'
					)
				) : (
					'Order Already Refunded'
				)}
			</button>
			{errorMessages.length > 0
				? errorMessages.map(message => (
						<p className="pt-2">{message}</p>
				  ))
				: null}
		</>
	);
};

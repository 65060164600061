import React, { FunctionComponent } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ApolloError } from '@apollo/client';

import {
	Loading,
	RefundButton,
	RefundDestination,
	RefundOrderItems,
	ReturnReasons
} from '../../../components';

import { StripeOrderQuery } from '../../../graphql';

export type RefundProps = RouteComponentProps<
	Record<string, never>,
	Record<string, never>,
	{
		data: StripeOrderQuery | undefined;
		loading: boolean;
		error: ApolloError | undefined;
	}
>;

export const Refund: FunctionComponent<RefundProps> = (
	props
): React.ReactElement => {
	const data = props.location.state.data;
	const loading = props.location.state.loading;
	const error = props.location.state.error;

	const order = data?.order;

	const creditCardPayments = order?.creditCardPayments;
	const giftCardPayments = order?.giftCardPayments;

	if (loading) {
		return <Loading />;
	}

	if (!data || error) {
		return (
			<div className="p-3">
				<div className="alert alert-danger">
					Please select your store to see order information.
					<Link to="/" className="text-danger">
						{' '}
						Go back.
					</Link>
				</div>
			</div>
		);
	}

	return (
		<div {...props} className="refund or-info-root text-light py-3">
			<div className="order-info-header">
				<h1 className="px-3">REFUND</h1>
			</div>
			<div
				className="alert alert-info alert-dismissible fade show"
				role="alert"
			>
				Please submit a ticket to{' '}
				<a href="mailto:tickets@biggby.com">tickets@biggby.com</a> once
				you have finished processing a refund so we can ensure reporting
				is updated. In the ticket please include the Date, Order ID,
				Store Number, and Amount Refunded. Thank you!
			</div>
			<p className="px-3 order-number">Store {order?.storeId}</p>
			<p className="px-3 order-number">{order?.id}</p>

			<hr />

			{data && <ReturnReasons />}

			<hr />

			{data && <RefundOrderItems {...data} />}

			<hr />

			{data && <RefundDestination {...data} />}

			{(creditCardPayments || giftCardPayments) && order && (
				<RefundButton
					creditCardPayments={creditCardPayments}
					giftCardPayments={giftCardPayments}
					orderId={order.id}
				/>
			)}
		</div>
	);
};

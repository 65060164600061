import React from 'react';

import { TextArea, Input, Select } from '../../Generic';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BrandStandardEdgeFieldsFragment } from '../../../graphql';

export type ActiveDefaultFormProps = {
	submit: React.FormEventHandler<HTMLFormElement>;
	change: React.ChangeEventHandler<
		HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
	>;
	edit: boolean;
	activeDefault: {
		id: string;
		description: string;
		defaultDate: string;
		notificationMethod: string;
		brandStandardsViolated: string;
		reportedBy: string;
		resolved: boolean;
		resolvedDate: string;
	};
	brandStandardsModal: boolean;
	setBrandStandardsModal: (value: React.SetStateAction<boolean>) => void;
	brandStandards: BrandStandardEdgeFieldsFragment[];
	onDelete: () => void;
	toggleModal: () => void;
};

export const ActiveDefaultForm: React.FC<ActiveDefaultFormProps> = ({
	submit,
	change,
	edit,
	activeDefault,
	brandStandardsModal,
	setBrandStandardsModal,
	brandStandards,
	onDelete,
	toggleModal
}) => {
	return (
		<form className="default-notices-form mb-4" onSubmit={submit}>
			<fieldset
				className="default-notices-fieldset"
				disabled={edit ? false : true}
			>
				<div className="default-notices-row-70">
					<TextArea
						classname="default-notices-item"
						label="Description"
						name="description"
						value={activeDefault.description}
						change={change}
					/>
					<Input
						classname="default-notices-item"
						label="Date of Default"
						name="defaultDate"
						type="date"
						value={activeDefault.defaultDate}
						change={change}
						disabled={true}
					/>
				</div>

				<div className="default-notices-row-25">
					<Select
						classname="default-notices-item"
						label="Method of Notification"
						name="notificationMethod"
						value={activeDefault.notificationMethod}
						change={change}
						options={[
							{
								optValue: 'Store Visit',
								optName: 'Store Visit'
							},
							{
								optValue: 'Customer Feedback',
								optName: 'Customer Feedback'
							},
							{
								optValue: 'Social Media',
								optName: 'Social Media'
							},
							{
								optValue: 'Others',
								optName: 'Others'
							}
						]}
					/>
					<div className="default-notices-item">
						<label>
							Brand Standard Violated{' '}
							<FontAwesomeIcon
								icon={faInfoCircle}
								color="white"
								onClick={() => {
									setBrandStandardsModal(
										!brandStandardsModal
									);
									toggleModal();
								}}
							/>
						</label>
						<select
							name="brandStandardsViolated"
							value={activeDefault.brandStandardsViolated}
							onChange={change}
						>
							{brandStandards.map(brandStandard => (
								<option
									key={brandStandard.node.id}
									value={brandStandard.node.brandStandardId}
								>
									{brandStandard.node.description}
								</option>
							))}
						</select>
					</div>
				</div>

				{edit && (
					<>
						<hr />
						<div className="default-notices-resolved-row">
							<div className="resolved-items">
								<Select
									classname="default-notices-item"
									label="Resolved"
									name="resolved"
									value={activeDefault.resolved ? '1' : '0'}
									change={change}
									options={[
										{
											optValue: '0',
											optName: 'Not Resolved'
										},
										{
											optValue: '1',
											optName: 'Resolved'
										}
									]}
								/>
								<Input
									classname="default-notices-item"
									label="Date of Resolution"
									name="resolvedDate"
									type="date"
									value={activeDefault.resolvedDate}
									change={change}
								/>
							</div>
							<button
								type="submit"
								className="btn btn-success h-25"
							>
								Save
							</button>
							<button
								type="button"
								className="btn btn-danger h-25 ml-3"
								onClick={onDelete}
							>
								Delete
							</button>
						</div>
					</>
				)}
			</fieldset>
		</form>
	);
};

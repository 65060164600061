import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { Dropdown } from '..';

import { Percomatic } from '@biggby-coffee/percomatic-typescript';

export interface ProductGroupsListProps {
	currentProductGroups: Percomatic.Serialized.ProductGroup[];
	allProductGroups: Percomatic.Serialized.ProductGroup[];
	selectProductGroup: (id: string) => void;
}

export const ProductGroupsList: React.FC<ProductGroupsListProps> = ({
	currentProductGroups,
	allProductGroups,
	selectProductGroup
}) => {
	const { push } = useHistory();
	const { pathname } = useLocation();
	return (
		<div className="productgroups-list grid-300">
			{pathname.includes('product-management') && (
				<Dropdown
					label={'Product Group'}
					items={allProductGroups}
					showDropdownButton={true}
					clickHandler={item =>
						push('/product-management/product-group/' + item.id)
					}
					linkedItems={true}
					alignLeft={true}
					state={currentProductGroups}
				/>
			)}
			{currentProductGroups?.map(({ id, name }, key) => (
				<button
					onClick={() => selectProductGroup(String(id))}
					key={key}
					className="btn btn-block btn-success mt-0 rounded  productgroups-list-productgroup"
				>
					{name}
				</button>
			))}
		</div>
	);
};

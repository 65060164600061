import actions from './actions';
import reducer from './reducer';
import selectors from './selectors';

const Refund: RefundDomain.RefundDomain = {
	actions: actions,
	selectors: selectors,
	reducer
};

export default Refund;

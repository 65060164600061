import React, { useState, useRef, useEffect } from 'react';

//import { useEditComponentMutation } from '../../graphql';

import actions from '../../../domains/UI/actions';

import { BooleanInput, Table, TableDropdown, TextInput } from '..';
import { Modal } from '../../Generic';
import { SaveButton } from '../..';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import api from '../../../worker';

// import {
// 	ticketNameTooltip,
// 	servingsTooltip,
// 	suppressNameFromRecipeTooltip,
// 	isVariableQuantityTooltip,
// 	staticTooltip,
// 	unitTooltip,
// 	baseComponentTooltip,
// 	componentModifierTooltip,
// 	componentVersionTooltip,
// 	inventoryItemTooltip
// } from '../tooltips';

// const mdx = require('@mdx-js/mdx');
// const result = async () => await mdx(` # Hello, MDX I <3 Markdown and JSX `);

export interface ComponentFieldsProps {
	component: Percomatic.Serialized.Component;
}

export const ComponentFields: React.FC<Percomatic.Serialized.Component> =
	component => {
		const toggleModal = actions.useToggleModal();

		const [units, setUnits] = useState<Percomatic.Serialized.Unit[]>([]);

		const [componentUnit, setComponentUnit] = useState<
			Percomatic.Serialized.Unit[]
		>([]);

		const [components, setComponents] = useState<
			Percomatic.Serialized.Component[]
		>([]);

		const [baseComponent, setBaseComponent] = useState<
			Percomatic.Serialized.Component[]
		>([]);

		const [modifiers, setModifiers] = useState<
			Percomatic.Serialized.Modifier[]
		>([]);

		useEffect(() => {
			(async () => {
				const units = await api.getUnits();
				const modifiers = await api.getModifiers();
				const inventoryItems = await api.getInventoryItems();
				const components = await api.getComponents();
				const componentVersions = await api.getComponentVersions();

				setUnits(units);
				setModifiers(modifiers);
				setInventoryItems(inventoryItems);
				setComponents(components);
				setComponentVersions(componentVersions);

				if (component.componentVersion) {
					setComponentVersion([component.componentVersion]);
				}

				setBaseComponent(
					components.filter(
						apiComponent =>
							apiComponent.id === component.baseComponentId
					)
				);
				setComponentUnit(
					units.filter(
						unit => unit.unitName === component.recipeUnits
					)
				);
				setComponentInventoryItem(
					inventoryItems.filter(
						item => item.id === component.inventoryItem.id
					)
				);
			})();
		}, [
			component.baseComponentId,
			component.componentVersion,
			component.inventoryItem.id,
			component.recipeUnits
		]);

		const [componentId] = useState(component.id ? component.id : '');
		const [name, setName] = useState(component.name ?? '');

		const [suppressNameFromRecipe, setSuppressNameFromRecipe] = useState(
			component.suppressNameFromRecipe
		);
		const [ticketName, setTicketName] = useState(
			component.ticketName ?? ''
		);

		const [inventoryItems, setInventoryItems] = useState<
			Percomatic.Serialized.InventoryItem[]
		>([]);

		const [componentInventoryItem, setComponentInventoryItem] = useState<
			Percomatic.Serialized.InventoryItem[]
		>([]);

		const [componentVersions, setComponentVersions] = useState<
			Percomatic.Serialized.ComponentVersion[]
		>([]);

		const [componentVersion, setComponentVersion] = useState<
			Percomatic.Serialized.ComponentVersion[]
		>([]);

		const [servings, setServings] = useState(`${component.servings}` ?? '');

		const [isVariableQuantity, setIsVariableQuantity] = useState(
			component.isVariableQuantity
		);
		const [isStatic, setIsStatic] = useState(component.static);

		const [componentModifier, setComponentModifier] = useState<
			Percomatic.Serialized.Modifier[]
		>([]);

		// const editedComponent = {
		// 	componentId: Number(component.id),
		// 	name: name,
		// 	suppressNameFromRecipe,
		// 	ticketName,ss
		// 	unitId: Number(componentUnit[0]),
		// 	inventoryItemId: Number(componentInventoryItem[0]),
		// 	servings: Number(servings),
		// 	componentVersionId: componentVersion[0] ? componentVersion[0].id : 0,
		// 	baseComponentId: baseComponent[0] ? baseComponent[0].id : undefined,
		// 	isVariableQuantity: isVariableQuantity,
		// 	static: isStatic,
		// 	modifier: componentModifier[0] ? componentModifier[0].id : 0
		// };

		const preventDuplicates = (array: unknown[]) => {
			const valuesSoFar = [];

			for (let i = 0; i < array.length; ++i) {
				const value = array[i];

				if (valuesSoFar.indexOf(value) !== -1) {
					array.pop();
				}

				valuesSoFar.push(value);
			}

			return false;
		};

		const validated = useRef(false);

		useEffect(() => {
			isNaN(Number(servings))
				? (validated.current = false)
				: (validated.current = true);
		}, [servings]);

		preventDuplicates(componentUnit);
		preventDuplicates(componentInventoryItem);

		// const [editComponent] = useEditComponentMutation({
		// 	variables: { component: editedComponent }
		// });

		const submitEditedComponent = () => {
			//editComponent();
		};

		return (
			<>
				<Table>
					<TextInput label="Id" state={componentId} readOnly={true} />
					<TextInput label="Name" state={name} setState={setName} />
					<TextInput
						label="Ticket Name"
						state={ticketName}
						setState={setTicketName}
						content={'ticketNameTooltip'}
					/>
					<TextInput
						label={`Servings (${component.recipeUnitsPlural})`}
						state={servings}
						setState={setServings}
						content={'servingsTooltip'}
					/>
					<BooleanInput
						label="Suppress Name From Recipe"
						setState={setSuppressNameFromRecipe}
						selected={suppressNameFromRecipe}
						content={'suppressNameFromRecipeTooltip'}
					/>
					<BooleanInput
						label="Is Variable Quantity"
						setState={setIsVariableQuantity}
						selected={isVariableQuantity}
						content={'isVariableQuantityTooltip'}
					/>
					<BooleanInput
						label="Static"
						setState={setIsStatic}
						selected={isStatic}
						content={'staticTooltip'}
					/>
				</Table>

				<TableDropdown
					singleRelationship={true}
					state={componentInventoryItem}
					dropdownItems={inventoryItems}
					showDropdownButton={true}
					label="Inventory Item"
					onDelete={(_, key) =>
						setComponentInventoryItem(items =>
							items.filter((_, index) => index !== key)
						)
					}
					onAdd={(item, key) => setComponentInventoryItem([item])}
					content={'inventoryItemTooltip'}
				/>

				<TableDropdown
					singleRelationship={true}
					state={componentUnit}
					dropdownItems={units}
					label="Unit"
					onDelete={(_, key) =>
						setComponentUnit(items =>
							items.filter((_, index) => index !== key)
						)
					}
					onAdd={(item, key) => setComponentUnit([item])}
					content={'unitTooltip'}
				/>

				<TableDropdown
					singleRelationship={true}
					state={baseComponent}
					dropdownItems={components}
					label="Base Component"
					onDelete={(_, key) =>
						setBaseComponent(items =>
							items.filter((_, index) => index !== key)
						)
					}
					onAdd={(item, key) => setBaseComponent([item])}
					content={'baseComponentTooltip'}
				/>

				<TableDropdown
					singleRelationship={true}
					state={componentModifier}
					dropdownItems={modifiers}
					label="Component Modifier"
					onDelete={(_, key) =>
						setComponentModifier(items =>
							items.filter((_, index) => index !== key)
						)
					}
					onAdd={(item, key) => setComponentModifier([item])}
					content={'componentModifierTooltip'}
				/>

				<TableDropdown
					singleRelationship={true}
					state={componentVersion}
					dropdownItems={componentVersions}
					showDropdownButton={false}
					label="Component Version"
					onDelete={(_, key) =>
						setComponentVersion(items =>
							items.filter((_, index) => index !== key)
						)
					}
					onAdd={(item, key) => setComponentVersion([item])}
					content={'componentVersionTooltip'}
				/>

				<Modal
					showConfirmationButton={validated.current ? true : false}
					confirmationMessage="Yes"
					confirmationButtonHandler={submitEditedComponent}
				>
					{validated.current === false ? (
						<>
							<h1 className="mb-4 text-danger">
								There was a problem
							</h1>
							<p>The following properties must be a number:</p>
							<ul>
								<li>Servings</li>
							</ul>
						</>
					) : (
						<>
							<h1>Are you sure?</h1>
							<p>
								This action will affect the {component.name}{' '}
								component.
							</p>
						</>
					)}
				</Modal>
				<SaveButton onClick={() => toggleModal()} />
			</>
		);
	};

import { useState, useEffect, useRef } from 'react';

//import { useAddProductGroupMutation } from '../../../graphql';

import actions from '../../../../domains/UI/actions';

import { BooleanInput, Table, TableDropdown, TextInput } from '../../';
import { Modal } from '../../../Generic';
import { SaveButton } from '../../../';

import api from '../../../../worker';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

// import {
// 	priorityTooltip,
// 	isVisibleTooltip,
// 	productGroupTooltip
// } from '../../tooltips';

export const ProductGroup: React.FC = () => {
	useEffect(() => {
		(async () => {
			const productGroups = await api.getProductGroups();

			setProductGroups(productGroups);
			setProductGroupId(
				String(productGroups[productGroups.length - 2].id + 1)
			);
		})();
	}, []);

	const [productGroups, setProductGroups] = useState<
		Percomatic.Serialized.ProductGroup[]
	>([]);

	const [productGroupId, setProductGroupId] = useState<string>();

	const [name, setName] = useState('');

	const [parentProductGroup, setParentProductGroup] = useState<
		Percomatic.Serialized.ProductGroup[]
	>([]);

	const [priority, setPriority] = useState('');

	const [, setIsVisible] = useState(false);

	const toggleModal = actions.useToggleModal();

	// const newProductGroupData = {
	// 	productGroupId: Number(productGroupId),
	// 	name: name,
	// 	parentId: parentProductGroup[0] ? parentProductGroup[0].id : 0,
	// 	priority: Number(priority),
	// 	isVisible
	// };

	// const [addProductGroup] = useAddProductGroupMutation({
	// 	variables: { productGroup: newProductGroupData }
	// });

	const submitAddProductGroup = () => {
		//addProductGroup();
		setName('');
		setParentProductGroup([]);
		setPriority('');
		setIsVisible(false);
	};

	const validated = useRef(false);

	useEffect(() => {
		isNaN(Number(priority))
			? (validated.current = false)
			: (validated.current = true);
	}, [priority]);

	return (
		<>
			<Table>
				<TextInput label="Name" setState={setName} state={name} />
				<TextInput label="Id" state={productGroupId} readOnly={true} />
				<TextInput
					label="Priority"
					setState={setPriority}
					state={priority}
					content={'priorityTooltip'}
				/>
				<BooleanInput
					label="Is Visible"
					setState={setIsVisible}
					content={'isVisibleTooltip'}
				/>
			</Table>

			<TableDropdown
				state={parentProductGroup}
				dropdownItems={productGroups}
				label="Parent Product Group"
				showDropdownButton={true}
				singleRelationship={true}
				onDelete={(_, key) =>
					setParentProductGroup(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setParentProductGroup([item])}
				content={'productGroupTooltip'}
			/>

			<Modal
				showConfirmationButton={true}
				confirmationMessage="Yes"
				confirmationButtonHandler={submitAddProductGroup}
			>
				{validated.current === false ? (
					<>
						<h1 className="mb-4 text-danger">
							There was a problem
						</h1>
						<p>The following properties must be a number:</p>
						<ul>
							<li>Parent Id</li>
							<li>Priority</li>
						</ul>
					</>
				) : (
					<>
						<h1>Are you sure?</h1>
						<p>This action will create a new product group.</p>
					</>
				)}
			</Modal>
			<SaveButton onClick={() => toggleModal()} />
		</>
	);
};

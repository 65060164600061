import React, { useState, useEffect, useRef } from 'react';

// import { useAddInventoryItemMutation } from '../../../graphql';

import actions from '../../../../domains/UI/actions';

import { AllergenFields, Table, TableDropdown, TextInput } from '../..';
import { Modal } from '../../../Generic';
import api from '../../../../worker';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import { PercomaticLoading, SaveButton } from '../../..';

// import { unitTooltip, containerSizeTooltip } from '../../tooltips';

function Body() {
	const toggleModal = actions.useToggleModal();

	useEffect(() => {
		(async () => {
			const inventoryItems = await api.getInventoryItems();
			const units = await api.getUnits();

			setId(String(inventoryItems[inventoryItems.length - 2].id + 1));
			setUnits(units);
		})();
	}, []);

	const [id, setId] = useState('');
	const [name, setName] = useState('');
	const [containerName, setContainerName] = useState('');
	const [containerNamePlural, setContainerNamePlural] = useState('');
	const [unit, setUnit] = useState<Percomatic.Serialized.Unit[]>([]);
	const [containerSize, setContainerSize] = useState('');
	const [calories, setCalories] = useState('');
	const [caloriesFromFat, setCaloriesFromFat] = useState('');
	const [fat, setFat] = useState('');
	const [saturatedFat, setSaturatedFat] = useState('');
	const [transFat, setTransFat] = useState('');
	const [cholesterol, setCholesterol] = useState('');
	const [sodium, setSodium] = useState('');
	const [carbohydrates, setCarbohydrates] = useState('');
	const [dietaryFiber, setDietaryFiber] = useState('');
	const [sugar, setSugar] = useState('');
	const [protein, setProtein] = useState('');
	const [caffeine, setCaffeine] = useState('');

	const [units, setUnits] = useState<Percomatic.Serialized.Unit[]>([]);

	const [allergens] = useState<Record<number, string>[]>([]);

	// const newInventoryItemData = {
	// 	name: name,
	// 	containerName: containerName,
	// 	containerNamePlural: containerNamePlural,
	// 	units: unit[0]?.unitName,
	// 	unitsPlural: unit[0]?.unitNamePlural,
	// 	containerSize: Number(containerSize),
	// 	calories: Number(calories),
	// 	caloriesFromFat: Number(caloriesFromFat),
	// 	fat: Number(fat),
	// 	saturatedFat: Number(saturatedFat),
	// 	transFat: Number(transFat),
	// 	cholesterol: Number(cholesterol),
	// 	carbohydrates: Number(carbohydrates),
	// 	sodium: Number(sodium),
	// 	dietaryFiber: Number(dietaryFiber),
	// 	sugar: Number(sugar),
	// 	protein: Number(protein),
	// 	caffeine: Number(caffeine),
	// 	inventoryItemId: Number(id)
	// };

	// const [addInventoryItem] = useAddInventoryItemMutation({
	// 	variables: { inventoryItem: newInventoryItemData }
	// });

	const submitNewInventoryItem = () => {
		// addInventoryItem();
		setId('');
		setName('');
		setContainerName('');
		setContainerNamePlural('');
		setUnit([]);
		setContainerSize('');
		setCalories('');
		setCaloriesFromFat('');
		setFat('');
		setSaturatedFat('');
		setTransFat('');
		setCholesterol('');
		setSodium('');
		setCarbohydrates('');
		setDietaryFiber('');
		setSugar('');
		setProtein('');
		setCaffeine('');
	};

	const validated = useRef(false);

	useEffect(() => {
		isNaN(Number(calories)) ||
		isNaN(Number(caloriesFromFat)) ||
		isNaN(Number(fat)) ||
		isNaN(Number(saturatedFat)) ||
		isNaN(Number(transFat)) ||
		isNaN(Number(cholesterol)) ||
		isNaN(Number(sodium)) ||
		isNaN(Number(carbohydrates)) ||
		isNaN(Number(dietaryFiber)) ||
		isNaN(Number(sugar)) ||
		isNaN(Number(protein)) ||
		isNaN(Number(caffeine))
			? (validated.current = false)
			: (validated.current = true);
	}, [
		calories,
		caloriesFromFat,
		fat,
		saturatedFat,
		transFat,
		cholesterol,
		sodium,
		carbohydrates,
		dietaryFiber,
		sugar,
		protein,
		caffeine
	]);

	return (
		<>
			<Table>
				<TextInput label="Name" state={name} setState={setName} />
				<TextInput
					label="Container Name"
					state={containerName}
					setState={setContainerName}
				/>
				<TextInput
					label="Container Name Plural"
					state={containerNamePlural}
					setState={setContainerNamePlural}
				/>
				<TextInput
					label="Container Size"
					state={containerSize}
					setState={setContainerSize}
					content={'containerSizeTooltip'}
				/>
				<TextInput
					label="Calories"
					state={calories}
					setState={setCalories}
				/>
				<TextInput
					label="Calories From Fat"
					state={caloriesFromFat}
					setState={setCaloriesFromFat}
				/>
				<TextInput label="Fat" state={fat} setState={setFat} />
				<TextInput
					label="Saturated Fat"
					state={saturatedFat}
					setState={setSaturatedFat}
				/>
				<TextInput
					label="Trans Fat"
					state={transFat}
					setState={setTransFat}
				/>
				<TextInput
					label="Cholesterol"
					state={cholesterol}
					setState={setCholesterol}
				/>
				<TextInput
					label="Carbohydrates"
					state={carbohydrates}
					setState={setCarbohydrates}
				/>
				<TextInput label="Sodium" state={sodium} setState={setSodium} />
				<TextInput
					label="Dietary Fiber"
					state={dietaryFiber}
					setState={setDietaryFiber}
				/>
				<TextInput label="Sugar" state={sugar} setState={setSugar} />
				<TextInput
					label="Protein"
					state={protein}
					setState={setProtein}
				/>
				<TextInput
					label="Caffeine"
					state={caffeine}
					setState={setCaffeine}
				/>
				<TextInput
					label="Id"
					state={id}
					setState={setId}
					readOnly={true}
				/>
			</Table>
			<TableDropdown
				state={unit}
				dropdownItems={units}
				label="Unit"
				showDropdownButton={false}
				singleRelationship={true}
				onDelete={(_, key) =>
					setUnit(items => items.filter((_, index) => index !== key))
				}
				onAdd={(item, key) => setUnit([item])}
				content={'unitTooltip'}
			/>
			<AllergenFields allergens={allergens} />
			<Modal
				showConfirmationButton={true}
				confirmationMessage="Yes"
				confirmationButtonHandler={submitNewInventoryItem}
			>
				{validated.current === false ? (
					<>
						<h1 className="mb-4 text-danger">
							There was a problem
						</h1>
						<p>The following properties must be a number:</p>
						<ul>
							<li>Calories</li>
							<li>Calories From Fat</li>
							<li>Fat</li>
							<li>Saturated Fat</li>
							<li>Trans Fat</li>
							<li>Cholesterol</li>
							<li>Sodium</li>
							<li>Carbohydrates</li>
							<li>Dietary Fiber</li>
							<li>Sugar</li>
							<li>Protein</li>
							<li>Caffeine</li>
						</ul>
					</>
				) : (
					<>
						<h1>Are you sure?</h1>
						<p>This action will create a new inventory item.</p>
					</>
				)}
			</Modal>
			<SaveButton onClick={() => toggleModal()} />
		</>
	);
}

export const InventoryItem: React.FC = () => {
	return (
		<div>
			<PercomaticLoading loaded children={<Body />} />
		</div>
	);
};

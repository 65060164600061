import React from 'react';

import { Tooltip } from '../Generic/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

interface TaxStateInputProps {
	label: string;
	state?: string;
	setState: React.Dispatch<React.SetStateAction<string>>;
	content?: React.ReactNode;
}

export const TaxStateInput: React.FC<TaxStateInputProps> = ({
	label,
	state = undefined,
	setState,
	content
}) => {
	const body = (
		<>
			<div className="d-flex align-items-center">
				<th className="border-0 py-3">{label}</th>
				{content && (
					<Tooltip content={content}>
						{' '}
						<FontAwesomeIcon
							icon={faQuestionCircle}
							color="white"
						/>
					</Tooltip>
				)}
			</div>
			<td className="border-0">
				<select
					onChange={e => setState(e.target.value)}
					name={label}
					className="form-control"
					value={state ? state : undefined}
				>
					<option disabled selected>
						{' '}
						-- select an option --{' '}
					</option>
					{stateList.map(option => (
						<option value={option.value}>{option.label}</option>
					))}
				</select>
			</td>
		</>
	);

	return <>{body}</>;
};

export default TaxStateInput;

const stateList = [
	{
		label: 'Alabama',
		value: 'Alabama'
	},
	{
		label: 'Alaska',
		value: 'Alaska'
	},
	{
		label: 'Arizona',
		value: 'Arizona'
	},
	{
		label: 'Arkansas',
		value: 'Arkansas'
	},
	{
		label: 'California',
		value: 'California'
	},
	{
		label: 'Colorado',
		value: 'Colorado'
	},
	{
		label: 'Connecticut',
		value: 'Connecticut'
	},
	{
		label: 'Delaware',
		value: 'Delaware'
	},
	{
		label: 'District of Columbia',
		value: 'District of Columbia'
	},
	{
		label: 'Florida',
		value: 'Florida'
	},
	{
		label: 'Georgia',
		value: 'Georgia'
	},
	{
		label: 'Hawaii',
		value: 'Hawaii'
	},
	{
		label: 'Idaho',
		value: 'Idaho'
	},
	{
		label: 'Illinois',
		value: 'Illinois'
	},
	{
		label: 'Indiana',
		value: 'Indiana'
	},
	{
		label: 'Iowa',
		value: 'Iowa'
	},
	{
		label: 'Kansas',
		value: 'Kansas'
	},
	{
		label: 'Kentucky',
		value: 'Kentucky'
	},
	{
		label: 'Louisiana',
		value: 'Louisiana'
	},
	{
		label: 'Maine',
		value: 'Maine'
	},
	{
		label: 'Maryland',
		value: 'Maryland'
	},
	{
		label: 'Massachusetts',
		value: 'Massachusetts'
	},
	{
		label: 'Michigan',
		value: 'Michigan'
	},
	{
		label: 'Minnesota',
		value: 'Minnesota'
	},
	{
		label: 'Mississippi',
		value: 'Mississippi'
	},
	{
		label: 'Missouri',
		value: 'Missouri'
	},
	{
		label: 'Montana',
		value: 'Montana'
	},
	{
		label: 'Nebraska',
		value: 'Nebraska'
	},
	{
		label: 'Nevada',
		value: 'Nevada'
	},
	{
		label: 'New Hampshire',
		value: 'New Hampshire'
	},
	{
		label: 'New Jersey',
		value: 'New Jersey'
	},
	{
		label: 'New Mexico',
		value: 'New Mexico'
	},
	{
		label: 'New York',
		value: 'New York'
	},
	{
		label: 'North Carolina',
		value: 'North Carolina'
	},
	{
		label: 'North Dakota',
		value: 'North Dakota'
	},
	{
		label: 'Ohio',
		value: 'Ohio'
	},
	{
		label: 'Oklahoma',
		value: 'Oklahoma'
	},
	{
		label: 'Oregon',
		value: 'Oregon'
	},
	{
		label: 'Pennsylvania',
		value: 'Pennsylvania'
	},
	{
		label: 'Rhode Island',
		value: 'Rhode Island'
	},
	{
		label: 'South Carolina',
		value: 'South Carolina'
	},
	{
		label: 'South Dakota',
		value: 'South Dakota'
	},
	{
		label: 'Tennessee',
		value: 'Tennessee'
	},
	{
		label: 'Texas',
		value: 'Texas'
	},
	{
		label: 'Utah',
		value: 'Utah'
	},
	{
		label: 'Vermont',
		value: 'Vermont'
	},
	{
		label: 'Virginia',
		value: 'Virginia'
	},
	{
		label: 'Washington',
		value: 'Washington'
	},
	{
		label: 'West Virginia',
		value: 'West Virginia'
	},
	{
		label: 'Wisconsin',
		value: 'Wisconsin'
	},
	{
		label: 'Wyoming',
		value: 'Wyoming'
	}
];

import actions from './actions';
import reducer from './reducer';
import selectors from './selectors';

const Order: OrderDomain.OrderDomain = {
	actions: actions,
	selectors: selectors,
	reducer
};

export default Order;

import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import UI from '../../domains/UI';

import {
	Error,
	Loading,
	makeTableComponent,
	WrapApollo
} from '../../components';

import { useOrdersQuery, useOrdersLazyQuery, OrdersQuery } from '../../graphql';
import { formatCents } from '../../utils';

const OrdersTable = makeTableComponent<
	OrdersQuery['orders']['edges'][number]['node']
>(
	[
		'id',
		'timePlaced',
		'storeId',
		'pickupName',
		'status',
		'discrepancy',
		'subTotal',
		'taxAmount',
		'total',
		'id'
	],
	{
		columnNamesMap: {
			id: (id, index) => (index === 0 ? 'Order ID' : ''),
			subTotal: 'Subtotal',
			taxAmount: 'Tax',
			storeId: 'Store'
		},
		formatColumnFunctions: {
			timePlaced: v => moment(v).fromNow(),
			status: v => v.status.substr(6),
			discrepancy: (v = 0) =>
				v > 0
					? formatCents(v)
					: v < 0
					? `-${formatCents(Math.abs(v))}`
					: '-',
			subTotal: formatCents,
			taxAmount: formatCents,
			total: formatCents,
			id: (id, row, data, columnIndex) =>
				columnIndex === 0 ? (
					id
				) : (
					<Link to={`/admin/orders/${id}`}>Details</Link>
				)
		}
	}
);

const Body: React.FC<{ data?: OrdersQuery['orders']['edges'] }> = ({
	data
}) => <OrdersTable data={data?.map(({ node }) => node) ?? []} />;

export const Orders: React.FC = () => {
	UI.actions.useSetOrientation('landscape');

	const [orders, setOrders] = useState<OrdersQuery['orders']['edges']>([]);
	const [currentOffset, setCurrentOffset] = useState<string>();

	const { loading, error } = useOrdersQuery({
		errorPolicy: 'all',
		variables: {
			first: 20,
			filter: { type: 'BHLOUNGE', hasDiscrepancy: true },
			orderBy: 'created',
			orderDirection: 'desc'
		},
		onCompleted(data) {
			setCurrentOffset(data?.orders.pageInfo.endCursor);

			setOrders(data.orders.edges);
		}
	});

	const [ordersQuery, { loading: lazyLoading, error: lazyError }] =
		useOrdersLazyQuery({
			errorPolicy: 'all',
			onCompleted(data) {
				setCurrentOffset(data.orders.pageInfo.endCursor);

				setOrders(orders => [...orders, ...data.orders.edges]);
			}
		});

	if (error) {
		return <Error error={error.message} />;
	}

	if (lazyError) {
		return <Error error={lazyError.message} />;
	}

	return (
		<div className="text-light py-3">
			<h1 className="px-3">Orders</h1>

			<WrapApollo
				data={orders}
				loading={loading}
				error={error}
				component={Body}
			/>

			{lazyLoading && <Loading />}

			{!lazyLoading && !loading && (
				<div className="d-flex justify-content-center">
					<button
						className="btn btn-lg bg-primary text-white"
						onClick={() =>
							ordersQuery({
								variables: {
									first: 10,
									after: currentOffset,
									filter: {
										type: 'BHLOUNGE',
										hasDiscrepancy: true
									},
									orderBy: 'created',
									orderDirection: 'desc'
								}
							})
						}
					>
						Load More
					</button>
				</div>
			)}
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitbucket } from '@fortawesome/free-brands-svg-icons';
import {
	faCoffee,
	faDotCircle,
	faGlassWhiskey,
	faPumpSoap,
	faUtensilSpoon,
	faVial,
	faMugHot,
	faWineBottle,
	faBoxOpen,
	faShoppingBag,
	faCube,
	faIdCard,
	faCompressAlt,
	faCut,
	faPaperclip,
	faFilter,
	faNewspaper,
	faUndo,
	faTint,
	faCircle,
	faRuler,
	faChevronUp,
	faChevronDown,
	IconDefinition
} from '@fortawesome/free-solid-svg-icons';

import { IngredientDetails } from '..';
import { Drawer, Header, ProductNutrition } from '../..';
import api from '../../../worker';
import { defined } from '../../../utils';
import Product from '../../../domains/Product';

const UNIT_ICONS: { [x: string]: IconDefinition | undefined } = {
	'1': faPumpSoap,
	'2': faUtensilSpoon,
	'4': faBitbucket,
	'11': faVial,
	'14': faUtensilSpoon,
	'17': faGlassWhiskey,
	'18': faVial,
	'19': faMugHot,
	'20': faCoffee,
	'21': faMugHot,
	'22': faMugHot,
	'23': faCoffee,
	'24': faMugHot,
	'25': faCoffee,
	'45': faDotCircle,
	'54': faWineBottle,
	'55': faBoxOpen,
	'57': faCut,
	'58': faShoppingBag,
	'59': faCube,
	'60': faBoxOpen,
	'61': faIdCard,
	'62': faCompressAlt,
	'63': faPaperclip,
	'64': faFilter,
	'65': faMugHot,
	'68': faNewspaper,
	'69': faVial,
	'71': faUndo,
	'73': faTint,
	'78': faVial,
	'81': faCircle,
	'83': faRuler,
	'87': faCircle,
	'88': faUtensilSpoon
};

interface IngredientProps {
	component: Percomatic.Serialized.Component;
	quantity: number;
}

const Ingredient: React.FC<IngredientProps> = ({ component, quantity }) => {
	const icon: IconDefinition | undefined =
		UNIT_ICONS[String(component.unitId)];
	const [isActive, setIsActive] = useState(false);

	return (
		<Drawer
			isActive={isActive}
			tag="div"
			className="ingredient border-light border-bottom"
		>
			<Header
				isActive={isActive}
				color="light"
				onClick={() => setIsActive(!isActive)}
				noHr
				paddingX={3}
				paddingY={1}
			>
				<div className="d-flex align-items-center py-1">
					{icon && (
						<FontAwesomeIcon className="d-block mr-3" icon={icon} />
					)}
					{Math.round(quantity * 10) / 10}{' '}
					{quantity > 1
						? component.recipeUnitsPlural
						: component.recipeUnits}
					{' of '}
					{component.name}
				</div>
			</Header>

			<IngredientDetails
				ingredientNutritionFacts={{
					component,
					quantity,
					servings: component.servings,
					nutritionFacts: component.inventoryItem.nutritionFacts
				}}
			/>
		</Drawer>
	);
};

interface RecipeItemProps {
	item: Percomatic.Serialized.Item;
}

const RecipeItem: React.FC<RecipeItemProps> = ({
	item: {
		size,
		recipe,
		product: { name }
	}
}) => {
	const [isActive, setIsActive] = useState(false);

	const ingredients = recipe.filter(({ component }) =>
		defined(component)
	) as ProductDomain.UI.ComponentIngredient[];

	const components: ProductDomain.UI.IngredientNutritionFacts[] =
		ingredients.map(({ component, quantity }) => ({
			component,
			nutritionFacts: component.inventoryItem.nutritionFacts,
			quantity,
			servings: component.servings
		}));
	const nutritionRows = Product.selectors.useSelectNutrition(components);

	return (
		// this div exists so that the grid items do not expand when their neighbors expand
		<div>
			<Drawer
				isActive={isActive}
				className="recipe-item card border-light"
				footer
			>
				<div
					onClick={() => setIsActive(!isActive)}
					className="card-header cursor-pointer px-3 text-primary font-weight-bold d-flex align-items-center justify-content-between border-bottom-0"
				>
					<h4 className="mb-0">{size?.name ?? name}</h4>

					<FontAwesomeIcon
						icon={isActive ? faChevronUp : faChevronDown}
					/>
				</div>

				<div className="ingredients">
					{ingredients.map(({ component, quantity }, key) => (
						<Ingredient
							component={component}
							quantity={quantity}
							key={key}
						/>
					))}
				</div>

				<div className="card-footer p-0 m-0 border-light">
					<ProductNutrition nutritionRows={nutritionRows} />
				</div>
			</Drawer>
		</div>
	);
};

export interface ItemsProps {
	product: Percomatic.Serialized.Product;
}

export const Items: React.FC<ItemsProps> = ({ product }) => {
	const [items, setItems] = useState<Percomatic.Serialized.Item[]>([]);

	useEffect(() => {
		(async () => {
			const items = [];

			for (const id of product.itemIds) {
				try {
					const item = await api.getItem(id);

					if (item.recipe.length > 0) {
						items.push(item);
					}
				} catch (err) {
					console.error(err);
				}
			}

			setItems(items);
		})();
	}, [product]);

	return (
		<div className="grid-350-gap-15 px-3 pb-3">
			{items.map((item, key) => (
				<RecipeItem item={item} key={key} />
			))}
		</div>
	);
};

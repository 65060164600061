import { OrderFieldFragment } from '../../graphql';

export const updateTotalPrices = (
	order: OrderFieldFragment,
	updatedCheckedState: boolean[]
): number[] => {
	return updatedCheckedState.reduce(
		([subTotal, tax], checked, index) => {
			if (checked === true) {
				return [
					subTotal + order?.items[index].price,
					tax + order?.items[index].taxAmount
				];
			}
			return [subTotal, tax];
		},
		[0, 0]
	);
};

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { GenericDrawer } from '../..';
import { LylaQuestion } from '../LylaQuestion';
import { LylaQuestionFieldsFragment } from '../../../graphql';

export type DropdownProps = React.HTMLAttributes<HTMLDivElement> & {
	activeQuestions: boolean;
	setLylaQuestions: Dispatch<
		SetStateAction<
			| ({
					__typename?: 'LylaQuestion' | undefined;
			  } & LylaQuestionFieldsFragment)[]
			| undefined
		>
	>;
	lylaQuestions:
		| ({
				__typename?: 'LylaQuestion' | undefined;
		  } & LylaQuestionFieldsFragment)[]
		| undefined;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	whereToShowOptions: { value: string; label: string }[];
};

export const Dropdown: React.FC<DropdownProps> = ({
	activeQuestions,
	lylaQuestions,
	setLylaQuestions,
	setIsLoading,
	whereToShowOptions
}) => {
	const [isActive, setIsActive] = useState<boolean>(true);
	const [show, setShow] = useState<boolean>(true);

	useEffect(() => {
		console.log('I made it to the effect');
	}, [lylaQuestions]);

	return (
		<GenericDrawer
			className="lyla-dropdown"
			isActive={isActive}
			onRest={() => {
				if (!isActive && show) {
					setShow(false);
				}
			}}
		>
			<>
				<hr className="m-0 p-0" />

				<div
					onClick={() => {
						if (show) {
							setIsActive(false);
							setShow(false);
						} else {
							setShow(true);
							setIsActive(true);
						}
					}}
					className="d-flex cursor-pointer align-items-center py-2 px-3 text-secondary"
				>
					<h2 className="lyla-dropdown-title mr-auto mb-1 py-2">
						{activeQuestions
							? 'Active Questions:'
							: 'Inactive Questions:'}
					</h2>

					<FontAwesomeIcon
						icon={isActive ? faChevronUp : faChevronDown}
					/>
				</div>
			</>
			{show &&
				lylaQuestions &&
				lylaQuestions
					.filter(question =>
						activeQuestions
							? question?.isActive
							: !question?.isActive
					)
					.map(question => (
						<LylaQuestion
							className="lyla-dropdown-question"
							key={question?.questionId}
							question={question}
							lylaQuestions={lylaQuestions}
							setLylaQuestions={setLylaQuestions}
							setIsLoading={setIsLoading}
							whereToShowOptions={whereToShowOptions}
						/>
					))}
		</GenericDrawer>
	);
};

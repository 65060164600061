import React from 'react';

import { CartItem } from '..';

import Order from '../../domains/Order';
import { OrderItemFieldsFragment } from '../../graphql';

interface TotalProps {
	subtotal: string;
	tax: string;
	total: string;
}

export const Total: React.FC<TotalProps> = ({ subtotal, tax, total }) => (
	<div className="total border-top border-light text-light-gray px-1 py-1 mt-auto">
		<div className="d-flex">
			<div className="mr-auto">Subtotal</div>

			<div>${subtotal}</div>
		</div>

		<div className="d-flex">
			<div className="mr-auto">Tax</div>

			<div>${tax}</div>
		</div>

		<div className="d-flex text-light">
			<div className="mr-auto">Total</div>

			<div>${total}</div>
		</div>
	</div>
);

export const Cart: React.FC = () => {
	const items = Order.selectors.useSelectCart();

	const totalQuantity = items.reduce(
		(amount, item) => amount + item.quantity,
		0
	);

	const totalPrice = items.reduce(
		(amount, item) => amount + item.price * item.quantity,
		0
	);

	return (
		<div className="cart border border-light d-flex flex-column overflow-auto">
			{/* <h4 className="text-light border-bottom border-light font-weight-bold mb-0 px-1 py-1">
				Order #1
			</h4> */}

			<div className="list-group list-group-flush overflow-auto">
				{items.map((item: OrderItemFieldsFragment, key) => (
					<CartItem item={item} key={key} />
				))}
			</div>

			{totalQuantity > 0 && (
				<Total
					subtotal={(totalPrice / 100).toFixed(2)}
					tax={(totalPrice / 100).toFixed(2)}
					total={(totalPrice / 100).toFixed(2)}
				/>
			)}
		</div>
	);
};

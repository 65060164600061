import { useMemo, useState } from 'react';
import { useInterval } from './useInterval';

const getCurrentDateString = () => {
	const today = new Date();

	return today.toLocaleDateString('en-US', {
		weekday: 'long',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric'
	});
};

export const useClock = (): string => {
	const initialDate = useMemo(getCurrentDateString, []);
	const [time, setTime] = useState(initialDate);

	useInterval(() => {
		const currentDate = getCurrentDateString();

		const [dateLeft, dateRight] = currentDate.split(':');

		const dateMiddle = new Date().getSeconds() % 2 === 0 ? ':' : ' ';

		setTime(dateLeft + dateMiddle + dateRight);
	}, 1000);

	return time;
};

import actions from './actions';
import reducer from './reducer';
import selectors from './selectors';

const Admin: AdminDomain.AdminDomain = {
	actions: actions,
	selectors: selectors,
	reducer
};

export default Admin;

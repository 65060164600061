import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import React from 'react';

import { useHistory } from 'react-router';

import { MenuQuery } from '../../../graphql';
import { Dropdown } from '..';

export interface ProductsListProps {
	products: MenuQuery['menu']['products'];
	selectProduct(id: string | number): void;
	productsListRef: React.MutableRefObject<HTMLDivElement | null>;
	allProducts: Percomatic.Serialized.Product[];
}

export const ProductsList: React.FC<ProductsListProps> = ({
	products,
	selectProduct,
	productsListRef,
	allProducts
}) => {
	const { push } = useHistory();

	return (
		<div ref={productsListRef} className="grid-150">
			<Dropdown
				label={'Product'}
				items={allProducts}
				showDropdownButton={true}
				clickHandler={item =>
					push('/product-management/product/' + item.id)
				}
				alignLeft={true}
				linkedItems={true}
				state={[]}
			/>

			{products?.map(({ id, name }, key) => (
				<button
					onClick={() => selectProduct(id)}
					key={key}
					className="btn btn-block btn-secondary mt-0 rounded products-list-product"
				>
					{name}
				</button>
			))}
		</div>
	);
};

import { AnyAction, CombinedState, combineReducers, Reducer } from 'redux';

import Network from './domains/Network';
import Session from './domains/Session';
import Product from './domains/Product';
import Order from './domains/Order';
import UI from './domains/UI';
import Admin from './domains/Admin';
import ProductManagement from './domains/ProductManagement';
import Refund from './domains/Refund';

const rootReducer = (): Reducer<CombinedState<RootState>, AnyAction> =>
	combineReducers<RootState>({
		network: Network.reducer,
		session: Session.reducer,
		product: Product.reducer,
		order: Order.reducer,
		ui: UI.reducer,
		admin: Admin.reducer,
		productManagement: ProductManagement.reducer,
		refund: Refund.reducer
	});

export default rootReducer;

import React, { useRef } from 'react';

import {
	Cart,
	ModifierGroups,
	ModifierScales,
	OtherVersions,
	ProductFooter,
	ProductSide,
	Size
} from '..';

import Product from '../../domains/Product';

export const ProductBody: React.FC = () => {
	const hasSize = Product.selectors.useSelectHasSizes();
	const hasOtherVersions = Product.selectors.useSelectHasProductVersions();
	const hasModifierGroups = Product.selectors.useSelectHasModifierGroups();
	const hasModifierScales = Product.selectors.useSelectHasModifierScales();
	const modifierGroupsRef = useRef<HTMLDivElement>(null);

	return (
		<div className="px-3 product-body">
			<div className="left">
				<Cart />

				{hasSize && <Size />}
				{hasOtherVersions && <OtherVersions />}

				<ProductFooter />
			</div>

			{hasModifierScales && <ModifierScales />}

			<div className="modifier-groups-container">
				{hasModifierGroups && (
					<ModifierGroups scrollRef={modifierGroupsRef} />
				)}

				<ProductSide scrollRef={modifierGroupsRef} />
			</div>
		</div>
	);
};

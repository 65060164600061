import { useEffect, useState } from 'react';

import Session from '../../domains/Session';

import {
	StoreTerminalUpdatesSubscription,
	TerminalStatus,
	useStoreTerminalsQuery,
	useStoreTerminalUpdatesSubscription
} from '../../graphql';

export const useTerminalStatus = (): {
	bhStatus: TerminalStatus;
	fhStatus: TerminalStatus;
	dtStatus: TerminalStatus;
} => {
	const store = Session.selectors.useSelectStore();

	const { data } = useStoreTerminalsQuery();

	const { data: updates } = useStoreTerminalUpdatesSubscription({
		variables: { storeId: store?.storeId || 0 }
	});

	const [bhStatus, setBhStatus] = useState<TerminalStatus>('DISCONNECTED');
	const [fhStatus, setFhStatus] = useState<TerminalStatus>('DISCONNECTED');
	const [dtStatus, setDtStatus] = useState<TerminalStatus>('DISCONNECTED');

	const updateStatuses = (
		data: StoreTerminalUpdatesSubscription['terminals']
	) => {
		console.log('data', data);
		const bh = data.find(terminal => terminal.termId === 1);
		const fh = data.find(terminal => terminal.termId === 2);
		const dt = data.find(terminal => terminal.termId === 3);

		setBhStatus(bh?.status || 'DISCONNECTED');
		setFhStatus(fh?.status || 'DISCONNECTED');
		setDtStatus(dt?.status || 'DISCONNECTED');
	};

	useEffect(() => {
		if (data && data.session.store) {
			updateStatuses(data.session.store?.terminals);
		}
	}, [data]);

	useEffect(() => {
		if (updates) {
			updateStatuses(updates.terminals);
		}
	}, [updates]);

	return {
		bhStatus,
		fhStatus,
		dtStatus
	};
};

import React from 'react';
import Admin from '../../domains/Admin';

export interface OrderProps {
	orderId: string;
	storeId: number;
	termId: number;
}

export const TerminalOrder: React.FC<OrderProps> = ({
	orderId,
	storeId,
	termId
}: OrderProps) => {
	const total: number[] = [];

	const order = Admin.selectors.useSelectOrder(storeId, termId, orderId);

	return (
		<div
			style={{ width: '20vw' }}
			className="border border-dark rounded list-group-item mt-1 mb-1"
		>
			<p className="text-center">
				Placed on: <br />
				<strong>
					{new Date(order.timePlaced).toDateString()}
					<br />
					{new Date(order.timePlaced).toLocaleTimeString()}
				</strong>
				<br />
			</p>

			<p className="text-center">
				By:
				<br />
				<strong>
					{order.user?.type || 'GUEST'}

					<br />

					{order.user?.id}
				</strong>
			</p>

			<p className="text-center">Items:</p>

			{order.items.map((item, key) => (
				<p className="text-center" key={key}>
					<strong>
						{item.quantity}X ID:{item.id}
						{/* {item.quantity} {item.name} @ {item.price} */}
					</strong>
				</p>
			))}

			<p className="text-center">Total:</p>

			<p className="text-center">
				<strong>{total.reduce((a, b) => a + b, 0)}</strong>
			</p>
		</div>
	);
};

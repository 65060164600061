import React from 'react';
import { useParams } from 'react-router';

import {
	CustomerDetails,
	FileUpload,
	ProductDetails,
	SubmitEvidenceButton,
	SupportEvidence
} from '../../components';

export const DisputesEvidence: React.FC = () => {
	const { orderId, storeId } = useParams<{
		orderId: string;
		storeId: string;
	}>();

	// const { data, loading, error } = useStripeOrderQuery({
	// 	variables: {
	// 		storeId: Number(storeId),
	// 		id: orderId
	// 	}
	// });

	// if (loading) {
	// 	return <Loading />;
	// }

	return (
		<div className="dp-evidence-root text-light py-3 mb-4">
			<div className="dp-evidence-header">
				<h1 className="px-3">Submit Dispute Evidence</h1>
				<button className="btn btn-secondary mr-3">Save</button>
			</div>
			<p className="px-3 order-number">Store {storeId}</p>
			<p className="px-3 order-number">{orderId}</p>
			<hr />

			<CustomerDetails />

			<ProductDetails />

			<SupportEvidence />

			<FileUpload />

			<SubmitEvidenceButton />
		</div>
	);
};

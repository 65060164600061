const initialState: UIDomain.State = {
	alertIds: [],
	alerts: {},
	orientation: 'landscape',
	modalOpen: false
};

const reducer = (
	state: UIDomain.State = initialState,
	action: UIDomain.Action
): UIDomain.State => {
	let filteredAlerts: { [x: string]: UIDomain.Alert };
	switch (action.type) {
		case 'ADD_ALERT':
			return {
				...state,
				alertIds: [...state.alertIds, action.payload.id],
				alerts: {
					...state.alerts,
					[action.payload.id]: {
						...action.payload,
						id: action.payload.id
					}
				}
			};
		case 'REMOVE_ALERT':
			filteredAlerts = state.alerts;

			delete filteredAlerts[action.payload];

			return {
				...state,
				alertIds: state.alertIds.filter(id => id !== action.payload),
				alerts: filteredAlerts
			};
		case 'SET_ORIENTATION':
			return {
				...state,
				orientation: action.payload
			};
		case 'TOGGLE_MODAL':
			return { ...state, modalOpen: !state.modalOpen };

		default:
			return state;
	}
};

export default reducer;

import React from 'react';
import { Link } from 'react-router-dom';

export interface BottomButtonProps {
	label: string;
}

export const DropdownButton: React.FC<BottomButtonProps> = ({ label }) => {
	return (
		<div className="mb-3">
			<Link
				to={`/product-management/new/${label
					.split(' ')
					.join('-')
					.toLowerCase()}`}
				className="text-decoration-none"
			>
				<button className="dropdown-item bg-success text-white text-center rounded">
					{`Create New ${label}`}
				</button>
			</Link>
		</div>
	);
};

import { useSelector } from 'react-redux';

const selectors: SessionDomain.SessionDomainSelectors = {
	useSelectEmployee() {
		const employee = useSelector(
			(state: RootState) => state.session.employee
		);

		return employee;
	},
	useSelectStore() {
		const store = useSelector((state: RootState) => state.session.store);

		return store;
	},
	useSelectRoles() {
		const roles = useSelector(
			(state: RootState) => state.session.employee?.roles || []
		);

		return roles;
	},
	useSelectIsAdmin(): boolean {
		const roles = this.useSelectRoles();

		return roles.some(role => role.roleId === 1);
	},
	useSelectAuthenticated() {
		const authenticated = useSelector(
			(state: RootState) => state.session.authenticated
		);

		return authenticated;
	}
};

export default selectors;

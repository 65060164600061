import { pullAt } from 'lodash';
import { OLOModifier, PERCModifier, ModifierPair } from './types';

export const getModifierPairs = (
	oloModifiers: OLOModifier[],
	percModifiers: PERCModifier[]
): {
	modifierPairs: ModifierPair[];
	orphans: { oloModifiers: OLOModifier[]; percModifiers: PERCModifier[] };
} => {
	const modifierPairs: ModifierPair[] = [];
	const oloModifierOrphans: OLOModifier[] = [];
	const availablePercModifiers = [...percModifiers];

	for (const oloModifier of oloModifiers) {
		const percModifierIndex = availablePercModifiers.findIndex(
			percModifier => percModifier.modifierId === oloModifier.modifierId
		);

		if (percModifierIndex === -1) {
			oloModifierOrphans.push(oloModifier);
			continue;
		}

		const percModifier = { ...availablePercModifiers[percModifierIndex] };

		pullAt(availablePercModifiers, percModifierIndex);

		modifierPairs.push({ oloModifier, percModifier });
	}

	return {
		modifierPairs,
		orphans: {
			oloModifiers: oloModifierOrphans,
			percModifiers: availablePercModifiers
		}
	};
};

import React, { useState } from 'react';
import '../../../sass/Tooltip.scss';

export interface TooltipProps {
	delay?: number;
	direction?: 'top' | 'right' | 'bottom' | 'left';
	content: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({
	delay = 400,
	direction = 'right',
	content,
	children
}) => {
	let timeout: NodeJS.Timeout;
	const [active, setActive] = useState(false);

	const showTip = () => {
		timeout = setTimeout(() => {
			setActive(true);
		}, delay);
	};

	const hideTip = () => {
		clearInterval(timeout);
		setActive(false);
	};

	return (
		<div
			className="Tooltip-Wrapper"
			onMouseEnter={showTip}
			onMouseLeave={hideTip}
		>
			{children}
			{active && (
				<div className={`Tooltip-Tip ${direction} p-3 m-0`}>
					{content}
				</div>
			)}
		</div>
	);
};

import { useState } from 'react';

import { PendingOrder } from '../components/PendingOrder';

import Admin from '../domains/Admin';
import Session from '../domains/Session';
import UI from '../domains/UI';

import { ModifierScaleSelector } from '../components';

const filters: AdminDomain.FilterPendingOrders[] = ['ALL', 'ASAP', 'SCHEDULED'];

export default function PendingOrders(): JSX.Element {
	UI.actions.useSetOrientation('portrait');
	Session.actions.useProtectAdmin();
	Admin.actions.useLoadPendingOrderIds();

	const ids = Admin.selectors.useSelectPendingOrderIds();
	const toggleSortOrder = Admin.actions.useTogglePendingOrdersSortOrder();

	const setFilterPendingOrders = Admin.actions.useSetFilterPendingOrders();

	const [selectedIndex, setSelectedIndex] = useState(0);

	return (
		<div className="pending-orders mt-1 h-100 overflow-auto">
			<button
				className="btn btn-primary mb-3 mx-3"
				onClick={() => {
					toggleSortOrder();
				}}
			>
				Toggle Sort Order
			</button>

			<div className="px-3 mb-1">
				<ModifierScaleSelector
					selectedIndex={selectedIndex}
					modifiers={[
						{ name: 'All', id: 1, priority: 1 },
						{ name: 'Current', id: 2, priority: 1 },
						{ name: 'Future', id: 3, priority: 1 }
					]}
					clickHandler={(_, index) => {
						setFilterPendingOrders(filters[index]);
						setSelectedIndex(index);
					}}
				/>
			</div>

			<ul
				className="list-group-flush"
				style={{
					paddingInlineStart: 0
				}}
			>
				{ids.map((id, key) => (
					<PendingOrder id={id} key={key} />
				))}
			</ul>
		</div>
	);
}

import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { Loading } from '../../components';
import { useEmployeeStoresQuery } from '../../graphql';

export const Stores: React.FC = () => {
	const { data, loading } = useEmployeeStoresQuery();

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="text-light py-3">
			<h1 className="px-3">Stores</h1>

			<div className="list-group list-group-flush">
				{data?.session.employee?.stores
					.filter(store => store.oLOEnabled)
					.map(({ storeId }, key) => (
						<li
							className="list-group-item px-3 py-2 d-flex align-items-center"
							key={key}
						>
							<h3 className="mb-0 mr-auto">{storeId}</h3>

							<Link
								className="btn btn-secondary mr-3"
								to={`/stripe/store/${storeId}`}
							>
								<FontAwesomeIcon icon={faChevronRight} />
							</Link>
						</li>
					))}
			</div>
		</div>
	);
};

import React from 'react';
import { DisputeFieldsFragment } from '../../../graphql';

export const DisputeInfo: React.FC<DisputeFieldsFragment> = props => {
	const date = `${props.created.slice(5, 7)}-${props.created.slice(
		8,
		10
	)}-${props.created.slice(0, 4)}`;

	const time =
		Number(props.created.slice(11, 13)) > 12
			? `${String(
					Number(props.created.slice(11, 13)) - 12
			  )}${props.created.slice(13, 16)} PM`
			: `${props.created.slice(11, 16)} AM`;
	return (
		<div className={!props.id ? 'no-dispute-info' : 'dispute-info'}>
			<h3 className="px-3">DISPUTE INFORMATION</h3>
			<hr />
			<div className="order-info-container px-3">
				<div className="order-info">
					<h3>Dispute ID</h3>
					<p className="text-break">{props.id}</p>
				</div>
				<div className="order-info">
					<h3>Amount</h3>
					<p>${(props.amount / 100).toFixed(2)}</p>
				</div>
				<div className="order-info">
					<h3>Reason</h3>
					<p>
						{props.reason.charAt(0).toUpperCase() +
							props.reason.slice(1)}
					</p>
				</div>
				<div className="order-info">
					<h3>Status</h3>
					<p>{props.status.toUpperCase()}</p>
				</div>
				<div className="order-info">
					<h3>Date</h3>
					<p>{date}</p>
				</div>
				<div className="order-info">
					<h3>Time</h3>
					<p>{time}</p>
				</div>
				<div className="order-info">
					<h3>Currency</h3>
					<p>{props.currency.toUpperCase()}</p>
				</div>
			</div>
		</div>
	);
};

import React from 'react';

import { Stores } from '../../components/OwnerOperator';

import UI from '../../domains/UI';

export const OwnerOperator: React.FC = () => {
	UI.actions.useSetOrientation('portrait');

	return (
		<div className="stripe overflow-auto h-100">
			<Stores />
		</div>
	);
};

import { Link } from 'react-router-dom';

export interface ErrorProps {
	error: string;
}

export const Error: React.FC<ErrorProps> = ({ error }) => (
	<div className="p-3">
		<div className="alert alert-danger">
			<p>{error}</p>

			<Link to="/" className="btn btn-outline-dark">
				Go back.
			</Link>
		</div>
	</div>
);

import { TableItemType as Item } from '../TableDropdown/TableDropdown';

export interface TableItemProps<Item> {
	key: number;
	val: Item;
}

export const TableItem = ({
	key,
	val
}: TableItemProps<Item>): React.ReactElement => {
	return <td key={key}>{val}</td>;
};

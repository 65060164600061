import { useEffect, useState } from 'react';

import { useWhereToShowQuery } from '../../graphql';

const unsetWhereToShowOption = {
	value: '',
	label: 'Not Set'
};

const whereToShowQuery = useWhereToShowQuery;

export const useWhereToShowOptions = (): {
	value: string;
	label: string;
}[] => {
	const { data } = whereToShowQuery();

	const [whereToShowOptions, setWhereToShowOptions] = useState<
		{ value: string; label: string }[]
	>([unsetWhereToShowOption]);

	useEffect(() => {
		const loadSelectOptions = (
			input:
				| {
						__typename?: 'WhereToShow' | undefined;
						questionLocationId: number;
						screenname: string;
				  }[]
				| undefined
		) => {
			let databaseWhereToShowValues: { value: string; label: string }[] =
				[];
			if (data) {
				databaseWhereToShowValues =
					input?.map(option => {
						return {
							value: option.questionLocationId.toString(),
							label: option.screenname
						};
					}) || [];
			}

			const allWhereToShowValues = [
				unsetWhereToShowOption,
				...databaseWhereToShowValues
			];

			setWhereToShowOptions(allWhereToShowValues);
		};

		loadSelectOptions(data?.whereToShow);
	}, [data]);

	return whereToShowOptions;
};

import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloClient, DefaultOptions } from 'apollo-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { v4 } from 'uuid';

console.log('process.env', process.env);
console.log('window.location.host', window.location.host);

const GRAPHQL_WS_ENDPOINT =
	process.env.NODE_ENV === 'production'
		? `wss://${window.location.host}/graphql`
		: process.env.REACT_APP_GRAPHQL_WS_ENDPOINT ?? '';

const defaultOptions: DefaultOptions | undefined = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore'
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all'
	}
};
// const defaultOptions: any =
// 	process.env.NODE_ENV === 'production'
// 		? {}
// 		: {
// 				watchQuery: {
// 					fetchPolicy: 'no-cache',
// 					errorPolicy: 'ignore',
// 				},
// 				query: {
// 					fetchPolicy: 'no-cache',
// 					errorPolicy: 'all',
// 				},
// 		  };

const client = new SubscriptionClient(GRAPHQL_WS_ENDPOINT, {
	reconnect: true,
	timeout: 60000,
	lazy: true
});

client.on('connecting', () => {
	console.log('subscription-client: connecting');
});

client.on('connected', () => {
	console.log('subscription-client: connected');
});

client.on('reconnecting', () => {
	console.log('subscription-client: reconnecting');
});

client.on('reconnected', () => {
	console.log('subscription-client: reconnected');
});

client.on('disconnected', () => {
	console.log('subscription-client: disconnected');
});

// Create an http link:
const httpLink = new HttpLink({
	uri: '/graphql'
});

// Create a WebSocket link:
const wsLink = new WebSocketLink(client);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
	// split based on operation type
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	httpLink
);

export default new ApolloClient({
	link,
	cache: new InMemoryCache({
		dataIdFromObject(object) {
			switch (object.__typename) {
				case 'ModifierScale':
				case 'ModifierGroup':
					return v4();
				default:
					return defaultDataIdFromObject(object); // fall back to default handling
			}
		}
	}),
	defaultOptions
});

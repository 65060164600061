import React from 'react';

import { Terminal } from '../../components';

import Admin from '../../domains/Admin';

export default function Terminals(): JSX.Element {
	const terminalData = Admin.selectors.useSelectTerminals();

	return (
		<div className="mt-1 grid-200">
			{terminalData
				.sort((a, b) =>
					a.storeId === b.storeId
						? a.termId - b.termId
						: a.storeId - b.storeId
				)
				.map((terminal, key) => (
					<Terminal
						key={key}
						storeId={terminal.storeId}
						termId={terminal.termId}
					/>
				))}
		</div>
	);
}

import React from 'react';
import * as Sentry from '@sentry/react';

import { ErrorFallback } from './components';
import Layout from './Layout';

import './sass/App.scss';
import Session from './domains/Session';

// import required css from library
import 'react-datepicker/dist/react-datepicker.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const ErrorComponent = () => {
// 	throw new Error('Test error.');
// };

const App: React.FC = () => {
	// const authenticated = Session.selectors.useSelectAuthenticated();
	const employee = Session.selectors.useSelectEmployee();
	const store = Session.selectors.useSelectStore();

	return (
		<Sentry.ErrorBoundary
			fallback={ErrorFallback}
			dialogOptions={{
				user: {
					email: store
						? `store${store.storeId}@biggby.com`
						: 'storeXXX@biggby.com',
					name: employee ? String(employee.id) : '000000000'
				},
				labelName: 'Your employee number:',
				labelEmail: "Your store's email address:",
				labelClose: 'Skip',
				labelSubmit: 'Send',
				title: 'There was an error.',
				subtitle: 'The BRAINS team has been notified.',
				successMessage:
					'Your feedback has been sent to the BRAINS team. Thank you!'
			}}
			showDialog
		>
			<Layout />
			{/* <ErrorComponent /> */}
		</Sentry.ErrorBoundary>
	);
};

export default App;

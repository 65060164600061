import React from 'react';

import { PageHeader } from '../../components';

import { Routes } from './Routes';

export const ProductManagement: React.FC = () => {
	return (
		<div className="p-3 overflow-auto">
			<PageHeader />

			<Routes />
		</div>
	);
};

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
	prefix: '$',
	suffix: '',
	includeThousandsSeparator: true,
	thousandsSeparatorSymbol: ',',
	allowDecimal: true,
	decimalSymbol: '.',
	decimalLimit: 2, // how many digits allowed after the decimal
	integerLimit: 7, // limit length of integer numbers
	allowNegative: false,
	allowLeadingZeroes: false
};

export type CurrencyInputProps = React.HTMLAttributes<HTMLDivElement> & {
	maskOptions?: Record<string, unknown>;
};

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
	maskOptions,
	...props
}) => {
	const currencyMask = createNumberMask({
		...defaultMaskOptions,
		...maskOptions
	});
	return (
		<span className="currency-input">
			<MaskedInput mask={currencyMask} {...props} />
		</span>
	);
};

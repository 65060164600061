import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
	Component,
	ComponentGroup,
	createRoute,
	InventoryItem,
	Item,
	Product,
	ProductGroup
} from '../../components';

import ProductManagement from '../../domains/ProductManagement';

const BASE = '/product-management/new';

const Route = createRoute(BASE);

export const New: React.FC = () => {
	const component = useRouteMatch(`${BASE}/component`);
	const componentGroup = useRouteMatch(`${BASE}/component-group`);
	const inventoryItem = useRouteMatch(`${BASE}/inventory-item`);
	const item = useRouteMatch(`${BASE}/item/product-id/:productId`);
	const product = useRouteMatch(`${BASE}/product`);
	const productGroup = useRouteMatch(`${BASE}/product-group`);

	const updateHeader = ProductManagement.actions.useUpdateHeader();

	useEffect(() => {
		let label = '';

		if (component) {
			label = 'Component';
		}

		if (componentGroup) {
			label = 'Component Group';
		}

		if (inventoryItem) {
			label = 'Inventory Item';
		}

		if (item) {
			label = 'Item';
		}

		if (product) {
			label = 'Product';
		}

		if (productGroup) {
			label = 'Product Group';
		}

		updateHeader({
			showButton: false,
			label: `Create New ${label}`
		});
	}, [
		component,
		componentGroup,
		inventoryItem,
		item,
		product,
		productGroup,
		updateHeader
	]);

	return (
		<>
			<Route exact path="/component">
				<Component />
			</Route>

			<Route exact path="/component-group">
				<ComponentGroup />
			</Route>

			<Route exact path="/inventory-item">
				<InventoryItem />
			</Route>

			<Route exact path="/item/product-id/:productId">
				<Item />
			</Route>

			<Route exact path={['/product', '/product/:productId']}>
				<Product />
			</Route>

			<Route exact path="/product-group">
				<ProductGroup />
			</Route>
		</>
	);
};

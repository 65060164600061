export interface IStateResult {
	label: string;
	value: string;
	territory: boolean;
	capital: string;
	contiguous: boolean;
}

export const useUSAStates = (): Array<IStateResult> => {
	const _states: IStateResult[] = [
		{
			label: 'Alabama',
			value: 'AL',
			territory: false,
			capital: 'Montgomery',
			contiguous: true
		},
		{
			label: 'Alaska',
			value: 'AK',
			territory: false,
			capital: 'Juneau',
			contiguous: false
		},
		{
			label: 'American Samoa',
			value: 'AS',
			territory: true,
			capital: 'Pago Pago',
			contiguous: false
		},
		{
			label: 'Arizona',
			value: 'AZ',
			territory: false,
			capital: 'Phoenix',
			contiguous: true
		},
		{
			label: 'Arkansas',
			value: 'AR',
			territory: false,
			capital: 'Little Rock',
			contiguous: true
		},
		{
			label: 'California',
			value: 'CA',
			territory: false,
			capital: 'Sacramento',
			contiguous: true
		},
		{
			label: 'Colorado',
			value: 'CO',
			territory: false,
			capital: 'Denver',
			contiguous: true
		},
		{
			label: 'Connecticut',
			value: 'CT',
			territory: false,
			capital: 'Hartford',
			contiguous: true
		},
		{
			label: 'Delaware',
			value: 'DE',
			territory: false,
			capital: 'Dover',
			contiguous: true
		},
		{
			label: 'District Of Columbia',
			value: 'DC',
			territory: false,
			capital: '',
			contiguous: true
		},
		{
			label: 'Federated States Of Micronesia',
			value: 'FM',
			territory: true,
			capital: 'Palikir',
			contiguous: false
		},
		{
			label: 'Florida',
			value: 'FL',
			territory: false,
			capital: 'Tallahassee',
			contiguous: true
		},
		{
			label: 'Georgia',
			value: 'GA',
			territory: false,
			capital: 'Atlanta',
			contiguous: true
		},
		{
			label: 'Guam',
			value: 'GU',
			territory: true,
			capital: 'Hagåtña',
			contiguous: false
		},
		{
			label: 'Hawaii',
			value: 'HI',
			territory: false,
			capital: 'Honolulu',
			contiguous: false
		},
		{
			label: 'Idaho',
			value: 'ID',
			territory: false,
			capital: 'Boise',
			contiguous: true
		},
		{
			label: 'Illinois',
			value: 'IL',
			territory: false,
			capital: 'Springfield',
			contiguous: true
		},
		{
			label: 'Indiana',
			value: 'IN',
			territory: false,
			capital: 'Indianapolis',
			contiguous: true
		},
		{
			label: 'Iowa',
			value: 'IA',
			territory: false,
			capital: 'Des Moines',
			contiguous: true
		},
		{
			label: 'Kansas',
			value: 'KS',
			territory: false,
			capital: 'Topeka',
			contiguous: true
		},
		{
			label: 'Kentucky',
			value: 'KY',
			territory: false,
			capital: 'Frankfort',
			contiguous: true
		},
		{
			label: 'Louisiana',
			value: 'LA',
			territory: false,
			capital: 'Baton Rouge',
			contiguous: true
		},
		{
			label: 'Maine',
			value: 'ME',
			territory: false,
			capital: 'Augusta',
			contiguous: true
		},
		{
			label: 'Marshall Islands',
			value: 'MH',
			territory: true,
			capital: 'Majuro',
			contiguous: false
		},
		{
			label: 'Maryland',
			value: 'MD',
			territory: false,
			capital: 'Annapolis',
			contiguous: true
		},
		{
			label: 'Massachusetts',
			value: 'MA',
			territory: false,
			capital: 'Boston',
			contiguous: true
		},
		{
			label: 'Michigan',
			value: 'MI',
			territory: false,
			capital: 'Lansing',
			contiguous: true
		},
		{
			label: 'Minnesota',
			value: 'MN',
			territory: false,
			capital: 'Saint Paul',
			contiguous: true
		},
		{
			label: 'Mississippi',
			value: 'MS',
			territory: false,
			capital: 'Jackson',
			contiguous: true
		},
		{
			label: 'Missouri',
			value: 'MO',
			territory: false,
			capital: 'Jefferson City',
			contiguous: true
		},
		{
			label: 'Montana',
			value: 'MT',
			territory: false,
			capital: 'Helena',
			contiguous: true
		},
		{
			label: 'Nebraska',
			value: 'NE',
			territory: false,
			capital: 'Lincoln',
			contiguous: true
		},
		{
			label: 'Nevada',
			value: 'NV',
			territory: false,
			capital: 'Carson City',
			contiguous: true
		},
		{
			label: 'New Hampshire',
			value: 'NH',
			territory: false,
			capital: 'Concord',
			contiguous: true
		},
		{
			label: 'New Jersey',
			value: 'NJ',
			territory: false,
			capital: 'Trenton',
			contiguous: true
		},
		{
			label: 'New Mexico',
			value: 'NM',
			territory: false,
			capital: 'Santa Fe',
			contiguous: true
		},
		{
			label: 'New York',
			value: 'NY',
			territory: false,
			capital: 'Albany',
			contiguous: true
		},
		{
			label: 'North Carolina',
			value: 'NC',
			territory: false,
			capital: 'Raleigh',
			contiguous: true
		},
		{
			label: 'North Dakota',
			value: 'ND',
			territory: false,
			capital: 'Bismarck',
			contiguous: true
		},
		{
			label: 'Northern Mariana Islands',
			value: 'MP',
			territory: true,
			capital: 'Saipan',
			contiguous: false
		},
		{
			label: 'Ohio',
			value: 'OH',
			territory: false,
			capital: 'Columbus',
			contiguous: true
		},
		{
			label: 'Oklahoma',
			value: 'OK',
			territory: false,
			capital: 'Oklahoma City',
			contiguous: true
		},
		{
			label: 'Oregon',
			value: 'OR',
			territory: false,
			capital: 'Salem',
			contiguous: true
		},
		{
			label: 'Palau',
			value: 'PW',
			territory: true,
			capital: 'Ngerulmud',
			contiguous: false
		},
		{
			label: 'Pennsylvania',
			value: 'PA',
			territory: false,
			capital: 'Harrisburg',
			contiguous: true
		},
		{
			label: 'Puerto Rico',
			value: 'PR',
			territory: true,
			capital: 'San Juan',
			contiguous: false
		},
		{
			label: 'Rhode Island',
			value: 'RI',
			territory: false,
			capital: 'Providence',
			contiguous: true
		},
		{
			label: 'South Carolina',
			value: 'SC',
			territory: false,
			capital: 'Columbia',
			contiguous: true
		},
		{
			label: 'South Dakota',
			value: 'SD',
			territory: false,
			capital: 'Pierre',
			contiguous: true
		},
		{
			label: 'Tennessee',
			value: 'TN',
			territory: false,
			capital: 'Nashville',
			contiguous: true
		},
		{
			label: 'Texas',
			value: 'TX',
			territory: false,
			capital: 'Austin',
			contiguous: true
		},
		{
			label: 'Utah',
			value: 'UT',
			territory: false,
			capital: 'Salt Lake City',
			contiguous: true
		},
		{
			label: 'Vermont',
			value: 'VT',
			territory: false,
			capital: 'Montpelier',
			contiguous: true
		},
		{
			label: 'Virgin Islands',
			value: 'VI',
			territory: true,
			capital: 'Charlotte Amalie',
			contiguous: false
		},
		{
			label: 'Virginia',
			value: 'VA',
			territory: false,
			capital: 'Richmond',
			contiguous: true
		},
		{
			label: 'Washington',
			value: 'WA',
			territory: false,
			capital: 'Olympia',
			contiguous: true
		},
		{
			label: 'West Virginia',
			value: 'WV',
			territory: false,
			capital: 'Charleston',
			contiguous: true
		},
		{
			label: 'Wisconsin',
			value: 'WI',
			territory: false,
			capital: 'Madison',
			contiguous: true
		},
		{
			label: 'Wyoming',
			value: 'WY',
			territory: false,
			capital: 'Cheyenne',
			contiguous: true
		}
	];

	// const generateStates = (): IStateResult[] => {
	// 	const states: IStateResult[] = [];
	// 	for (const state of _states) {
	// 		if (state.value.length > 0) {
	// 			states.push(state);
	// 		}
	// 	}
	// 	return states;
	// };

	return _states;
};

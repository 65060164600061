import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useBrandStandardQuery } from '../../../graphql';
import { Loading } from '../..';
import { BrandStandardEditForm } from '../BrandStandardEditForm/BrandStandardEditForm';
import { Modal } from '../../Generic';
import actions from '../../../domains/UI/actions';

export const BrandStandardDetails: React.FC = () => {
	const toggleModal = actions.useToggleModal();

	const { brandStandardId } = useParams<{ brandStandardId: string }>();

	const { data, loading, error } = useBrandStandardQuery({
		variables: {
			id: brandStandardId
		}
	});

	const [edit, setEdit] = useState<boolean>(false);

	const [editModal, setEditModal] = useState<boolean>(false);

	/* Delete Functionality commented out for future usage if needed */
	/*
	const [deleteBrandStandard] = useDeleteBrandStandardMutation();

	const history = useHistory();

	const [deleteModal, setDeleteModal] = useState<boolean>(false);

	const [deleteSuccessModal, setDeleteSuccessModal] = useState<boolean>(
		false
	);
	
	const handleDelete = async () => {
		const id = data?.brandStandard.id;

		if (id) {
			const response = await deleteBrandStandard({
				variables: {
					id
				}
			});

			if (response) {
				setDeleteModal(false);
				setDeleteSuccessModal(true);
				toggleModal();
			}
		}
	};*/

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return (
			<div className="p-3">
				<div className="alert alert-danger">
					There's no data available for brand standard (ID:
					{brandStandardId}). If you think this is an error, please
					contact Biggby Support. Thank you.
					<Link to="/" className="text-danger">
						{' '}
						Go back.
					</Link>
				</div>
			</div>
		);
	}

	return (
		<div className="brand-standard-details text-light">
			<div className="d-flex justify-content-between">
				<h1 className="m-0 px-3">BRAND STANDARD</h1>
				{!edit && (
					<div>
						{/* Delete Functionality commented out for future usage if needed */}
						{/* <button
							className="btn btn-danger mr-3"
							onClick={() => {
								toggleModal();
								setDeleteModal(true);
							}}
						>
							Delete
						</button> */}
						<button
							className="btn btn-primary mr-3"
							onClick={() => setEdit(true)}
						>
							Edit {edit}
						</button>
					</div>
				)}
				{edit && (
					<div>
						<button
							className="btn btn-danger mr-3 px-3"
							onClick={() => {
								toggleModal();
								setEditModal(true);
							}}
						>
							Cancel
						</button>
					</div>
				)}
			</div>
			<p className="m-0 px-3 text-primary">
				Brand Standard ID: {data?.brandStandard.brandStandardId}
			</p>
			<hr />
			{!edit && (
				<div className="brand-standard-details-info p-3">
					<div className="mb-5">
						<h3 className="text-primary">
							Brand Standard Description
						</h3>
						<p>{data?.brandStandard.description}</p>
					</div>
					<div className="mb-5">
						<h3 className="text-primary">
							Brand Standard Resolution
						</h3>
						<p>{data?.brandStandard.resolution}</p>
					</div>
				</div>
			)}

			{edit && data && (
				<BrandStandardEditForm
					{...data.brandStandard}
					onSave={() => window.location.reload()}
				/>
			)}

			{editModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Yes"
					confirmationButtonHandler={() => setEdit(false)}
					closeMessage="No"
					closeButtonHandler={() => setEditModal(false)}
				>
					<>
						<h1 className="text-dark text-center">
							Are you sure you want to cancel?
						</h1>
						<p className="text-dark">
							The brand standard{' '}
							<em>'{data?.brandStandard.description}'</em>&nbsp;
							changes made wouldn't be save.
						</p>
					</>
				</Modal>
			)}

			{/* Delete Functionality commented out for future usage if needed */}
			{/* {deleteModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Delete"
					confirmationButtonHandler={handleDelete}
					closeButtonHandler={() => setDeleteModal(false)}
				>
					<>
						<h1 className="text-dark text-center">
							Are you sure you want to delete?
						</h1>
						<p className="text-dark">
							The brand standard {data?.brandStandard.description}{' '}
							will be permanently deleted.
						</p>
					</>
				</Modal>
			)}

			{deleteSuccessModal && (
				<Modal
					closeButtonHandler={() => {
						setDeleteSuccessModal(false);
						history.goBack();
					}}
				>
					<>
						<h1 className="text-dark text-center">
							Successfully Deleted!
						</h1>
						<p className="text-dark">
							The brand standard {data?.brandStandard.description}{' '}
							has been permanently deleted.
						</p>
					</>
				</Modal>
			)} */}
		</div>
	);
};

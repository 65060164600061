import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	CreditCardPaymentsFieldFragment,
	DisputeFieldsFragment
} from '../../../graphql';

import {
	AcceptDisputeButton,
	DisputeStatusButton,
	RefundButton,
	SubmitDisputeEvidence
} from '..';

export interface DisputeButtonsProps {
	dispute: DisputeFieldsFragment;
	orderId: string;
	storeId: string;
	creditCardPayments: CreditCardPaymentsFieldFragment[];
}

export const DisputeButtons: React.FC<{
	dispute: DisputeFieldsFragment;
	orderId: string;
	storeId: string;
	creditCardPayments: CreditCardPaymentsFieldFragment[];
}> = ({ dispute, orderId, storeId, creditCardPayments }) => {
	const [acceptedDispute, setAcceptedDispute] = useState(false);
	const [resolvedDispute, setResolvedDispute] = useState(false);

	useEffect(() => {
		const status = dispute.status;

		if (status === 'lost' || status === 'warning_closed') {
			setResolvedDispute(true);
		}
	}, [dispute, resolvedDispute]);

	const isChargeRefundable = dispute.isChargeRefundable;

	const handledAccepted = () => {
		setResolvedDispute(true);
		setAcceptedDispute(true);
	};

	return (
		<div>
			{(resolvedDispute || acceptedDispute) && (
				<DisputeStatusButton
					disputeStatus={
						acceptedDispute ? 'Dispute Accepted' : dispute.status
					}
				/>
			)}
			{/* need to handle if dispute is in review */}
			{!resolvedDispute && (
				<>
					<Link
						to={`/stripe/store/${storeId}/${orderId}/dispute-evidence`}
					>
						<SubmitDisputeEvidence />
					</Link>

					{isChargeRefundable && (
						<RefundButton
							orderId={orderId}
							creditCardPayments={creditCardPayments}
						/>
					)}

					{!isChargeRefundable && (
						<AcceptDisputeButton
							disputeId={dispute.id}
							accepted={handledAccepted}
						/>
					)}
				</>
			)}
		</div>
	);
};

import { TextField } from '../../../components';
import { ModifierFieldsFragment } from '../../../graphql';

export type ModifierDetailsProps = {
	modifier: ModifierFieldsFragment;
};

export const ModifierDetails: React.FC<ModifierDetailsProps> = ({
	modifier
}) => (
	<div className="gap">
		<TextField label="ID" value={modifier.id} />

		<TextField label="Name" value={modifier.name} />
	</div>
);

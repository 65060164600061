import actions from './actions';
import reducer from './reducer';
import selectors from './selectors';

const ProductManagement: ProductManagementDomain.ProductManagementDomain = {
	actions: actions,
	selectors: selectors,
	reducer
};

export default ProductManagement;

import React from 'react';
import { StripeOrderQuery } from '../../../graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

interface OrderSummaryProps extends StripeOrderQuery {
	errorMessage?: string;
}

export const OrderSummary = (props: OrderSummaryProps): JSX.Element => {
	const order = props.order;
	const errorMessage = props.errorMessage;

	return (
		<div className="order-info-container px-3">
			<div className="order-info">
				<h3>Pickup Name</h3>

				<p className="text-break">{order?.pickupName}</p>
			</div>

			<div className="order-info">
				<h3>Loyalty Member</h3>

				{order?.profileId === null ? (
					<p>
						<FontAwesomeIcon
							className="text-danger"
							icon={faTimes}
							style={{ padding: 0 }}
						/>
					</p>
				) : (
					<p>
						<FontAwesomeIcon
							className="text-success"
							style={{ padding: 0 }}
							icon={faCheck}
						/>
					</p>
				)}
			</div>

			<div className="order-info">
				<h3>Email</h3>

				<p className="text-break">
					{order?.guestEmail ?? order?.profile?.email}
				</p>
			</div>

			<div className="order-info">
				<h3>Time Placed</h3>

				<p>{moment(order?.timePlaced).fromNow()}</p>
			</div>

			<div className="order-info">
				<h3>Status</h3>

				<p>
					{order?.status.status.substr(6)}
					{errorMessage && ` - ${errorMessage}`}
				</p>
			</div>

			<div className="order-info">
				<h3>Subtotal</h3>

				<p>${((order?.subTotal ?? 0) / 100).toFixed(2)}</p>
			</div>

			<div className="order-info">
				<h3>Tax</h3>

				<p>${((order?.taxAmount ?? 0) / 100).toFixed(2)}</p>
			</div>

			<div className="order-info">
				<h3>Total</h3>

				<p>${((order?.total ?? 0) / 100).toFixed(2)}</p>
			</div>
		</div>
	);
};

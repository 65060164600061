import { useFetchSession } from './useFetchSession';
import { useFetchAllProductData } from './useFetchAllProductData';

export const usePageLoaded: NetworkDomain.NetworkDomainActions['usePageLoaded'] =
	(): void => {
		console.log('Network: actions: usePageLoaded');

		useFetchSession();
		useFetchAllProductData();
	};

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

const actions: UIDomain.UIDomainActions = {
	useAddAlert() {
		const dispatch = useDispatch();
		const removeAlert = actions.useRemoveAlert();
		const [removed, setRemoved] = useState(false);
		const [autoDismiss, setAutoDismiss] = useState(false);
		const [id, setId] = useState('');

		useEffect(() => {
			if (!removed && autoDismiss) {
				setTimeout(() => {
					removeAlert(id);
				}, 2000);

				setRemoved(true);
			}
		}, [dispatch, removed, autoDismiss, removeAlert, id]);

		return (alert: UIDomain.NewAlert) => {
			const newAlertId = v4();

			const action: UIDomain.Actions.AddAlert = {
				type: 'ADD_ALERT',
				payload: { ...alert, id: newAlertId }
			};

			dispatch(action);

			if (alert.autoDismiss) {
				setAutoDismiss(true);
				setId(newAlertId);
			}
		};
	},
	useRemoveAlert() {
		const dispatch = useDispatch();

		return (id: string) => {
			const action: UIDomain.Actions.RemoveAlert = {
				type: 'REMOVE_ALERT',
				payload: id
			};

			dispatch(action);
		};
	},
	useSetOrientation(orientation) {
		const dispatch = useDispatch();
		const [, forceUpdate] = useState(false);

		const setOrientationAction = useCallback(
			(orientation: UIDomain.Orientation) => {
				const action: UIDomain.Actions.SetOrienation = {
					type: 'SET_ORIENTATION',
					payload: orientation
				};

				forceUpdate(s => !s);
				dispatch(action);
			},
			[dispatch]
		);

		useEffect(() => {
			if (orientation) {
				setOrientationAction(orientation);
			}
		}, [setOrientationAction, orientation]);

		return (orientation: UIDomain.Orientation) =>
			setOrientationAction(orientation);
	},
	useToggleModal() {
		const dispatch = useDispatch();

		return () => {
			const action = {
				type: 'TOGGLE_MODAL'
			};

			dispatch(action);
		};
	}
};

export default actions;

import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import { useEmployeeStoresQuery } from '../graphql';

import TextInputBox from '../components/ProductManagement/TextInput/TextInputBox';
import { useUSAStates } from '../components/ProductManagement/TextInput/useUSAStates';
import { Modal } from '../components/Generic';
import { Loading } from '../components/Loading';
import actions from '../domains/UI/actions';

const today = new Date();
const month = today.getMonth() + 1;
const currentDate = today.getFullYear() + '-' + month + '-' + today.getDate();

interface IStatus {
	loading: boolean;
	error: string;
	data: string;
}
interface IErrors {
	[key: string]: string | null;
}

interface SelectDropDown {
	label: string;
	value: string;
}

interface IEwardEntryform {
	email: string;
	first_name: string;
	last_name: string;
	street: string;
	unit: string;
	city: string;
	state: string;
	postal_code: string;
	phone: string;
	card_number: string;
	storeid: string;
	birth_date: string;
	birthday_promotion_medium: string;
	eward_subscription: boolean;
	eward_signup_date: string;
	errors?: IErrors | null;
}

const initialState = {
	email: '',
	first_name: '',
	last_name: '',
	street: '',
	unit: '',
	city: '',
	state: '',
	postal_code: '',
	phone: '',
	card_number: '',
	storeid: '',
	birth_date: '',
	birthday_promotion_medium: 'BiggbyCard',
	eward_subscription: true,
	eward_signup_date: currentDate
};

const initialUSAStates: SelectDropDown = {
	label: 'Michigan',
	value: 'MI'
};

const initialStore: SelectDropDown = {
	label: '481',
	value: '481'
};

const defaultStore = {
	label: 'Select Store Id',
	value: '-1'
};

export const EwardEntryForm: React.FC = () => {
	const USAStates = useUSAStates();
	const divRef = useRef<HTMLDivElement>(null);

	const [formData, setFormData] = useState<IEwardEntryform>(initialState);
	const [responseMessage, setResponseMessage] = useState<IStatus>({
		loading: false,
		error: '',
		data: ''
	});

	const [storeData, setStoreData] = useState<Array<SelectDropDown>>([]);
	const [storeSelect, setStoreSelect] =
		useState<SelectDropDown>(defaultStore);
	const [allUSAStates, setUSAStates] =
		useState<SelectDropDown>(initialUSAStates);

	const { data: employeeStoresData } = useEmployeeStoresQuery();

	const createEwardUser = async () => {
		try {
			setResponseMessage({ ...responseMessage, loading: true });
			let ACCOUNTS_API_URL = '';
			if (process.env.REACT_APP_ACCOUNTS_API_URL) {
				ACCOUNTS_API_URL = process.env.REACT_APP_ACCOUNTS_API_URL;
			}
			const res = await fetch(ACCOUNTS_API_URL, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			});

			const resdata = await res.json();

			if (!res.ok) {
				const err = resdata['detail']
					? resdata['detail']
					: resdata['user_message']
					? resdata['user_message']
					: resdata['message']
					? resdata['message']
					: 'Error';
				setResponseMessage({
					loading: false,
					error: err,
					data: ''
				});
			} else {
				setResponseMessage({ loading: false, error: '', data: 'ok' });

				setFormData(initialState);
				setStoreSelect(defaultStore);
				setUSAStates(initialUSAStates);

				setTimeout(() => {
					setResponseMessage({ loading: false, error: '', data: '' });
				}, 10000);
			}
		} catch (error) {
			setResponseMessage({
				loading: false,
				error: 'Response error: ' + error,
				data: ''
			});
		}
	};

	const scrollToTop = () => {
		divRef.current?.scroll({
			top: 0,
			behavior: 'smooth'
		});
	};

	const onSubmit = async () => {
		createEwardUser();

		setTimeout(() => {
			scrollToTop();
		}, 100);
	};

	useEffect(() => {
		const getStores = async () => {
			const storeIds = employeeStoresData?.session.employee?.stores.map(
				store => {
					const s = String(store.storeId);

					const stores: SelectDropDown = {
						label: s,
						value: s
					};
					return stores;
				}
			);
			return storeIds;
		};

		const populateStoreId = async () => {
			await getStores().then(store => {
				setStoreData(store || [initialStore]);
			});
		};

		populateStoreId();
	}, [employeeStoresData]);

	const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value
		});
	};

	const handleSelectChanges = (data: SelectDropDown | null, key: string) => {
		if (data && key === 'STATE') {
			setUSAStates(data);
			setFormData({
				...formData,
				state: data.value
			});
		}

		if (data && key === 'STORE') {
			setStoreSelect(data);
			setFormData({
				...formData,
				storeid: data.value
			});
		}
	};

	const openToggle = actions.useToggleModal();
	const toggleModal = () => {
		if (validateForm()) {
			openToggle();
		} else {
			setTimeout(() => {
				scrollToTop();
			}, 100);
		}
	};

	const validateForm = (): boolean => {
		const errors: IErrors = {};
		let formIsValid = true;

		if (formData.email.length <= 0) {
			formIsValid = false;
			errors['email'] = 'Email cannot be empty';
		} else if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)
		) {
			formIsValid = false;
			errors['email'] = 'Invalid Email';
		} else {
			formIsValid = true;
			errors['email'] = null;
		}

		if (storeSelect?.value === '-1') {
			formIsValid = false;
			errors['storeid'] = 'Store id cannot be empty';
		} else {
			formIsValid = true;
			errors['storeid'] = null;
		}

		if (formData.birth_date.length <= 0) {
			formData.birth_date = '0001-01-01';
			//formIsValid = false;
			//errors['birth_date'] = 'Birth date cannot be empty';
		} else {
			formIsValid = true;
			errors['birth_date'] = null;
		}

		setFormData({
			...formData,
			errors: errors
		});
		return formIsValid;
	};

	return (
		<div className="overflow-auto px-4" id="ewardEntry" ref={divRef}>
			<div className="w-100">
				<div className="row">
					<div className="col-md-3 col-sm-1"></div>

					<div className="col-md-6 col-sm-10">
						<div className="p-2 mt-4">
							<h2 className="text-light font-weight-bold d-flex justify-content-center">
								E-Ward Entry
							</h2>

							{responseMessage.error && (
								<div
									className="alert alert-danger alert-dismissible fade show"
									role="alert"
								>
									{`We weren't able to complete this task. Please try again later. \n ${responseMessage.error}`}
								</div>
							)}

							{responseMessage.loading && <Loading />}

							{responseMessage.data === 'ok' && (
								<div
									className="alert alert-success alert-dismissible fade show"
									role="alert"
								>
									{`Eward Successfully submitted successfully`}
								</div>
							)}

							<TextInputBox
								inputType="email"
								name="email"
								placeholder="E-Mail"
								label="E-Mail*"
								onChange={handleChanges}
								value={formData.email}
								error={
									formData.errors
										? formData.errors['email']
										: null
								}
							/>

							<div className="mb-4">
								<div className="form-group  d-flex flex-column">
									<label
										htmlFor={'storeDD'}
										className="text-light"
									>
										{'Store Id*'}
									</label>
									<Select
										name="storeDD"
										options={storeData}
										defaultValue={defaultStore}
										value={storeSelect}
										onChange={data =>
											handleSelectChanges(data, 'STORE')
										}
									/>
									{formData.errors &&
										formData.errors['storeid'] && (
											<small
												id="error"
												className="form-text text-danger"
											>
												{formData.errors['storeid']}
											</small>
										)}
								</div>
							</div>

							<TextInputBox
								inputType="text"
								name="first_name"
								placeholder="First Name"
								label="First Name"
								onChange={handleChanges}
								value={formData.first_name}
								error={
									formData.errors
										? formData.errors['first_name']
										: null
								}
							/>

							<TextInputBox
								inputType="text"
								name="last_name"
								placeholder="Last Name"
								label="Last Name"
								onChange={handleChanges}
								value={formData.last_name}
								error={
									formData.errors
										? formData.errors['last_name']
										: null
								}
							/>

							<TextInputBox
								inputType="text"
								name="street"
								placeholder="Address"
								label="Street Name"
								onChange={handleChanges}
								value={formData.street}
							/>

							<TextInputBox
								inputType="text"
								name="unit"
								placeholder="Unit"
								label="Unit Name"
								onChange={handleChanges}
								value={formData.unit}
							/>

							<TextInputBox
								inputType="text"
								name="city"
								placeholder="City"
								label="City Name"
								onChange={handleChanges}
								value={formData.city}
							/>

							<div className="mb-4">
								<div className="form-group  d-flex flex-column">
									<label
										htmlFor={'stateDD'}
										className="text-light"
									>
										{'State'}
									</label>
									<Select
										name="stateDD"
										options={USAStates}
										defaultValue={initialUSAStates}
										value={allUSAStates}
										onChange={data =>
											handleSelectChanges(data, 'STATE')
										}
									/>
								</div>
							</div>

							<TextInputBox
								inputType="number"
								name="postal_code"
								placeholder="Postal code"
								label="Postal code"
								onChange={handleChanges}
								value={formData.postal_code}
								maxLength={6}
							/>

							<TextInputBox
								inputType="tel"
								name="phone"
								placeholder="Phone"
								label="Phone"
								onChange={handleChanges}
								value={formData.phone}
								pattern="[0-9]{10}"
							/>

							<TextInputBox
								inputType="text"
								name="card_number"
								placeholder="Card Number"
								label="Card Number"
								onChange={handleChanges}
								value={formData.card_number}
								maxLength={15}
								error={
									formData.errors
										? formData.errors['card_number']
										: null
								}
							/>

							<TextInputBox
								inputType="date"
								name="birth_date"
								placeholder="Birth date"
								label="Birth date"
								onChange={handleChanges}
								value={formData.birth_date}
								error={
									formData.errors
										? formData.errors['birth_date']
										: null
								}
							/>

							<div className="mb-4 d-flex justify-content-center">
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => toggleModal()}
								>
									Save
								</button>
							</div>
						</div>
					</div>

					<div className="col-md-3 col-sm-1"></div>
				</div>
			</div>
			<Modal
				showConfirmationButton={true}
				confirmationMessage="Yes"
				confirmationButtonHandler={onSubmit}
			>
				<>
					<h1>Are you sure?</h1>
					<p>
						This action will create a new E-Ward entry with an email
						of {formData.email}.
					</p>
				</>
			</Modal>
		</div>
	);
};

import React, { useState } from 'react';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import { RecipeBookProduct } from '..';
import { Drawer } from '../..';

export interface ProductGroupProps {
	group: Percomatic.Serialized.ProductGroup;
}

export const RecipeBookProductGroup: React.FC<ProductGroupProps> = ({
	group
}: ProductGroupProps) => {
	const [isActive, setIsActive] = useState(false);
	const [show, setShow] = useState(false);

	return (
		<Drawer
			isActive={isActive}
			onRest={() => {
				if (!isActive && show) {
					setShow(false);
				}
			}}
		>
			<>
				<hr className="m-0 p-0" />

				<div
					className="d-flex cursor-pointer align-items-center py-2 px-3 text-primary"
					onClick={() => {
						if (show) {
							setIsActive(false);
						} else {
							setShow(true);
							setIsActive(true);
						}
					}}
				>
					<h1 className="mr-auto mb-0">{group.name}</h1>

					<FontAwesomeIcon
						icon={isActive ? faChevronUp : faChevronDown}
					/>
				</div>
			</>

			{show &&
				group.visibleProducts
					.filter(
						product =>
							product.itemIds.length > 0 && product.isVisible
					)
					.map((product, key) => (
						<RecipeBookProduct product={product} key={key} />
					))}
		</Drawer>
	);
};

import { pick } from 'lodash';
import { TableItemType } from '../TableDropdown/TableDropdown';

export interface TableHeadProps {
	dropdownItems: TableItemType[];
}

export const TableHead = ({
	dropdownItems
}: TableHeadProps): React.ReactElement => {
	return (
		<thead>
			{Object.keys(
				pick(dropdownItems[0] ?? {}, ['unitName', 'name', 'id'])
			).map((item, key) => (
				<th key={key}>{item}</th>
			))}

			<th className="text-center">Delete</th>
		</thead>
	);
};

import React from 'react';

import { Link } from 'react-router-dom';

export const TopButtons: React.FC = () => {
	// const history = useHistory();
	return (
		<>
			<div className="first-row">
				<div className="left">
					{/* <button className="btn col-7 rounded border border-dark utility-button">
						#1
					</button> */}
					<Link
						className="btn col-5 rounded border border-dark utility-button"
						to="/"
					>
						Home
					</Link>
				</div>

				<div className="right d-flex justify-content-end">
					{/* <Link
						className="btn rounded border border-dark utility-button"
						to="/orders"
					>
						X
					</Link>

					<button className="btn rounded border border-dark utility-button">
						<i className="fa fa-search"></i>
					</button> */}

					{/* <Link
						className="btn col-5 rounded border border-dark utility-button"
						to="/"
					>
						Home
					</Link> */}
				</div>
			</div>

			{/* <div className="second-row">
				<div className="left">
					<button className="btn rounded border border-dark utility-button px-0 ">
						<i className="fa fa-search"></i>
					</button>

					<button className="btn rounded border border-dark utility-button px-0 ">
						<i className="fa fa-angle-double-left"></i>
					</button>

					<button className="btn rounded border border-dark utility-button px-0 ">
						<i className="fa fa-angle-left"></i>
					</button>

					<button className="btn rounded border border-dark utility-button px-0 ">
						<i className="fa fa-angle-right"></i>
					</button>

					<button className="btn rounded border border-dark utility-button px-0 ">
						<i className="fa fa-angle-double-right"></i>
					</button>

					<button className="btn rounded border border-dark utility-button px-0 ">
						New
					</button>

					<button className="btn col-3 rounded border border-dark utility-button px-0">
						Main
					</button>
				</div>

				<div className="right"></div>
			</div> */}
		</>
	);
};

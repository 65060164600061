import React from 'react';

import Order from '../../../domains/Order';
import Session from '../../../domains/Session';

import { useTerminalStatus } from '../../../hooks/useTerminalStatus/useTerminalStatus';
import { useStoreTerminalsQuery } from '../../../graphql';

interface UtilityButtonProps {
	label: string;
	background?: string;
}

const UtilityButton: React.FC<
	UtilityButtonProps & React.HTMLAttributes<HTMLButtonElement>
> = ({ label, background = 'utility-button', ...otherProps }) => (
	<button
		{...otherProps}
		className={`btn rounded border border-dark col-6 ${background}`}
	>
		{label}
	</button>
);

export const MenuUtilityButtons: React.FC = () => {
	const store = Session.selectors.useSelectStore();
	const qty = Order.selectors.useSelectCartQuantity();

	const sendOrder = Order.actions.useSendOrder();

	const { data } = useStoreTerminalsQuery();

	const { fhStatus, dtStatus } = useTerminalStatus();

	let fhLabel = 'FH DISCONNECTED';
	let dtLabel = 'DT DISCONNECTED';

	let fhAction: () => void = () =>
		alert(
			'Your counter terminal is not connected to the server. Please restart PERC-O-Matic on your counter terminal.\n\nIf the problem persists, please contact the FCL.'
		);
	let dtAction: () => void = () =>
		alert(
			'Your drive thru terminal is not connected to the server. Please restart PERC-O-Matic on your drive thru terminal.\n\nIf the problem persists, please contact the FCL.'
		);

	let fhBg = 'bg-danger';
	let dtBg = 'bg-danger';

	if (fhStatus === 'CONNECTED') {
		fhLabel = 'SEND TO FH';
		fhAction = () => sendOrder(2);
		fhBg = 'bg-success';
	}

	if (dtStatus === 'CONNECTED') {
		dtLabel = 'SEND TO DT';
		dtAction = () => sendOrder(3);
		dtBg = 'bg-success';
	}

	return (
		<div className="utility-buttons">
			{qty > 0 && data && (
				<>
					<UtilityButton
						label={fhLabel}
						background={fhBg}
						onClick={fhAction}
					/>

					{store?.driveThru && (
						<UtilityButton
							label={dtLabel}
							background={dtBg}
							onClick={dtAction}
						/>
					)}
				</>
			)}
			{/* <UtilityButton label="Payments" />
			<UtilityButton label="Coupons" />
			<UtilityButton label="Customer" />
			<UtilityButton label="Close Order" /> */}
		</div>
	);
};

import React from 'react';
import FadeIn from 'react-fade-in';

import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import { QuantityControl } from '..';

import Product from '../../domains/Product';

export interface ModifierScaleProps {
	modifierScaleIndex: number;
	isActive: boolean;
	modifierScale: Percomatic.Serialized.ModifierScale;
	selectedIndex: number;
}

export const ModifierScale: React.FC<ModifierScaleProps> = ({
	modifierScaleIndex,
	modifierScale,
	selectedIndex,
	isActive
}) => {
	const modifying = Product.selectors.useSelectModifying();
	const increaseModifierLevel = Product.actions.useIncreaseModifierLevel();
	const decreaseModifierLevel = Product.actions.useDecreaseModifierLevel();

	const modifiers = modifierScale.modifiers;
	const scaleSelected = modifierScale.modifiers[selectedIndex];

	return (
		<div className="option option-active modifier-scale col-6 p-0">
			<div className="modifier-group">
				<FadeIn className="px-0">
					<QuantityControl
						quantity={scaleSelected.name}
						margins={3}
						size="large"
						color="primary"
						fontColor="dark"
						modifier={true}
						increment={() => {
							if (!modifying) {
								if (selectedIndex < modifiers.length - 1) {
									increaseModifierLevel(
										1,
										modifierScaleIndex
									);
								}
							}
						}}
						decrement={() => {
							if (!modifying) {
								if (selectedIndex > 0) {
									decreaseModifierLevel(
										1,
										modifierScaleIndex
									);
								}
							}
						}}
					/>
				</FadeIn>
			</div>
		</div>
	);
};

import React, { useState } from 'react';

import { NutritionRow } from '..';
import { Drawer, Header, HeaderProps } from '../..';

export type ProductNutritionProps = {
	nutritionRows: ProductDomain.UI.NutritionRow[];
	title?: string;
	tag?: HeaderProps['tag'];
};

export const ProductNutrition: React.FC<ProductNutritionProps> = ({
	nutritionRows,
	title = 'Nutritional Information',
	tag = 'h5'
}) => {
	const [isActive, setIsActive] = useState(false);

	return (
		<Drawer isActive={isActive} className="product-nutrition">
			<Header
				color="secondary"
				onClick={() => setIsActive(!isActive)}
				tag={tag}
				isActive={isActive}
				label={title}
			/>

			{nutritionRows.map(({ label, value, child }, key) => (
				<NutritionRow
					label={label}
					value={value}
					child={child}
					key={key}
				/>
			))}
		</Drawer>
	);
};

const initialState: ProductDomain.State = {
	activeModifierGroup: -1,
	activeModifierScale: -1,
	isProductVersionOptionActive: false,
	isSizeOptionActive: false,
	loadingQueue: [],
	initAndLinkProgress: 0,
	initAndLinkProgressMessage: 'Downloading menu data...',
	modifying: false,
	percomatic: {
		transItem: null,
		modifierScales: {
			modifierScales: [],
			modifierScaleIndices: []
		},
		modifierGroups: {
			modifierGroups: [],
			modifierModels: []
		},
		sizes: {
			sizes: [],
			selectedIndex: -1
		},
		productVersions: {
			productVersions: [],
			selectedIndex: -1
		},
		transItemModel: {
			transItems: []
		},
		transactionModel: {
			currentTransactionIndex: -1,
			openTransactionIndices: [],
			transactions: []
		}
	}
};

const reducer = (
	state: ProductDomain.State = initialState,
	action: ProductDomain.Action
): ProductDomain.State => {
	let loadingQueue, newProgress;
	switch (action.type) {
		case 'CLICK_MODIFIER_GROUP':
			return {
				...state,
				activeModifierGroup:
					action.modifierGroupIndex === state.activeModifierGroup
						? -1
						: action.modifierGroupIndex,
				activeModifierScale: -1,
				isProductVersionOptionActive: false,
				isSizeOptionActive: false
			};
		case 'CLICK_MODIFIER_SCALE':
			return {
				...state,
				activeModifierGroup: -1,
				activeModifierScale:
					action.modifierScaleIndex === state.activeModifierScale
						? -1
						: action.modifierScaleIndex,
				isProductVersionOptionActive: false,
				isSizeOptionActive: false
			};
		case 'CLICK_SIZES':
			return {
				...state,
				activeModifierGroup: -1,
				activeModifierScale: -1,
				isProductVersionOptionActive: false,
				isSizeOptionActive: !state.isSizeOptionActive
			};
		case 'CLICK_PRODUCT_VERSIONS':
			return {
				...state,
				activeModifierGroup: -1,
				activeModifierScale: -1,
				isProductVersionOptionActive:
					!state.isProductVersionOptionActive,
				isSizeOptionActive: false
			};
		case 'START_MODIFYING':
			return {
				...state,
				modifying: true
			};
		case 'UPDATE_LOADING_QUEUE':
			console.log('Product: reducer: UPDATE_LOADING_QUEUE');

			return {
				...state,
				loadingQueue: [...state.loadingQueue, action.payload]
			};
		case 'UPDATE_INIT_PROGRESS':
			console.log('Product: reducer: UPDATE_INIT_PROGRESS');
			loadingQueue = [...state.loadingQueue];

			newProgress = loadingQueue.shift();

			return {
				...state,
				loadingQueue,
				...newProgress
			};
		case 'RESET_PRODUCT':
			console.log('Product: reducer: RESET_PRODUCT');

			return {
				...state,
				percomatic: initialState.percomatic
			};
		case 'INIT_AND_LINK': {
			console.log('Product: reducer: INIT_AND_LINK');

			return {
				...state
			};
		}
		case 'SELECT_PRODUCT': {
			console.log('Product: reducer: SELECT_PRODUCT');

			return {
				...state,
				percomatic: { ...action.payload },
				modifying: false
			};
		}
		case 'SELECT_MODIFIER': {
			console.log('Product: reducer: SELECT_MODIFIER');

			return {
				...state,
				percomatic: { ...action.payload },
				modifying: false
			};
		}
		case 'DESELECT_MODIFIER': {
			console.log('Product: reducer: DESELECT_MODIFIER');

			return {
				...state,
				percomatic: { ...action.payload },
				modifying: false
			};
		}
		case 'INCREASE_MODIFIER_LEVEL': {
			console.log('Product: reducer: INCREASE_MODIFIER_LEVEL');

			return {
				...state,
				percomatic: { ...action.payload },
				modifying: false
			};
		}
		case 'DECREASE_MODIFIER_LEVEL': {
			console.log('Product: reducer: DECREASE_MODIFIER_LEVEL');

			return {
				...state,
				percomatic: { ...action.payload },
				modifying: false
			};
		}
		case 'CHANGE_SIZE': {
			console.log('Product: reducer: CHANGE_SIZE');

			return {
				...state,
				percomatic: { ...action.payload },
				modifying: false
			};
		}
		case 'CHANGE_VERSION': {
			console.log('Product: reducer: CHANGE_VERSION');

			return {
				...state,
				percomatic: { ...action.payload },
				modifying: false
			};
		}
		default:
			return state;
	}
};

export default reducer;

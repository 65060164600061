import React from 'react';

export const SupportEvidence: React.FC = () => {
	return (
		<>
			<h3 className="px-3 dp-h3">Supporting Evidence</h3>
			<div className="px-3">
				<p>
					Submit accurate and relevant high-resolution documents to
					support your case.{' '}
					<a href="https://stripe.com/docs/disputes/responding">
						Learn best practices for submitting evidence.
					</a>
				</p>
				<h6>RECOMMENDED DOCUMENTS</h6>
				{/* need to include tooltip */}
				{/* Below is used for the reason Fraudulant / Inquiry (with is_charge_refundable=true /  */}
				<ul>
					<li>Customer communication</li>
					<li>Customer signature</li>
					<li>Shipping documentation</li>
					<li>Other evidence</li>
					<li>Reciept</li>
				</ul>
				{/* need to include tooltip */}
				{/* Below is used for the reason (Product not received) */}
				{/* <ul>
					<li>Customer communication</li>
				</ul> */}
			</div>
		</>
	);
};

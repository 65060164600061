import {
	RefundFieldsFragment,
	RefundDestinationInput,
	OrderCreditCardPaymentFieldsFragment,
	OrderGiftCardPaymentFieldsFragment
} from '../../graphql';
import { orderRefundsToDestinations } from '../orderRefundsToDestinations';

export const validateRefundInput = (
	orderPayments: (
		| OrderCreditCardPaymentFieldsFragment
		| OrderGiftCardPaymentFieldsFragment
	)[],
	refundDestinations: RefundDestinationInput[],
	prices: {
		// Total prices of checked items on refund page
		subtotal: number;
		tax: number;
	},
	orderRefunds: RefundFieldsFragment[]
): [boolean, string[]] => {
	const errorMessages: string[] = [];
	let destinationTotal = 0;
	let pricesCheckOut = true;

	const paymentsMap = new Map(
		orderPayments.map(payment => [payment.id, payment])
	);

	for (const destination of refundDestinations) {
		const [, amountRefundedForSpecificPaymentId] =
			orderRefundsToDestinations(orderRefunds, destination.paymentId);

		const totalPaidOnPaymentId =
			paymentsMap.get(destination.paymentId)?.amount ?? 0;

		if (
			destination.amount + amountRefundedForSpecificPaymentId >
			totalPaidOnPaymentId
		) {
			const originPayment = paymentsMap.get(destination.paymentId);
			const cardNum =
				originPayment?.__typename === 'OrderGiftCardPayment'
					? originPayment.cardNumber
					: originPayment?.__typename === 'OrderCreditCardPayment'
					? originPayment.lastFour
					: null;

			errorMessages.push(
				`Error: Cannot refund card ${cardNum} the amount: $${(
					destination.amount / 100
				).toFixed(2)}. ` +
					(originPayment &&
						`There is only $${(
							(originPayment.amount -
								amountRefundedForSpecificPaymentId) /
							100
						).toFixed(2)} left to be refunded for this payment.`)
			);

			pricesCheckOut = false;
		}

		destinationTotal += destination.amount;
	}

	if (prices.subtotal + prices.tax !== destinationTotal) {
		errorMessages.push(
			'Please input refund amounts that add up to $' +
				((prices.subtotal + prices.tax) / 100).toFixed(2)
		);
		pricesCheckOut = false;
	}

	return [pricesCheckOut, errorMessages];
};

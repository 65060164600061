import { useSelector } from 'react-redux';

const selectors: RefundDomain.RefundDomainSelectors = {
	useSelectReturnReasonId() {
		const returnReason = useSelector(
			(state: RootState) => state.refund.returnReasonId
		);

		return returnReason;
	},
	useSelectRefundItems() {
		const items = useSelector((state: RootState) => state.refund.items);

		return items;
	},
	useSelectRefundDestinations() {
		const destinations = useSelector(
			(state: RootState) => state.refund.destinations
		);

		return destinations;
	},
	useSelectPrices() {
		const prices = useSelector((state: RootState) => state.refund.prices);

		return prices;
	}
};

export default selectors;

import { useEffect, useMemo, useState } from 'react';

import { useTerminalStatus } from '..';

import Refund from '../../domains/Refund';

export const useRefundButtonDisabled = (isRefunded: boolean): boolean => {
	const refundItems = Refund.selectors.useSelectRefundItems();
	const noRefundItemsSelected = useMemo(
		() => refundItems.length === 0,
		[refundItems]
	);

	const { bhStatus, fhStatus, dtStatus } = useTerminalStatus();

	const isAnyTerminalAvailable = useMemo(
		() =>
			[bhStatus, fhStatus, dtStatus].some(
				terminalStatus => terminalStatus === 'CONNECTED'
			),
		[bhStatus, dtStatus, fhStatus]
	);

	const [isRefundButtonDisabled, setIsRefundButtonDisabled] = useState(false);

	useEffect(() => {
		if (noRefundItemsSelected) {
			setIsRefundButtonDisabled(true);
		} else {
			setIsRefundButtonDisabled(
				isAnyTerminalAvailable ? isRefunded : true
			);
		}
	}, [isAnyTerminalAvailable, isRefunded, noRefundItemsSelected]);

	return isRefundButtonDisabled;
};

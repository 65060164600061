import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import React from 'react';

export interface ModifierScaleSelectorProps {
	modifiers: Percomatic.Serialized.Modifier[];
	selectedIndex: number;
	clickHandler: (
		modifier: Percomatic.Serialized.Modifier,
		index: number
	) => void;
}

export const ModifierScaleSelector: React.FC<ModifierScaleSelectorProps> = ({
	modifiers,
	selectedIndex,
	clickHandler
}) => {
	return (
		<div className="modifier-scale-selector">
			{modifiers.map(
				(modifier: Percomatic.Serialized.Modifier, index: number) => (
					<div
						onClick={() => clickHandler(modifier, index)}
						className={`modifier-scale-selector-option${
							selectedIndex === index
								? ' modifier-scale-selector-option-active'
								: ''
						}`}
						key={index}
					>
						{modifier.name}
					</div>
				)
			)}
		</div>
	);
};

// import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Tooltip } from "./Tooltip";

export type SelectorProps = {
	options: [id: string, label: string][];
	label: string;
	setValue: (x: string) => void;
};

export const Selector: React.FC<SelectorProps> = ({
	options,
	label,
	setValue
}: SelectorProps) => {
	return (
		<>
			<div className="d-flex align-items-center">
				<th className="border-0 py-3">{label}</th>
				{/* {content && (
					<Tooltip content={content}>
						{' '}
						<FontAwesomeIcon
							icon={faQuestionCircle}
							color="white"
						/>
					</Tooltip>
				)} */}
			</div>
			<td className="border-0">
				<select
					className="custom-select"
					// value={state}
					onChange={e => setValue(e.target.value)}
				>
					{options.map(([id, label], key) => (
						<option key={key} value={id}>
							{label}
						</option>
					))}
				</select>
			</td>
		</>
	);
};

export const determineSlug = (
	label: string,
	id: string | number,
	pathname: string
): string => {
	let slug: string;
	switch (label) {
		case 'Component':
			slug = pathname + '/component/' + String(id);
			break;
		case 'Inventory Item':
			slug = pathname + '/inventory-item/' + String(id);
			break;
		case 'Product':
			slug = '/product-management/new/product/' + String(id);
			break;
		case 'Token Product':
			slug = '/product-management/new/product/' + String(id);
			break;
		case 'Product Group':
			slug = '/product-management/product-group/' + String(id);
			break;
		default:
			slug = pathname;
	}
	return slug;
};

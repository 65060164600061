import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { useInterval } from '../../hooks/useInterval';
import Admin from '../../domains/Admin';
import Session from '../../domains/Session';
import { useNow } from '../../hooks';
import { PendingOrderQuery } from '../../graphql';
import { GenericDrawer } from '..';
import { Title } from '.';

import './styles.scss';

const adminRoles = [1, 7];

const formatMoney = (x: number) => (x / 100).toFixed(2);

const useTimes = (
	timeInput?: string,
	timePlacedInput?: string
): { time: string; timePlaced: string } => {
	const time = useLiveTimeFromNow(timeInput || new Date().toISOString());
	const timePlaced = `${moment(
		timePlacedInput || new Date().toISOString()
	).format('M/D/YY, h:mm A')}`;

	return { time, timePlaced };
};

const useLiveTimeFromNow = (isoString: string, interval = 5000): string => {
	const fromNow = useMemo(() => moment(isoString).fromNow(), [isoString]);

	const [time, setTime] = useState(fromNow);

	useEffect(() => {
		setTime(fromNow);
	}, [fromNow]);

	useInterval(() => {
		setTime(moment(isoString).fromNow());
	}, interval);

	return time;
};

type PendingOrderTemplateProps = {
	headerLeft: React.ReactNode;
	headerRight: React.ReactNode;
	id: string;
	placed: string;
	pickupName: string;
	pickupLocation: string;
	customerPhoneNumber?: string;
	button: React.ReactNode;
	carDetails?: React.ReactNode;
};

const PendingOrderTemplate: React.FC<PendingOrderTemplateProps> = ({
	headerLeft,
	headerRight,
	id,
	placed,
	pickupName,
	pickupLocation,
	customerPhoneNumber,
	button,
	carDetails
}) => {
	return (
		<>
			<div className="d-flex align-items-center pb-1">
				<h5 className="text-primary font-weight-bold mb-0 mr-auto">
					{headerLeft}
				</h5>

				{headerRight}
			</div>

			<small className="order-id d-block mb-2">{id}</small>

			<h6>
				Placed <span className="text-info">{placed}</span>
			</h6>

			{customerPhoneNumber && (
				<h6 className="mb-2">
					Customer Phone #:{' '}
					<span className="text-info">{customerPhoneNumber}</span>
				</h6>
			)}

			<div className="d-flex align-items-center">
				<h5 className="mr-auto mb-0">
					{pickupName} - Picking up{' '}
					<strong className="text-success">{pickupLocation}</strong>
				</h5>

				<div
					className="d-flex align-items-center justify-content-between"
					style={{ gap: '0.5rem' }}
				>
					{button}
				</div>
			</div>

			{carDetails && carDetails}
		</>
	);
};

type CarDetailsProps = Exclude<
	PendingOrderQuery['pendingOrder']['car'],
	undefined
>;

const CarDetails: React.FC<CarDetailsProps> = ({ color, make, model }) => (
	<h5 className="mr-auto mb-0">
		In a{' '}
		<strong className="text-secondary">
			{color} {make[0].toUpperCase() + make.slice(1)}{' '}
			{model[0].toUpperCase() + model.slice(1)}
		</strong>
	</h5>
);

type ButtonBaseProps = React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>;

const ButtonBase: React.FC<ButtonBaseProps> = ({
	onClick,
	children,
	...props
}) => (
	<button
		onClick={e => {
			e.stopPropagation();

			if (onClick) onClick(e);
		}}
		{...props}
	>
		{children}
	</button>
);

type ResendButtonProps = {
	status: PendingOrderQuery['pendingOrder']['status'];
	sendOrder: () => void;
};

const ResendButton: React.FC<ResendButtonProps> = ({ status, sendOrder }) => {
	const timeSentInMs = useMemo(
		() => new Date(status.time).getTime(),
		[status.time]
	);
	const now = useNow();

	const allowResend = useMemo(
		() => now.getTime() - timeSentInMs > 5000,
		[now, timeSentInMs]
	);

	if (allowResend) {
		return (
			<ButtonBase className="btn btn-danger" onClick={() => sendOrder()}>
				Send Again
			</ButtonBase>
		);
	}

	return (
		<ButtonBase disabled className="btn btn-primary">
			Ready
		</ButtonBase>
	);
};

type ButtonCommonProps = {
	status: PendingOrderQuery['pendingOrder']['status'];
	sendOrder: () => void;
	orderComplete: () => void;
};

const AdminOrderCompleteButton: React.FC<ButtonCommonProps> = ({
	status,
	sendOrder,
	orderComplete
}) => {
	switch (status.status) {
		case 'ORDER_PROCESSING':
		case 'ORDER_ERROR':
		case 'ORDER_REFUND_REQUESTED':
		case 'ORDER_REFUND_PROCESSED':
			return (
				<>
					<ButtonBase
						className="btn btn-warning text-white"
						onClick={() => sendOrder()}
					>
						Force Resend
					</ButtonBase>

					<ButtonBase
						className="btn btn-danger text-white"
						onClick={() => orderComplete()}
					>
						Force Complete
					</ButtonBase>
				</>
			);
		case 'ORDER_RECEIVED':
		case 'ORDER_SENT':
		case 'ORDER_SCHEDULED':
		case 'ORDER_READY':
		case 'ORDER_COMPLETE':
			return null;
	}
};

type ButtonProps = ButtonCommonProps & {
	id: string;
	orderReady: () => void;
};

const Button: React.FC<ButtonProps> = ({
	id,
	status,
	orderReady,
	sendOrder,
	orderComplete
}) => {
	const dispatch = useDispatch();

	switch (status.status) {
		case 'ORDER_SENT':
			return <ResendButton status={status} sendOrder={sendOrder} />;
		case 'ORDER_PROCESSING':
			return <span className="text-info">Processing</span>;
		case 'ORDER_ERROR':
			return <span className="text-danger">Error</span>;
		case 'ORDER_REFUND_REQUESTED':
			return <span className="text-warning">Refund Processing</span>;
		case 'ORDER_REFUND_PROCESSED':
			return <span className="text-warning">Refunded</span>;
		case 'ORDER_RECEIVED':
			return (
				<ButtonBase
					className="btn btn-primary"
					onClick={() => orderReady()}
				>
					Ready
				</ButtonBase>
			);
		case 'ORDER_SCHEDULED':
			return (
				<div className="d-flex flex-column align-items-center justify-content-between">
					<h5>Scheduled</h5>

					<ButtonBase
						className="btn btn-danger"
						onClick={() => sendOrder()}
					>
						Send Now
					</ButtonBase>
				</div>
			);
		case 'ORDER_READY':
			return (
				<ButtonBase
					className="btn btn-secondary text-white"
					onClick={() => orderComplete()}
				>
					Complete
				</ButtonBase>
			);
		case 'ORDER_COMPLETE': {
			const action: AdminDomain.Actions.PendingOrderComplete = {
				type: 'PENDING_ORDER_COMPLETE',
				payload: id
			};

			dispatch(action);

			return (
				<ButtonBase disabled className="btn btn-secondary text-white">
					Complete
				</ButtonBase>
			);
		}
	}
};

export type PendingOrderProps = {
	id: string;
};

export const PendingOrder: React.FC<PendingOrderProps> = ({ id }) => {
	const [active, setActive] = useState<boolean>(false);

	const toggleActive = () => setActive(!active);

	const { order, status, orderReady, orderComplete, sendOrder } =
		Admin.actions.usePendingOrder(id);

	const filtered = Admin.selectors.useSelectFilterPendingOrders();
	const { time, timePlaced } = useTimes(order?.time, order?.timePlaced);
	const employee = Session.selectors.useSelectEmployee();

	if (
		order?.status.status !== 'ORDER_SCHEDULED' &&
		filtered === 'SCHEDULED'
	) {
		return null;
	}

	if (order?.status.status === 'ORDER_SCHEDULED' && filtered === 'ASAP') {
		return null;
	}

	let body: React.ReactNode;

	if (order && status) {
		let pickupLocation: string;

		switch (order.pickupLocation) {
			case 'DRIVE_THRU':
				pickupLocation = 'at drive thru';
				break;

			case 'IN_STORE':
				pickupLocation = 'inside store';

				break;
			case 'PARKING_LOT':
				pickupLocation = 'in parking lot';

				break;
			default:
				pickupLocation = 'somewhere';
		}

		const carDetails = order.car;

		const customerPhoneNumber = order.phone ?? order.profile?.phone;

		body = (
			<GenericDrawer isActive={active} className="order-root">
				<div onClick={toggleActive} className="p-3 pb-0 cursor-pointer">
					<PendingOrderTemplate
						headerLeft={
							order.time ? (
								<Title
									scheduledTimeRelative={time}
									scheduledTime={moment(order.time)}
									status={order.status}
									statuses={order.statuses}
									items={order.items}
								/>
							) : (
								'ASAP'
							)
						}
						headerRight={
							active ? (
								<FontAwesomeIcon
									icon={faChevronUp}
									className="text-secondary order-view-btn"
									onClick={toggleActive}
								/>
							) : (
								<FontAwesomeIcon
									icon={faChevronDown}
									className="text-secondary order-view-btn"
									onClick={toggleActive}
								/>
							)
						}
						id={order.id}
						placed={timePlaced}
						pickupName={order.pickupName}
						pickupLocation={pickupLocation}
						customerPhoneNumber={customerPhoneNumber}
						button={
							<>
								{employee?.roles.some(role =>
									adminRoles.includes(role.roleId)
								) ? (
									<AdminOrderCompleteButton
										status={order.status}
										sendOrder={sendOrder}
										orderComplete={() =>
											orderComplete(true)
										}
									/>
								) : null}
								<Button
									id={id}
									status={order.status}
									sendOrder={sendOrder}
									orderReady={orderReady}
									orderComplete={orderComplete}
								/>
							</>
						}
						carDetails={
							carDetails &&
							order.pickupLocation === 'PARKING_LOT' && (
								<CarDetails {...carDetails} />
							)
						}
					/>
				</div>

				<div className="border-top border-list-group-border p-3">
					{/* <hr className="my-0" /> */}

					{order.items
						.sort(({ item: itemA }, { item: itemB }) =>
							itemA.size?.name && !itemB.size?.name ? -1 : 1
						)
						.map(({ item, price, modifiers }, key) => (
							<div
								key={key}
								className="d-flex justify-content-between align-items-center pb-2 mb-2 border-bottom border-list-group-border"
							>
								<div>
									<div className="text-primary mb-1">
										{item.size?.name} {item.product?.name}
									</div>

									<small className="d-block">
										{modifiers
											.map(
												modifier =>
													modifier.modifier.name
											)
											.join(', ')}
									</small>
								</div>

								<p>${formatMoney(price)}</p>
							</div>
						))}

					<div className="d-flex justify-content-between mt-2">
						<div>
							<h6 className="font-weight-normal mb-1">
								Subtotal
							</h6>

							<h6 className="font-weight-normal mb-1">Tax</h6>

							<h5 className="mb-0">Total</h5>
						</div>

						<div>
							<h6 className="font-weight-normal text-right mb-1">
								${formatMoney(order.subTotal)}
							</h6>

							<h6 className="font-weight-normal text-right mb-1">
								${formatMoney(order.taxAmount)}
							</h6>

							<h5 className="mb-0">
								${formatMoney(order.total)}
							</h5>
						</div>
					</div>
				</div>
			</GenericDrawer>
		);
	} else {
		body = (
			<div className="p-3 pb-0">
				<PendingOrderTemplate
					headerLeft={'...'}
					headerRight={'...'}
					id={'...'}
					placed={'...'}
					pickupName={'...'}
					pickupLocation={'...'}
					customerPhoneNumber={'...'}
					button={'...'}
					carDetails={undefined}
				/>
			</div>
		);
	}

	return <li className="list-group-item pending-order p-0">{body}</li>;
};

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export type CancelButtonProps = {
	width?: number;
	height?: number;
};

export const CancelButton: React.FC<
	CancelButtonProps & React.HTMLAttributes<HTMLDivElement>
> = ({ width = 25, height = 25, ...props }) => {
	return (
		<div
			{...props}
			className="bg-danger text-light d-flex align-items-center justify-content-center cursor-pointer rounded"
			style={{
				...props.style,
				width,
				height
			}}
		>
			<FontAwesomeIcon icon={faTimes} />
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
	PercomaticLoading,
	ProductFieldsForm,
	ProductItems
} from '../../components';

import ProductManagement from '../../domains/ProductManagement';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import api from '../../worker';

interface ParamProps {
	productId: string;
	groupId: string;
}

function Body() {
	const params = useParams<ParamProps>();

	const [product, setProduct] = useState<Percomatic.Serialized.Product>();

	useEffect(() => {
		(async () => {
			const product = await api.getProduct(Number(params.productId));
			setProduct(product);
		})();
	}, [params.productId]);

	const updateHeader = ProductManagement.actions.useUpdateHeader();

	useEffect(() => {
		updateHeader({
			label: product?.name ?? '',
			showButton: true,
			buttonTitle: 'Create New Product',
			linkTo: '/product-management/new/product'
		});
	}, [product?.name, updateHeader]);

	return (
		<>
			{product && (
				<>
					{/* <ProductVersions
						allProductsData={allProductsData}
						allProductsLoading={allProductsDataLoading}
						productData={productData}
					/> */}

					{/* <ProductImages product={product} /> */}

					<ProductItems product={product} />

					<ProductFieldsForm
						isVisible={product.isVisible}
						itemIds={product.itemIds}
						productGroupIds={product.productGroupIds}
						otherProductIds={product.otherProductIds}
						sizes={product.sizes}
						productVersions={product.productVersions}
						autoModify={product.autoModify}
						id={product.id}
						//image={''} // TODO: add to product data generator
						name={product.name}
						defaultSize={
							product.defaultSize
								? {
										id: product.defaultSize.id,
										name: product.defaultSize.name,
										priority: product.defaultSize.priority
										//itemName: product.defaultSize.name, // TODO: add to serialized namespace
										//itemNamePriority: 0 // TODO: add to serialized namespace
								  }
								: undefined
						}
						// includeInSales={product.includeInSales} // TODO: add to serialized namespace
						printable={product.printable}
						ticketName={product.ticketName}
						tokenProductId={product.tokenProductId}
						productVersion={product.productVersion} // TODO: adjust this type in percomatic-typescript to avoid the assertion
					/>
				</>
			)}
		</>
	);
}

export default function Product(): JSX.Element {
	return (
		<div>
			<PercomaticLoading loaded children={<Body />} />
		</div>
	);
}

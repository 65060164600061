import React from 'react';
import { ModifierGroup } from '..';
import Product from '../../domains/Product';

export interface ModifierGroupsProps {
	scrollRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const ModifierGroups: React.FC<ModifierGroupsProps> = ({
	scrollRef
}) => {
	const modifierGroupModel = Product.selectors.useSelectModifierGroups();

	if (modifierGroupModel) {
		return (
			<div className="modifier-groups" ref={scrollRef}>
				{modifierGroupModel.modifierGroups.map(
					(modifierGroup, index) => (
						<ModifierGroup
							key={index}
							modifierGroupIndex={index}
							isActive={
								index === modifierGroupModel.activeModifierGroup
							}
							modifierGroup={modifierGroup}
							modifierModel={
								modifierGroupModel.modifierModels[index]
							}
						/>
					)
				)}
			</div>
		);
	} else {
		return <></>;
	}
};

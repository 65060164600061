const initialState: NetworkDomain.State = {
	session: {
		loaded: false,
		loading: false
	},
	allProductData: {
		loaded: false,
		loading: false
	},
	settingStoreId: {
		loaded: true,
		loading: false
	}
};

const reducer = (
	state: NetworkDomain.State = initialState,
	action: NetworkDomain.Action
): NetworkDomain.State => {
	switch (action.type) {
		case 'STARTED_LOADING_SESSION':
			return {
				...state,
				session: {
					...state.session,
					loading: true
				}
			};
		case 'SESSION_LOADED':
			return {
				...state,
				session: {
					loaded: true,
					loading: false
				}
			};
		case 'STARTED_LOADING_ALL_PRODUCT_DATA':
			return {
				...state,
				allProductData: {
					...state.allProductData,
					loading: true
				}
			};
		case 'ALL_PRODUCT_DATA_LOADED':
			return {
				...state,
				allProductData: {
					...state.allProductData,
					loaded: true,
					loading: false
				}
			};
		case 'SET_STORE':
			return {
				...state,
				settingStoreId: {
					loading: true,
					loaded: false
				}
			};
		case 'STORE_SET': {
			return {
				...state,
				settingStoreId: {
					loaded: true,
					loading: false
				}
			};
		}
		default:
			return state;
	}
};

export default reducer;

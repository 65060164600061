import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogRocket from 'logrocket';

import { useSessionQuery } from '../../../graphql';

export const useFetchSession = (): void => {
	const dispatch = useDispatch();

	const { loading: loadingState, loaded: loadedState } = useSelector(
		(state: RootState) => state.network.session
	);
	const { data: session, loading: loadingSession } = useSessionQuery();

	useEffect(() => {
		if (loadingSession && !loadingState) {
			const action: SessionDomain.Actions.StartedLoadingSession = {
				type: 'STARTED_LOADING_SESSION'
			};

			dispatch(action);
		} else if (!loadedState && session && session.session) {
			const action: SessionDomain.Actions.SessionLoaded = {
				type: 'SESSION_LOADED',
				payload: session.session
			};

			dispatch(action);

			if (session.session.profile) {
				const traits = {
					name:
						session.session.profile.firstName +
						' ' +
						session.session.profile.lastName,
					type: 'EMPLOYEE',
					selectedStore: session.session.store?.storeId ?? '',
					cart: session.session.cart.id,
					order: session.session.order?.id ?? ''
				};

				LogRocket.identify(String(session.session.profile.id), traits);
			}
		}
	}, [dispatch, loadingSession, session, loadingState, loadedState]);
};

import React, { useState, useEffect } from 'react';
// import JSONPretty from 'react-json-pretty';
// import stripAnsi from 'strip-ansi';

import { useLogsSubscription } from '../../graphql';

import Convert from 'ansi-to-html';
const convert = new Convert();

const Logs: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [logs, setLogs] = useState<any[]>([]);

	const { data, error } = useLogsSubscription();

	if (error) throw error;

	useEffect(() => {
		if (data && data.logs) {
			setLogs(prevState =>
				prevState.length > 200 ? [] : [data.logs, ...prevState]
			);
		}
	}, [data]);

	return (
		<pre
			className="text-white mx-2 overflow-hidden bg-white text-dark p-2 rounded"
			style={{ whiteSpace: 'pre-wrap', filter: 'invert(1)' }}
		>
			{logs
				.flatMap(value => JSON.parse(value))
				.flatMap(value => {
					if (typeof value === 'string') {
						return value.split('\n');
						// return strippedValue;
					}

					if (typeof value === 'object') {
						return JSON.stringify(value, null, ' ').split('\n');
					}

					return String(value);
				})
				.map((value, key) => (
					<div
						dangerouslySetInnerHTML={{
							__html: convert.toHtml(value)
						}}
						key={key}
					/>
				))}
		</pre>
	);
};

export default Logs;

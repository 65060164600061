import React, { useEffect, useState, MouseEvent } from 'react';

import { Error, Loading } from '../..';

import {
	StoreStripeFieldsFragment,
	useStripeQuery,
	useStripeLazyQuery
} from '../../../graphql';

interface ListProps {
	stores: StoreStripeFieldsFragment[];
}

const List: React.FC<ListProps> = ({ stores }) => {
	return (
		<ul className="list-group list-group-flush">
			{stores.map(({ storeId, stripeAccount }, key) => (
				<li
					className="list-group-item d-flex align-items-center"
					key={key}
				>
					<h5 className="font-weight-normal text-secondary mb-0">
						{storeId}
					</h5>

					{stripeAccount?.loginUrl && (
						<a
							href={stripeAccount.loginUrl}
							className="btn btn-primary text-white mx-auto"
						>
							Login
						</a>
					)}

					{stripeAccount?.onboardingUrl && (
						<a
							className="d-block ml-auto"
							href={stripeAccount.onboardingUrl}
						>
							Onboarding URL
						</a>
					)}
				</li>
			))}
		</ul>
	);
};

export const Stores: React.FC = () => {
	const [stores, setStores] = useState<StoreStripeFieldsFragment[]>([]);
	const { data, loading, error } = useStripeQuery({
		errorPolicy: 'all',
		variables: { limit: 5, offset: 0 }
	});

	const [
		stripeQuery,
		{ data: lazyData, loading: lazyLoading, error: lazyError }
	] = useStripeLazyQuery({
		errorPolicy: 'all',
		variables: { limit: 5, offset: stores.length }
	});

	useEffect(() => {
		const stores = data?.session.employee?.stores;

		if (stores) {
			setStores(stores);
		}
	}, [data]);

	useEffect(() => {
		const stores = lazyData?.session.employee?.stores;

		if (stores && stores.length > 0) {
			setStores(state => [...state, ...stores]);
		}
	}, [lazyData]);

	const loadMore = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		stripeQuery({
			variables: { limit: 5, offset: stores.length }
		});
	};

	if (loading) {
		return (
			<div className="pt-3">
				<Loading />
			</div>
		);
	}

	if (error) {
		return <Error error={error.message} />;
	}

	if (lazyError) {
		return <Error error={lazyError.message} />;
	}

	return (
		<div className="owner-operator-stores">
			<h1 className="text-secondary mb-0 px-3">Stores</h1>

			<List stores={stores} />

			<hr className="m-0 p-0" />

			{lazyLoading ? (
				<Loading />
			) : (
				<button
					onClick={loadMore}
					className="btn btn-primary ml-3 mt-3"
				>
					Load More
				</button>
			)}
		</div>
	);
};

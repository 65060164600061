import { useSelector } from 'react-redux';

const selectors: ProductManagementDomain.ProductManagementSelectors = {
	useSelectHeader() {
		const header = useSelector<RootState, ProductManagementDomain.Header>(
			state => state.productManagement.header
		);

		return header;
	}
};

export default selectors;

import { Moment } from 'moment';
import { useMemo } from 'react';

import { PendingOrderQuery } from '../../../graphql';
import { getOrderQuantity, wasOrderSentEarly } from '../../../utils';

export const useWasOrderSentEarly = (
	scheduledTime: Moment,
	items: PendingOrderQuery['pendingOrder']['items'],
	status: PendingOrderQuery['pendingOrder']['status'],
	statuses: PendingOrderQuery['pendingOrder']['statuses']
): boolean =>
	useMemo(() => {
		switch (status.status) {
			case 'ORDER_PROCESSING':
			case 'ORDER_SCHEDULED':
				return true;
		}

		const sentStatus = statuses.find(
			status => status.status === 'ORDER_SENT'
		);

		if (!sentStatus) return false;

		const sentStatusTime = new Date(sentStatus.time).getTime();
		const scheduledTimeInMs = scheduledTime.toDate().getTime();
		const orderQuantity = getOrderQuantity(items);
		const sentEarly = wasOrderSentEarly(
			sentStatusTime,
			scheduledTimeInMs,
			orderQuantity
		);

		return sentEarly;
	}, [items, scheduledTime, status.status, statuses]);

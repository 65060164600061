import actions from './actions';
import reducer from './reducer';
import { selectors } from './selectors';

const Product: ProductDomain.ProductDomain = {
	actions: actions,
	selectors: selectors,
	reducer
};

export default Product;

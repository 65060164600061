import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import Admin from './views/Admin/Admin';
import Checkout from './views/Checkout';
import Employee from './views/Employee';
import HomeOffice from './views/HomeOffice';
import Stores from './views/Stores';
import SignIn from './views/SignIn';
import Menu from './views/Menu';
import Network from './domains/Network';
import PendingOrders from './views/PendingOrders';
import Product from './views/Product';
import Stripe from './views/Stripe';
import Success from './views/Success';

import Session from './domains/Session';
import { RecipeBook } from './views/RecipeBook';
import StoreSettings from './views/StoreSettings';
import DefaultNotices from './views/DefaultNotices';
import { LylaQuestions } from './views/LylaQuestions';
import { BrandStandards } from './views/BrandStandards';
import { CreateCards } from './views/CreateCards';
import { ProductManagement } from './views/ProductManagement/ProductManagement';

import { EwardEntryForm } from './views/EwardEntryForm';

const Router: React.FC = () => {
	Network.actions.usePageLoaded();

	const sessionLoaded = Network.selectors.useSelectSessionLoaded();
	const authenticated = Session.selectors.useSelectAuthenticated();

	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (sessionLoaded && !authenticated && location.pathname !== '/') {
			history.push('/');
		}
	}, [sessionLoaded, location, authenticated, history]);

	return (
		<Switch>
			<Route path="/success" children={<Success />} />

			<Route path="/checkout" children={<Checkout />} />

			<Route path="/menu/:groupId/:productId" children={<Product />} />

			<Route path="/menu/:groupId" children={<Menu />} />

			<Route path="/menu" children={<Menu />} />

			<Route path="/employee" children={<Employee />} />

			<Route path="/home-office" children={<HomeOffice />} />

			<Route path="/brand-standards" children={<BrandStandards />} />

			<Route path="/create-cards" children={<CreateCards />} />

			<Route
				path="/store-settings/:storeId/default-notices"
				children={<DefaultNotices />}
			/>

			<Route path="/lyla-questions" children={<LylaQuestions />} />

			<Route
				path="/store-settings/:storeId"
				children={<StoreSettings />}
			/>
			<Route path="/store-settings" children={<StoreSettings />} />

			<Route path="/stores" children={<Stores />} />

			<Route path="/e-ward" children={<EwardEntryForm />} />

			<Route path="/admin" children={<Admin />} />

			<Route path="/orders" children={<PendingOrders />} />

			<Route path="/stripe" children={<Stripe />} />

			<Route path="/recipe-book" children={<RecipeBook />} />

			<Route
				path="/product-management"
				children={<ProductManagement />}
			/>

			<Route path="/" children={<SignIn />} />
		</Switch>
	);
};

export default Router;

import React from 'react';

import { Loader, TerminalStore } from '../../components';

import { useAllStoresQuery } from '../../graphql';

import Admin from '../../domains/Admin';

import '../../sass/StoresContainer.scss';

export default function Stores(): JSX.Element {
	const { data } = useAllStoresQuery();
	const termIds = Admin.selectors.useSelectTerminals();

	return (
		<Loader loaded={data !== undefined}>
			{data && (
				<div className="mt-1 grid-175">
					{termIds
						.filter(
							(termId, index, self) =>
								index ===
									self.findIndex(
										id => termId.storeId === id.storeId
									) &&
								data.stores.some(
									store => store.storeId === termId.storeId
								)
						)
						.sort((a, b) => a.storeId - b.storeId)
						.map((termId, key) => (
							<TerminalStore
								key={key}
								store={
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									data.stores.find(
										store =>
											store.storeId === termId.storeId
									)!
								}
								storeId={termId.storeId}
								termIds={termIds.filter(
									id => id.storeId === termId.storeId
								)}
							/>
						))}
				</div>
			)}
		</Loader>
	);
}

import { CreditCardPaymentsFieldFragment } from '../graphql';

export const refundStatus = (
	creditCardPayments: CreditCardPaymentsFieldFragment[] | undefined
): boolean => {
	let disableRefund = true;
	creditCardPayments?.forEach(ccp => {
		if (!ccp.charge.refunded) {
			// if available for refund return false to NOT disable the button
			disableRefund = false;
		} else if (ccp.charge.refunded && disableRefund !== false) {
			disableRefund = true;
		}
	});
	return disableRefund;
};

import { Dispatch, SetStateAction } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

export type LylaQuestionInputProps = React.HTMLAttributes<HTMLDivElement> & {
	question: string;
	setQuestion: Dispatch<SetStateAction<string>>;
	isActive: boolean;
	setIsActive: Dispatch<SetStateAction<boolean>>;
	askAgainIfSkipped: boolean;
	setAskAgainIfSkipped: Dispatch<SetStateAction<boolean>>;
	askFrequency: (number | undefined) | number;
	setAskFrequency:
		| Dispatch<SetStateAction<number | undefined>>
		| Dispatch<SetStateAction<number>>;
	idleTime: (number | undefined) | number;
	setIdleTime:
		| Dispatch<SetStateAction<number | undefined>>
		| Dispatch<SetStateAction<number>>;
	startDate: (Date | undefined) | Date;
	setStartDate: Dispatch<SetStateAction<Date | undefined>>;
	endDate: (Date | undefined) | Date;
	setEndDate: Dispatch<SetStateAction<Date | undefined>>;
	whereToShow: (number | undefined) | number;
	setWhereToShow:
		| Dispatch<SetStateAction<number | undefined>>
		| Dispatch<SetStateAction<number>>;
	whereToShowOptions: { value: string; label: string }[];
};

export const LylaQuestionInput: React.FC<LylaQuestionInputProps> = ({
	question,
	setQuestion,
	isActive,
	setIsActive,
	askAgainIfSkipped,
	setAskAgainIfSkipped,
	askFrequency,
	setAskFrequency,
	idleTime,
	setIdleTime,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	whereToShow,
	setWhereToShow,
	whereToShowOptions
}) => {
	return (
		<div className="lyla-question-input">
			<label>
				<strong>Question: </strong>
				<input
					type="text"
					name="question"
					className="input-text"
					placeholder="Is BIGGBY supporting a life you love?"
					value={question}
					onChange={e => {
						setQuestion(e.target.value);
					}}
				/>
			</label>
			<label>
				<strong>Is this an active question? </strong>
				<span className="input">
					<input
						type="radio"
						name="isActive"
						value="Yes"
						checked={isActive}
						onChange={() => {
							setIsActive(true);
						}}
					/>{' '}
					Yes
					<input
						type="radio"
						name="isActive"
						value="No"
						checked={!isActive}
						onChange={() => {
							setIsActive(false);
						}}
					/>{' '}
					No
				</span>
			</label>
			<label>
				<strong>Ask question again if skipped? </strong>
				<input
					type="radio"
					name="askAgainIfSkipped"
					value="Yes"
					checked={askAgainIfSkipped}
					onChange={() => {
						setAskAgainIfSkipped(true);
					}}
				/>{' '}
				Yes
				<input
					type="radio"
					name="askAgainIfSkipped"
					value="No"
					checked={!askAgainIfSkipped}
					onChange={() => {
						setAskAgainIfSkipped(false);
					}}
				/>{' '}
				No
			</label>
			<label>
				<strong>Where to Show: </strong>
				<Select
					options={whereToShowOptions}
					defaultValue={
						whereToShow ||
						whereToShowOptions.length + 1 <=
							whereToShowOptions.length
							? whereToShowOptions[whereToShow || 0]
							: whereToShowOptions[0]
					}
					onChange={value => {
						if (value?.value) {
							setWhereToShow(Number(value.value));
						}
					}}
				/>
			</label>
			<label>
				<strong>Ask Frequency (minutes): </strong>
				<p>
					How often the question will be asked again after the user
					has answered it.
				</p>
				<input
					type="number"
					value={
						askFrequency?.toString() !== '0'
							? askFrequency?.toString()
							: '' || ''
					}
					onChange={e => setAskFrequency(Number(e.target.value))}
				/>
			</label>
			<label>
				<strong>Idle Time (minutes): </strong>
				<p>
					How often the question will be asked again after it has been
					skipped.
				</p>
				<input
					type="number"
					value={
						idleTime?.toString() !== '0'
							? idleTime?.toString()
							: '' || ''
					}
					onChange={e => setIdleTime(Number(e.target.value))}
				/>
			</label>
			<label>
				<strong>Start Date: </strong>
				<div className="datepicker-label">
					<DatePicker
						selected={startDate}
						onChange={date => {
							setStartDate(date || undefined);
						}}
					/>
				</div>
			</label>
			<label>
				<strong>End Date: </strong>
				<div className="datepicker-label">
					<DatePicker
						selected={endDate}
						onChange={date => {
							setEndDate(date || undefined);
						}}
					/>
				</div>
			</label>
		</div>
	);
};

import { startCase, isFunction } from 'lodash';

import { defined } from '../utils';

type ColumnNamesMap<TRow> = {
	[K in keyof TRow]?:
		| string
		| ((columnName: K, columnIndex: number) => string);
};

type FormatColumnFunctions<TRow> = {
	[K in keyof TRow]?: (
		column: TRow[K],
		row: TRow,
		data: TRow[],
		columnIndex: number,
		rowIndex: number
	) => JSX.Element | string | TRow[K] | null | undefined;
};

export type TableProps<TRow> = {
	data: TRow[];
	className?: string;
	root?: Omit<React.HTMLAttributes<HTMLTableElement>, 'className'>;
	thead?: React.HTMLAttributes<HTMLTableSectionElement>;
	tbody?: React.HTMLAttributes<HTMLTableSectionElement>;
	tr?: React.HTMLAttributes<HTMLTableRowElement>;
	th?: React.HTMLAttributes<HTMLTableHeaderCellElement>;
	td?: React.HTMLAttributes<HTMLTableDataCellElement>;
};

export type MakeTableComponentOptions<TRow> = {
	columnNamesMap?: ColumnNamesMap<TRow>;
	formatColumnFunctions?: FormatColumnFunctions<TRow>;
};

type TableComponent<TRow> = React.FC<TableProps<TRow>>;

export function makeTableComponent<TRow>(
	columnNames: (keyof TRow)[],
	{
		columnNamesMap = {},
		formatColumnFunctions = {}
	}: MakeTableComponentOptions<TRow> = {}
): TableComponent<TRow> {
	const headers = columnNames.map<string>((column, columnIndex) => {
		const columnMapEntry = columnNamesMap[column];

		return defined(columnMapEntry)
			? isFunction(columnMapEntry)
				? columnMapEntry(column, columnIndex)
				: columnMapEntry
			: startCase(String(column));
	});

	return ({ data, className, root, thead, tbody, tr, th, td }) => (
		<table
			{...root}
			className={`table table-dark${className ? ` ${className}` : ''}`}
		>
			<thead {...thead}>
				<tr {...tr}>
					{headers.map((header, key) => (
						<th {...th} key={key}>
							{header}
						</th>
					))}
				</tr>
			</thead>

			<tbody {...tbody}>
				{data.map((row, rowIndex) => (
					<tr {...tr} key={rowIndex}>
						{columnNames.map((column, columnIndex) => {
							const formatFunction =
								formatColumnFunctions[column];

							return (
								<td {...td} key={columnIndex}>
									{formatFunction
										? formatFunction(
												row[column],
												row,
												data,
												columnIndex,
												rowIndex
										  )
										: row[column]}
								</td>
							);
						})}
					</tr>
				))}
			</tbody>
		</table>
	);
}

import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { useStripeOrderQuery, useOrderQuery } from '../../graphql';
import { gcRefundStatus, refundStatus } from '../../utils';

import {
	CreditCardInfo,
	DisputeButtons,
	DisputeInfo,
	GiftCardInfo,
	Loading,
	OrderInfo,
	OrderSummary
} from '../../components';
import Refund from '../../domains/Refund';

export const OrderInformation = (): JSX.Element => {
	const { orderId, storeId } = useParams<{
		orderId: string;
		storeId: string;
	}>();

	const { data, loading, error } = useStripeOrderQuery({
		variables: {
			id: orderId
		}
	});

	const { data: orderData } = useOrderQuery({ variables: { id: orderId } });

	const creditCardPayments = data?.order?.creditCardPayments;
	const giftCardPayments = data?.order?.giftCardPayments;

	const order = data?.order;
	const orderError = orderData?.order.status.status;
	const orderErrorMessage = orderData?.order.status.details;

	const dispute = order?.creditCardPayments[0]?.charge.dispute;

	const setRefundDestinations = Refund.actions.useSetRefundDestinations();
	const clearRefundItems = Refund.actions.useClearRefundItems();

	const isRefunded =
		refundStatus(creditCardPayments) &&
		giftCardPayments &&
		gcRefundStatus(giftCardPayments);

	const refundMessage: string =
		orderError === 'ORDER_ERROR'
			? 'No Refund Applicable'
			: isRefunded === true
			? 'Order Already Refunded'
			: 'Refund';

	if (loading) {
		return <Loading />;
	}

	if (!data || error) {
		return (
			<div className="p-3">
				<div className="alert alert-danger">
					Please select your store to see order information.
					<Link to="/" className="text-danger">
						{' '}
						Go back.
					</Link>
				</div>
			</div>
		);
	}

	const creditCardInfo = creditCardPayments &&
		creditCardPayments[0] && { ...creditCardPayments[0] };

	const giftCardInfo = order?.giftCardPayments &&
		order?.giftCardPayments[0] && { ...order?.giftCardPayments[0] };

	return (
		<div className="or-info-root text-light py-3">
			<div className="order-info-header">
				<h1 className="px-3">ORDER INFORMATION</h1>
				{creditCardPayments && !dispute && (
					<Link
						to={{
							pathname: `/stripe/store/${storeId}/${orderId}/refund`,
							state: { data, loading, error }
						}}
					>
						<button
							className="btn btn-secondary mr-3"
							disabled={isRefunded}
							onClick={() => {
								setRefundDestinations([]);
								clearRefundItems();
							}}
						>
							{refundMessage}
						</button>
					</Link>
				)}
				{creditCardPayments && dispute && order && (
					<DisputeButtons
						dispute={dispute}
						orderId={orderId}
						storeId={storeId}
						creditCardPayments={creditCardPayments}
					/>
				)}
			</div>
			<p className="px-3 order-number">Store {storeId}</p>
			<p className="px-3 order-number">{orderId}</p>

			<hr />

			{data && (
				<OrderSummary {...data} errorMessage={orderErrorMessage} />
			)}

			{dispute && <DisputeInfo {...dispute} />}

			<h3 className="px-3">PAYMENT INFORMATION</h3>
			<hr />

			{creditCardInfo && <CreditCardInfo {...creditCardInfo} />}

			{giftCardInfo && <hr />}

			{giftCardInfo && <GiftCardInfo {...giftCardInfo} />}

			{data && <OrderInfo {...data} />}
		</div>
	);
};

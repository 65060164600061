import React from 'react';

import { QuantityControl } from '../QuantityControl/QuantityControl';
import Order from '../../domains/Order';

export interface CartItemProps {
	item: OrderDomain.State['cart']['items'][number];
}

export const CartItem: React.FC<CartItemProps> = ({ item }: CartItemProps) => {
	const {
		id,
		quantity,
		price,
		item: { product }
	} = item;

	const incrementQuantity = Order.actions.useIncrementItemQuantity(id);
	const decrementQuantity = Order.actions.useDecrementItemQuantity(id);

	return (
		<div className="item list-group-item py-0 px-1">
			<div className="d-flex align-items-center flex-wrap">
				<p className="text-primary mb-0 font-weight-bold">
					{product?.name}
				</p>

				<div className="mx-auto">
					<QuantityControl
						color={'light'}
						fontColor={'primary'}
						quantity={quantity}
						increment={incrementQuantity}
						decrement={decrementQuantity}
						margins={2}
					/>
				</div>

				<div className="my-auto text-right text-light">
					${((price / 100) * quantity).toFixed(2)}
				</div>
			</div>
		</div>
	);
};

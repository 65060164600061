import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  Decim: any;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A string that cannot be passed as an empty value */
  NonEmptyString: any;
  /** Integers that will have a value greater than 0. */
  PositiveInt: any;
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: any;
};



export type AuthenticateCustomerInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type AuthenticateEmployeeInput = {
  employeeId: Scalars['ID'];
  password: Scalars['String'];
};


export type BrandStandard = {
  __typename?: 'BrandStandard';
  id: Scalars['ID'];
  brandStandardId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  resolution?: Maybe<Scalars['String']>;
};

export type BrandStandardEdge = {
  __typename?: 'BrandStandardEdge';
  cursor: Scalars['String'];
  node: BrandStandard;
};

export type BrandStandardInput = {
  description?: Maybe<Scalars['String']>;
  resolution?: Maybe<Scalars['String']>;
};

export type BrandStandardsConnection = {
  __typename?: 'BrandStandardsConnection';
  pageInfo: PageInfo;
  edges: Array<BrandStandardEdge>;
  totalCount: Scalars['Int'];
  nodes: Array<BrandStandard>;
};

export type CacheControlScope =
  | 'PUBLIC'
  | 'PRIVATE';

export type Car = {
  __typename?: 'Car';
  make: Scalars['String'];
  model: Scalars['String'];
  color: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
};

export type CarInput = {
  year?: Maybe<Scalars['Int']>;
  make: Scalars['String'];
  model: Scalars['String'];
  color: Scalars['String'];
};

export type CardAddBalance = {
  __typename?: 'CardAddBalance';
  transactionId?: Maybe<Scalars['String']>;
  giftCardNumber?: Maybe<Scalars['String']>;
  currentBalance?: Maybe<Scalars['Int']>;
  balanceToAdd?: Maybe<Scalars['Int']>;
};

export type CardAddBalanceInput = {
  giftCardNumber: Scalars['BigInt'];
  currentBalance: Scalars['Float'];
  balanceToAdd: Scalars['Int'];
  token: Scalars['String'];
  app_user_id: Scalars['Int'];
  profile_id: Scalars['Int'];
  session_id: Scalars['String'];
  stripe_trans_id: Scalars['String'];
};

export type CardAddBalanceResult = {
  __typename?: 'CardAddBalanceResult';
  id?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  card?: Maybe<GiftCard>;
  error?: Maybe<Scalars['String']>;
};

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['ID'];
  items: Array<OrderItem>;
};

export type ClaimedReward = {
  __typename?: 'ClaimedReward';
  id: Scalars['Int'];
  profileId: Scalars['Int'];
  rewardId: Scalars['Int'];
  creationDate: Scalars['DateTime'];
  profile: Profile;
  reward: Reward;
};

export type Config = {
  __typename?: 'Config';
  storeId: Scalars['Int'];
  termId: Scalars['Int'];
  setting: Scalars['String'];
  value: Scalars['String'];
  type: ConfigType;
};

export type ConfigInput = {
  storeId: Scalars['Int'];
  termId: Scalars['Int'];
  setting: Scalars['String'];
  value: Scalars['String'];
};

export type ConfigTable = {
  __typename?: 'ConfigTable';
  storeId: Scalars['Int'];
  termId: Scalars['Int'];
  setting: Scalars['String'];
  value: Scalars['String'];
};

export type ConfigType = {
  __typename?: 'ConfigType';
  id: Scalars['Int'];
  type: ConfigTypeEnum;
  setting: Scalars['String'];
  metadata: Array<ConfigTypeMetadata>;
};

export type ConfigTypeEnum =
  | 'STRING'
  | 'DROPDOWN'
  | 'RADIO'
  | 'INTEGER'
  | 'FLOAT'
  | 'BOOLEAN';

export type ConfigTypeMetadata = {
  __typename?: 'ConfigTypeMetadata';
  id: Scalars['Int'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Connection = {
  __typename?: 'Connection';
  key: Scalars['ID'];
  storeId: Scalars['Int'];
  termId: Scalars['Int'];
  connectionId: Scalars['String'];
  disconnected: Scalars['Boolean'];
  value?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
};

export type CoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CreateCardsInSeriesInput = {
  cardSeries: Scalars['Int'];
  numberOfCards?: Maybe<Scalars['Int']>;
  sequential?: Maybe<Scalars['Boolean']>;
  initialCash?: Maybe<Scalars['Float']>;
  production?: Maybe<Scalars['Boolean']>;
};

export type CreateCardsInSeriesUpdate = {
  __typename?: 'CreateCardsInSeriesUpdate';
  executionArn: Scalars['ID'];
  batchIndex: Scalars['Int'];
  totalBatches: Scalars['Int'];
  cardNumbers: Array<Scalars['String']>;
  ok: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['Int']>;
};

export type CreatePdvChangeSetInput = {
  parentId: Scalars['ID'];
  ownerEmployeeId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreditCardPaymentInput = {
  token: Scalars['String'];
  amount: Scalars['Int'];
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['Int'];
  profile: Profile;
};

export type CustomerOrderInput = {
  pickupName?: Maybe<Scalars['String']>;
  pickupLocation: PercPickupLocation;
  car?: Maybe<CarInput>;
  guestEmail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  subtotal: Scalars['Int'];
  taxAmount: Scalars['Int'];
  total: Scalars['Int'];
  payment: PaymentInput;
  items: Array<OrderItemInput>;
  profileId?: Maybe<Scalars['Int']>;
  appUserId?: Maybe<Scalars['Int']>;
};



export type DefaultNotice = {
  __typename?: 'DefaultNotice';
  id: Scalars['ID'];
  description: Scalars['String'];
  defaultDate: Scalars['DateTime'];
  notificationMethod: Scalars['String'];
  brandStandardViolated: Scalars['Int'];
  brandStandard: BrandStandard;
  reportedBy: Scalars['String'];
  resolved: Scalars['Boolean'];
  resolutionDate?: Maybe<Scalars['DateTime']>;
  storeId: Scalars['Int'];
  store: Store;
};

export type DefaultNoticeEdge = {
  __typename?: 'DefaultNoticeEdge';
  cursor: Scalars['String'];
  node: DefaultNotice;
};

export type DefaultNoticeInput = {
  description: Scalars['String'];
  defaultDate: Scalars['DateTime'];
  notificationMethod: Scalars['String'];
  brandStandardViolated: Scalars['Int'];
  reportedBy: Scalars['String'];
  resolved: Scalars['Boolean'];
  resolutionDate?: Maybe<Scalars['DateTime']>;
  storeId: Scalars['Int'];
};

export type DefaultNoticesConnection = {
  __typename?: 'DefaultNoticesConnection';
  pageInfo: PageInfo;
  edges: Array<DefaultNoticeEdge>;
  totalCount: Scalars['Int'];
  nodes: Array<DefaultNotice>;
};

export type EditBrandStandardInput = {
  brandStandardId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  resolution?: Maybe<Scalars['String']>;
};

export type EditDefaultNoticeInput = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  defaultDate?: Maybe<Scalars['DateTime']>;
  notificationMethod?: Maybe<Scalars['String']>;
  brandStandardViolated?: Maybe<Scalars['Int']>;
  reportedBy?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  resolutionDate?: Maybe<Scalars['DateTime']>;
  storeId?: Maybe<Scalars['Int']>;
};

export type EditPdvChangeSetInput = {
  parentId?: Maybe<Scalars['ID']>;
  ownerEmployeeId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  actions?: Maybe<Array<PdvActionInput>>;
};


export type EmailReceipt = {
  destination: Scalars['String'];
  baristaFirstName: Scalars['String'];
  order: EmailReceiptOrder;
  lineItems: Array<EmailReceiptLine>;
  payment: Array<EmailReceiptLine>;
  loyalty: Array<EmailReceiptLine>;
};

export type EmailReceiptLine = {
  index: Scalars['Int'];
  left: Scalars['String'];
  right: Scalars['String'];
};

export type EmailReceiptOrder = {
  number: Scalars['String'];
  closeTime: Scalars['String'];
  subtotal: Scalars['String'];
  tax: Scalars['String'];
  total: Scalars['String'];
  tip: Scalars['String'];
  change: Scalars['String'];
};

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  person?: Maybe<Person>;
  stores: Array<Store>;
  roles: Array<EmployeeRole>;
  permissions: Array<EmployeePermission>;
};


export type EmployeeStoresArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type EmployeeOrderInput = {
  termId: Scalars['Int'];
  items: Array<OrderItemInput>;
};

export type EmployeePermission = {
  __typename?: 'EmployeePermission';
  id: Scalars['Int'];
  djangopk: Scalars['Int'];
  employeeId: Scalars['ID'];
  permissionId: Scalars['Int'];
  hasPermission?: Maybe<Scalars['Boolean']>;
  lastModified: Scalars['DateTime'];
  lastModifiedStore?: Maybe<Scalars['Int']>;
  lastModifiedTerm?: Maybe<Scalars['Int']>;
  serverLastModified?: Maybe<Scalars['DateTime']>;
  permission: Permission;
};

export type EmployeeRole = {
  __typename?: 'EmployeeRole';
  id: Scalars['Int'];
  djangopk: Scalars['Int'];
  employeeId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  roleId: Scalars['Int'];
  wage?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateTime']>;
  isSalaray?: Maybe<Scalars['Boolean']>;
  lastModified: Scalars['DateTime'];
  lastModifiedStore?: Maybe<Scalars['Int']>;
  lastModifiedTerm?: Maybe<Scalars['Int']>;
  serverLastModified?: Maybe<Scalars['DateTime']>;
  role: Role;
};

export type Error = {
  type: Scalars['String'];
  message: Scalars['String'];
  asString: Scalars['String'];
  componentStack: Array<Scalars['String']>;
};

export type EwardInput = {
  storeId: Scalars['Int'];
  email: Scalars['EmailAddress'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  birthYear?: Maybe<Scalars['PositiveInt']>;
  birthMonth?: Maybe<Scalars['PositiveInt']>;
  birthDay?: Maybe<Scalars['PositiveInt']>;
};

export type FavoriteDrink = {
  __typename?: 'FavoriteDrink';
  id: Scalars['ID'];
  orderitem: OrderItem;
  priority: Scalars['Int'];
};

export type FavoriteDrinkInput = {
  id?: Maybe<Scalars['ID']>;
  orderitemId?: Maybe<Scalars['ID']>;
  priority?: Maybe<Scalars['Int']>;
};

export type FavoriteStore = {
  __typename?: 'FavoriteStore';
  id: Scalars['ID'];
  store: Store;
  priority: Scalars['Int'];
};

export type FavoriteStoreInput = {
  id?: Maybe<Scalars['ID']>;
  storeId?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  feedbackText: Scalars['String'];
  success: Scalars['Boolean'];
  category: Scalars['String'];
};

export type FeedbackInput = {
  feedbackText: Scalars['String'];
  category: Scalars['String'];
  sentryEvent?: Maybe<SentryEvent>;
};

export type FilterDefaultNoticeInput = {
  createdBefore?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  notificationMethod?: Maybe<Scalars['String']>;
  brandStandardViolated?: Maybe<Scalars['Int']>;
  reportedBy?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  storeId?: Maybe<Scalars['Int']>;
};

export type FilterOrderInput = {
  type?: Maybe<SessionType>;
  /**
   * beforeTime: String
   * afterTime: String
   * scheduled: Boolean
   * guest: Boolean
   * status: OrderStatusEnum
   */
  store_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
  employee_id?: Maybe<Scalars['ID']>;
  pickup_name?: Maybe<Scalars['String']>;
  pickup_location?: Maybe<Scalars['String']>;
  hasDiscrepancy?: Maybe<Scalars['Boolean']>;
};

/**
 * authorization is tricky here, must be done based on the specific combination of
 * ids included in this input
 *
 * a rough draft of what this might look like (based on scoping for refund
 * reporting):
 *
 * there is a limited set of valid combinations
 *
 * combo 1:
 * 	desc:
 * 		this combination can be used by terminals to listen for refund requests
 * 		and in-progress order state changes (i.e. ready, complete)
 * 	checks:
 * 		authenticated: true
 * 		authorization:
 * 			terminal session
 * 			terminal session has specified store and term id
 * 	ids:
 * 		storeId
 * 		termId
 *
 * combo 2:
 * 	desc:
 * 		this combination is for the app/perc-orders frontends to live update the
 * 		statuses of orders. this removes some of the race condition issues
 * 		on the success view
 * 	checks:
 * 		authorization:
 * 			session has specified id
 * 	ids:
 * 		sessionId
 *
 * fields not included in the above combos won't be used for now but will be good
 * to have for the future
 */
export type FilterOrderStatuses = {
  storeId?: Maybe<Scalars['Int']>;
  termId?: Maybe<Scalars['Int']>;
  sessionId?: Maybe<Scalars['ID']>;
  orderId?: Maybe<Scalars['ID']>;
  profileId?: Maybe<Scalars['Int']>;
  employeeId?: Maybe<Scalars['ID']>;
};

export type FilterPdvChangeSetInput = {
  createdBefore?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  owner_employee_id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type GiftCard = {
  __typename?: 'GiftCard';
  id: Scalars['Int'];
  cardNumber: Scalars['ID'];
  balance: Scalars['Int'];
  profileId?: Maybe<Scalars['Int']>;
  tempProfileId?: Maybe<Scalars['Int']>;
  lastSaleDate?: Maybe<Scalars['DateTime']>;
  lastSaleAmount?: Maybe<Scalars['Float']>;
  lastRedeemDate?: Maybe<Scalars['DateTime']>;
  lastRedeemMmount?: Maybe<Scalars['Float']>;
  intitialSaleDate?: Maybe<Scalars['DateTime']>;
  intitialSaleAmount?: Maybe<Scalars['Float']>;
  LastModified?: Maybe<Scalars['DateTime']>;
};

export type GiftCardPaymentInput = {
  cardNumber: Scalars['ID'];
  amount: Scalars['Int'];
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['Int'];
  imageType?: Maybe<ImageType>;
  isDefault?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
  altText?: Maybe<Scalars['String']>;
};

export type ImageType =
  | 'BANNER'
  | 'ICON';

export type Item = {
  __typename?: 'Item';
  id: Scalars['ID'];
  itemId: Scalars['Int'];
  productId?: Maybe<Scalars['Int']>;
  sizeId?: Maybe<Scalars['Int']>;
  lastModified: Scalars['DateTime'];
  isVariablePrice?: Maybe<Scalars['Boolean']>;
  containerId?: Maybe<Scalars['Int']>;
  capacity?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  size?: Maybe<Size>;
  prices: Array<ItemPrice>;
};

export type ItemPrice = {
  __typename?: 'ItemPrice';
  id: Scalars['Int'];
  djangopk: Scalars['Int'];
  itemId: Scalars['Int'];
  tierId: Scalars['Int'];
  storeId: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
  lastModified: Scalars['DateTime'];
  lastModifiedStore?: Maybe<Scalars['Int']>;
  lastModifiedTerm?: Maybe<Scalars['Int']>;
  serverLastModified?: Maybe<Scalars['DateTime']>;
};


export type LylaQuestion = {
  __typename?: 'LylaQuestion';
  questionId: Scalars['ID'];
  question: Scalars['String'];
  isActive: Scalars['Boolean'];
  askAgainIfSkipped: Scalars['Boolean'];
  askFrequency?: Maybe<Scalars['Int']>;
  idleTime?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  whereToShow?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['DateTime']>;
};

export type LylaQuestionInput = {
  questionId: Scalars['ID'];
  question: Scalars['String'];
  isActive: Scalars['Boolean'];
  askAgainIfSkipped: Scalars['Boolean'];
  askFrequency?: Maybe<Scalars['Int']>;
  idleTime?: Maybe<Scalars['Int']>;
  whereToShow?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type Menu = {
  __typename?: 'Menu';
  productGroups: Array<ProductGroup>;
  products: Array<Product>;
};

export type Modifier = {
  __typename?: 'Modifier';
  id: Scalars['ID'];
  modifierId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  ticketName?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  itemNamePriority?: Maybe<Scalars['Int']>;
  isVariableQuantity?: Maybe<Scalars['Boolean']>;
  lastModified: Scalars['DateTime'];
  inverseId: Scalars['Int'];
  freeModId: Scalars['Int'];
  isPrintable?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  prices: Array<ModifierPrice>;
};

export type ModifierPrice = {
  __typename?: 'ModifierPrice';
  id: Scalars['Int'];
  djangopk: Scalars['Int'];
  modifierId: Scalars['Int'];
  tierId: Scalars['Int'];
  storeId: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
  lastModified: Scalars['DateTime'];
  lastModifiedStore?: Maybe<Scalars['Int']>;
  lastModifiedTerm?: Maybe<Scalars['Int']>;
  serverLastModified?: Maybe<Scalars['DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  upsertAddBalance?: Maybe<CardAddBalanceResult>;
  createCardsInSeries: Scalars['ID'];
  authenticateCustomer: Customer;
  authenticateLounge: Profile;
  updateProfile: Profile;
  sendFeedback: Feedback;
  sendEmailReceipt: Scalars['Boolean'];
  editFavoriteStores: Array<FavoriteStore>;
  editFavoriteDrinks: Array<FavoriteDrink>;
  createLoungeAccount: Profile;
  changeLoungePassword: Scalars['Boolean'];
  closeLoungeAccount: Scalars['Boolean'];
  convertLoungeAccountToDigitalOnly: Profile;
  addCard: Profile;
  authenticateEmployee: Employee;
  authenticateQRCode: Session;
  createEwardUser: Scalars['Boolean'];
  addLylaQuestion: LylaQuestion;
  deleteLylaQuestion: Scalars['Boolean'];
  editLylaQuestion: LylaQuestion;
  editStoreSettings: Array<Maybe<Config>>;
  createDefaultNotice: DefaultNotice;
  editDefaultNotice: DefaultNotice;
  deleteDefaultNotice: Scalars['Boolean'];
  createBrandStandard: BrandStandard;
  editBrandStandard: BrandStandard;
  deleteBrandStandard: Scalars['Boolean'];
  addCartItem: Cart;
  updateCartItem: Cart;
  deleteCartItem: Cart;
  sendCustomerOrder: Order;
  sendEmployeeOrder: Order;
  orderReady: PercOrderResponse;
  orderComplete: PercOrderResponse;
  sendOrder: PercOrderResponse;
  refundProcessed: PercOrderResponse;
  refund: Refund;
  percAuthenticate: PercAuthReponse;
  orderReceived: PercOrderResponse;
  orderError: PercOrderResponse;
  createPdvChangeSet: PdvChangeSet;
  editPdvChangeSet: PdvChangeSet;
  deletePdvChangeSet: Scalars['Boolean'];
  acceptDispute: StripeDispute;
  submitDispute: StripeDispute;
  logout: Scalars['Boolean'];
  setSessionStoreId: Session;
};


export type MutationUpsertAddBalanceArgs = {
  cardInfo?: Maybe<CardAddBalanceInput>;
};


export type MutationCreateCardsInSeriesArgs = {
  data: CreateCardsInSeriesInput;
};


export type MutationAuthenticateCustomerArgs = {
  credentials: AuthenticateCustomerInput;
};


export type MutationAuthenticateLoungeArgs = {
  credentials: AuthenticateCustomerInput;
};


export type MutationUpdateProfileArgs = {
  profile: ProfileInput;
};


export type MutationSendFeedbackArgs = {
  feedback: FeedbackInput;
};


export type MutationSendEmailReceiptArgs = {
  emailReceipt: EmailReceipt;
  storeId?: Maybe<Scalars['Int']>;
};


export type MutationEditFavoriteStoresArgs = {
  favoriteStores: Array<FavoriteStoreInput>;
};


export type MutationEditFavoriteDrinksArgs = {
  favoriteDrinks: Array<FavoriteDrinkInput>;
};


export type MutationCreateLoungeAccountArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  birth_date?: Maybe<Scalars['DateTime']>;
  eward_subscription?: Maybe<Scalars['Boolean']>;
  birthday_promotion_medium?: Maybe<Scalars['String']>;
};


export type MutationChangeLoungePasswordArgs = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationAddCardArgs = {
  cardNumber: Scalars['String'];
};


export type MutationAuthenticateEmployeeArgs = {
  credentials: AuthenticateEmployeeInput;
};


export type MutationAuthenticateQrCodeArgs = {
  qrcode: Scalars['String'];
};


export type MutationCreateEwardUserArgs = {
  ewardInput: EwardInput;
};


export type MutationAddLylaQuestionArgs = {
  question: LylaQuestionInput;
};


export type MutationDeleteLylaQuestionArgs = {
  id: Scalars['ID'];
};


export type MutationEditLylaQuestionArgs = {
  question: LylaQuestionInput;
};


export type MutationEditStoreSettingsArgs = {
  settings: Array<ConfigInput>;
};


export type MutationCreateDefaultNoticeArgs = {
  defaultNotice: DefaultNoticeInput;
};


export type MutationEditDefaultNoticeArgs = {
  defaultNotice: EditDefaultNoticeInput;
};


export type MutationDeleteDefaultNoticeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBrandStandardArgs = {
  brandStandard: BrandStandardInput;
};


export type MutationEditBrandStandardArgs = {
  brandStandard: EditBrandStandardInput;
};


export type MutationDeleteBrandStandardArgs = {
  id: Scalars['ID'];
};


export type MutationAddCartItemArgs = {
  item: OrderItemInput;
};


export type MutationUpdateCartItemArgs = {
  item: OrderItemInput;
};


export type MutationDeleteCartItemArgs = {
  id: Scalars['ID'];
};


export type MutationSendCustomerOrderArgs = {
  order: CustomerOrderInput;
  storeId?: Maybe<Scalars['Int']>;
};


export type MutationSendEmployeeOrderArgs = {
  order: EmployeeOrderInput;
};


export type MutationOrderReadyArgs = {
  id: Scalars['ID'];
};


export type MutationOrderCompleteArgs = {
  id: Scalars['ID'];
  storeId?: Maybe<Scalars['Int']>;
  forced?: Maybe<Scalars['Boolean']>;
};


export type MutationSendOrderArgs = {
  id: Scalars['ID'];
};


export type MutationRefundProcessedArgs = {
  orderId: Scalars['ID'];
  refundId: Scalars['Int'];
};


export type MutationRefundArgs = {
  orderId: Scalars['ID'];
  returnReasonId: Scalars['Int'];
  orderItemIds: Array<Scalars['ID']>;
  refundDestinations: Array<RefundDestinationInput>;
};


export type MutationPercAuthenticateArgs = {
  oauthToken: Scalars['String'];
};


export type MutationOrderReceivedArgs = {
  id: Scalars['ID'];
  balance?: Maybe<Scalars['Float']>;
  zeroedOut?: Maybe<Scalars['Boolean']>;
  pointsEarned?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
};


export type MutationOrderErrorArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePdvChangeSetArgs = {
  changeSet: CreatePdvChangeSetInput;
};


export type MutationEditPdvChangeSetArgs = {
  id: Scalars['ID'];
  changeSet: EditPdvChangeSetInput;
};


export type MutationDeletePdvChangeSetArgs = {
  id: Scalars['ID'];
};


export type MutationAcceptDisputeArgs = {
  disputeId: Scalars['String'];
};


export type MutationSubmitDisputeArgs = {
  disputeId: Scalars['String'];
  evidence: StripeDisputeEvidenceInput;
  submit: Scalars['Boolean'];
};


export type MutationSetSessionStoreIdArgs = {
  storeId: Scalars['Int'];
};


export type OnlineProductGroup = {
  __typename?: 'OnlineProductGroup';
  id: Scalars['ID'];
  productGroupId: Scalars['Int'];
  name: Scalars['String'];
  parentId: Scalars['Int'];
  priority: Scalars['Int'];
  isVisible: Scalars['Boolean'];
  image: Scalars['String'];
  images: Array<Image>;
  products: Array<Product>;
  children: Array<OnlineProductGroup>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  type: SessionType;
  status: OrderStatus;
  statuses: Array<OrderStatus>;
  timePlaced: Scalars['String'];
  lastUpdated: Scalars['String'];
  time?: Maybe<Scalars['String']>;
  cartId: Scalars['ID'];
  sessionId: Scalars['ID'];
  storeId: Scalars['Int'];
  termId: Scalars['Int'];
  profileId?: Maybe<Scalars['Int']>;
  appUserId?: Maybe<Scalars['Int']>;
  employeeId?: Maybe<Scalars['ID']>;
  subTotal: Scalars['Int'];
  taxAmount: Scalars['Int'];
  total: Scalars['Int'];
  discrepancy?: Maybe<Scalars['Float']>;
  pointsEarned: Scalars['Int'];
  profile?: Maybe<Profile>;
  employee?: Maybe<Employee>;
  phone?: Maybe<Scalars['String']>;
  guestEmail?: Maybe<Scalars['String']>;
  pickupName: Scalars['String'];
  pickupLocation: PercPickupLocation;
  car?: Maybe<Car>;
  session: Session;
  cart: Cart;
  store: Store;
  items: Array<OrderItem>;
  creditCardPayments: Array<OrderCreditCardPayment>;
  giftCardPayments: Array<OrderGiftCardPayment>;
  discounts: Array<OrderDiscount>;
  refunds: Array<Refund>;
  refund: Refund;
  transaction?: Maybe<Transaction>;
};


export type OrderRefundArgs = {
  id: Scalars['Int'];
};

export type OrderCreditCardPayment = {
  __typename?: 'OrderCreditCardPayment';
  id: Scalars['ID'];
  chargeId: Scalars['ID'];
  charge: StripeCharge;
  amount: Scalars['Int'];
  identifier?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  lastFour?: Maybe<Scalars['Int']>;
  orderId: Scalars['ID'];
  order: Order;
};

export type OrderDirection =
  | 'asc'
  | 'desc';

export type OrderDiscount = {
  __typename?: 'OrderDiscount';
  id: Scalars['ID'];
  discountId: Scalars['Int'];
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['String'];
  node: Order;
};

export type OrderGiftCardPayment = {
  __typename?: 'OrderGiftCardPayment';
  id: Scalars['ID'];
  cardId: Scalars['Int'];
  cardNumber: Scalars['ID'];
  amount: Scalars['Int'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  itemId: Scalars['Int'];
  item: Item;
  taxAmount: Scalars['Int'];
  taxRate: Scalars['Float'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
  modifiers: Array<OrderModifier>;
  created: Scalars['String'];
  updated: Scalars['String'];
  typewriterNote?: Maybe<Scalars['String']>;
};

export type OrderItemInput = {
  id: Scalars['ID'];
  itemId: Scalars['Int'];
  price: Scalars['Int'];
  taxAmount: Scalars['Int'];
  taxRate: Scalars['Float'];
  modifiers: Array<OrderModifierInput>;
  typewriterNote?: Maybe<Scalars['String']>;
};

export type OrderModifier = {
  __typename?: 'OrderModifier';
  id: Scalars['ID'];
  modifierId: Scalars['Int'];
  modifier: Modifier;
  quantity: Scalars['Float'];
  price: Scalars['Int'];
};

export type OrderModifierInput = {
  id: Scalars['ID'];
  modifierId: Scalars['Int'];
  price: Scalars['Int'];
};

export type OrderPayment = OrderCreditCardPayment | OrderGiftCardPayment;

export type OrderStatus = {
  __typename?: 'OrderStatus';
  id: Scalars['Int'];
  orderId: Scalars['String'];
  status: OrderStatusEnum;
  refundId?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['String']>;
  time: Scalars['DateTime'];
};

export type OrderStatusEnum =
  | 'ORDER_PROCESSING'
  | 'ORDER_SCHEDULED'
  | 'ORDER_SENT'
  | 'ORDER_RECEIVED'
  | 'ORDER_READY'
  | 'ORDER_COMPLETE'
  | 'ORDER_ERROR'
  | 'ORDER_REFUND_PROCESSED'
  | 'ORDER_REFUND_REQUESTED';

export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  pageInfo: PageInfo;
  edges: Array<OrderEdge>;
  totalCount: Scalars['Int'];
  nodes: Array<Order>;
};

/** for Relay spec based pagination */
export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type PaymentAddBalInput = {
  creditCardPayment?: Maybe<CreditCardPaymentInput>;
};

export type PaymentInput = {
  creditCardPayment?: Maybe<CreditCardPaymentInput>;
  giftCardPayments: Array<GiftCardPaymentInput>;
};

export type PdvAction = {
  __typename?: 'PdvAction';
  id: Scalars['ID'];
  changeSetId: Scalars['Int'];
  ownerEmployeeId: Scalars['ID'];
  order: Scalars['Int'];
  type: PdvActionType;
  tableName: Scalars['String'];
  data: Scalars['String'];
  created: Scalars['DateTime'];
  owner: Employee;
  changeSet: PdvChangeSet;
};

export type PdvActionInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<PdvActionType>;
  tableName?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  ownerEmployeeId?: Maybe<Scalars['ID']>;
};

export type PdvActionType =
  | 'CREATE'
  | 'EDIT'
  | 'DELETE';

export type PdvChangeSet = {
  __typename?: 'PdvChangeSet';
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  ownerEmployeeId: Scalars['ID'];
  name: Scalars['String'];
  created: Scalars['DateTime'];
  owner: Employee;
  actions: Array<PdvAction>;
  parent?: Maybe<PdvChangeSet>;
  children: Array<PdvChangeSet>;
};

export type PdvChangeSetEdge = {
  __typename?: 'PdvChangeSetEdge';
  cursor: Scalars['String'];
  node: PdvChangeSet;
};

export type PdvChangeSetsConnection = {
  __typename?: 'PdvChangeSetsConnection';
  pageInfo: PageInfo;
  edges: Array<PdvChangeSetEdge>;
  totalCount: Scalars['Int'];
  nodes: Array<PdvChangeSet>;
};

export type PercAuthReponse = {
  __typename?: 'PercAuthReponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  jwt?: Maybe<Scalars['String']>;
};

export type PercCreditCardPayment = {
  __typename?: 'PercCreditCardPayment';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  identifier?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  lastFour?: Maybe<Scalars['Int']>;
};

export type PercDiscount = {
  __typename?: 'PercDiscount';
  id: Scalars['Int'];
  itemIds: Array<Scalars['Int']>;
};

export type PercGiftCardPayment = {
  __typename?: 'PercGiftCardPayment';
  id: Scalars['Int'];
  cardNumber: Scalars['ID'];
  amount: Scalars['Int'];
};

export type PercItem = {
  __typename?: 'PercItem';
  id: Scalars['Int'];
  orderItemId: Scalars['ID'];
  price: Scalars['Int'];
  taxAmount: Scalars['Int'];
  taxRate: Scalars['Int'];
  quantity: Scalars['Int'];
  modifiers: Array<PercModifier>;
  typewriterNote?: Maybe<Scalars['String']>;
};

export type PercModifier = {
  __typename?: 'PercModifier';
  id: Scalars['Int'];
  orderModifierId: Scalars['ID'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type PercOrder = {
  __typename?: 'PercOrder';
  id: Scalars['ID'];
  subTotal: Scalars['Int'];
  taxAmount: Scalars['Int'];
  total: Scalars['Int'];
  user?: Maybe<PercUser>;
  payments: Array<PercPayment>;
  timePlaced: Scalars['String'];
  pickupLocation?: Maybe<PercPickupLocation>;
  pickupName?: Maybe<Scalars['String']>;
  guestEmail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['ID']>;
  storeId: Scalars['Int'];
  termId: Scalars['Int'];
  items: Array<PercItem>;
  discounts: Array<PercDiscount>;
};

export type PercOrderResponse = {
  __typename?: 'PercOrderResponse';
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type PercPayment = PercCreditCardPayment | PercGiftCardPayment;

export type PercPickupLocation =
  | 'IN_STORE'
  | 'DRIVE_THRU'
  | 'PARKING_LOT';

export type PercUser = {
  __typename?: 'PercUser';
  id: Scalars['Int'];
  type: PercUserType;
};

export type PercUserType =
  | 'CUSTOMER'
  | 'EMPLOYEE';

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['Int'];
  permissionId: Scalars['Int'];
  pageName?: Maybe<Scalars['String']>;
  permissionName?: Maybe<Scalars['String']>;
  application?: Maybe<Scalars['String']>;
  lastModified: Scalars['DateTime'];
};

export type Person = {
  __typename?: 'Person';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress?: Maybe<Scalars['String']>;
  emailAddress2?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  stores: Array<PersonStore>;
};


export type PersonStoresArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PersonStore = {
  __typename?: 'PersonStore';
  id: Scalars['Int'];
  store: Store;
  role: Scalars['String'];
  primary: Scalars['Boolean'];
  percentOwnership?: Maybe<Scalars['Float']>;
};



export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  autoModify: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  images: Array<Image>;
  visibility: Array<ProductVisibility>;
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  isVisible: Scalars['Boolean'];
  priority?: Maybe<Scalars['Int']>;
  products: Array<Product>;
  children: Array<ProductGroup>;
};

export type ProductVisibility = {
  __typename?: 'ProductVisibility';
  id: Scalars['ID'];
  productId: Scalars['Int'];
  storeId: Scalars['Int'];
  regionId: Scalars['Int'];
  isVisible?: Maybe<Scalars['Boolean']>;
  isVisibleOnlineOrdering: Scalars['Boolean'];
};

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  cash?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthdayPromotionMedium?: Maybe<Scalars['String']>;
  ewardSubscription?: Maybe<Scalars['Boolean']>;
  ewardSignupDate?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['String']>;
  cards: Array<GiftCard>;
  favoriteStores: Array<FavoriteStore>;
  favoriteDrinks: Array<FavoriteDrink>;
  orderHistory: Array<Order>;
  planProgress: Array<RewardProgress>;
  claimedRewards: Array<ClaimedReward>;
};


export type ProfileOrderHistoryArgs = {
  id?: Maybe<Scalars['String']>;
};

export type ProfileInput = {
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  profile: Profile;
  user?: Maybe<Customer>;
  validateCard: Scalars['Int'];
  favoriteProducts: Array<Product>;
  favoriteStores: Array<Maybe<Store>>;
  lylaQuestion?: Maybe<LylaQuestion>;
  lylaQuestions?: Maybe<Array<LylaQuestion>>;
  whereToShow?: Maybe<Array<WhereToShow>>;
  store: Store;
  defaultNotices: DefaultNoticesConnection;
  defaultNotice: DefaultNotice;
  brandStandards: BrandStandardsConnection;
  brandStandard: BrandStandard;
  /** @deprecated Field no longer supported */
  menu: Menu;
  onlineProductGroup: OnlineProductGroup;
  onlineProductGroups: Array<OnlineProductGroup>;
  product?: Maybe<Product>;
  pendingOrderIds: Array<Scalars['ID']>;
  pendingOrder: Order;
  orderStatus?: Maybe<OrderStatus>;
  orders: OrdersConnection;
  order: Order;
  refund: Refund;
  returnReasons: Array<ReturnReason>;
  itemPrice: Array<Scalars['JSON']>;
  modifierPrice: Array<Scalars['JSON']>;
  pdvChangeSets: PdvChangeSetsConnection;
  pdvChangeSet: PdvChangeSet;
  storesByCity: Array<Store>;
  stripeAccount: Array<StripeAccount>;
  tables: Tables;
  tableVersions: Array<TableVersion>;
  terminalCheck?: Maybe<Terminal>;
  unavailableProducts: Array<ProductVisibility>;
  session: Session;
  stores: Array<Store>;
  storesByZipCode: Array<Store>;
  storesByCoordinates: Array<Store>;
  terminals: Array<Terminal>;
  connections: Array<Connection>;
};


export type QueryProfileArgs = {
  id: Scalars['Int'];
};


export type QueryValidateCardArgs = {
  cardNumber: Scalars['ID'];
};


export type QueryFavoriteProductsArgs = {
  id: Array<Scalars['Int']>;
};


export type QueryFavoriteStoresArgs = {
  storeId: Array<Scalars['Int']>;
};


export type QueryLylaQuestionArgs = {
  id: Scalars['ID'];
};


export type QueryStoreArgs = {
  storeId: Scalars['Int'];
};


export type QueryDefaultNoticesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  orderByMultiple?: Maybe<Array<Scalars['String']>>;
  orderDirectionMultiple?: Maybe<Array<OrderDirection>>;
  useOffsetPagination?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<FilterDefaultNoticeInput>;
};


export type QueryDefaultNoticeArgs = {
  id: Scalars['ID'];
};


export type QueryBrandStandardsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  orderByMultiple?: Maybe<Array<Scalars['String']>>;
  orderDirectionMultiple?: Maybe<Array<OrderDirection>>;
  useOffsetPagination?: Maybe<Scalars['Boolean']>;
};


export type QueryBrandStandardArgs = {
  id: Scalars['ID'];
};


export type QueryMenuArgs = {
  productGroupId?: Maybe<Scalars['Int']>;
  filterOnlineOrdering?: Maybe<Scalars['Boolean']>;
};


export type QueryOnlineProductGroupArgs = {
  id: Scalars['ID'];
};


export type QueryOnlineProductGroupsArgs = {
  parentId?: Maybe<Scalars['Int']>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryPendingOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderStatusArgs = {
  orderId: Scalars['ID'];
};


export type QueryOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  orderByMultiple?: Maybe<Array<Scalars['String']>>;
  orderDirectionMultiple?: Maybe<Array<OrderDirection>>;
  useOffsetPagination?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<FilterOrderInput>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
  appUserId?: Maybe<Scalars['Int']>;
};


export type QueryRefundArgs = {
  id: Scalars['ID'];
};


export type QueryItemPriceArgs = {
  storeId: Scalars['Int'];
};


export type QueryModifierPriceArgs = {
  storeId: Scalars['Int'];
};


export type QueryPdvChangeSetsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  orderByMultiple?: Maybe<Array<Scalars['String']>>;
  orderDirectionMultiple?: Maybe<Array<OrderDirection>>;
  useOffsetPagination?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<FilterPdvChangeSetInput>;
};


export type QueryPdvChangeSetArgs = {
  id: Scalars['ID'];
};


export type QueryStoresByCityArgs = {
  city: Scalars['String'];
};


export type QueryTerminalCheckArgs = {
  storeId: Scalars['Int'];
  termId: Scalars['Int'];
};


export type QueryUnavailableProductsArgs = {
  storeId: Scalars['Int'];
};


export type QueryStoresArgs = {
  zip?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<CoordinatesInput>;
  all?: Maybe<Scalars['Boolean']>;
};


export type QueryStoresByZipCodeArgs = {
  zip?: Maybe<Scalars['Int']>;
};


export type QueryStoresByCoordinatesArgs = {
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
};

export type Refund = {
  __typename?: 'Refund';
  id: Scalars['ID'];
  refundId: Scalars['Int'];
  subTotal: Scalars['Int'];
  taxAmount: Scalars['Int'];
  total: Scalars['Int'];
  created: Scalars['DateTime'];
  orderId: Scalars['ID'];
  returnReasonId: Scalars['Int'];
  order: Order;
  returnReason: ReturnReason;
  items: Array<OrderItem>;
  destinations: Array<RefundDestination>;
};

export type RefundDestination = {
  __typename?: 'RefundDestination';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  refundDestinationId: Scalars['Int'];
  refundId: Scalars['Int'];
  paymentId: Scalars['ID'];
  refund: Refund;
  payment: OrderPayment;
};

export type RefundDestinationInput = {
  paymentId: Scalars['ID'];
  paymentType: RefundDestinationPaymentTypeInput;
  amount: Scalars['Int'];
};

export type RefundDestinationPaymentTypeInput =
  | 'CREDIT_CARD'
  | 'GIFT_CARD';

export type ReturnReason = {
  __typename?: 'ReturnReason';
  id: Scalars['ID'];
  returnReasonId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  returnToInventory?: Maybe<Scalars['Boolean']>;
  isVisiblePerc: Scalars['Boolean'];
  isVisibleOnlineOrdering: Scalars['Boolean'];
};

export type Reward = {
  __typename?: 'Reward';
  id: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  timeLimit?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  validEverywhere: Scalars['Boolean'];
  validLocations: Array<Store>;
};

export type RewardPlan = {
  __typename?: 'RewardPlan';
  id: Scalars['Int'];
  threshold: Scalars['Float'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rewardId: Scalars['Int'];
  reward: Reward;
};

export type RewardProgress = {
  __typename?: 'RewardProgress';
  id: Scalars['Int'];
  points: Scalars['Float'];
  lastModified: Scalars['DateTime'];
  profileId: Scalars['Int'];
  rewardPlanId: Scalars['Int'];
  profile: Profile;
  plan: RewardPlan;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int'];
  roleId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  lastModified: Scalars['DateTime'];
  permissions: Array<Permission>;
};

export type SentryEvent = {
  eventId: Scalars['String'];
  error: Error;
};

export type Session = {
  __typename?: 'Session';
  cart: Cart;
  order?: Maybe<Order>;
  id: Scalars['ID'];
  type: SessionType;
  authenticated: Scalars['Boolean'];
  employee?: Maybe<Employee>;
  customer?: Maybe<Customer>;
  profile?: Maybe<Profile>;
  store?: Maybe<Store>;
};


export type SessionOrderArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type SessionType =
  | 'EMPLOYEE'
  | 'APP_USER'
  | 'BHLOUNGE';

export type Size = {
  __typename?: 'Size';
  id: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  itemName: Scalars['String'];
  itemNamePriority: Scalars['Int'];
};

export type Store = {
  __typename?: 'Store';
  orders: OrdersConnection;
  order?: Maybe<Order>;
  stripeAccount?: Maybe<StripeAccount>;
  disputes: Array<StripeDispute>;
  storeId: Scalars['Int'];
  status: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  driveThru?: Maybe<Scalars['Boolean']>;
  monThruThursOpen?: Maybe<Scalars['String']>;
  monThruThursClose?: Maybe<Scalars['String']>;
  friOpen?: Maybe<Scalars['String']>;
  friClose?: Maybe<Scalars['String']>;
  satOpen?: Maybe<Scalars['String']>;
  satClose?: Maybe<Scalars['String']>;
  sunOpen?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  sunClose?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  wiFi?: Maybe<Scalars['Boolean']>;
  twitterUrl?: Maybe<Scalars['String']>;
  facebookUrl?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  pricingTier?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  inStorePickup: Scalars['Boolean'];
  driveThruPickup: Scalars['Boolean'];
  parkingLotPickup: Scalars['Boolean'];
  oLOEnabled?: Maybe<Scalars['Boolean']>;
  settings: Array<Config>;
  terminals: Array<Terminal>;
  priceTierId?: Maybe<Scalars['Int']>;
};


export type StoreOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  orderByMultiple?: Maybe<Array<Scalars['String']>>;
  orderDirectionMultiple?: Maybe<Array<OrderDirection>>;
  useOffsetPagination?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<FilterOrderInput>;
};


export type StoreOrderArgs = {
  id: Scalars['ID'];
};


export type StoreDisputesArgs = {
  endingBefore?: Maybe<Scalars['String']>;
  startingAfter?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type StoreAvailability = {
  __typename?: 'StoreAvailability';
  id: Scalars['Int'];
  storeId: Scalars['Int'];
  periodStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  deleted: Scalars['Boolean'];
  available: Scalars['Boolean'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  id: Scalars['ID'];
  accountId: Scalars['ID'];
  storeId?: Maybe<Scalars['Int']>;
  chargesEnabled: Scalars['Boolean'];
  onboardingUrl?: Maybe<Scalars['String']>;
  loginUrl?: Maybe<Scalars['String']>;
};

export type StripeCard = {
  __typename?: 'StripeCard';
  id: Scalars['ID'];
  brand?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  fingerprint: Scalars['ID'];
  funding: Scalars['String'];
  last4: Scalars['String'];
  network: Scalars['String'];
};

export type StripeCharge = {
  __typename?: 'StripeCharge';
  /** Stripe charge id */
  id: Scalars['ID'];
  /** Our data on the payment */
  payment?: Maybe<OrderCreditCardPayment>;
  /** Stripe data on the payment */
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  amountRefunded: Scalars['Int'];
  captured: Scalars['Boolean'];
  created: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  destinationId?: Maybe<Scalars['String']>;
  destination?: Maybe<StripeAccount>;
  disputeId?: Maybe<Scalars['String']>;
  dispute?: Maybe<StripeDispute>;
  disputed: Scalars['Boolean'];
  outcome?: Maybe<StripeChargeOutcome>;
  paid: Scalars['Boolean'];
  paymentMethodId: Scalars['String'];
  paymentMethod?: Maybe<StripeCard>;
  receiptUrl?: Maybe<Scalars['String']>;
  refunded: Scalars['Boolean'];
  refunds: StripeRefundList;
  sourceId?: Maybe<Scalars['ID']>;
  source?: Maybe<StripeCard>;
  status: Scalars['String'];
  transferId?: Maybe<Scalars['ID']>;
  transfer?: Maybe<StripeTransfer>;
  fee?: Maybe<Scalars['Int']>;
  net?: Maybe<Scalars['Int']>;
};

export type StripeChargeOutcome = {
  __typename?: 'StripeChargeOutcome';
  networkStatus?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  riskLevel?: Maybe<Scalars['String']>;
  riskScore?: Maybe<Scalars['Int']>;
  sellerMessage?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type StripeDispute = {
  __typename?: 'StripeDispute';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  chargeId: Scalars['ID'];
  charge?: Maybe<StripeCharge>;
  reason: Scalars['String'];
  status: Scalars['String'];
  created: Scalars['String'];
  currency: Scalars['String'];
  storeId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  fee?: Maybe<Scalars['Int']>;
  net?: Maybe<Scalars['Int']>;
  isChargeRefundable: Scalars['Boolean'];
  evidence?: Maybe<StripeDisputeEvidence>;
  evidenceDetails?: Maybe<StripeEvidenceDetails>;
};

export type StripeDisputeEvidence = {
  __typename?: 'StripeDisputeEvidence';
  accessActivityLog?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  cancellationPolicy?: Maybe<Scalars['String']>;
  cancellationPolicyDisclosure?: Maybe<Scalars['String']>;
  cancellationRebuttal?: Maybe<Scalars['String']>;
  customerCommunication?: Maybe<Scalars['String']>;
  customerEmailAddress?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerPurchaseIp?: Maybe<Scalars['String']>;
  customerSignature?: Maybe<Scalars['String']>;
  duplicateChargeDocumentation?: Maybe<Scalars['String']>;
  duplicateChargeExplanation?: Maybe<Scalars['String']>;
  duplicateChargeId?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  receipt?: Maybe<Scalars['String']>;
  refundPolicy?: Maybe<Scalars['String']>;
  refundPolicyDisclosure?: Maybe<Scalars['String']>;
  refundRefusalExplanation?: Maybe<Scalars['String']>;
  serviceDate?: Maybe<Scalars['String']>;
  serviceDocumentation?: Maybe<Scalars['String']>;
  uncategorizedFile?: Maybe<Scalars['String']>;
  uncategorizedText?: Maybe<Scalars['String']>;
};

export type StripeDisputeEvidenceInput = {
  accessActivityLog?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  cancellationPolicy?: Maybe<Scalars['String']>;
  cancellationPolicyDisclosure?: Maybe<Scalars['String']>;
  cancellationRebuttal?: Maybe<Scalars['String']>;
  customerCommunication?: Maybe<Scalars['String']>;
  customerEmailAddress?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerPurchaseIp?: Maybe<Scalars['String']>;
  customerSignature?: Maybe<Scalars['String']>;
  duplicateChargeDocumentation?: Maybe<Scalars['String']>;
  duplicateChargeExplanation?: Maybe<Scalars['String']>;
  duplicateChargeId?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  receipt?: Maybe<Scalars['String']>;
  refundPolicy?: Maybe<Scalars['String']>;
  refundPolicyDisclosure?: Maybe<Scalars['String']>;
  refundRefusalExplanation?: Maybe<Scalars['String']>;
  serviceDate?: Maybe<Scalars['String']>;
  serviceDocumentation?: Maybe<Scalars['String']>;
  uncategorizedFile?: Maybe<Scalars['String']>;
  uncategorizedText?: Maybe<Scalars['String']>;
};

export type StripeEvidenceDetails = {
  __typename?: 'StripeEvidenceDetails';
  dueBy: Scalars['String'];
  hasEvidence: Scalars['Boolean'];
  pastDue: Scalars['Boolean'];
  submissionCount: Scalars['Int'];
};

export type StripeRefund = {
  __typename?: 'StripeRefund';
  id: Scalars['ID'];
  object: Scalars['String'];
  amount: Scalars['Int'];
  /** charge: StripeRefundCharge! */
  created: Scalars['Int'];
  currency: Scalars['String'];
  description: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  receiptNumber?: Maybe<Scalars['String']>;
  sourceTransferReversal?: Maybe<StripeTransfer>;
  transferReversal?: Maybe<StripeTransfer>;
};

export type StripeRefundList = {
  __typename?: 'StripeRefundList';
  data: Array<StripeRefund>;
  hasMore: Scalars['Boolean'];
  totalCount: Scalars['Int'];
  url: Scalars['String'];
};

export type StripeTransfer = {
  __typename?: 'StripeTransfer';
  id: Scalars['ID'];
  amount?: Maybe<Scalars['Int']>;
  destination: StripeAccount;
  group: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  createCardsInSeriesUpdates: CreateCardsInSeriesUpdate;
  newOrder: PercOrder;
  orderStatus?: Maybe<OrderStatus>;
  orderStatuses: OrderStatus;
  allOrders?: Maybe<PercOrder>;
  orders: Array<PercOrder>;
  terminals: Array<Terminal>;
  logs?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SubscriptionCreateCardsInSeriesUpdatesArgs = {
  executionArn: Scalars['ID'];
};


export type SubscriptionOrderStatusArgs = {
  id: Scalars['ID'];
};


export type SubscriptionOrderStatusesArgs = {
  filter: FilterOrderStatuses;
};


export type SubscriptionTerminalsArgs = {
  storeId?: Maybe<Scalars['Int']>;
};

export type TableName =
  | 'componentGroupRatioQuantities'
  | 'componentGroupRatios'
  | 'componentGroupRatiosItemQualifiers'
  | 'componentGroupRatioType'
  | 'componentGroupRatioTypeItemQualifiers'
  | 'componentGroups'
  | 'componentQualifiers'
  | 'components'
  | 'componentsComponentGroups'
  | 'componentsModifiers'
  | 'componentUnitRatios'
  | 'componentVersions'
  | 'containers'
  | 'containerVolumes'
  | 'inventoryItems'
  | 'itemPrices'
  | 'itemQualifiers'
  | 'items'
  | 'itemsComponents'
  | 'modifierFunctions'
  | 'modifierFunctionsItemQualifiers'
  | 'modifierGroups'
  | 'modifierGroupsItemQualifiers'
  | 'modifierGroupsModifiers'
  | 'modifierPrices'
  | 'modifiers'
  | 'modifiersItemQualifiers'
  | 'modifierScales'
  | 'modifierScalesItemQualifiers'
  | 'modifierScalesModifiers'
  | 'modifierVisibility'
  | 'productGroups'
  | 'productGroupsProducts'
  | 'products'
  | 'productVersions'
  | 'productVisibility'
  | 'sizes'
  | 'stateLocale'
  | 'taxLocal'
  | 'taxState'
  | 'units';

export type TableVersion = {
  __typename?: 'TableVersion';
  tableName: TableName;
  version: Scalars['DateTime'];
};

export type Tables = {
  __typename?: 'Tables';
  config: Array<ConfigTable>;
  itemPrices: Array<Scalars['JSON']>;
  modifierPrices: Array<Scalars['JSON']>;
};


export type TablesConfigArgs = {
  storeId?: Maybe<Scalars['Int']>;
};

export type Terminal = {
  __typename?: 'Terminal';
  connectionId?: Maybe<Scalars['ID']>;
  status: TerminalStatus;
  termId: Scalars['Int'];
  storeId: Scalars['Int'];
  time: Scalars['String'];
};

export type TerminalStatus =
  | 'CONNECTED'
  | 'DISCONNECTED';

export type TransItem = {
  __typename?: 'TransItem';
  id: Scalars['ID'];
  storeId: Scalars['Int'];
  termId: Scalars['Int'];
  transStartTime: Scalars['DateTime'];
  itemAddTime: Scalars['DateTime'];
  itemId?: Maybe<Scalars['Int']>;
  ticketName?: Maybe<Scalars['String']>;
  return?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  discountAddTime?: Maybe<Scalars['DateTime']>;
  itemAddDate?: Maybe<Scalars['Int']>;
  includeInSales?: Maybe<Scalars['Boolean']>;
  lastModified: Scalars['DateTime'];
  serverLastModified?: Maybe<Scalars['DateTime']>;
  aggregated?: Maybe<Scalars['Boolean']>;
  returnReasonId?: Maybe<Scalars['Int']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  taxAmount?: Maybe<Scalars['Float']>;
  typewriterNote?: Maybe<Scalars['String']>;
  item?: Maybe<Item>;
  void?: Maybe<TransItemVoid>;
  returnReason?: Maybe<ReturnReason>;
  /** discount: TransDiscount */
  modifiers: Array<TransModifier>;
};

export type TransItemVoid = {
  __typename?: 'TransItemVoid';
  id: Scalars['ID'];
  storeId: Scalars['Int'];
  termId: Scalars['Int'];
  transStartTime: Scalars['DateTime'];
  itemAddTime: Scalars['DateTime'];
  voidTime?: Maybe<Scalars['DateTime']>;
  voidReasonId?: Maybe<Scalars['Int']>;
  lastModified: Scalars['DateTime'];
  aggregated?: Maybe<Scalars['Boolean']>;
};

export type TransModifier = {
  __typename?: 'TransModifier';
  id: Scalars['ID'];
  storeId: Scalars['Int'];
  termId: Scalars['Int'];
  transStartTime: Scalars['DateTime'];
  modifierId: Scalars['Int'];
  modifierAddTime: Scalars['DateTime'];
  itemAddTime?: Maybe<Scalars['DateTime']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  lastModified: Scalars['DateTime'];
  serverLastModified?: Maybe<Scalars['DateTime']>;
  aggregated?: Maybe<Scalars['Boolean']>;
  returnReasonId?: Maybe<Scalars['Int']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  basePrice?: Maybe<Scalars['Float']>;
  modifier: Modifier;
  item?: Maybe<TransItem>;
  returnReason?: Maybe<ReturnReason>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  storeId: Scalars['Int'];
  termId: Scalars['Int'];
  transStartTime: Scalars['DateTime'];
  tillId: Scalars['Int'];
  orderNumber?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
  transCloseTime?: Maybe<Scalars['DateTime']>;
  cashierId?: Maybe<Scalars['ID']>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  grossPrice?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  netPrice?: Maybe<Scalars['Float']>;
  nonSaleRevenue?: Maybe<Scalars['Float']>;
  lastModified: Scalars['DateTime'];
  aggregated?: Maybe<Scalars['Boolean']>;
  serverLastModified: Scalars['DateTime'];
  isDigitalOrder?: Maybe<Scalars['Boolean']>;
  onlineOrderId?: Maybe<Scalars['ID']>;
  onlineTimePlaced?: Maybe<Scalars['DateTime']>;
  pickupName?: Maybe<Scalars['String']>;
  pickupLocation?: Maybe<Scalars['String']>;
  wasZeroedOut?: Maybe<Scalars['Boolean']>;
  cashier?: Maybe<Employee>;
  onlineOrder?: Maybe<Order>;
  items: Array<TransItem>;
};

export type WhereToShow = {
  __typename?: 'WhereToShow';
  questionLocationId: Scalars['Int'];
  screenname: Scalars['String'];
  lastModified?: Maybe<Scalars['DateTime']>;
};

export type CartFieldsFragment = { __typename?: 'Cart', id: string, items: Array<(
    { __typename?: 'OrderItem' }
    & OrderItemFieldsFragment
  )> };

export type ConfigFieldsFragment = { __typename?: 'ConfigTable', storeId: number, termId: number, setting: string, value: string };

export type EmployeeFieldsFragment = { __typename?: 'Employee', id: string, firstName: string, lastName: string, roles: Array<{ __typename?: 'EmployeeRole', id: number, roleId: number, role: { __typename?: 'Role', id: number, name?: Maybe<string> } }> };

export type ItemFieldsFragment = { __typename?: 'Item', id: string, product?: Maybe<{ __typename?: 'Product', id: string, name: string }>, size?: Maybe<{ __typename?: 'Size', id: string, name: string }>, prices: Array<(
    { __typename?: 'ItemPrice' }
    & ItemPriceFieldsFragment
  )> };

export type ItemPriceFieldsFragment = { __typename?: 'ItemPrice', id: number, itemId: number, tierId: number, storeId: number, price?: Maybe<number>, lastModified: any, serverLastModified?: Maybe<any> };

export type LylaQuestionFieldsFragment = { __typename?: 'LylaQuestion', questionId: string, question: string, askFrequency?: Maybe<number>, idleTime?: Maybe<number>, startDate?: Maybe<any>, endDate?: Maybe<any>, isActive: boolean, whereToShow?: Maybe<number>, askAgainIfSkipped: boolean, lastModified?: Maybe<any> };

export type ModifierFieldsFragment = { __typename?: 'Modifier', id: string, name?: Maybe<string>, prices: Array<(
    { __typename?: 'ModifierPrice' }
    & ModifierPriceFieldsFragment
  )> };

export type ModifierPriceFieldsFragment = { __typename?: 'ModifierPrice', id: number, modifierId: number, tierId: number, storeId: number, price?: Maybe<number>, lastModified: any, serverLastModified?: Maybe<any> };

export type OrderCreditCardPaymentFieldsFragment = { __typename?: 'OrderCreditCardPayment', id: string, chargeId: string, amount: number, identifier?: Maybe<string>, cardType?: Maybe<string>, lastFour?: Maybe<number> };

export type OrderFieldsFragment = { __typename?: 'Order', id: string, timePlaced: string, lastUpdated: string, cartId: string, storeId: number, termId: number, profileId?: Maybe<number>, employeeId?: Maybe<string>, guestEmail?: Maybe<string>, pickupName: string, pickupLocation: PercPickupLocation, status: { __typename?: 'OrderStatus', id: number, orderId: string, status: OrderStatusEnum, details?: Maybe<string>, time: any }, car?: Maybe<{ __typename?: 'Car', make: string, model: string, color: string, year?: Maybe<number> }>, session: { __typename?: 'Session', cart: { __typename?: 'Cart', id: string, items: Array<(
        { __typename?: 'OrderItem' }
        & OrderItemFieldsFragment
      )> } }, store: (
    { __typename?: 'Store' }
    & StoreFieldsFragment
  ), items: Array<(
    { __typename?: 'OrderItem' }
    & OrderItemFieldsFragment
  )>, creditCardPayments: Array<(
    { __typename?: 'OrderCreditCardPayment' }
    & OrderCreditCardPaymentFieldsFragment
  )>, giftCardPayments: Array<{ __typename?: 'OrderGiftCardPayment', id: string, cardId: number, cardNumber: string, amount: number }>, refunds: Array<(
    { __typename?: 'Refund' }
    & RefundFieldsFragment
  )> };

export type OrderGiftCardPaymentFieldsFragment = { __typename?: 'OrderGiftCardPayment', id: string, cardId: number, cardNumber: string, amount: number };

export type GiftCardPaymentsFieldFragment = { __typename?: 'OrderGiftCardPayment', id: string, cardId: number, cardNumber: string, amount: number };

export type OrderItemFieldsFragment = { __typename?: 'OrderItem', id: string, quantity: number, price: number, taxAmount: number, taxRate: number, created: string, item: { __typename?: 'Item', id: string, product?: Maybe<{ __typename?: 'Product', id: string, name: string }> }, modifiers: Array<{ __typename?: 'OrderModifier', id: string, quantity: number, price: number, modifier: { __typename?: 'Modifier', id: string, name?: Maybe<string> } }> };

export type OrderStatusFieldsFragment = { __typename?: 'OrderStatus', id: number, orderId: string, status: OrderStatusEnum, details?: Maybe<string>, time: any };

export type PersonFieldsFragment = { __typename?: 'Person', id: number, firstName: string, lastName: string, emailAddress?: Maybe<string>, emailAddress2?: Maybe<string>, address1?: Maybe<string>, address2?: Maybe<string>, city?: Maybe<string>, state?: Maybe<string>, zipCode?: Maybe<string> };

export type ProductFieldsFragment = { __typename?: 'Product', id: string, name: string, image?: Maybe<string>, autoModify: boolean };

export type ProductGroupFieldsFragment = { __typename?: 'ProductGroup', id: string, isVisible: boolean, priority?: Maybe<number>, products: Array<(
    { __typename?: 'Product' }
    & ProductFieldsFragment
  )> };

export type ProfileFieldsFragment = { __typename?: 'Profile', id: number, email?: Maybe<string>, firstName: string, lastName: string, cash?: Maybe<number>, unit?: Maybe<string>, street?: Maybe<string>, city?: Maybe<string>, state?: Maybe<string>, postal_code?: Maybe<string>, phone?: Maybe<string>, birth_date?: Maybe<string>, gender?: Maybe<string>, birthdayPromotionMedium?: Maybe<string>, ewardSubscription?: Maybe<boolean>, ewardSignupDate?: Maybe<string>, lastModified?: Maybe<string> };

export type RefundDestinationFieldsFragment = { __typename?: 'RefundDestination', id: string, refundDestinationId: number, amount: number, refundId: number, paymentId: string, payment: (
    { __typename?: 'OrderCreditCardPayment' }
    & OrderCreditCardPaymentFieldsFragment
  ) | (
    { __typename?: 'OrderGiftCardPayment' }
    & GiftCardPaymentsFieldFragment
  ) };

export type RefundFieldsFragment = { __typename?: 'Refund', id: string, refundId: number, orderId: string, returnReasonId: number, created: any, returnReason: (
    { __typename?: 'ReturnReason' }
    & ReturnReasonFieldsFragment
  ), items: Array<(
    { __typename?: 'OrderItem' }
    & OrderItemFieldsFragment
  )>, destinations: Array<(
    { __typename?: 'RefundDestination' }
    & RefundDestinationFieldsFragment
  )> };

export type ReturnReasonFieldsFragment = { __typename?: 'ReturnReason', id: string, returnReasonId: number, name?: Maybe<string>, returnToInventory?: Maybe<boolean>, isVisiblePerc: boolean, isVisibleOnlineOrdering: boolean };

export type SessionFieldsFragment = { __typename?: 'Session', type: SessionType, authenticated: boolean, employee?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeFieldsFragment
  )>, customer?: Maybe<{ __typename?: 'Customer', id: number, profile: (
      { __typename?: 'Profile' }
      & ProfileFieldsFragment
    ) }>, profile?: Maybe<(
    { __typename?: 'Profile' }
    & ProfileFieldsFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & StoreFieldsFragment
    & StoreFieldsFragment
  )>, cart: { __typename?: 'Cart', id: string, items: Array<(
      { __typename?: 'OrderItem' }
      & OrderItemFieldsFragment
    )> }, order?: Maybe<(
    { __typename?: 'Order' }
    & OrderFieldsFragment
  )> };

export type SizeFieldsFragment = { __typename?: 'Size', id: string, name: string, priority: number, itemName: string, itemNamePriority: number };

export type StoreFieldsFragment = { __typename?: 'Store', storeId: number, phone?: Maybe<string>, driveThru?: Maybe<boolean>, monThruThursOpen?: Maybe<string>, monThruThursClose?: Maybe<string>, friOpen?: Maybe<string>, friClose?: Maybe<string>, satOpen?: Maybe<string>, satClose?: Maybe<string>, sunOpen?: Maybe<string>, sunClose?: Maybe<string>, address1?: Maybe<string>, address2?: Maybe<string>, city?: Maybe<string>, state?: Maybe<string>, zipCode?: Maybe<string>, location?: Maybe<string>, latitude?: Maybe<number>, longitude?: Maybe<number>, wiFi?: Maybe<boolean>, twitterUrl?: Maybe<string>, facebookUrl?: Maybe<string>, distance?: Maybe<number>, priceTierId?: Maybe<number> };

export type StoreStripeFieldsFragment = (
  { __typename?: 'Store', stripeAccount?: Maybe<{ __typename?: 'StripeAccount', id: string, accountId: string, storeId?: Maybe<number>, chargesEnabled: boolean, onboardingUrl?: Maybe<string>, loginUrl?: Maybe<string> }> }
  & StoreFieldsFragment
);

export type TablesFieldsFragment = { __typename?: 'Tables', itemPrices: Array<any>, modifierPrices: Array<any>, config: Array<(
    { __typename?: 'ConfigTable' }
    & ConfigFieldsFragment
  )> };

export type AcceptDisputeMutationVariables = Exact<{
  disputeId: Scalars['String'];
}>;


export type AcceptDisputeMutation = { __typename?: 'Mutation', acceptDispute: { __typename?: 'StripeDispute', id: string, amount: number, chargeId: string, reason: string, status: string, created: string, currency: string, charge?: Maybe<{ __typename?: 'StripeCharge', id: string }> } };

export type AddCartItemMutationVariables = Exact<{
  item: OrderItemInput;
}>;


export type AddCartItemMutation = { __typename?: 'Mutation', addCartItem: { __typename?: 'Cart', id: string, items: Array<(
      { __typename?: 'OrderItem' }
      & OrderItemFieldsFragment
    )> } };

export type AddLylaQuestionMutationVariables = Exact<{
  question: LylaQuestionInput;
}>;


export type AddLylaQuestionMutation = { __typename?: 'Mutation', addLylaQuestion: (
    { __typename?: 'LylaQuestion' }
    & LylaQuestionFieldsFragment
  ) };

export type AuthenticateCustomerMutationVariables = Exact<{
  credentials: AuthenticateCustomerInput;
}>;


export type AuthenticateCustomerMutation = { __typename?: 'Mutation', authenticateCustomer: { __typename?: 'Customer', id: number, profile: (
      { __typename?: 'Profile' }
      & ProfileFieldsFragment
    ) } };

export type AuthenticateEmployeeMutationVariables = Exact<{
  credentials: AuthenticateEmployeeInput;
}>;


export type AuthenticateEmployeeMutation = { __typename?: 'Mutation', authenticateEmployee: (
    { __typename?: 'Employee' }
    & EmployeeFieldsFragment
  ) };

export type AuthenticateQrCodeMutationVariables = Exact<{
  qrcode: Scalars['String'];
}>;


export type AuthenticateQrCodeMutation = { __typename?: 'Mutation', authenticateQRCode: (
    { __typename?: 'Session' }
    & SessionFieldsFragment
  ) };

export type CreateBrandStandardMutationVariables = Exact<{
  brandStandard: BrandStandardInput;
}>;


export type CreateBrandStandardMutation = { __typename?: 'Mutation', createBrandStandard: { __typename?: 'BrandStandard', id: string, brandStandardId: number, description?: Maybe<string>, resolution?: Maybe<string> } };

export type CreateCardsInSeriesMutationVariables = Exact<{
  data: CreateCardsInSeriesInput;
}>;


export type CreateCardsInSeriesMutation = { __typename?: 'Mutation', createCardsInSeries: string };

export type CreateDefaultNoticeMutationVariables = Exact<{
  defaultNotice: DefaultNoticeInput;
}>;


export type CreateDefaultNoticeMutation = { __typename?: 'Mutation', createDefaultNotice: { __typename?: 'DefaultNotice', id: string, description: string, defaultDate: any, notificationMethod: string, brandStandardViolated: number, reportedBy: string, resolved: boolean, resolutionDate?: Maybe<any>, storeId: number } };

export type CreateEwardUserMutationVariables = Exact<{
  ewardInput: EwardInput;
}>;


export type CreateEwardUserMutation = { __typename?: 'Mutation', createEwardUser: boolean };

export type DeleteBrandStandardMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBrandStandardMutation = { __typename?: 'Mutation', deleteBrandStandard: boolean };

export type DeleteCartItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCartItemMutation = { __typename?: 'Mutation', deleteCartItem: { __typename?: 'Cart', id: string, items: Array<(
      { __typename?: 'OrderItem' }
      & OrderItemFieldsFragment
    )> } };

export type DeleteDefaultNoticeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDefaultNoticeMutation = { __typename?: 'Mutation', deleteDefaultNotice: boolean };

export type DeleteLylaQuestionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLylaQuestionMutation = { __typename?: 'Mutation', deleteLylaQuestion: boolean };

export type EditBrandStandardMutationVariables = Exact<{
  brandStandard: EditBrandStandardInput;
}>;


export type EditBrandStandardMutation = { __typename?: 'Mutation', editBrandStandard: { __typename?: 'BrandStandard', brandStandardId: number, description?: Maybe<string>, resolution?: Maybe<string> } };

export type EditDefaultNoticeMutationVariables = Exact<{
  defaultNotice: EditDefaultNoticeInput;
}>;


export type EditDefaultNoticeMutation = { __typename?: 'Mutation', editDefaultNotice: { __typename?: 'DefaultNotice', id: string, description: string, defaultDate: any, notificationMethod: string, brandStandardViolated: number, reportedBy: string, resolved: boolean, resolutionDate?: Maybe<any>, storeId: number } };

export type EditLylaQuestionMutationVariables = Exact<{
  question: LylaQuestionInput;
}>;


export type EditLylaQuestionMutation = { __typename?: 'Mutation', editLylaQuestion: { __typename?: 'LylaQuestion', questionId: string } };

export type EditStoreSettingsMutationVariables = Exact<{
  settings: Array<ConfigInput> | ConfigInput;
}>;


export type EditStoreSettingsMutation = { __typename?: 'Mutation', editStoreSettings: Array<Maybe<{ __typename?: 'Config', setting: string }>> };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type OrderCompleteMutationVariables = Exact<{
  id: Scalars['ID'];
  forced?: Maybe<Scalars['Boolean']>;
}>;


export type OrderCompleteMutation = { __typename?: 'Mutation', orderComplete: { __typename?: 'PercOrderResponse', success: boolean, error?: Maybe<string> } };

export type OrderReadyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderReadyMutation = { __typename?: 'Mutation', orderReady: { __typename?: 'PercOrderResponse', success: boolean, error?: Maybe<string> } };

export type RefundMutationVariables = Exact<{
  orderId: Scalars['ID'];
  returnReasonId: Scalars['Int'];
  orderItemIds: Array<Scalars['ID']> | Scalars['ID'];
  refundDestinations: Array<RefundDestinationInput> | RefundDestinationInput;
}>;


export type RefundMutation = { __typename?: 'Mutation', refund: { __typename?: 'Refund', id: string, orderId: string } };

export type RefundProcessedMutationVariables = Exact<{
  orderId: Scalars['ID'];
  refundId: Scalars['Int'];
}>;


export type RefundProcessedMutation = { __typename?: 'Mutation', refundProcessed: { __typename?: 'PercOrderResponse', success: boolean, error?: Maybe<string> } };

export type SendCustomerOrderMutationVariables = Exact<{
  order: CustomerOrderInput;
}>;


export type SendCustomerOrderMutation = { __typename?: 'Mutation', sendCustomerOrder: (
    { __typename?: 'Order' }
    & OrderFieldsFragment
  ) };

export type SendEmployeeOrderMutationVariables = Exact<{
  order: EmployeeOrderInput;
}>;


export type SendEmployeeOrderMutation = { __typename?: 'Mutation', sendEmployeeOrder: (
    { __typename?: 'Order' }
    & OrderFieldsFragment
  ) };

export type SendOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SendOrderMutation = { __typename?: 'Mutation', sendOrder: { __typename?: 'PercOrderResponse', success: boolean, error?: Maybe<string> } };

export type SetSessionStoreIdMutationVariables = Exact<{
  storeId: Scalars['Int'];
}>;


export type SetSessionStoreIdMutation = { __typename?: 'Mutation', setSessionStoreId: { __typename?: 'Session', cart: { __typename?: 'Cart', id: string, items: Array<(
        { __typename?: 'OrderItem' }
        & OrderItemFieldsFragment
      )> }, store?: Maybe<(
      { __typename?: 'Store' }
      & StoreFieldsFragment
    )> } };

export type UpdateCartItemMutationVariables = Exact<{
  item: OrderItemInput;
}>;


export type UpdateCartItemMutation = { __typename?: 'Mutation', updateCartItem: { __typename?: 'Cart', id: string, items: Array<(
      { __typename?: 'OrderItem' }
      & OrderItemFieldsFragment
    )> } };

export type LylaQuestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type LylaQuestionsQuery = { __typename?: 'Query', lylaQuestions?: Maybe<Array<(
    { __typename?: 'LylaQuestion' }
    & LylaQuestionFieldsFragment
  )>> };

export type AllStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type AllStoresQuery = { __typename?: 'Query', stores: Array<{ __typename?: 'Store', storeId: number, driveThru?: Maybe<boolean>, oLOEnabled?: Maybe<boolean> }> };

export type BrandStandardQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BrandStandardQuery = { __typename?: 'Query', brandStandard: { __typename?: 'BrandStandard', id: string, brandStandardId: number, description?: Maybe<string>, resolution?: Maybe<string> } };

export type BrandStandardEdgeFieldsFragment = { __typename?: 'BrandStandardEdge', cursor: string, node: { __typename?: 'BrandStandard', id: string, brandStandardId: number, description?: Maybe<string>, resolution?: Maybe<string> } };

export type BrandStandardsConnectionFieldsFragment = { __typename?: 'BrandStandardsConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: Maybe<string>, endCursor?: Maybe<string> }, edges: Array<(
    { __typename?: 'BrandStandardEdge' }
    & BrandStandardEdgeFieldsFragment
  )> };

export type BrandStandardsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  orderByMultiple?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orderDirectionMultiple?: Maybe<Array<OrderDirection> | OrderDirection>;
  useOffsetPagination?: Maybe<Scalars['Boolean']>;
}>;


export type BrandStandardsQuery = { __typename?: 'Query', brandStandards: (
    { __typename?: 'BrandStandardsConnection' }
    & BrandStandardsConnectionFieldsFragment
  ) };

export type ConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigQuery = { __typename?: 'Query', tables: (
    { __typename?: 'Tables' }
    & TablesFieldsFragment
  ) };

export type ConnectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConnectionsQuery = { __typename?: 'Query', connections: Array<{ __typename?: 'Connection', key: string, storeId: number, termId: number, connectionId: string, disconnected: boolean, value?: Maybe<string>, time?: Maybe<any> }> };

export type DefaultNoticeEdgeFieldsFragment = { __typename?: 'DefaultNoticeEdge', cursor: string, node: { __typename?: 'DefaultNotice', id: string, description: string, defaultDate: any, notificationMethod: string, brandStandardViolated: number, reportedBy: string, resolved: boolean, resolutionDate?: Maybe<any>, storeId: number, brandStandard: { __typename?: 'BrandStandard', brandStandardId: number, description?: Maybe<string> }, store: { __typename?: 'Store', storeId: number } } };

export type DefaultNoticesConnectionFieldsFragment = { __typename?: 'DefaultNoticesConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: Maybe<string>, endCursor?: Maybe<string> }, edges: Array<(
    { __typename?: 'DefaultNoticeEdge' }
    & DefaultNoticeEdgeFieldsFragment
  )> };

export type DefaultNoticesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  orderByMultiple?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orderDirectionMultiple?: Maybe<Array<OrderDirection> | OrderDirection>;
  useOffsetPagination?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<FilterDefaultNoticeInput>;
}>;


export type DefaultNoticesQuery = { __typename?: 'Query', defaultNotices: (
    { __typename?: 'DefaultNoticesConnection' }
    & DefaultNoticesConnectionFieldsFragment
  ) };

export type EmployeeStoresFieldsFragment = { __typename?: 'Store', storeId: number, priceTierId?: Maybe<number>, oLOEnabled?: Maybe<boolean> };

export type EmployeeStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployeeStoresQuery = { __typename?: 'Query', session: { __typename?: 'Session', employee?: Maybe<{ __typename?: 'Employee', stores: Array<(
        { __typename?: 'Store' }
        & EmployeeStoresFieldsFragment
      )> }> } };

export type MenuQueryVariables = Exact<{
  productGroupId?: Maybe<Scalars['Int']>;
}>;


export type MenuQuery = { __typename?: 'Query', menu: { __typename?: 'Menu', productGroups: Array<{ __typename?: 'ProductGroup', id: string, name: string }>, products: Array<{ __typename?: 'Product', id: string, name: string }> } };

export type SessionQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionQuery = { __typename?: 'Query', session: (
    { __typename?: 'Session' }
    & SessionFieldsFragment
  ) };

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderQuery = { __typename?: 'Query', order: { __typename?: 'Order', id: string, storeId: number, termId: number, type: SessionType, subTotal: number, taxAmount: number, total: number, discrepancy?: Maybe<number>, timePlaced: string, store: { __typename?: 'Store', storeId: number, priceTierId?: Maybe<number> }, status: { __typename?: 'OrderStatus', id: number, status: OrderStatusEnum, details?: Maybe<string> }, items: Array<{ __typename?: 'OrderItem', id: string, taxAmount: number, taxRate: number, quantity: number, price: number, itemId: number, item: (
        { __typename?: 'Item' }
        & ItemFieldsFragment
      ), modifiers: Array<{ __typename?: 'OrderModifier', id: string, quantity: number, price: number, modifierId: number, modifier: (
          { __typename?: 'Modifier' }
          & ModifierFieldsFragment
        ) }> }>, transaction?: Maybe<{ __typename?: 'Transaction', id: string, transStartTime: any, orderNumber?: Maybe<number>, open?: Maybe<boolean>, transCloseTime?: Maybe<any>, taxExempt?: Maybe<boolean>, grossPrice?: Maybe<number>, tax?: Maybe<number>, discountAmount?: Maybe<number>, netPrice?: Maybe<number>, nonSaleRevenue?: Maybe<number>, wasZeroedOut?: Maybe<boolean>, items: Array<{ __typename?: 'TransItem', id: string, storeId: number, termId: number, transStartTime: any, itemAddTime: any, itemId?: Maybe<number>, ticketName?: Maybe<string>, return?: Maybe<boolean>, price?: Maybe<number>, taxRate?: Maybe<number>, discountAmount?: Maybe<number>, taxAmount?: Maybe<number>, item?: Maybe<(
          { __typename?: 'Item' }
          & ItemFieldsFragment
        )>, modifiers: Array<{ __typename?: 'TransModifier', id: string, storeId: number, termId: number, transStartTime: any, modifierId: number, modifierAddTime: any, itemAddTime?: Maybe<any>, price?: Maybe<number>, quantity?: Maybe<number>, lastModified: any, serverLastModified?: Maybe<any>, aggregated?: Maybe<boolean>, returnReasonId?: Maybe<number>, isVoided?: Maybe<boolean>, basePrice?: Maybe<number>, modifier: (
            { __typename?: 'Modifier' }
            & ModifierFieldsFragment
          ) }> }> }> } };

export type OrderRefundsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderRefundsQuery = { __typename?: 'Query', order: { __typename?: 'Order', id: string, refunds: Array<(
      { __typename?: 'Refund' }
      & RefundFieldsFragment
    )>, creditCardPayments: Array<(
      { __typename?: 'OrderCreditCardPayment' }
      & OrderCreditCardPaymentFieldsFragment
    )>, giftCardPayments: Array<(
      { __typename?: 'OrderGiftCardPayment' }
      & OrderGiftCardPaymentFieldsFragment
    )> } };

export type OrdersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  orderByMultiple?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orderDirectionMultiple?: Maybe<Array<OrderDirection> | OrderDirection>;
  useOffsetPagination?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<FilterOrderInput>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: { __typename?: 'OrdersConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: Maybe<string>, endCursor?: Maybe<string> }, edges: Array<{ __typename?: 'OrderEdge', cursor: string, node: { __typename?: 'Order', id: string, storeId: number, termId: number, type: SessionType, discrepancy?: Maybe<number>, timePlaced: string, pickupName: string, subTotal: number, taxAmount: number, total: number, status: { __typename?: 'OrderStatus', id: number, status: OrderStatusEnum } } }> } };

export type OwnerOperatorQueryVariables = Exact<{ [key: string]: never; }>;


export type OwnerOperatorQuery = { __typename?: 'Query', session: { __typename?: 'Session', employee?: Maybe<{ __typename?: 'Employee', person?: Maybe<(
        { __typename?: 'Person' }
        & PersonFieldsFragment
      )> }> } };

export type PendingOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PendingOrderQuery = { __typename?: 'Query', pendingOrder: { __typename?: 'Order', id: string, type: SessionType, timePlaced: string, lastUpdated: string, time?: Maybe<string>, storeId: number, termId: number, subTotal: number, taxAmount: number, total: number, discrepancy?: Maybe<number>, pointsEarned: number, guestEmail?: Maybe<string>, pickupName: string, pickupLocation: PercPickupLocation, phone?: Maybe<string>, status: { __typename?: 'OrderStatus', orderId: string, status: OrderStatusEnum, details?: Maybe<string>, time: any }, statuses: Array<(
      { __typename?: 'OrderStatus' }
      & OrderStatusFieldsFragment
    )>, profile?: Maybe<(
      { __typename?: 'Profile' }
      & ProfileFieldsFragment
    )>, car?: Maybe<{ __typename?: 'Car', make: string, model: string, color: string, year?: Maybe<number> }>, items: Array<{ __typename?: 'OrderItem', id: string, price: number, quantity: number, taxAmount: number, modifiers: Array<{ __typename?: 'OrderModifier', id: string, price: number, quantity: number, modifier: { __typename?: 'Modifier', id: string, name?: Maybe<string> } }>, item: { __typename?: 'Item', id: string, size?: Maybe<{ __typename?: 'Size', id: string, name: string }>, product?: Maybe<{ __typename?: 'Product', id: string, name: string }> } }>, creditCardPayments: Array<(
      { __typename?: 'OrderCreditCardPayment' }
      & OrderCreditCardPaymentFieldsFragment
    )>, giftCardPayments: Array<{ __typename?: 'OrderGiftCardPayment', id: string, cardId: number, cardNumber: string, amount: number }>, discounts: Array<{ __typename?: 'OrderDiscount', id: string, discountId: number }> } };

export type PendingOrderIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type PendingOrderIdsQuery = { __typename?: 'Query', pendingOrderIds: Array<string> };

export type ReturnReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReturnReasonsQuery = { __typename?: 'Query', returnReasons: Array<(
    { __typename?: 'ReturnReason' }
    & ReturnReasonFieldsFragment
  )> };

export type StoreQueryVariables = Exact<{
  storeId: Scalars['Int'];
}>;


export type StoreQuery = { __typename?: 'Query', store: (
    { __typename?: 'Store', settings: Array<{ __typename?: 'Config', storeId: number, termId: number, setting: string, value: string, type: { __typename?: 'ConfigType', type: ConfigTypeEnum, metadata: Array<{ __typename?: 'ConfigTypeMetadata', id: number, key: string, value: string }> } }> }
    & StoreFieldsFragment
  ) };

export type StoreTerminalsQueryVariables = Exact<{ [key: string]: never; }>;


export type StoreTerminalsQuery = { __typename?: 'Query', session: { __typename?: 'Session', store?: Maybe<{ __typename?: 'Store', terminals: Array<{ __typename?: 'Terminal', termId: number, status: TerminalStatus }> }> } };

export type StripeQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type StripeQuery = { __typename?: 'Query', session: { __typename?: 'Session', employee?: Maybe<{ __typename?: 'Employee', stores: Array<(
        { __typename?: 'Store', stripeAccount?: Maybe<{ __typename?: 'StripeAccount', id: string, accountId: string, storeId?: Maybe<number>, chargesEnabled: boolean, onboardingUrl?: Maybe<string>, loginUrl?: Maybe<string> }> }
        & StoreFieldsFragment
      )> }> } };

export type DisputeOrderFieldsFragment = { __typename?: 'Order', id: string, storeId: number, type: SessionType, timePlaced: string, subTotal: number, taxAmount: number, total: number, pickupName: string, guestEmail?: Maybe<string>, profileId?: Maybe<number>, profile?: Maybe<{ __typename?: 'Profile', email?: Maybe<string> }>, status: { __typename?: 'OrderStatus', status: OrderStatusEnum } };

export type DisputeFieldFragment = { __typename?: 'StripeDispute', id: string, amount: number, chargeId: string, reason: string, status: string, created: string, currency: string, charge?: Maybe<{ __typename?: 'StripeCharge', payment?: Maybe<{ __typename?: 'OrderCreditCardPayment', order: (
        { __typename?: 'Order' }
        & DisputeOrderFieldsFragment
      ) }> }> };

export type StripeDisputesQueryVariables = Exact<{
  storeId: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  endingBefore?: Maybe<Scalars['String']>;
  startingAfter?: Maybe<Scalars['String']>;
}>;


export type StripeDisputesQuery = { __typename?: 'Query', store: { __typename?: 'Store', storeId: number, disputes: Array<(
      { __typename?: 'StripeDispute' }
      & DisputeFieldFragment
    )> } };

export type DisputeFieldsFragment = { __typename?: 'StripeDispute', id: string, amount: number, reason: string, status: string, created: string, currency: string, isChargeRefundable: boolean };

export type CreditCardPaymentsFieldFragment = (
  { __typename?: 'OrderCreditCardPayment', charge: { __typename?: 'StripeCharge', id: string, amount: number, description?: Maybe<string>, disputeId?: Maybe<string>, disputed: boolean, paymentMethodId: string, refunded: boolean, status: string, fee?: Maybe<number>, net?: Maybe<number>, payment?: Maybe<{ __typename?: 'OrderCreditCardPayment', id: string, chargeId: string, amount: number, identifier?: Maybe<string>, cardType?: Maybe<string>, lastFour?: Maybe<number> }>, dispute?: Maybe<(
      { __typename?: 'StripeDispute' }
      & DisputeFieldsFragment
    )>, transfer?: Maybe<{ __typename?: 'StripeTransfer', id: string, amount?: Maybe<number>, group: string, destination: { __typename?: 'StripeAccount', id: string, accountId: string, chargesEnabled: boolean } }> } }
  & OrderCreditCardPaymentFieldsFragment
);

export type OrderFieldFragment = { __typename?: 'Order', id: string, timePlaced: string, time?: Maybe<string>, lastUpdated: string, cartId: string, storeId: number, termId: number, profileId?: Maybe<number>, employeeId?: Maybe<string>, subTotal: number, taxAmount: number, total: number, guestEmail?: Maybe<string>, pickupName: string, status: { __typename?: 'OrderStatus', status: OrderStatusEnum }, profile?: Maybe<{ __typename?: 'Profile', email?: Maybe<string> }>, employee?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeFieldsFragment
  )>, items: Array<(
    { __typename?: 'OrderItem' }
    & OrderItemFieldsFragment
  )>, creditCardPayments: Array<(
    { __typename?: 'OrderCreditCardPayment' }
    & CreditCardPaymentsFieldFragment
  )>, giftCardPayments: Array<(
    { __typename?: 'OrderGiftCardPayment' }
    & GiftCardPaymentsFieldFragment
  )>, refunds: Array<(
    { __typename?: 'Refund' }
    & RefundFieldsFragment
  )> };

export type StripeOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StripeOrderQuery = { __typename?: 'Query', order: (
    { __typename?: 'Order' }
    & OrderFieldFragment
  ) };

export type OrderEdgeFieldsFragment = { __typename?: 'OrderEdge', cursor: string, node: { __typename?: 'Order', id: string, storeId: number, type: SessionType, timePlaced: string, subTotal: number, taxAmount: number, total: number, pickupName: string, guestEmail?: Maybe<string>, profileId?: Maybe<number>, profile?: Maybe<{ __typename?: 'Profile', email?: Maybe<string> }>, status: { __typename?: 'OrderStatus', status: OrderStatusEnum } } };

export type OrdersConnectionFieldsFragment = { __typename?: 'OrdersConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: Maybe<string>, endCursor?: Maybe<string> }, edges: Array<(
    { __typename?: 'OrderEdge' }
    & OrderEdgeFieldsFragment
  )> };

export type StripeOrdersQueryVariables = Exact<{
  storeId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<OrderDirection>;
  orderByMultiple?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orderDirectionMultiple?: Maybe<Array<OrderDirection> | OrderDirection>;
  useOffsetPagination?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<FilterOrderInput>;
}>;


export type StripeOrdersQuery = { __typename?: 'Query', store: { __typename?: 'Store', storeId: number, orders: (
      { __typename?: 'OrdersConnection' }
      & OrdersConnectionFieldsFragment
    ) } };

export type TerminalsQueryVariables = Exact<{ [key: string]: never; }>;


export type TerminalsQuery = { __typename?: 'Query', terminals: Array<{ __typename?: 'Terminal', connectionId?: Maybe<string>, status: TerminalStatus, termId: number, storeId: number, time: string }> };

export type WhereToShowQueryVariables = Exact<{ [key: string]: never; }>;


export type WhereToShowQuery = { __typename?: 'Query', whereToShow?: Maybe<Array<{ __typename?: 'WhereToShow', questionLocationId: number, screenname: string }>> };

export type AllOrdersSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AllOrdersSubscription = { __typename?: 'Subscription', allOrders?: Maybe<{ __typename?: 'PercOrder', id: string, timePlaced: string, employeeId?: Maybe<string>, storeId: number, termId: number, user?: Maybe<{ __typename?: 'PercUser', id: number, type: PercUserType }>, payments: Array<{ __typename: 'PercCreditCardPayment', amount: number, identifier?: Maybe<string>, cardType?: Maybe<string>, lastFour?: Maybe<number>, creditCardPaymentId: string } | { __typename: 'PercGiftCardPayment', amount: number, cardNumber: string, giftCardPaymentId: number }>, items: Array<{ __typename?: 'PercItem', id: number, quantity: number, modifiers: Array<{ __typename?: 'PercModifier', id: number, quantity: number }> }> }> };

export type CreateCardsInSeriesUpdatesSubscriptionVariables = Exact<{
  executionArn: Scalars['ID'];
}>;


export type CreateCardsInSeriesUpdatesSubscription = { __typename?: 'Subscription', createCardsInSeriesUpdates: { __typename?: 'CreateCardsInSeriesUpdate', executionArn: string, batchIndex: number, totalBatches: number, cardNumbers: Array<string>, ok: boolean, errorMessage?: Maybe<string>, errorCode?: Maybe<number> } };

export type LogsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type LogsSubscription = { __typename?: 'Subscription', logs?: Maybe<Array<Maybe<string>>> };

export type OrderStatusSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderStatusSubscription = { __typename?: 'Subscription', orderStatus?: Maybe<(
    { __typename?: 'OrderStatus' }
    & OrderStatusFieldsFragment
  )> };

export type PendingOrdersSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PendingOrdersSubscription = { __typename?: 'Subscription', newOrder: { __typename?: 'PercOrder', id: string } };

export type StoreTerminalUpdatesSubscriptionVariables = Exact<{
  storeId: Scalars['Int'];
}>;


export type StoreTerminalUpdatesSubscription = { __typename?: 'Subscription', terminals: Array<{ __typename?: 'Terminal', termId: number, status: TerminalStatus }> };

export type TerminalsUpdatesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TerminalsUpdatesSubscription = { __typename?: 'Subscription', terminals: Array<{ __typename?: 'Terminal', connectionId?: Maybe<string>, status: TerminalStatus, termId: number, storeId: number, time: string }> };

export const OrderItemFieldsFragmentDoc = gql`
    fragment orderItemFields on OrderItem {
  id
  quantity
  price
  taxAmount
  taxRate
  created
  item {
    id
    product {
      id
      name
    }
  }
  modifiers {
    id
    modifier {
      id
      name
    }
    quantity
    price
  }
}
    `;
export const CartFieldsFragmentDoc = gql`
    fragment cartFields on Cart {
  id
  items {
    ...orderItemFields
  }
}
    ${OrderItemFieldsFragmentDoc}`;
export const ItemPriceFieldsFragmentDoc = gql`
    fragment itemPriceFields on ItemPrice {
  id
  itemId
  tierId
  storeId
  price
  lastModified
  serverLastModified
}
    `;
export const ItemFieldsFragmentDoc = gql`
    fragment itemFields on Item {
  id
  product {
    id
    name
  }
  size {
    id
    name
  }
  prices {
    ...itemPriceFields
  }
}
    ${ItemPriceFieldsFragmentDoc}`;
export const LylaQuestionFieldsFragmentDoc = gql`
    fragment lylaQuestionFields on LylaQuestion {
  questionId
  question
  askFrequency
  idleTime
  startDate
  endDate
  isActive
  whereToShow
  askAgainIfSkipped
  lastModified
}
    `;
export const ModifierPriceFieldsFragmentDoc = gql`
    fragment modifierPriceFields on ModifierPrice {
  id
  modifierId
  tierId
  storeId
  price
  lastModified
  serverLastModified
}
    `;
export const ModifierFieldsFragmentDoc = gql`
    fragment modifierFields on Modifier {
  id
  name
  prices {
    ...modifierPriceFields
  }
}
    ${ModifierPriceFieldsFragmentDoc}`;
export const OrderGiftCardPaymentFieldsFragmentDoc = gql`
    fragment orderGiftCardPaymentFields on OrderGiftCardPayment {
  id
  cardId
  cardNumber
  amount
}
    `;
export const OrderStatusFieldsFragmentDoc = gql`
    fragment orderStatusFields on OrderStatus {
  id
  orderId
  status
  details
  time
}
    `;
export const PersonFieldsFragmentDoc = gql`
    fragment personFields on Person {
  id
  firstName
  lastName
  emailAddress
  emailAddress2
  address1
  address2
  city
  state
  zipCode
}
    `;
export const ProductFieldsFragmentDoc = gql`
    fragment productFields on Product {
  id
  name
  image
  autoModify
}
    `;
export const ProductGroupFieldsFragmentDoc = gql`
    fragment productGroupFields on ProductGroup {
  id
  isVisible
  priority
  products {
    ...productFields
  }
}
    ${ProductFieldsFragmentDoc}`;
export const EmployeeFieldsFragmentDoc = gql`
    fragment employeeFields on Employee {
  id
  firstName
  lastName
  roles {
    id
    roleId
    role {
      id
      name
    }
  }
}
    `;
export const ProfileFieldsFragmentDoc = gql`
    fragment profileFields on Profile {
  id
  email
  firstName
  lastName
  cash
  unit
  street
  city
  state
  postal_code
  phone
  birth_date
  gender
  birthdayPromotionMedium
  ewardSubscription
  ewardSignupDate
  lastModified
}
    `;
export const StoreFieldsFragmentDoc = gql`
    fragment storeFields on Store {
  storeId
  phone
  driveThru
  monThruThursOpen
  monThruThursClose
  friOpen
  friClose
  satOpen
  satClose
  sunOpen
  sunClose
  address1
  address2
  city
  state
  zipCode
  location
  latitude
  longitude
  wiFi
  twitterUrl
  facebookUrl
  distance
  priceTierId
}
    `;
export const OrderCreditCardPaymentFieldsFragmentDoc = gql`
    fragment orderCreditCardPaymentFields on OrderCreditCardPayment {
  id
  chargeId
  amount
  identifier
  cardType
  lastFour
}
    `;
export const ReturnReasonFieldsFragmentDoc = gql`
    fragment returnReasonFields on ReturnReason {
  id
  returnReasonId
  name
  returnToInventory
  isVisiblePerc
  isVisibleOnlineOrdering
}
    `;
export const GiftCardPaymentsFieldFragmentDoc = gql`
    fragment giftCardPaymentsField on OrderGiftCardPayment {
  id
  cardId
  cardNumber
  amount
}
    `;
export const RefundDestinationFieldsFragmentDoc = gql`
    fragment refundDestinationFields on RefundDestination {
  id
  refundDestinationId
  amount
  refundId
  paymentId
  payment {
    ... on OrderCreditCardPayment {
      ...orderCreditCardPaymentFields
    }
    ... on OrderGiftCardPayment {
      ...giftCardPaymentsField
    }
  }
}
    ${OrderCreditCardPaymentFieldsFragmentDoc}
${GiftCardPaymentsFieldFragmentDoc}`;
export const RefundFieldsFragmentDoc = gql`
    fragment refundFields on Refund {
  id
  refundId
  orderId
  returnReasonId
  returnReason {
    ...returnReasonFields
  }
  items {
    ...orderItemFields
  }
  destinations {
    ...refundDestinationFields
  }
  created
}
    ${ReturnReasonFieldsFragmentDoc}
${OrderItemFieldsFragmentDoc}
${RefundDestinationFieldsFragmentDoc}`;
export const OrderFieldsFragmentDoc = gql`
    fragment orderFields on Order {
  id
  status {
    id
    orderId
    status
    details
    time
  }
  timePlaced
  lastUpdated
  cartId
  storeId
  termId
  profileId
  employeeId
  guestEmail
  pickupName
  pickupLocation
  car {
    make
    model
    color
    year
  }
  session {
    cart {
      id
      items {
        ...orderItemFields
      }
    }
  }
  store {
    ...storeFields
  }
  items {
    ...orderItemFields
  }
  creditCardPayments {
    ...orderCreditCardPaymentFields
  }
  giftCardPayments {
    id
    cardId
    cardNumber
    amount
  }
  refunds {
    ...refundFields
  }
}
    ${OrderItemFieldsFragmentDoc}
${StoreFieldsFragmentDoc}
${OrderCreditCardPaymentFieldsFragmentDoc}
${RefundFieldsFragmentDoc}`;
export const SessionFieldsFragmentDoc = gql`
    fragment sessionFields on Session {
  type
  authenticated
  employee {
    ...employeeFields
  }
  customer {
    id
    profile {
      ...profileFields
    }
  }
  profile {
    ...profileFields
  }
  store {
    ...storeFields
    ...storeFields
  }
  store {
    ...storeFields
  }
  cart {
    id
    items {
      ...orderItemFields
    }
  }
  order {
    ...orderFields
  }
}
    ${EmployeeFieldsFragmentDoc}
${ProfileFieldsFragmentDoc}
${StoreFieldsFragmentDoc}
${OrderItemFieldsFragmentDoc}
${OrderFieldsFragmentDoc}`;
export const SizeFieldsFragmentDoc = gql`
    fragment sizeFields on Size {
  id
  name
  priority
  itemName
  itemNamePriority
}
    `;
export const StoreStripeFieldsFragmentDoc = gql`
    fragment storeStripeFields on Store {
  ...storeFields
  stripeAccount {
    id
    accountId
    storeId
    chargesEnabled
    onboardingUrl
    loginUrl
  }
}
    ${StoreFieldsFragmentDoc}`;
export const ConfigFieldsFragmentDoc = gql`
    fragment configFields on ConfigTable {
  storeId
  termId
  setting
  value
}
    `;
export const TablesFieldsFragmentDoc = gql`
    fragment tablesFields on Tables {
  itemPrices
  modifierPrices
  config {
    ...configFields
  }
}
    ${ConfigFieldsFragmentDoc}`;
export const BrandStandardEdgeFieldsFragmentDoc = gql`
    fragment brandStandardEdgeFields on BrandStandardEdge {
  cursor
  node {
    id
    brandStandardId
    description
    resolution
  }
}
    `;
export const BrandStandardsConnectionFieldsFragmentDoc = gql`
    fragment brandStandardsConnectionFields on BrandStandardsConnection {
  pageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
  edges {
    ...brandStandardEdgeFields
  }
  totalCount
}
    ${BrandStandardEdgeFieldsFragmentDoc}`;
export const DefaultNoticeEdgeFieldsFragmentDoc = gql`
    fragment defaultNoticeEdgeFields on DefaultNoticeEdge {
  cursor
  node {
    id
    description
    defaultDate
    notificationMethod
    brandStandardViolated
    brandStandard {
      brandStandardId
      description
    }
    reportedBy
    resolved
    resolutionDate
    storeId
    store {
      storeId
    }
  }
}
    `;
export const DefaultNoticesConnectionFieldsFragmentDoc = gql`
    fragment defaultNoticesConnectionFields on DefaultNoticesConnection {
  pageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
  edges {
    ...defaultNoticeEdgeFields
  }
  totalCount
}
    ${DefaultNoticeEdgeFieldsFragmentDoc}`;
export const EmployeeStoresFieldsFragmentDoc = gql`
    fragment employeeStoresFields on Store {
  storeId
  priceTierId
  oLOEnabled
}
    `;
export const DisputeOrderFieldsFragmentDoc = gql`
    fragment disputeOrderFields on Order {
  id
  storeId
  type
  timePlaced
  subTotal
  taxAmount
  total
  pickupName
  guestEmail
  profileId
  profile {
    email
  }
  status {
    status
  }
}
    `;
export const DisputeFieldFragmentDoc = gql`
    fragment disputeField on StripeDispute {
  id
  amount
  chargeId
  charge {
    payment {
      order {
        ...disputeOrderFields
      }
    }
  }
  reason
  status
  created
  currency
}
    ${DisputeOrderFieldsFragmentDoc}`;
export const DisputeFieldsFragmentDoc = gql`
    fragment disputeFields on StripeDispute {
  id
  amount
  reason
  status
  created
  currency
  isChargeRefundable
}
    `;
export const CreditCardPaymentsFieldFragmentDoc = gql`
    fragment creditCardPaymentsField on OrderCreditCardPayment {
  ...orderCreditCardPaymentFields
  charge {
    id
    payment {
      id
      chargeId
      amount
      identifier
      cardType
      lastFour
    }
    amount
    description
    disputeId
    dispute {
      ...disputeFields
    }
    disputed
    paymentMethodId
    refunded
    status
    transfer {
      id
      amount
      destination {
        id
        accountId
        chargesEnabled
      }
      group
    }
    fee
    net
  }
}
    ${OrderCreditCardPaymentFieldsFragmentDoc}
${DisputeFieldsFragmentDoc}`;
export const OrderFieldFragmentDoc = gql`
    fragment orderField on Order {
  id
  timePlaced
  time
  status {
    status
  }
  lastUpdated
  cartId
  storeId
  termId
  profileId
  employeeId
  subTotal
  taxAmount
  total
  profile {
    email
  }
  employee {
    ...employeeFields
  }
  guestEmail
  pickupName
  items {
    ...orderItemFields
  }
  creditCardPayments {
    ...creditCardPaymentsField
  }
  giftCardPayments {
    ...giftCardPaymentsField
  }
  refunds {
    ...refundFields
  }
}
    ${EmployeeFieldsFragmentDoc}
${OrderItemFieldsFragmentDoc}
${CreditCardPaymentsFieldFragmentDoc}
${GiftCardPaymentsFieldFragmentDoc}
${RefundFieldsFragmentDoc}`;
export const OrderEdgeFieldsFragmentDoc = gql`
    fragment orderEdgeFields on OrderEdge {
  cursor
  node {
    id
    storeId
    type
    timePlaced
    subTotal
    taxAmount
    total
    pickupName
    guestEmail
    profileId
    profile {
      email
    }
    status {
      status
    }
  }
}
    `;
export const OrdersConnectionFieldsFragmentDoc = gql`
    fragment ordersConnectionFields on OrdersConnection {
  totalCount
  pageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
  edges {
    ...orderEdgeFields
  }
}
    ${OrderEdgeFieldsFragmentDoc}`;
export const AcceptDisputeDocument = gql`
    mutation AcceptDispute($disputeId: String!) {
  acceptDispute(disputeId: $disputeId) {
    id
    amount
    chargeId
    charge {
      id
    }
    reason
    status
    created
    currency
  }
}
    `;
export type AcceptDisputeMutationFn = ApolloReactCommon.MutationFunction<AcceptDisputeMutation, AcceptDisputeMutationVariables>;

/**
 * __useAcceptDisputeMutation__
 *
 * To run a mutation, you first call `useAcceptDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptDisputeMutation, { data, loading, error }] = useAcceptDisputeMutation({
 *   variables: {
 *      disputeId: // value for 'disputeId'
 *   },
 * });
 */
export function useAcceptDisputeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptDisputeMutation, AcceptDisputeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AcceptDisputeMutation, AcceptDisputeMutationVariables>(AcceptDisputeDocument, options);
      }
export type AcceptDisputeMutationHookResult = ReturnType<typeof useAcceptDisputeMutation>;
export type AcceptDisputeMutationResult = ApolloReactCommon.MutationResult<AcceptDisputeMutation>;
export type AcceptDisputeMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptDisputeMutation, AcceptDisputeMutationVariables>;
export const AddCartItemDocument = gql`
    mutation AddCartItem($item: OrderItemInput!) {
  addCartItem(item: $item) {
    id
    items {
      ...orderItemFields
    }
  }
}
    ${OrderItemFieldsFragmentDoc}`;
export type AddCartItemMutationFn = ApolloReactCommon.MutationFunction<AddCartItemMutation, AddCartItemMutationVariables>;

/**
 * __useAddCartItemMutation__
 *
 * To run a mutation, you first call `useAddCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCartItemMutation, { data, loading, error }] = useAddCartItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useAddCartItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCartItemMutation, AddCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddCartItemMutation, AddCartItemMutationVariables>(AddCartItemDocument, options);
      }
export type AddCartItemMutationHookResult = ReturnType<typeof useAddCartItemMutation>;
export type AddCartItemMutationResult = ApolloReactCommon.MutationResult<AddCartItemMutation>;
export type AddCartItemMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCartItemMutation, AddCartItemMutationVariables>;
export const AddLylaQuestionDocument = gql`
    mutation AddLylaQuestion($question: LylaQuestionInput!) {
  addLylaQuestion(question: $question) {
    ...lylaQuestionFields
  }
}
    ${LylaQuestionFieldsFragmentDoc}`;
export type AddLylaQuestionMutationFn = ApolloReactCommon.MutationFunction<AddLylaQuestionMutation, AddLylaQuestionMutationVariables>;

/**
 * __useAddLylaQuestionMutation__
 *
 * To run a mutation, you first call `useAddLylaQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLylaQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLylaQuestionMutation, { data, loading, error }] = useAddLylaQuestionMutation({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useAddLylaQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddLylaQuestionMutation, AddLylaQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddLylaQuestionMutation, AddLylaQuestionMutationVariables>(AddLylaQuestionDocument, options);
      }
export type AddLylaQuestionMutationHookResult = ReturnType<typeof useAddLylaQuestionMutation>;
export type AddLylaQuestionMutationResult = ApolloReactCommon.MutationResult<AddLylaQuestionMutation>;
export type AddLylaQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddLylaQuestionMutation, AddLylaQuestionMutationVariables>;
export const AuthenticateCustomerDocument = gql`
    mutation AuthenticateCustomer($credentials: AuthenticateCustomerInput!) {
  authenticateCustomer(credentials: $credentials) {
    id
    profile {
      ...profileFields
    }
  }
}
    ${ProfileFieldsFragmentDoc}`;
export type AuthenticateCustomerMutationFn = ApolloReactCommon.MutationFunction<AuthenticateCustomerMutation, AuthenticateCustomerMutationVariables>;

/**
 * __useAuthenticateCustomerMutation__
 *
 * To run a mutation, you first call `useAuthenticateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateCustomerMutation, { data, loading, error }] = useAuthenticateCustomerMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useAuthenticateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthenticateCustomerMutation, AuthenticateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AuthenticateCustomerMutation, AuthenticateCustomerMutationVariables>(AuthenticateCustomerDocument, options);
      }
export type AuthenticateCustomerMutationHookResult = ReturnType<typeof useAuthenticateCustomerMutation>;
export type AuthenticateCustomerMutationResult = ApolloReactCommon.MutationResult<AuthenticateCustomerMutation>;
export type AuthenticateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthenticateCustomerMutation, AuthenticateCustomerMutationVariables>;
export const AuthenticateEmployeeDocument = gql`
    mutation AuthenticateEmployee($credentials: AuthenticateEmployeeInput!) {
  authenticateEmployee(credentials: $credentials) {
    ...employeeFields
  }
}
    ${EmployeeFieldsFragmentDoc}`;
export type AuthenticateEmployeeMutationFn = ApolloReactCommon.MutationFunction<AuthenticateEmployeeMutation, AuthenticateEmployeeMutationVariables>;

/**
 * __useAuthenticateEmployeeMutation__
 *
 * To run a mutation, you first call `useAuthenticateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateEmployeeMutation, { data, loading, error }] = useAuthenticateEmployeeMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useAuthenticateEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthenticateEmployeeMutation, AuthenticateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AuthenticateEmployeeMutation, AuthenticateEmployeeMutationVariables>(AuthenticateEmployeeDocument, options);
      }
export type AuthenticateEmployeeMutationHookResult = ReturnType<typeof useAuthenticateEmployeeMutation>;
export type AuthenticateEmployeeMutationResult = ApolloReactCommon.MutationResult<AuthenticateEmployeeMutation>;
export type AuthenticateEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthenticateEmployeeMutation, AuthenticateEmployeeMutationVariables>;
export const AuthenticateQrCodeDocument = gql`
    mutation AuthenticateQRCode($qrcode: String!) {
  authenticateQRCode(qrcode: $qrcode) {
    ...sessionFields
  }
}
    ${SessionFieldsFragmentDoc}`;
export type AuthenticateQrCodeMutationFn = ApolloReactCommon.MutationFunction<AuthenticateQrCodeMutation, AuthenticateQrCodeMutationVariables>;

/**
 * __useAuthenticateQrCodeMutation__
 *
 * To run a mutation, you first call `useAuthenticateQrCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateQrCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateQrCodeMutation, { data, loading, error }] = useAuthenticateQrCodeMutation({
 *   variables: {
 *      qrcode: // value for 'qrcode'
 *   },
 * });
 */
export function useAuthenticateQrCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthenticateQrCodeMutation, AuthenticateQrCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AuthenticateQrCodeMutation, AuthenticateQrCodeMutationVariables>(AuthenticateQrCodeDocument, options);
      }
export type AuthenticateQrCodeMutationHookResult = ReturnType<typeof useAuthenticateQrCodeMutation>;
export type AuthenticateQrCodeMutationResult = ApolloReactCommon.MutationResult<AuthenticateQrCodeMutation>;
export type AuthenticateQrCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthenticateQrCodeMutation, AuthenticateQrCodeMutationVariables>;
export const CreateBrandStandardDocument = gql`
    mutation CreateBrandStandard($brandStandard: BrandStandardInput!) {
  createBrandStandard(brandStandard: $brandStandard) {
    id
    brandStandardId
    description
    resolution
  }
}
    `;
export type CreateBrandStandardMutationFn = ApolloReactCommon.MutationFunction<CreateBrandStandardMutation, CreateBrandStandardMutationVariables>;

/**
 * __useCreateBrandStandardMutation__
 *
 * To run a mutation, you first call `useCreateBrandStandardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandStandardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandStandardMutation, { data, loading, error }] = useCreateBrandStandardMutation({
 *   variables: {
 *      brandStandard: // value for 'brandStandard'
 *   },
 * });
 */
export function useCreateBrandStandardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBrandStandardMutation, CreateBrandStandardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateBrandStandardMutation, CreateBrandStandardMutationVariables>(CreateBrandStandardDocument, options);
      }
export type CreateBrandStandardMutationHookResult = ReturnType<typeof useCreateBrandStandardMutation>;
export type CreateBrandStandardMutationResult = ApolloReactCommon.MutationResult<CreateBrandStandardMutation>;
export type CreateBrandStandardMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBrandStandardMutation, CreateBrandStandardMutationVariables>;
export const CreateCardsInSeriesDocument = gql`
    mutation CreateCardsInSeries($data: CreateCardsInSeriesInput!) {
  createCardsInSeries(data: $data)
}
    `;
export type CreateCardsInSeriesMutationFn = ApolloReactCommon.MutationFunction<CreateCardsInSeriesMutation, CreateCardsInSeriesMutationVariables>;

/**
 * __useCreateCardsInSeriesMutation__
 *
 * To run a mutation, you first call `useCreateCardsInSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardsInSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardsInSeriesMutation, { data, loading, error }] = useCreateCardsInSeriesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCardsInSeriesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCardsInSeriesMutation, CreateCardsInSeriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCardsInSeriesMutation, CreateCardsInSeriesMutationVariables>(CreateCardsInSeriesDocument, options);
      }
export type CreateCardsInSeriesMutationHookResult = ReturnType<typeof useCreateCardsInSeriesMutation>;
export type CreateCardsInSeriesMutationResult = ApolloReactCommon.MutationResult<CreateCardsInSeriesMutation>;
export type CreateCardsInSeriesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCardsInSeriesMutation, CreateCardsInSeriesMutationVariables>;
export const CreateDefaultNoticeDocument = gql`
    mutation CreateDefaultNotice($defaultNotice: DefaultNoticeInput!) {
  createDefaultNotice(defaultNotice: $defaultNotice) {
    id
    description
    defaultDate
    notificationMethod
    brandStandardViolated
    reportedBy
    resolved
    resolutionDate
    storeId
  }
}
    `;
export type CreateDefaultNoticeMutationFn = ApolloReactCommon.MutationFunction<CreateDefaultNoticeMutation, CreateDefaultNoticeMutationVariables>;

/**
 * __useCreateDefaultNoticeMutation__
 *
 * To run a mutation, you first call `useCreateDefaultNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefaultNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefaultNoticeMutation, { data, loading, error }] = useCreateDefaultNoticeMutation({
 *   variables: {
 *      defaultNotice: // value for 'defaultNotice'
 *   },
 * });
 */
export function useCreateDefaultNoticeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDefaultNoticeMutation, CreateDefaultNoticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateDefaultNoticeMutation, CreateDefaultNoticeMutationVariables>(CreateDefaultNoticeDocument, options);
      }
export type CreateDefaultNoticeMutationHookResult = ReturnType<typeof useCreateDefaultNoticeMutation>;
export type CreateDefaultNoticeMutationResult = ApolloReactCommon.MutationResult<CreateDefaultNoticeMutation>;
export type CreateDefaultNoticeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDefaultNoticeMutation, CreateDefaultNoticeMutationVariables>;
export const CreateEwardUserDocument = gql`
    mutation CreateEwardUser($ewardInput: EwardInput!) {
  createEwardUser(ewardInput: $ewardInput)
}
    `;
export type CreateEwardUserMutationFn = ApolloReactCommon.MutationFunction<CreateEwardUserMutation, CreateEwardUserMutationVariables>;

/**
 * __useCreateEwardUserMutation__
 *
 * To run a mutation, you first call `useCreateEwardUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEwardUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEwardUserMutation, { data, loading, error }] = useCreateEwardUserMutation({
 *   variables: {
 *      ewardInput: // value for 'ewardInput'
 *   },
 * });
 */
export function useCreateEwardUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEwardUserMutation, CreateEwardUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateEwardUserMutation, CreateEwardUserMutationVariables>(CreateEwardUserDocument, options);
      }
export type CreateEwardUserMutationHookResult = ReturnType<typeof useCreateEwardUserMutation>;
export type CreateEwardUserMutationResult = ApolloReactCommon.MutationResult<CreateEwardUserMutation>;
export type CreateEwardUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEwardUserMutation, CreateEwardUserMutationVariables>;
export const DeleteBrandStandardDocument = gql`
    mutation DeleteBrandStandard($id: ID!) {
  deleteBrandStandard(id: $id)
}
    `;
export type DeleteBrandStandardMutationFn = ApolloReactCommon.MutationFunction<DeleteBrandStandardMutation, DeleteBrandStandardMutationVariables>;

/**
 * __useDeleteBrandStandardMutation__
 *
 * To run a mutation, you first call `useDeleteBrandStandardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandStandardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandStandardMutation, { data, loading, error }] = useDeleteBrandStandardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBrandStandardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBrandStandardMutation, DeleteBrandStandardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteBrandStandardMutation, DeleteBrandStandardMutationVariables>(DeleteBrandStandardDocument, options);
      }
export type DeleteBrandStandardMutationHookResult = ReturnType<typeof useDeleteBrandStandardMutation>;
export type DeleteBrandStandardMutationResult = ApolloReactCommon.MutationResult<DeleteBrandStandardMutation>;
export type DeleteBrandStandardMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBrandStandardMutation, DeleteBrandStandardMutationVariables>;
export const DeleteCartItemDocument = gql`
    mutation DeleteCartItem($id: ID!) {
  deleteCartItem(id: $id) {
    id
    items {
      ...orderItemFields
    }
  }
}
    ${OrderItemFieldsFragmentDoc}`;
export type DeleteCartItemMutationFn = ApolloReactCommon.MutationFunction<DeleteCartItemMutation, DeleteCartItemMutationVariables>;

/**
 * __useDeleteCartItemMutation__
 *
 * To run a mutation, you first call `useDeleteCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCartItemMutation, { data, loading, error }] = useDeleteCartItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCartItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCartItemMutation, DeleteCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteCartItemMutation, DeleteCartItemMutationVariables>(DeleteCartItemDocument, options);
      }
export type DeleteCartItemMutationHookResult = ReturnType<typeof useDeleteCartItemMutation>;
export type DeleteCartItemMutationResult = ApolloReactCommon.MutationResult<DeleteCartItemMutation>;
export type DeleteCartItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCartItemMutation, DeleteCartItemMutationVariables>;
export const DeleteDefaultNoticeDocument = gql`
    mutation DeleteDefaultNotice($id: ID!) {
  deleteDefaultNotice(id: $id)
}
    `;
export type DeleteDefaultNoticeMutationFn = ApolloReactCommon.MutationFunction<DeleteDefaultNoticeMutation, DeleteDefaultNoticeMutationVariables>;

/**
 * __useDeleteDefaultNoticeMutation__
 *
 * To run a mutation, you first call `useDeleteDefaultNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDefaultNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDefaultNoticeMutation, { data, loading, error }] = useDeleteDefaultNoticeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDefaultNoticeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDefaultNoticeMutation, DeleteDefaultNoticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteDefaultNoticeMutation, DeleteDefaultNoticeMutationVariables>(DeleteDefaultNoticeDocument, options);
      }
export type DeleteDefaultNoticeMutationHookResult = ReturnType<typeof useDeleteDefaultNoticeMutation>;
export type DeleteDefaultNoticeMutationResult = ApolloReactCommon.MutationResult<DeleteDefaultNoticeMutation>;
export type DeleteDefaultNoticeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDefaultNoticeMutation, DeleteDefaultNoticeMutationVariables>;
export const DeleteLylaQuestionDocument = gql`
    mutation DeleteLylaQuestion($id: ID!) {
  deleteLylaQuestion(id: $id)
}
    `;
export type DeleteLylaQuestionMutationFn = ApolloReactCommon.MutationFunction<DeleteLylaQuestionMutation, DeleteLylaQuestionMutationVariables>;

/**
 * __useDeleteLylaQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteLylaQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLylaQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLylaQuestionMutation, { data, loading, error }] = useDeleteLylaQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLylaQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLylaQuestionMutation, DeleteLylaQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteLylaQuestionMutation, DeleteLylaQuestionMutationVariables>(DeleteLylaQuestionDocument, options);
      }
export type DeleteLylaQuestionMutationHookResult = ReturnType<typeof useDeleteLylaQuestionMutation>;
export type DeleteLylaQuestionMutationResult = ApolloReactCommon.MutationResult<DeleteLylaQuestionMutation>;
export type DeleteLylaQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLylaQuestionMutation, DeleteLylaQuestionMutationVariables>;
export const EditBrandStandardDocument = gql`
    mutation EditBrandStandard($brandStandard: EditBrandStandardInput!) {
  editBrandStandard(brandStandard: $brandStandard) {
    brandStandardId
    description
    resolution
  }
}
    `;
export type EditBrandStandardMutationFn = ApolloReactCommon.MutationFunction<EditBrandStandardMutation, EditBrandStandardMutationVariables>;

/**
 * __useEditBrandStandardMutation__
 *
 * To run a mutation, you first call `useEditBrandStandardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBrandStandardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBrandStandardMutation, { data, loading, error }] = useEditBrandStandardMutation({
 *   variables: {
 *      brandStandard: // value for 'brandStandard'
 *   },
 * });
 */
export function useEditBrandStandardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditBrandStandardMutation, EditBrandStandardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditBrandStandardMutation, EditBrandStandardMutationVariables>(EditBrandStandardDocument, options);
      }
export type EditBrandStandardMutationHookResult = ReturnType<typeof useEditBrandStandardMutation>;
export type EditBrandStandardMutationResult = ApolloReactCommon.MutationResult<EditBrandStandardMutation>;
export type EditBrandStandardMutationOptions = ApolloReactCommon.BaseMutationOptions<EditBrandStandardMutation, EditBrandStandardMutationVariables>;
export const EditDefaultNoticeDocument = gql`
    mutation EditDefaultNotice($defaultNotice: EditDefaultNoticeInput!) {
  editDefaultNotice(defaultNotice: $defaultNotice) {
    id
    description
    defaultDate
    notificationMethod
    brandStandardViolated
    reportedBy
    resolved
    resolutionDate
    storeId
  }
}
    `;
export type EditDefaultNoticeMutationFn = ApolloReactCommon.MutationFunction<EditDefaultNoticeMutation, EditDefaultNoticeMutationVariables>;

/**
 * __useEditDefaultNoticeMutation__
 *
 * To run a mutation, you first call `useEditDefaultNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDefaultNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDefaultNoticeMutation, { data, loading, error }] = useEditDefaultNoticeMutation({
 *   variables: {
 *      defaultNotice: // value for 'defaultNotice'
 *   },
 * });
 */
export function useEditDefaultNoticeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDefaultNoticeMutation, EditDefaultNoticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditDefaultNoticeMutation, EditDefaultNoticeMutationVariables>(EditDefaultNoticeDocument, options);
      }
export type EditDefaultNoticeMutationHookResult = ReturnType<typeof useEditDefaultNoticeMutation>;
export type EditDefaultNoticeMutationResult = ApolloReactCommon.MutationResult<EditDefaultNoticeMutation>;
export type EditDefaultNoticeMutationOptions = ApolloReactCommon.BaseMutationOptions<EditDefaultNoticeMutation, EditDefaultNoticeMutationVariables>;
export const EditLylaQuestionDocument = gql`
    mutation EditLylaQuestion($question: LylaQuestionInput!) {
  editLylaQuestion(question: $question) {
    questionId
  }
}
    `;
export type EditLylaQuestionMutationFn = ApolloReactCommon.MutationFunction<EditLylaQuestionMutation, EditLylaQuestionMutationVariables>;

/**
 * __useEditLylaQuestionMutation__
 *
 * To run a mutation, you first call `useEditLylaQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLylaQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLylaQuestionMutation, { data, loading, error }] = useEditLylaQuestionMutation({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useEditLylaQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditLylaQuestionMutation, EditLylaQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditLylaQuestionMutation, EditLylaQuestionMutationVariables>(EditLylaQuestionDocument, options);
      }
export type EditLylaQuestionMutationHookResult = ReturnType<typeof useEditLylaQuestionMutation>;
export type EditLylaQuestionMutationResult = ApolloReactCommon.MutationResult<EditLylaQuestionMutation>;
export type EditLylaQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<EditLylaQuestionMutation, EditLylaQuestionMutationVariables>;
export const EditStoreSettingsDocument = gql`
    mutation EditStoreSettings($settings: [ConfigInput!]!) {
  editStoreSettings(settings: $settings) {
    setting
  }
}
    `;
export type EditStoreSettingsMutationFn = ApolloReactCommon.MutationFunction<EditStoreSettingsMutation, EditStoreSettingsMutationVariables>;

/**
 * __useEditStoreSettingsMutation__
 *
 * To run a mutation, you first call `useEditStoreSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStoreSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStoreSettingsMutation, { data, loading, error }] = useEditStoreSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useEditStoreSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditStoreSettingsMutation, EditStoreSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditStoreSettingsMutation, EditStoreSettingsMutationVariables>(EditStoreSettingsDocument, options);
      }
export type EditStoreSettingsMutationHookResult = ReturnType<typeof useEditStoreSettingsMutation>;
export type EditStoreSettingsMutationResult = ApolloReactCommon.MutationResult<EditStoreSettingsMutation>;
export type EditStoreSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditStoreSettingsMutation, EditStoreSettingsMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const OrderCompleteDocument = gql`
    mutation OrderComplete($id: ID!, $forced: Boolean) {
  orderComplete(id: $id, forced: $forced) {
    success
    error
  }
}
    `;
export type OrderCompleteMutationFn = ApolloReactCommon.MutationFunction<OrderCompleteMutation, OrderCompleteMutationVariables>;

/**
 * __useOrderCompleteMutation__
 *
 * To run a mutation, you first call `useOrderCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCompleteMutation, { data, loading, error }] = useOrderCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      forced: // value for 'forced'
 *   },
 * });
 */
export function useOrderCompleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrderCompleteMutation, OrderCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<OrderCompleteMutation, OrderCompleteMutationVariables>(OrderCompleteDocument, options);
      }
export type OrderCompleteMutationHookResult = ReturnType<typeof useOrderCompleteMutation>;
export type OrderCompleteMutationResult = ApolloReactCommon.MutationResult<OrderCompleteMutation>;
export type OrderCompleteMutationOptions = ApolloReactCommon.BaseMutationOptions<OrderCompleteMutation, OrderCompleteMutationVariables>;
export const OrderReadyDocument = gql`
    mutation OrderReady($id: ID!) {
  orderReady(id: $id) {
    success
    error
  }
}
    `;
export type OrderReadyMutationFn = ApolloReactCommon.MutationFunction<OrderReadyMutation, OrderReadyMutationVariables>;

/**
 * __useOrderReadyMutation__
 *
 * To run a mutation, you first call `useOrderReadyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderReadyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderReadyMutation, { data, loading, error }] = useOrderReadyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderReadyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrderReadyMutation, OrderReadyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<OrderReadyMutation, OrderReadyMutationVariables>(OrderReadyDocument, options);
      }
export type OrderReadyMutationHookResult = ReturnType<typeof useOrderReadyMutation>;
export type OrderReadyMutationResult = ApolloReactCommon.MutationResult<OrderReadyMutation>;
export type OrderReadyMutationOptions = ApolloReactCommon.BaseMutationOptions<OrderReadyMutation, OrderReadyMutationVariables>;
export const RefundDocument = gql`
    mutation Refund($orderId: ID!, $returnReasonId: Int!, $orderItemIds: [ID!]!, $refundDestinations: [RefundDestinationInput!]!) {
  refund(
    orderId: $orderId
    returnReasonId: $returnReasonId
    orderItemIds: $orderItemIds
    refundDestinations: $refundDestinations
  ) {
    id
    orderId
  }
}
    `;
export type RefundMutationFn = ApolloReactCommon.MutationFunction<RefundMutation, RefundMutationVariables>;

/**
 * __useRefundMutation__
 *
 * To run a mutation, you first call `useRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundMutation, { data, loading, error }] = useRefundMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      returnReasonId: // value for 'returnReasonId'
 *      orderItemIds: // value for 'orderItemIds'
 *      refundDestinations: // value for 'refundDestinations'
 *   },
 * });
 */
export function useRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundMutation, RefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RefundMutation, RefundMutationVariables>(RefundDocument, options);
      }
export type RefundMutationHookResult = ReturnType<typeof useRefundMutation>;
export type RefundMutationResult = ApolloReactCommon.MutationResult<RefundMutation>;
export type RefundMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundMutation, RefundMutationVariables>;
export const RefundProcessedDocument = gql`
    mutation RefundProcessed($orderId: ID!, $refundId: Int!) {
  refundProcessed(orderId: $orderId, refundId: $refundId) {
    success
    error
  }
}
    `;
export type RefundProcessedMutationFn = ApolloReactCommon.MutationFunction<RefundProcessedMutation, RefundProcessedMutationVariables>;

/**
 * __useRefundProcessedMutation__
 *
 * To run a mutation, you first call `useRefundProcessedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundProcessedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundProcessedMutation, { data, loading, error }] = useRefundProcessedMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      refundId: // value for 'refundId'
 *   },
 * });
 */
export function useRefundProcessedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundProcessedMutation, RefundProcessedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RefundProcessedMutation, RefundProcessedMutationVariables>(RefundProcessedDocument, options);
      }
export type RefundProcessedMutationHookResult = ReturnType<typeof useRefundProcessedMutation>;
export type RefundProcessedMutationResult = ApolloReactCommon.MutationResult<RefundProcessedMutation>;
export type RefundProcessedMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundProcessedMutation, RefundProcessedMutationVariables>;
export const SendCustomerOrderDocument = gql`
    mutation SendCustomerOrder($order: CustomerOrderInput!) {
  sendCustomerOrder(order: $order) {
    ...orderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type SendCustomerOrderMutationFn = ApolloReactCommon.MutationFunction<SendCustomerOrderMutation, SendCustomerOrderMutationVariables>;

/**
 * __useSendCustomerOrderMutation__
 *
 * To run a mutation, you first call `useSendCustomerOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCustomerOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCustomerOrderMutation, { data, loading, error }] = useSendCustomerOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSendCustomerOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendCustomerOrderMutation, SendCustomerOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SendCustomerOrderMutation, SendCustomerOrderMutationVariables>(SendCustomerOrderDocument, options);
      }
export type SendCustomerOrderMutationHookResult = ReturnType<typeof useSendCustomerOrderMutation>;
export type SendCustomerOrderMutationResult = ApolloReactCommon.MutationResult<SendCustomerOrderMutation>;
export type SendCustomerOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<SendCustomerOrderMutation, SendCustomerOrderMutationVariables>;
export const SendEmployeeOrderDocument = gql`
    mutation SendEmployeeOrder($order: EmployeeOrderInput!) {
  sendEmployeeOrder(order: $order) {
    ...orderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type SendEmployeeOrderMutationFn = ApolloReactCommon.MutationFunction<SendEmployeeOrderMutation, SendEmployeeOrderMutationVariables>;

/**
 * __useSendEmployeeOrderMutation__
 *
 * To run a mutation, you first call `useSendEmployeeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmployeeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmployeeOrderMutation, { data, loading, error }] = useSendEmployeeOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSendEmployeeOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmployeeOrderMutation, SendEmployeeOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SendEmployeeOrderMutation, SendEmployeeOrderMutationVariables>(SendEmployeeOrderDocument, options);
      }
export type SendEmployeeOrderMutationHookResult = ReturnType<typeof useSendEmployeeOrderMutation>;
export type SendEmployeeOrderMutationResult = ApolloReactCommon.MutationResult<SendEmployeeOrderMutation>;
export type SendEmployeeOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmployeeOrderMutation, SendEmployeeOrderMutationVariables>;
export const SendOrderDocument = gql`
    mutation SendOrder($id: ID!) {
  sendOrder(id: $id) {
    success
    error
  }
}
    `;
export type SendOrderMutationFn = ApolloReactCommon.MutationFunction<SendOrderMutation, SendOrderMutationVariables>;

/**
 * __useSendOrderMutation__
 *
 * To run a mutation, you first call `useSendOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOrderMutation, { data, loading, error }] = useSendOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendOrderMutation, SendOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SendOrderMutation, SendOrderMutationVariables>(SendOrderDocument, options);
      }
export type SendOrderMutationHookResult = ReturnType<typeof useSendOrderMutation>;
export type SendOrderMutationResult = ApolloReactCommon.MutationResult<SendOrderMutation>;
export type SendOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<SendOrderMutation, SendOrderMutationVariables>;
export const SetSessionStoreIdDocument = gql`
    mutation SetSessionStoreId($storeId: Int!) {
  setSessionStoreId(storeId: $storeId) {
    cart {
      id
      items {
        ...orderItemFields
      }
    }
    store {
      ...storeFields
    }
  }
}
    ${OrderItemFieldsFragmentDoc}
${StoreFieldsFragmentDoc}`;
export type SetSessionStoreIdMutationFn = ApolloReactCommon.MutationFunction<SetSessionStoreIdMutation, SetSessionStoreIdMutationVariables>;

/**
 * __useSetSessionStoreIdMutation__
 *
 * To run a mutation, you first call `useSetSessionStoreIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSessionStoreIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSessionStoreIdMutation, { data, loading, error }] = useSetSessionStoreIdMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useSetSessionStoreIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetSessionStoreIdMutation, SetSessionStoreIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SetSessionStoreIdMutation, SetSessionStoreIdMutationVariables>(SetSessionStoreIdDocument, options);
      }
export type SetSessionStoreIdMutationHookResult = ReturnType<typeof useSetSessionStoreIdMutation>;
export type SetSessionStoreIdMutationResult = ApolloReactCommon.MutationResult<SetSessionStoreIdMutation>;
export type SetSessionStoreIdMutationOptions = ApolloReactCommon.BaseMutationOptions<SetSessionStoreIdMutation, SetSessionStoreIdMutationVariables>;
export const UpdateCartItemDocument = gql`
    mutation UpdateCartItem($item: OrderItemInput!) {
  updateCartItem(item: $item) {
    id
    items {
      ...orderItemFields
    }
  }
}
    ${OrderItemFieldsFragmentDoc}`;
export type UpdateCartItemMutationFn = ApolloReactCommon.MutationFunction<UpdateCartItemMutation, UpdateCartItemMutationVariables>;

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useUpdateCartItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCartItemMutation, UpdateCartItemMutationVariables>(UpdateCartItemDocument, options);
      }
export type UpdateCartItemMutationHookResult = ReturnType<typeof useUpdateCartItemMutation>;
export type UpdateCartItemMutationResult = ApolloReactCommon.MutationResult<UpdateCartItemMutation>;
export type UpdateCartItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>;
export const LylaQuestionsDocument = gql`
    query lylaQuestions {
  lylaQuestions {
    ...lylaQuestionFields
  }
}
    ${LylaQuestionFieldsFragmentDoc}`;

/**
 * __useLylaQuestionsQuery__
 *
 * To run a query within a React component, call `useLylaQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLylaQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLylaQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLylaQuestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LylaQuestionsQuery, LylaQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LylaQuestionsQuery, LylaQuestionsQueryVariables>(LylaQuestionsDocument, options);
      }
export function useLylaQuestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LylaQuestionsQuery, LylaQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LylaQuestionsQuery, LylaQuestionsQueryVariables>(LylaQuestionsDocument, options);
        }
export type LylaQuestionsQueryHookResult = ReturnType<typeof useLylaQuestionsQuery>;
export type LylaQuestionsLazyQueryHookResult = ReturnType<typeof useLylaQuestionsLazyQuery>;
export type LylaQuestionsQueryResult = ApolloReactCommon.QueryResult<LylaQuestionsQuery, LylaQuestionsQueryVariables>;
export const AllStoresDocument = gql`
    query AllStores {
  stores {
    storeId
    driveThru
    oLOEnabled
  }
}
    `;

/**
 * __useAllStoresQuery__
 *
 * To run a query within a React component, call `useAllStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllStoresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllStoresQuery, AllStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AllStoresQuery, AllStoresQueryVariables>(AllStoresDocument, options);
      }
export function useAllStoresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllStoresQuery, AllStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AllStoresQuery, AllStoresQueryVariables>(AllStoresDocument, options);
        }
export type AllStoresQueryHookResult = ReturnType<typeof useAllStoresQuery>;
export type AllStoresLazyQueryHookResult = ReturnType<typeof useAllStoresLazyQuery>;
export type AllStoresQueryResult = ApolloReactCommon.QueryResult<AllStoresQuery, AllStoresQueryVariables>;
export const BrandStandardDocument = gql`
    query BrandStandard($id: ID!) {
  brandStandard(id: $id) {
    id
    brandStandardId
    description
    resolution
  }
}
    `;

/**
 * __useBrandStandardQuery__
 *
 * To run a query within a React component, call `useBrandStandardQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandStandardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandStandardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrandStandardQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BrandStandardQuery, BrandStandardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BrandStandardQuery, BrandStandardQueryVariables>(BrandStandardDocument, options);
      }
export function useBrandStandardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrandStandardQuery, BrandStandardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BrandStandardQuery, BrandStandardQueryVariables>(BrandStandardDocument, options);
        }
export type BrandStandardQueryHookResult = ReturnType<typeof useBrandStandardQuery>;
export type BrandStandardLazyQueryHookResult = ReturnType<typeof useBrandStandardLazyQuery>;
export type BrandStandardQueryResult = ApolloReactCommon.QueryResult<BrandStandardQuery, BrandStandardQueryVariables>;
export const BrandStandardsDocument = gql`
    query BrandStandards($first: Int, $after: String, $last: Int, $before: String, $orderBy: String, $orderDirection: OrderDirection, $orderByMultiple: [String!], $orderDirectionMultiple: [OrderDirection!], $useOffsetPagination: Boolean) {
  brandStandards(
    first: $first
    after: $after
    last: $last
    before: $before
    orderBy: $orderBy
    orderDirection: $orderDirection
    orderByMultiple: $orderByMultiple
    orderDirectionMultiple: $orderDirectionMultiple
    useOffsetPagination: $useOffsetPagination
  ) {
    ...brandStandardsConnectionFields
  }
}
    ${BrandStandardsConnectionFieldsFragmentDoc}`;

/**
 * __useBrandStandardsQuery__
 *
 * To run a query within a React component, call `useBrandStandardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandStandardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandStandardsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      orderByMultiple: // value for 'orderByMultiple'
 *      orderDirectionMultiple: // value for 'orderDirectionMultiple'
 *      useOffsetPagination: // value for 'useOffsetPagination'
 *   },
 * });
 */
export function useBrandStandardsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrandStandardsQuery, BrandStandardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BrandStandardsQuery, BrandStandardsQueryVariables>(BrandStandardsDocument, options);
      }
export function useBrandStandardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrandStandardsQuery, BrandStandardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BrandStandardsQuery, BrandStandardsQueryVariables>(BrandStandardsDocument, options);
        }
export type BrandStandardsQueryHookResult = ReturnType<typeof useBrandStandardsQuery>;
export type BrandStandardsLazyQueryHookResult = ReturnType<typeof useBrandStandardsLazyQuery>;
export type BrandStandardsQueryResult = ApolloReactCommon.QueryResult<BrandStandardsQuery, BrandStandardsQueryVariables>;
export const ConfigDocument = gql`
    query Config {
  tables {
    ...tablesFields
  }
}
    ${TablesFieldsFragmentDoc}`;

/**
 * __useConfigQuery__
 *
 * To run a query within a React component, call `useConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConfigQuery, ConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
      }
export function useConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConfigQuery, ConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
        }
export type ConfigQueryHookResult = ReturnType<typeof useConfigQuery>;
export type ConfigLazyQueryHookResult = ReturnType<typeof useConfigLazyQuery>;
export type ConfigQueryResult = ApolloReactCommon.QueryResult<ConfigQuery, ConfigQueryVariables>;
export const ConnectionsDocument = gql`
    query Connections {
  connections {
    key
    storeId
    termId
    connectionId
    disconnected
    value
    time
  }
}
    `;

/**
 * __useConnectionsQuery__
 *
 * To run a query within a React component, call `useConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConnectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ConnectionsQuery, ConnectionsQueryVariables>(ConnectionsDocument, options);
      }
export function useConnectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ConnectionsQuery, ConnectionsQueryVariables>(ConnectionsDocument, options);
        }
export type ConnectionsQueryHookResult = ReturnType<typeof useConnectionsQuery>;
export type ConnectionsLazyQueryHookResult = ReturnType<typeof useConnectionsLazyQuery>;
export type ConnectionsQueryResult = ApolloReactCommon.QueryResult<ConnectionsQuery, ConnectionsQueryVariables>;
export const DefaultNoticesDocument = gql`
    query DefaultNotices($first: Int, $after: String, $last: Int, $before: String, $orderBy: String, $orderDirection: OrderDirection, $orderByMultiple: [String!], $orderDirectionMultiple: [OrderDirection!], $useOffsetPagination: Boolean, $filter: FilterDefaultNoticeInput) {
  defaultNotices(
    first: $first
    after: $after
    last: $last
    before: $before
    orderBy: $orderBy
    orderDirection: $orderDirection
    orderByMultiple: $orderByMultiple
    orderDirectionMultiple: $orderDirectionMultiple
    useOffsetPagination: $useOffsetPagination
    filter: $filter
  ) {
    ...defaultNoticesConnectionFields
  }
}
    ${DefaultNoticesConnectionFieldsFragmentDoc}`;

/**
 * __useDefaultNoticesQuery__
 *
 * To run a query within a React component, call `useDefaultNoticesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultNoticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultNoticesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      orderByMultiple: // value for 'orderByMultiple'
 *      orderDirectionMultiple: // value for 'orderDirectionMultiple'
 *      useOffsetPagination: // value for 'useOffsetPagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDefaultNoticesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DefaultNoticesQuery, DefaultNoticesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DefaultNoticesQuery, DefaultNoticesQueryVariables>(DefaultNoticesDocument, options);
      }
export function useDefaultNoticesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefaultNoticesQuery, DefaultNoticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DefaultNoticesQuery, DefaultNoticesQueryVariables>(DefaultNoticesDocument, options);
        }
export type DefaultNoticesQueryHookResult = ReturnType<typeof useDefaultNoticesQuery>;
export type DefaultNoticesLazyQueryHookResult = ReturnType<typeof useDefaultNoticesLazyQuery>;
export type DefaultNoticesQueryResult = ApolloReactCommon.QueryResult<DefaultNoticesQuery, DefaultNoticesQueryVariables>;
export const EmployeeStoresDocument = gql`
    query EmployeeStores {
  session {
    employee {
      stores {
        ...employeeStoresFields
      }
    }
  }
}
    ${EmployeeStoresFieldsFragmentDoc}`;

/**
 * __useEmployeeStoresQuery__
 *
 * To run a query within a React component, call `useEmployeeStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeStoresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeStoresQuery, EmployeeStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<EmployeeStoresQuery, EmployeeStoresQueryVariables>(EmployeeStoresDocument, options);
      }
export function useEmployeeStoresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeStoresQuery, EmployeeStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<EmployeeStoresQuery, EmployeeStoresQueryVariables>(EmployeeStoresDocument, options);
        }
export type EmployeeStoresQueryHookResult = ReturnType<typeof useEmployeeStoresQuery>;
export type EmployeeStoresLazyQueryHookResult = ReturnType<typeof useEmployeeStoresLazyQuery>;
export type EmployeeStoresQueryResult = ApolloReactCommon.QueryResult<EmployeeStoresQuery, EmployeeStoresQueryVariables>;
export const MenuDocument = gql`
    query Menu($productGroupId: Int) {
  menu(productGroupId: $productGroupId) {
    productGroups {
      id
      name
    }
    products {
      id
      name
    }
  }
}
    `;

/**
 * __useMenuQuery__
 *
 * To run a query within a React component, call `useMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuQuery({
 *   variables: {
 *      productGroupId: // value for 'productGroupId'
 *   },
 * });
 */
export function useMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuQuery, MenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MenuQuery, MenuQueryVariables>(MenuDocument, options);
      }
export function useMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuQuery, MenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MenuQuery, MenuQueryVariables>(MenuDocument, options);
        }
export type MenuQueryHookResult = ReturnType<typeof useMenuQuery>;
export type MenuLazyQueryHookResult = ReturnType<typeof useMenuLazyQuery>;
export type MenuQueryResult = ApolloReactCommon.QueryResult<MenuQuery, MenuQueryVariables>;
export const SessionDocument = gql`
    query session {
  session {
    ...sessionFields
  }
}
    ${SessionFieldsFragmentDoc}`;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SessionQuery, SessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
      }
export function useSessionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SessionQuery, SessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
        }
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionQueryResult = ApolloReactCommon.QueryResult<SessionQuery, SessionQueryVariables>;
export const OrderDocument = gql`
    query Order($id: ID!) {
  order(id: $id) {
    id
    storeId
    termId
    type
    subTotal
    taxAmount
    total
    discrepancy
    timePlaced
    store {
      storeId
      priceTierId
    }
    status {
      id
      status
      details
    }
    items {
      id
      taxAmount
      taxRate
      quantity
      price
      itemId
      item {
        ...itemFields
      }
      modifiers {
        id
        quantity
        price
        modifierId
        modifier {
          ...modifierFields
        }
      }
    }
    transaction {
      id
      transStartTime
      orderNumber
      open
      transCloseTime
      taxExempt
      grossPrice
      tax
      discountAmount
      netPrice
      nonSaleRevenue
      wasZeroedOut
      items {
        id
        storeId
        termId
        transStartTime
        itemAddTime
        itemId
        ticketName
        return
        price
        taxRate
        discountAmount
        taxAmount
        item {
          ...itemFields
        }
        modifiers {
          id
          storeId
          termId
          transStartTime
          modifierId
          modifierAddTime
          itemAddTime
          price
          quantity
          lastModified
          serverLastModified
          aggregated
          returnReasonId
          isVoided
          basePrice
          modifier {
            ...modifierFields
          }
        }
      }
    }
  }
}
    ${ItemFieldsFragmentDoc}
${ModifierFieldsFragmentDoc}`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: ApolloReactHooks.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = ApolloReactCommon.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrderRefundsDocument = gql`
    query OrderRefunds($id: ID!) {
  order(id: $id) {
    id
    refunds {
      ...refundFields
    }
    creditCardPayments {
      ...orderCreditCardPaymentFields
    }
    giftCardPayments {
      ...orderGiftCardPaymentFields
    }
  }
}
    ${RefundFieldsFragmentDoc}
${OrderCreditCardPaymentFieldsFragmentDoc}
${OrderGiftCardPaymentFieldsFragmentDoc}`;

/**
 * __useOrderRefundsQuery__
 *
 * To run a query within a React component, call `useOrderRefundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderRefundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderRefundsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderRefundsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<OrderRefundsQuery, OrderRefundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrderRefundsQuery, OrderRefundsQueryVariables>(OrderRefundsDocument, options);
      }
export function useOrderRefundsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderRefundsQuery, OrderRefundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrderRefundsQuery, OrderRefundsQueryVariables>(OrderRefundsDocument, options);
        }
export type OrderRefundsQueryHookResult = ReturnType<typeof useOrderRefundsQuery>;
export type OrderRefundsLazyQueryHookResult = ReturnType<typeof useOrderRefundsLazyQuery>;
export type OrderRefundsQueryResult = ApolloReactCommon.QueryResult<OrderRefundsQuery, OrderRefundsQueryVariables>;
export const OrdersDocument = gql`
    query Orders($first: Int, $after: String, $last: Int, $before: String, $orderBy: String, $orderDirection: OrderDirection, $orderByMultiple: [String!], $orderDirectionMultiple: [OrderDirection!], $useOffsetPagination: Boolean, $filter: FilterOrderInput) {
  orders(
    first: $first
    after: $after
    last: $last
    before: $before
    orderBy: $orderBy
    orderDirection: $orderDirection
    orderByMultiple: $orderByMultiple
    orderDirectionMultiple: $orderDirectionMultiple
    useOffsetPagination: $useOffsetPagination
    filter: $filter
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        storeId
        termId
        type
        discrepancy
        timePlaced
        pickupName
        subTotal
        taxAmount
        total
        status {
          id
          status
        }
      }
    }
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      orderByMultiple: // value for 'orderByMultiple'
 *      orderDirectionMultiple: // value for 'orderDirectionMultiple'
 *      useOffsetPagination: // value for 'useOffsetPagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = ApolloReactCommon.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OwnerOperatorDocument = gql`
    query OwnerOperator {
  session {
    employee {
      person {
        ...personFields
      }
    }
  }
}
    ${PersonFieldsFragmentDoc}`;

/**
 * __useOwnerOperatorQuery__
 *
 * To run a query within a React component, call `useOwnerOperatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerOperatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerOperatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useOwnerOperatorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OwnerOperatorQuery, OwnerOperatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OwnerOperatorQuery, OwnerOperatorQueryVariables>(OwnerOperatorDocument, options);
      }
export function useOwnerOperatorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OwnerOperatorQuery, OwnerOperatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OwnerOperatorQuery, OwnerOperatorQueryVariables>(OwnerOperatorDocument, options);
        }
export type OwnerOperatorQueryHookResult = ReturnType<typeof useOwnerOperatorQuery>;
export type OwnerOperatorLazyQueryHookResult = ReturnType<typeof useOwnerOperatorLazyQuery>;
export type OwnerOperatorQueryResult = ApolloReactCommon.QueryResult<OwnerOperatorQuery, OwnerOperatorQueryVariables>;
export const PendingOrderDocument = gql`
    query PendingOrder($id: ID!) {
  pendingOrder(id: $id) {
    id
    type
    status {
      orderId
      status
      details
      time
    }
    statuses {
      ...orderStatusFields
    }
    timePlaced
    lastUpdated
    time
    storeId
    termId
    subTotal
    taxAmount
    total
    discrepancy
    pointsEarned
    profile {
      ...profileFields
    }
    guestEmail
    pickupName
    pickupLocation
    phone
    car {
      make
      model
      color
      year
    }
    items {
      id
      price
      quantity
      taxAmount
      modifiers {
        id
        price
        quantity
        modifier {
          id
          name
        }
      }
      item {
        id
        size {
          id
          name
        }
        product {
          id
          name
        }
      }
    }
    creditCardPayments {
      ...orderCreditCardPaymentFields
    }
    giftCardPayments {
      id
      cardId
      cardNumber
      amount
    }
    discounts {
      id
      discountId
    }
  }
}
    ${OrderStatusFieldsFragmentDoc}
${ProfileFieldsFragmentDoc}
${OrderCreditCardPaymentFieldsFragmentDoc}`;

/**
 * __usePendingOrderQuery__
 *
 * To run a query within a React component, call `usePendingOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePendingOrderQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PendingOrderQuery, PendingOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PendingOrderQuery, PendingOrderQueryVariables>(PendingOrderDocument, options);
      }
export function usePendingOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PendingOrderQuery, PendingOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PendingOrderQuery, PendingOrderQueryVariables>(PendingOrderDocument, options);
        }
export type PendingOrderQueryHookResult = ReturnType<typeof usePendingOrderQuery>;
export type PendingOrderLazyQueryHookResult = ReturnType<typeof usePendingOrderLazyQuery>;
export type PendingOrderQueryResult = ApolloReactCommon.QueryResult<PendingOrderQuery, PendingOrderQueryVariables>;
export const PendingOrderIdsDocument = gql`
    query PendingOrderIds {
  pendingOrderIds
}
    `;

/**
 * __usePendingOrderIdsQuery__
 *
 * To run a query within a React component, call `usePendingOrderIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingOrderIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingOrderIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingOrderIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PendingOrderIdsQuery, PendingOrderIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PendingOrderIdsQuery, PendingOrderIdsQueryVariables>(PendingOrderIdsDocument, options);
      }
export function usePendingOrderIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PendingOrderIdsQuery, PendingOrderIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PendingOrderIdsQuery, PendingOrderIdsQueryVariables>(PendingOrderIdsDocument, options);
        }
export type PendingOrderIdsQueryHookResult = ReturnType<typeof usePendingOrderIdsQuery>;
export type PendingOrderIdsLazyQueryHookResult = ReturnType<typeof usePendingOrderIdsLazyQuery>;
export type PendingOrderIdsQueryResult = ApolloReactCommon.QueryResult<PendingOrderIdsQuery, PendingOrderIdsQueryVariables>;
export const ReturnReasonsDocument = gql`
    query ReturnReasons {
  returnReasons {
    ...returnReasonFields
  }
}
    ${ReturnReasonFieldsFragmentDoc}`;

/**
 * __useReturnReasonsQuery__
 *
 * To run a query within a React component, call `useReturnReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReturnReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReturnReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReturnReasonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReturnReasonsQuery, ReturnReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReturnReasonsQuery, ReturnReasonsQueryVariables>(ReturnReasonsDocument, options);
      }
export function useReturnReasonsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReturnReasonsQuery, ReturnReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReturnReasonsQuery, ReturnReasonsQueryVariables>(ReturnReasonsDocument, options);
        }
export type ReturnReasonsQueryHookResult = ReturnType<typeof useReturnReasonsQuery>;
export type ReturnReasonsLazyQueryHookResult = ReturnType<typeof useReturnReasonsLazyQuery>;
export type ReturnReasonsQueryResult = ApolloReactCommon.QueryResult<ReturnReasonsQuery, ReturnReasonsQueryVariables>;
export const StoreDocument = gql`
    query Store($storeId: Int!) {
  store(storeId: $storeId) {
    ...storeFields
    settings {
      storeId
      termId
      setting
      value
      type {
        type
        metadata {
          id
          key
          value
        }
      }
    }
  }
}
    ${StoreFieldsFragmentDoc}`;

/**
 * __useStoreQuery__
 *
 * To run a query within a React component, call `useStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useStoreQuery(baseOptions: ApolloReactHooks.QueryHookOptions<StoreQuery, StoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StoreQuery, StoreQueryVariables>(StoreDocument, options);
      }
export function useStoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StoreQuery, StoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StoreQuery, StoreQueryVariables>(StoreDocument, options);
        }
export type StoreQueryHookResult = ReturnType<typeof useStoreQuery>;
export type StoreLazyQueryHookResult = ReturnType<typeof useStoreLazyQuery>;
export type StoreQueryResult = ApolloReactCommon.QueryResult<StoreQuery, StoreQueryVariables>;
export const StoreTerminalsDocument = gql`
    query StoreTerminals {
  session {
    store {
      terminals {
        termId
        status
      }
    }
  }
}
    `;

/**
 * __useStoreTerminalsQuery__
 *
 * To run a query within a React component, call `useStoreTerminalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreTerminalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreTerminalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoreTerminalsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StoreTerminalsQuery, StoreTerminalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StoreTerminalsQuery, StoreTerminalsQueryVariables>(StoreTerminalsDocument, options);
      }
export function useStoreTerminalsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StoreTerminalsQuery, StoreTerminalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StoreTerminalsQuery, StoreTerminalsQueryVariables>(StoreTerminalsDocument, options);
        }
export type StoreTerminalsQueryHookResult = ReturnType<typeof useStoreTerminalsQuery>;
export type StoreTerminalsLazyQueryHookResult = ReturnType<typeof useStoreTerminalsLazyQuery>;
export type StoreTerminalsQueryResult = ApolloReactCommon.QueryResult<StoreTerminalsQuery, StoreTerminalsQueryVariables>;
export const StripeDocument = gql`
    query Stripe($limit: Int, $offset: Int) {
  session {
    employee {
      stores(limit: $limit, offset: $offset) {
        ...storeFields
        stripeAccount {
          id
          accountId
          storeId
          chargesEnabled
          onboardingUrl
          loginUrl
        }
      }
    }
  }
}
    ${StoreFieldsFragmentDoc}`;

/**
 * __useStripeQuery__
 *
 * To run a query within a React component, call `useStripeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useStripeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StripeQuery, StripeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StripeQuery, StripeQueryVariables>(StripeDocument, options);
      }
export function useStripeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StripeQuery, StripeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StripeQuery, StripeQueryVariables>(StripeDocument, options);
        }
export type StripeQueryHookResult = ReturnType<typeof useStripeQuery>;
export type StripeLazyQueryHookResult = ReturnType<typeof useStripeLazyQuery>;
export type StripeQueryResult = ApolloReactCommon.QueryResult<StripeQuery, StripeQueryVariables>;
export const StripeDisputesDocument = gql`
    query StripeDisputes($storeId: Int!, $limit: Int, $endingBefore: String, $startingAfter: String) {
  store(storeId: $storeId) {
    storeId
    disputes(
      endingBefore: $endingBefore
      startingAfter: $startingAfter
      limit: $limit
    ) {
      ...disputeField
    }
  }
}
    ${DisputeFieldFragmentDoc}`;

/**
 * __useStripeDisputesQuery__
 *
 * To run a query within a React component, call `useStripeDisputesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeDisputesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeDisputesQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      limit: // value for 'limit'
 *      endingBefore: // value for 'endingBefore'
 *      startingAfter: // value for 'startingAfter'
 *   },
 * });
 */
export function useStripeDisputesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<StripeDisputesQuery, StripeDisputesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StripeDisputesQuery, StripeDisputesQueryVariables>(StripeDisputesDocument, options);
      }
export function useStripeDisputesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StripeDisputesQuery, StripeDisputesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StripeDisputesQuery, StripeDisputesQueryVariables>(StripeDisputesDocument, options);
        }
export type StripeDisputesQueryHookResult = ReturnType<typeof useStripeDisputesQuery>;
export type StripeDisputesLazyQueryHookResult = ReturnType<typeof useStripeDisputesLazyQuery>;
export type StripeDisputesQueryResult = ApolloReactCommon.QueryResult<StripeDisputesQuery, StripeDisputesQueryVariables>;
export const StripeOrderDocument = gql`
    query StripeOrder($id: ID!) {
  order(id: $id) {
    ...orderField
  }
}
    ${OrderFieldFragmentDoc}`;

/**
 * __useStripeOrderQuery__
 *
 * To run a query within a React component, call `useStripeOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStripeOrderQuery(baseOptions: ApolloReactHooks.QueryHookOptions<StripeOrderQuery, StripeOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StripeOrderQuery, StripeOrderQueryVariables>(StripeOrderDocument, options);
      }
export function useStripeOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StripeOrderQuery, StripeOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StripeOrderQuery, StripeOrderQueryVariables>(StripeOrderDocument, options);
        }
export type StripeOrderQueryHookResult = ReturnType<typeof useStripeOrderQuery>;
export type StripeOrderLazyQueryHookResult = ReturnType<typeof useStripeOrderLazyQuery>;
export type StripeOrderQueryResult = ApolloReactCommon.QueryResult<StripeOrderQuery, StripeOrderQueryVariables>;
export const StripeOrdersDocument = gql`
    query StripeOrders($storeId: Int!, $first: Int, $after: String, $last: Int, $before: String, $orderBy: String, $orderDirection: OrderDirection, $orderByMultiple: [String!], $orderDirectionMultiple: [OrderDirection!], $useOffsetPagination: Boolean, $filter: FilterOrderInput) {
  store(storeId: $storeId) {
    storeId
    orders(
      first: $first
      after: $after
      last: $last
      before: $before
      orderBy: $orderBy
      orderDirection: $orderDirection
      orderByMultiple: $orderByMultiple
      orderDirectionMultiple: $orderDirectionMultiple
      useOffsetPagination: $useOffsetPagination
      filter: $filter
    ) {
      ...ordersConnectionFields
    }
  }
}
    ${OrdersConnectionFieldsFragmentDoc}`;

/**
 * __useStripeOrdersQuery__
 *
 * To run a query within a React component, call `useStripeOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeOrdersQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      orderByMultiple: // value for 'orderByMultiple'
 *      orderDirectionMultiple: // value for 'orderDirectionMultiple'
 *      useOffsetPagination: // value for 'useOffsetPagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStripeOrdersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<StripeOrdersQuery, StripeOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StripeOrdersQuery, StripeOrdersQueryVariables>(StripeOrdersDocument, options);
      }
export function useStripeOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StripeOrdersQuery, StripeOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StripeOrdersQuery, StripeOrdersQueryVariables>(StripeOrdersDocument, options);
        }
export type StripeOrdersQueryHookResult = ReturnType<typeof useStripeOrdersQuery>;
export type StripeOrdersLazyQueryHookResult = ReturnType<typeof useStripeOrdersLazyQuery>;
export type StripeOrdersQueryResult = ApolloReactCommon.QueryResult<StripeOrdersQuery, StripeOrdersQueryVariables>;
export const TerminalsDocument = gql`
    query Terminals {
  terminals {
    connectionId
    status
    termId
    storeId
    time
  }
}
    `;

/**
 * __useTerminalsQuery__
 *
 * To run a query within a React component, call `useTerminalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerminalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTerminalsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TerminalsQuery, TerminalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TerminalsQuery, TerminalsQueryVariables>(TerminalsDocument, options);
      }
export function useTerminalsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TerminalsQuery, TerminalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TerminalsQuery, TerminalsQueryVariables>(TerminalsDocument, options);
        }
export type TerminalsQueryHookResult = ReturnType<typeof useTerminalsQuery>;
export type TerminalsLazyQueryHookResult = ReturnType<typeof useTerminalsLazyQuery>;
export type TerminalsQueryResult = ApolloReactCommon.QueryResult<TerminalsQuery, TerminalsQueryVariables>;
export const WhereToShowDocument = gql`
    query WhereToShow {
  whereToShow {
    questionLocationId
    screenname
  }
}
    `;

/**
 * __useWhereToShowQuery__
 *
 * To run a query within a React component, call `useWhereToShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhereToShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhereToShowQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhereToShowQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WhereToShowQuery, WhereToShowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WhereToShowQuery, WhereToShowQueryVariables>(WhereToShowDocument, options);
      }
export function useWhereToShowLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WhereToShowQuery, WhereToShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WhereToShowQuery, WhereToShowQueryVariables>(WhereToShowDocument, options);
        }
export type WhereToShowQueryHookResult = ReturnType<typeof useWhereToShowQuery>;
export type WhereToShowLazyQueryHookResult = ReturnType<typeof useWhereToShowLazyQuery>;
export type WhereToShowQueryResult = ApolloReactCommon.QueryResult<WhereToShowQuery, WhereToShowQueryVariables>;
export const AllOrdersDocument = gql`
    subscription AllOrders {
  allOrders {
    id
    user {
      id
      type
    }
    payments {
      __typename
      ... on PercCreditCardPayment {
        creditCardPaymentId: id
        amount
        identifier
        cardType
        lastFour
      }
      ... on PercGiftCardPayment {
        giftCardPaymentId: id
        amount
        cardNumber
      }
    }
    timePlaced
    employeeId
    storeId
    termId
    items {
      id
      quantity
      modifiers {
        id
        quantity
      }
    }
  }
}
    `;

/**
 * __useAllOrdersSubscription__
 *
 * To run a query within a React component, call `useAllOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrdersSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAllOrdersSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AllOrdersSubscription, AllOrdersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<AllOrdersSubscription, AllOrdersSubscriptionVariables>(AllOrdersDocument, options);
      }
export type AllOrdersSubscriptionHookResult = ReturnType<typeof useAllOrdersSubscription>;
export type AllOrdersSubscriptionResult = ApolloReactCommon.SubscriptionResult<AllOrdersSubscription>;
export const CreateCardsInSeriesUpdatesDocument = gql`
    subscription CreateCardsInSeriesUpdates($executionArn: ID!) {
  createCardsInSeriesUpdates(executionArn: $executionArn) {
    executionArn
    batchIndex
    totalBatches
    cardNumbers
    ok
    errorMessage
    errorCode
  }
}
    `;

/**
 * __useCreateCardsInSeriesUpdatesSubscription__
 *
 * To run a query within a React component, call `useCreateCardsInSeriesUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreateCardsInSeriesUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateCardsInSeriesUpdatesSubscription({
 *   variables: {
 *      executionArn: // value for 'executionArn'
 *   },
 * });
 */
export function useCreateCardsInSeriesUpdatesSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<CreateCardsInSeriesUpdatesSubscription, CreateCardsInSeriesUpdatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<CreateCardsInSeriesUpdatesSubscription, CreateCardsInSeriesUpdatesSubscriptionVariables>(CreateCardsInSeriesUpdatesDocument, options);
      }
export type CreateCardsInSeriesUpdatesSubscriptionHookResult = ReturnType<typeof useCreateCardsInSeriesUpdatesSubscription>;
export type CreateCardsInSeriesUpdatesSubscriptionResult = ApolloReactCommon.SubscriptionResult<CreateCardsInSeriesUpdatesSubscription>;
export const LogsDocument = gql`
    subscription Logs {
  logs
}
    `;

/**
 * __useLogsSubscription__
 *
 * To run a query within a React component, call `useLogsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLogsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useLogsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<LogsSubscription, LogsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<LogsSubscription, LogsSubscriptionVariables>(LogsDocument, options);
      }
export type LogsSubscriptionHookResult = ReturnType<typeof useLogsSubscription>;
export type LogsSubscriptionResult = ApolloReactCommon.SubscriptionResult<LogsSubscription>;
export const OrderStatusDocument = gql`
    subscription OrderStatus($id: ID!) {
  orderStatus(id: $id) {
    ...orderStatusFields
  }
}
    ${OrderStatusFieldsFragmentDoc}`;

/**
 * __useOrderStatusSubscription__
 *
 * To run a query within a React component, call `useOrderStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatusSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderStatusSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<OrderStatusSubscription, OrderStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<OrderStatusSubscription, OrderStatusSubscriptionVariables>(OrderStatusDocument, options);
      }
export type OrderStatusSubscriptionHookResult = ReturnType<typeof useOrderStatusSubscription>;
export type OrderStatusSubscriptionResult = ApolloReactCommon.SubscriptionResult<OrderStatusSubscription>;
export const PendingOrdersDocument = gql`
    subscription PendingOrders {
  newOrder {
    id
  }
}
    `;

/**
 * __usePendingOrdersSubscription__
 *
 * To run a query within a React component, call `usePendingOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePendingOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingOrdersSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePendingOrdersSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<PendingOrdersSubscription, PendingOrdersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<PendingOrdersSubscription, PendingOrdersSubscriptionVariables>(PendingOrdersDocument, options);
      }
export type PendingOrdersSubscriptionHookResult = ReturnType<typeof usePendingOrdersSubscription>;
export type PendingOrdersSubscriptionResult = ApolloReactCommon.SubscriptionResult<PendingOrdersSubscription>;
export const StoreTerminalUpdatesDocument = gql`
    subscription StoreTerminalUpdates($storeId: Int!) {
  terminals(storeId: $storeId) {
    termId
    status
  }
}
    `;

/**
 * __useStoreTerminalUpdatesSubscription__
 *
 * To run a query within a React component, call `useStoreTerminalUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStoreTerminalUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreTerminalUpdatesSubscription({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useStoreTerminalUpdatesSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<StoreTerminalUpdatesSubscription, StoreTerminalUpdatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<StoreTerminalUpdatesSubscription, StoreTerminalUpdatesSubscriptionVariables>(StoreTerminalUpdatesDocument, options);
      }
export type StoreTerminalUpdatesSubscriptionHookResult = ReturnType<typeof useStoreTerminalUpdatesSubscription>;
export type StoreTerminalUpdatesSubscriptionResult = ApolloReactCommon.SubscriptionResult<StoreTerminalUpdatesSubscription>;
export const TerminalsUpdatesDocument = gql`
    subscription TerminalsUpdates {
  terminals {
    connectionId
    status
    termId
    storeId
    time
  }
}
    `;

/**
 * __useTerminalsUpdatesSubscription__
 *
 * To run a query within a React component, call `useTerminalsUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTerminalsUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminalsUpdatesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTerminalsUpdatesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TerminalsUpdatesSubscription, TerminalsUpdatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<TerminalsUpdatesSubscription, TerminalsUpdatesSubscriptionVariables>(TerminalsUpdatesDocument, options);
      }
export type TerminalsUpdatesSubscriptionHookResult = ReturnType<typeof useTerminalsUpdatesSubscription>;
export type TerminalsUpdatesSubscriptionResult = ApolloReactCommon.SubscriptionResult<TerminalsUpdatesSubscription>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "OrderPayment",
        "possibleTypes": [
          {
            "name": "OrderCreditCardPayment"
          },
          {
            "name": "OrderGiftCardPayment"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PercPayment",
        "possibleTypes": [
          {
            "name": "PercCreditCardPayment"
          },
          {
            "name": "PercGiftCardPayment"
          }
        ]
      }
    ]
  }
};
      export default result;
    
import React, { useState } from 'react';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import { Items } from '..';
import { GenericDrawer } from '../..';

export interface ProductProps {
	product: Percomatic.Serialized.Product;
}

export const RecipeBookProduct: React.FC<ProductProps> = ({ product }) => {
	const [isActive, setIsActive] = useState(false);
	const [show, setShow] = useState(false);

	return (
		<GenericDrawer
			isActive={isActive}
			onRest={() => {
				if (!isActive && show) {
					setShow(false);
				}
			}}
		>
			<>
				<hr className="m-0 p-0" />

				<div
					onClick={() => {
						if (show) {
							setIsActive(false);
						} else {
							setShow(true);
							setIsActive(true);
						}
					}}
					className="d-flex cursor-pointer align-items-center py-2 px-3 text-secondary"
				>
					<h2 className="mr-auto mb-0">{product.name}</h2>

					<FontAwesomeIcon
						icon={isActive ? faChevronUp : faChevronDown}
					/>
				</div>
			</>

			{show && <Items product={product} />}
		</GenericDrawer>
	);
};

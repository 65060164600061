import { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { Loading } from '../components/Loading';
import { Error } from '../components/Generic/Error';

import {
	ActiveDefault,
	AddDefaultNotice,
	DefaultNoticesHistory
} from '../components/DefaultNotices';
import {
	BrandStandardEdgeFieldsFragment,
	DefaultNoticeEdgeFieldsFragment,
	useBrandStandardsQuery,
	useDefaultNoticesQuery
} from '../graphql';
import selectors from '../domains/Session/selectors';

const approvedEditRoles = [1, 14];

const brandStandardsLink =
	'https://frc.biggby.com/Operations/Forms/Forms/AllItems.aspx?RootFolder=%2fOperations%2fForms%2fBrand%20Standards&FolderCTID=0x01200090957FC196F5B24DB5DACFCE1F6C09F3&View=%7b83DCE5E0%2dE427%2d46B7%2d8F4E%2dDB76A5320322%7d';

export const DefaultNotices: React.FC = () => {
	const { storeId } = useParams<{ storeId: string }>();

	const roles = selectors.useSelectRoles();

	const canEdit = roles.some(role => approvedEditRoles.includes(role.roleId));

	const now = new Date();
	now.setFullYear(now.getFullYear() - 1);

	const [activeDefaults, setActiveDefaults] = useState<
		DefaultNoticeEdgeFieldsFragment[]
	>([]);

	const [brandStandards, setBrandStandards] = useState<
		BrandStandardEdgeFieldsFragment[]
	>([]);

	const [historyDefaults, setHistoryDefaults] = useState<
		DefaultNoticeEdgeFieldsFragment[]
	>([]);

	const {
		data: activeDefaultNoticesQuery,
		loading: activeDefaultNoticesLoading,
		error: activeDefaultNoticesError
	} = useDefaultNoticesQuery({
		variables: {
			first: 20,
			filter: {
				storeId: Number(storeId),
				resolved: false
			},
			orderBy: 'defaultDate',
			orderDirection: 'desc'
		}
	});

	const {
		data: brandStandardsQuery,
		loading: brandStandardsLoading,
		error: brandStandardsError
	} = useBrandStandardsQuery({
		variables: {
			first: 30,
			orderDirection: 'asc'
		}
	});

	const {
		data: historyDefaultNoticesQuery,
		loading: historyDefaultNoticesLoading,
		error: historyDefaultNoticesError
	} = useDefaultNoticesQuery({
		variables: {
			first: 30,
			filter: {
				storeId: Number(storeId),
				resolved: true,
				createdAfter: now.toISOString().slice(0, 10)
			},
			orderBy: 'resolutionDate',
			orderDirection: 'desc'
		}
	});

	useEffect(() => {
		if (activeDefaultNoticesQuery?.defaultNotices.edges) {
			setActiveDefaults(activeDefaultNoticesQuery.defaultNotices.edges);
		}
	}, [activeDefaultNoticesQuery]);

	useEffect(() => {
		if (brandStandardsQuery?.brandStandards.edges) {
			setBrandStandards(brandStandardsQuery.brandStandards.edges);
		}
	}, [brandStandardsQuery]);

	useEffect(() => {
		if (historyDefaultNoticesQuery?.defaultNotices.edges) {
			setHistoryDefaults(historyDefaultNoticesQuery.defaultNotices.edges);
		}
	}, [historyDefaultNoticesQuery]);

	if (
		activeDefaultNoticesLoading &&
		historyDefaultNoticesLoading &&
		brandStandardsLoading
	) {
		return <Loading />;
	}

	if (
		activeDefaultNoticesError ||
		historyDefaultNoticesError ||
		brandStandardsError
	) {
		const message =
			(
				activeDefaultNoticesError ||
				historyDefaultNoticesError ||
				brandStandardsError
			)?.message ?? 'Something went wrong! Please check back in later.';
		return <Error error={message} />;
	}

	return (
		<div className="default-notices-root text-light py-3 overflow-auto">
			<div className="default-notices-header">
				<h1 className="px-3">
					Default Notices:{' '}
					<span className="store-number">Store # {storeId}</span>
				</h1>

				<button
					className="btn btn-secondary mr-3"
					onClick={() => window.open(brandStandardsLink, '_blank')}
				>
					Brand Standards
				</button>
			</div>
			<hr />

			<div className="px-3 mb-5">
				<h3>Active Defaults</h3>
				{activeDefaults &&
					brandStandards &&
					activeDefaults.map(active => (
						<ActiveDefault
							key={active.node.id}
							active={active}
							edit={canEdit}
							brandStandards={brandStandards}
						/>
					))}
				{activeDefaults.length === 0 && (
					<div className="default-notice-message">
						<p>There are no active defaults.</p>
					</div>
				)}
			</div>

			{historyDefaults && !historyDefaultNoticesLoading && (
				<DefaultNoticesHistory histories={historyDefaults} />
			)}

			{canEdit && brandStandards && (
				<AddDefaultNotice
					brandStandards={brandStandards}
					storeId={storeId}
				/>
			)}
		</div>
	);
};

export default DefaultNotices;

import {
	TextField,
	DateField,
	MoneyField,
	CentsField
} from '../../../components';
import { OrderQuery } from './types';

export const Metadata: React.FC<OrderQuery> = ({
	discrepancy,
	status,
	store,
	storeId,
	subTotal,
	taxAmount,
	termId,
	timePlaced,
	total,
	transaction,
	type
}) => (
	<div className="row">
		<div className="col-5">
			<div className="border border-light rounded py-2">
				<div className="row">
					<div className="col-12">
						<div className="gap gap-sm justify-content-between">
							<TextField label="Store ID" value={storeId} />

							<TextField label="Term ID" value={termId} />

							<TextField label="Type" value={type} />

							<DateField label="Time Placed" value={timePlaced} />

							<TextField label="Status" value={status.status} />

							{transaction && (
								<>
									<TextField
										label="Order Number"
										value={transaction.orderNumber}
									/>

									<TextField
										label="Is Open"
										value={String(transaction.open)}
									/>

									<DateField
										label="Transaction Start Time"
										value={transaction.transStartTime}
									/>

									{transaction.transCloseTime && (
										<DateField
											label="Transaction Close Time"
											value={transaction.transCloseTime}
										/>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="col-7">
			<div className="border border-light rounded py-2">
				<div className="row">
					<div className="col-12">
						<div className="gap">
							<TextField
								label="Store Price Tier ID"
								value={store.priceTierId}
							/>

							<CentsField
								label="Discrepancy"
								value={discrepancy}
							/>

							<TextField
								label="Zeroed Out"
								value={String(transaction?.wasZeroedOut)}
							/>

							<TextField
								label="Tax Exempt"
								value={String(transaction?.taxExempt)}
							/>
						</div>
					</div>

					<div className="col-4">
						<h5 className="text-secondary p-0 mb-0">OLO</h5>
					</div>

					<div className="col-8">
						<h5 className="text-secondary p-0 mb-0">PERC</h5>
					</div>

					<div className="col-4">
						<CentsField label="Subtotal" value={subTotal} />
					</div>

					<div className="col-8">
						<div className="gap">
							<MoneyField
								label="Net Price"
								value={transaction?.netPrice}
							/>

							<MoneyField
								label="Non-sale revenue"
								value={transaction?.nonSaleRevenue}
							/>
						</div>
					</div>

					<div className="col-4">
						<CentsField label="Tax Amount" value={taxAmount} />
					</div>

					<div className="col-8">
						<div className="gap">
							<MoneyField label="Tax" value={transaction?.tax} />

							<MoneyField
								label="Discount Amount"
								value={transaction?.discountAmount}
							/>
						</div>
					</div>

					<div className="col-4">
						<CentsField label="Total" value={total} />
					</div>

					<div className="col-8">
						<MoneyField
							label="Gross Price"
							value={transaction?.grossPrice}
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
);

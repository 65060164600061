import React from 'react';
import { useDispatch } from 'react-redux';
import FadeIn from 'react-fade-in';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import Product from '../../domains/Product';

export interface ModifierGroupProps {
	modifierGroupIndex: number;
	isActive: boolean;
	modifierGroup: Percomatic.Serialized.ModifierGroup;
	modifierModel: Percomatic.Serialized.ModifierModel;
}

export const ModifierGroup: React.FC<ModifierGroupProps> = ({
	modifierGroupIndex,
	isActive,
	modifierGroup,
	modifierModel
}) => {
	const dispatch = useDispatch();

	const modifying = Product.selectors.useSelectModifying();
	const selectModifier = Product.actions.useSelectModifier();
	const deselectModifier = Product.actions.useDeselectModifier();

	const buttonClickHandler = (modifierIndex: number) => () => {
		if (!modifying) {
			if (modifierModel.modifiersAreSelected[modifierIndex]) {
				deselectModifier(modifierGroupIndex, modifierIndex);
			} else {
				selectModifier(modifierGroupIndex, modifierIndex);
			}
		}
	};

	const { name: groupName } = modifierGroup;
	const groupSelected = modifierModel.modifiers
		.filter((_, index) => modifierModel.modifiersAreSelected[index])
		.map(({ name }) => name);

	if (isActive) {
		const body = modifierModel.modifiers.map((modifier, modifierIndex) => (
			<FadeIn
				className={`col-6 w-100 m-0 p-0 rounded border border-dark bg-secondary ${
					groupSelected.includes(modifier.name)
						? 'modifier-group-selected'
						: ''
				}`}
				key={modifierIndex}
			>
				<button
					className={`btn btn-secondary rounded btn-block w-100`}
					onClick={buttonClickHandler(modifierIndex)}
				>
					{modifier.name}
				</button>
			</FadeIn>
		));

		return (
			<div className="d-flex option list-group-item p-0 align-items-start border-0 bg-dark">
				<div
					onClick={() => {
						const action: ProductDomain.Actions.ClickModifierGroup =
							{
								type: 'CLICK_MODIFIER_GROUP',
								modifierGroupIndex
							};

						dispatch(action);
					}}
					className="d-flex bg-success text-dark justify-content-between px-1 py-2 border border-dark rounded col-4"
				>
					<p className="mb-0">{groupName}</p>

					<p className="mb-0 ml-auto">
						<FontAwesomeIcon icon={faChevronUp} />
					</p>
				</div>

				<div className="modifier-group row col-8 p-0">{body}</div>
			</div>
		);
	} else {
		let body = [];

		body = modifierModel.modifiers.map((modifier, modifierIndex) =>
			modifier.priority > 0 && modifier.priority !== null ? (
				<FadeIn
					className={`col-6 w-100 m-0 p-0 rounded border border-dark bg-secondary ${
						groupSelected.includes(modifier.name)
							? 'modifier-group-selected'
							: ''
					}`}
					key={modifierIndex}
				>
					<button
						className={`modifier-group-button btn btn-secondary rounded btn-block w-100`}
						onClick={buttonClickHandler(modifierIndex)}
					>
						{modifier.name}
					</button>
				</FadeIn>
			) : null
		);
		return (
			<div className="d-flex option list-group-item p-0 align-items-start border-0 bg-dark">
				<div
					onClick={() => {
						const action: ProductDomain.Actions.ClickModifierGroup =
							{
								type: 'CLICK_MODIFIER_GROUP',
								modifierGroupIndex
							};

						dispatch(action);
					}}
					className="d-flex bg-success text-dark justify-content-between px-1 py-2 border border-dark rounded col-4"
				>
					<p className="mb-0">{groupName}</p>
					<p className="mb-0 ml-auto">
						<FontAwesomeIcon icon={faChevronDown} />
					</p>
				</div>
				<div className="modifier-group row col-8 p-0">{body}</div>
			</div>
		);
	}
};

import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import { MenuQuery } from '../../../graphql';

export interface MenuProductGroupsListProps {
	productGroups:
		| MenuQuery['menu']['productGroups']
		| undefined
		| Percomatic.Serialized.ProductGroup[];
	selectProductGroup: (id: number) => void;
}

type GroupType = {
	id: string | number;
	name: string;
};

export const MenuProductGroupsList: React.FC<MenuProductGroupsListProps> = ({
	productGroups,
	selectProductGroup
}) => {
	return (
		<div className="productgroups-list grid-300">
			{productGroups?.map(({ id, name }: GroupType, key: number) => (
				<button
					onClick={() => selectProductGroup(Number(id))}
					key={key}
					className="btn btn-block btn-success mt-0 rounded  productgroups-list-productgroup"
				>
					{name}
				</button>
			))}
		</div>
	);
};

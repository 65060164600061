import { TextField } from '../../../components';
import { ItemFieldsFragment } from '../../../graphql';

export type ItemDetailsProps = {
	item: ItemFieldsFragment;
};

export const ItemDetails: React.FC<ItemDetailsProps> = ({ item }) => (
	<div className="gap">
		<TextField label="Item ID" value={item.id} />

		<TextField label="Product Name" value={item.product?.name} />

		<TextField label="Product ID" value={item.product?.id} />

		<TextField label="Size Name" value={item.size?.name} />

		<TextField label="Size ID" value={item.size?.id} />
	</div>
);

import React from 'react';
import { Link } from 'react-router-dom';

import Product from '../../domains/Product';

export const ProductFooter: React.FC = () => {
	const addToCart = Product.actions.useAddToCart();
	const modifying = Product.selectors.useSelectModifying();

	const clickHandler = (redirect: string) => () => {
		if (!modifying) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});

			addToCart(1, redirect);
		}
	};

	return (
		<div className="product-footer mt-1">
			{/* <div className="row">
				<div className="col-6 p-0 border border-dark">
					<button
						className="btn btn-block text-black rounded"
						// onClick={clickHandler('/checkout')}
						style={{ backgroundColor: '#FFD040' }}
					>
						Recipe
					</button>
				</div>

				<div className="col-6 p-0  border border-dark">
					<button
						className="btn btn-block text-black rounded"
						// onClick={clickHandler('/menu')}
						style={{ backgroundColor: '#FFD040' }}
					>
						Duplicate
					</button>
				</div>
			</div> */}

			<div className="row">
				<div className="col-6 p-0 border border-dark">
					<Link
						className={`btn btn-block btn-danger text-white rounded${
							modifying ? ' disabled' : ''
						}`}
						to="/menu"
					>
						CANCEL
					</Link>
				</div>

				<div className="col-6 p-0 border border-dark">
					<button
						className="btn btn-block text-white rounded"
						onClick={clickHandler('/menu')}
						style={{ backgroundColor: '#5CAD40' }}
						disabled={modifying}
					>
						DONE
					</button>
				</div>
			</div>
		</div>
	);
};

import React from 'react';

import { ModifierScale } from '..';

import Product from '../../domains/Product';

export const ModifierScales: React.FC = () => {
	const modifierScaleModel = Product.selectors.useSelectModifierScales();

	if (modifierScaleModel) {
		return (
			<div className="modifier-scales row">
				{modifierScaleModel.modifierScales.map(
					(modifierScale, index) => (
						<ModifierScale
							key={index}
							modifierScaleIndex={index}
							isActive={
								index === modifierScaleModel.activeModifierScale
							}
							modifierScale={modifierScale}
							selectedIndex={
								modifierScaleModel.modifierScaleIndices[index]
							}
						/>
					)
				)}
			</div>
		);
	} else {
		return <></>;
	}
};

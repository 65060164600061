import actions from './actions';
import reducer from './reducer';
import selectors from './selectors';

const Session: SessionDomain.SessionDomain = {
	actions: actions,
	selectors: selectors,
	reducer
};

export default Session;

import React, { useState } from 'react';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import { useThrottleFn } from 'react-use';

import { RecipeBookProduct, RecipeBookProductGroup } from '..';
import { Drawer, Header } from '../..';

interface InputProps {
	setValue: React.Dispatch<string>;
	value: string;
}

const Input: React.FC<InputProps> = ({ value, setValue }) => (
	<div className="px-3 pb-3">
		<input
			type="text"
			className="form-control bg-light"
			value={value}
			onChange={e => setValue(e.target.value)}
		/>
	</div>
);

export interface SearchProps {
	products: Percomatic.Serialized.Product[];
	productGroups: Percomatic.Serialized.ProductGroup[];
}

export const Search: React.FC<SearchProps> = ({
	products: allProducts,
	productGroups: allProductGroups
}) => {
	const [isActive, setIsActive] = useState(false);
	const [isProductGroupsActive, setIsProductGroupsActive] = useState(true);
	const [isProductsActive, setIsProductsActive] = useState(true);
	const [value, setValue] = useState('');

	const [products, setProducts] = useState<Percomatic.Serialized.Product[]>(
		[]
	);
	const [productGroups, setProductGroups] = useState<
		Percomatic.Serialized.ProductGroup[]
	>([]);

	useThrottleFn(
		value => {
			if (value.length > 2) {
				setProducts(
					allProducts.filter(product =>
						product.name.toLowerCase().includes(value.toLowerCase())
					)
				);
				setProductGroups(
					allProductGroups.filter(productGroup =>
						productGroup.name
							.toLowerCase()
							.includes(value.toLowerCase())
					)
				);
			}
		},
		100,
		[value]
	);

	return (
		<Drawer isActive={isActive} className="recipe-book-search">
			<Header
				isActive={isActive}
				onClick={() => setIsActive(!isActive)}
				label="Search"
				tag="h1"
				color="secondary"
				noHr
			/>

			<Input value={value} setValue={setValue} />

			{value.length > 2 && (
				<>
					<Drawer
						isActive={isProductGroupsActive}
						className="recipe-book-search"
					>
						<Header
							isActive={isProductGroupsActive}
							onClick={() =>
								setIsProductGroupsActive(!isProductGroupsActive)
							}
							label="Product Groups"
							tag="h2"
							color="primary"
						/>

						{productGroups?.map((group, key) => (
							<RecipeBookProductGroup group={group} key={key} />
						))}
					</Drawer>

					<Drawer
						isActive={isProductsActive}
						className="recipe-book-search"
					>
						<Header
							isActive={isProductsActive}
							onClick={() =>
								setIsProductsActive(!isProductsActive)
							}
							label="Products"
							tag="h2"
							color="secondary"
						/>

						{products.map((product, key) => (
							<RecipeBookProduct product={product} key={key} />
						))}
					</Drawer>
				</>
			)}
		</Drawer>
	);
};

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

export interface ItemComponentGroupsProps {
	componentGroups: Percomatic.Serialized.ComponentGroup[] | undefined;
	params: {
		groupId: string;
		productId: string;
		itemId: string;
		componentId: string;
	};
}

export const ItemComponentGroups: React.FC<ItemComponentGroupsProps> = ({
	componentGroups
}) => {
	const params = useParams<{
		groupId: string;
		productId: string;
		itemId: string;
	}>();
	// const state: unknown = useSelector(state => state);
	// const darkMode = state.ui.darkMode;

	return (
		<div className="item-component-groups">
			<h2 className="text-light font-weight-bold mt-5 m-3">
				Component Groups
			</h2>

			<ul className="list-group d-flex col-8">
				{componentGroups && componentGroups.length > 0 ? (
					componentGroups.map((componentGroup, key) => (
						<li
							key={key}
							className={`list-group-item text-light d-flex justify-content-between bg-dark`}
						>
							<Link
								to={
									'/product-management/product-group/' +
									params.groupId +
									'/product/' +
									params.productId +
									'/item/' +
									params.itemId +
									'/component-group/' +
									componentGroup.id
								}
								className="col-7"
							>
								<button className="btn btn-sm bg-success text-white rounded mr-3">
									Edit Component Group
								</button>
							</Link>

							<span className="col-5 text-left text-light font-weight-bold">
								{componentGroup.name}
							</span>
						</li>
					))
				) : (
					// <h3 className={`${darkMode ? 'text-light' : 'text-dark'}`}>
					<h3 className="text-light">
						No Component Groups Available
					</h3>
				)}
			</ul>
		</div>
	);
};

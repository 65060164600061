import actions from './actions';
import reducer from './reducer';
import selectors from './selectors';

const UI: UIDomain.UIDomain = {
	actions: actions,
	selectors: selectors,
	reducer
};

export default UI;

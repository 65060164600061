import { useDispatch } from 'react-redux';

const actions: ProductManagementDomain.ProductManagementActions = {
	useUpdateHeader() {
		const dispatch = useDispatch();

		return header => {
			const action: ProductManagementDomain.Actions.UpdateHeader = {
				type: 'UPDATE_HEADER',
				payload: header
			};

			dispatch(action);
		};
	}
};

export default actions;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import { GenericDrawer, Terminal } from '..';

import { AllStoresQuery } from '../../graphql';

import Admin from '../../domains/Admin';

export interface TerminalStoreProps {
	storeId: number;
	store: AllStoresQuery['stores'][number];
	termIds: AdminDomain.TerminalId[];
}

type TermStatus = boolean | undefined;
type TermStatusTriple = [TermStatus, TermStatus, TermStatus];
type BgColor = 'success' | 'danger' | 'warning' | 'gray';

const TERM_IDS = [1, 2, 3] as const;
type TermId = typeof TERM_IDS[number];

const getTermStatus =
	(storeTerminals: AdminDomain.Terminal[number]) =>
	(termId: TermId): TermStatus => {
		if (!storeTerminals) {
			return undefined;
		}

		const term = storeTerminals[termId];

		if (!term) {
			return undefined;
		}

		return term.status === 'CONNECTED';
	};

function def<T>(x: T): x is Exclude<T, undefined> {
	return x !== undefined;
}

const b2s = (b: boolean): '0' | '1' => (b ? '1' : '0');

const greenIfConnected = (termStatus: TermStatus): 'success' | 'danger' =>
	termStatus ? 'success' : 'danger';

const yellowOrGreen = (
	bohStatus: TermStatus,
	othersStatus: TermStatus
): 'success' | 'danger' | 'warning' => {
	if (!othersStatus) return 'danger';

	if (!bohStatus) return 'warning';

	return 'success';
};

const getBgColor = (
	bh: TermStatus,
	ct: TermStatus,
	dt: TermStatus
): BgColor => {
	const bhD = def(bh);
	const ctD = def(ct);
	const dtD = def(dt);

	const definedBinaryString = `${b2s(bhD)}${b2s(ctD)}${b2s(dtD)}`;

	switch (definedBinaryString) {
		case '001':
			return greenIfConnected(dt);
		case '010':
			return greenIfConnected(ct);
		case '011':
			return greenIfConnected(ct && dt);
		case '100':
			return greenIfConnected(bh);
		case '101':
			return yellowOrGreen(bh, dt);
		case '110':
			return yellowOrGreen(bh, ct);
		case '111':
			return yellowOrGreen(bh, ct && dt);
		default:
			return 'gray';
	}
};

const Sound: React.FC<{ storeId: number; termId: number }> = ({
	storeId,
	termId
}) => {
	console.debug('Sound', storeId, termId);
	Admin.actions.useTerminalSounds(storeId, termId);

	return null;
};

const SoundMemo = React.memo(Sound);

const Flag: React.FC<{
	status: TermStatus;
	termId: number;
	empty: boolean;
}> = ({ status, termId, empty }) => (
	<small
		className={`d-block w-25 text-center${
			empty
				? ''
				: ` border border-dark bg-${
						status
							? 'success'
							: status === undefined
							? 'gray'
							: 'danger'
				  }`
		}`}
	>
		{!empty && termId + 1}
	</small>
);

export const TerminalStore: React.FC<TerminalStoreProps> = ({
	store,
	storeId,
	termIds
}: TerminalStoreProps) => {
	console.debug('TerminalStore', storeId);
	const [show, setShow] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [bgColor, setBgcolor] = useState<BgColor>('gray');

	const terminalStatuses = useSelector<RootState, TermStatusTriple>(
		({ admin: { terminals } }) => {
			return TERM_IDS.map(
				getTermStatus(terminals[storeId])
			) as TermStatusTriple;
		}
	);

	useEffect(() => {
		setBgcolor(getBgColor(...terminalStatuses));
	}, [terminalStatuses]);

	return (
		<div className="terminal-store-container">
			{termIds.map((termId, key) => (
				<SoundMemo {...termId} key={key} />
			))}

			<GenericDrawer
				isActive={isActive}
				onRest={() => {
					if (!isActive && show) {
						setShow(false);
					}
				}}
			>
				<div
					className={`terminal-store cursor-pointer rounded py-1 bg-${bgColor}`}
					onClick={() => {
						if (show) {
							setIsActive(false);
						} else {
							setShow(true);
							setIsActive(true);
						}
					}}
				>
					{store.oLOEnabled && (
						<FontAwesomeIcon
							size="lg"
							icon={faShoppingCart}
							className="pl-1 terminal-store-oo-icon"
						/>
					)}
					<h2 className="text-center mx-auto mb-0">{storeId}</h2>

					<div className="d-flex justify-content-between mt-2 px-2">
						{terminalStatuses.map((status, termId) => (
							<Flag
								key={termId}
								status={status}
								termId={termId}
								empty={!store.driveThru && termId === 2}
							/>
						))}
					</div>
				</div>

				<div className="mt-1">
					{show &&
						[...termIds]
							.sort((a, b) => a.termId - b.termId)
							.map((termId, key) => (
								<div className="mb-1" key={key}>
									<Terminal
										storeId={termId.storeId}
										termId={termId.termId}
									/>
								</div>
							))}
				</div>
			</GenericDrawer>
		</div>
	);
};

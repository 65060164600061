import React from 'react';

import actions from '../../../domains/UI/actions';
import selectors from '../../../domains/UI/selectors';

export interface ModalProps {
	children: JSX.Element;
	showConfirmationButton?: boolean;
	confirmationMessage?: string;
	confirmationButtonHandler?: () => void;
	closeMessage?: string;
	closeButtonHandler?: () => void;
	name?: string;
}

export const Modal: React.FC<ModalProps> = ({
	children,
	showConfirmationButton,
	confirmationMessage,
	confirmationButtonHandler,
	closeMessage,
	closeButtonHandler,
	name
}) => {
	let buttons = <></>;
	const showModal = selectors.useSelectIsModalOpen();
	const toggleModal = actions.useToggleModal();

	buttons = (
		<div
			className={
				showConfirmationButton && confirmationButtonHandler
					? 'row mt-2 w-100 justify-content-between'
					: 'row mt-2 w-100 justify-content-center'
			}
		>
			<button
				className="btn col-4 text-center btn-danger text-white rounded"
				onClick={() => {
					closeButtonHandler && closeButtonHandler();
					toggleModal();
				}}
			>
				{closeMessage ? closeMessage : 'Close'}
			</button>
			{showConfirmationButton && confirmationButtonHandler && (
				<button
					className="btn col-4 text-center btn-success text-white rounded"
					onClick={() => {
						confirmationButtonHandler();
						toggleModal();
					}}
				>
					{confirmationMessage}
				</button>
			)}
		</div>
	);

	const childArray = React.Children.toArray(children);

	return (
		<div
			className={
				showModal
					? 'modal display-block-modal'
					: 'modal display-none-modal'
			}
		>
			<section className="modal-main d-flex flex-column justify-content-between align-items-center px-5 py-4 text-wrap">
				<div className="d-flex flex-column justify-content-between align-items-center overflow-auto text-dark">
					{childArray.map(child => child)}
				</div>
				{buttons}
			</section>
		</div>
	);
};

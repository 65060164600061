import React from 'react';

export interface SelectProps {
	classname?: string;
	label: string;
	name?: string;
	value: string | number;
	change: React.ChangeEventHandler<HTMLSelectElement>;
	required?: boolean;
	options: { optValue: string; optName: string }[];
	defaultOptions?: boolean;
}

export const Select: React.FC<SelectProps> = ({
	classname = '',
	label,
	name = '',
	value,
	change,
	required = false,
	options,
	defaultOptions = false
}: SelectProps) => {
	return (
		<div className={classname}>
			<label>{label}</label>
			<select
				name={name}
				value={value}
				onChange={change}
				required={required}
			>
				{defaultOptions && (
					<option disabled value="">
						-- select an option --
					</option>
				)}
				{options.map((option, key) => (
					<option key={key} value={option.optValue}>
						{option.optName}
					</option>
				))}
			</select>
		</div>
	);
};

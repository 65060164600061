import { useSelector } from 'react-redux';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import { round } from '../../utils';

export const selectors: ProductDomain.ProductDomainSelectors = {
	useSelectModifying() {
		const modifying = useSelector<RootState, boolean>(
			({ product: { modifying } }) => modifying
		);

		return modifying;
	},
	useSelectComponents() {
		const transComponents = useSelector(
			(state: RootState) =>
				state.product.percomatic.transItem?.components ?? []
		);

		const ingredients = useSelector<
			RootState,
			ProductDomain.UI.ComponentIngredient[]
		>(
			state =>
				(state.product.percomatic.transItem?.item.recipe ?? []).filter(
					({ component }) => component !== undefined
				) as ProductDomain.UI.ComponentIngredient[]
		);

		const components: ReturnType<
			ProductDomain.ProductDomainSelectors['useSelectComponents']
		> = [
			...transComponents.map(({ component, quantity }) => ({
				component,
				nutritionFacts: component.inventoryItem.nutritionFacts,
				servings: component.servings,
				quantity
			})),
			...ingredients.map(({ component, quantity }) => ({
				component,
				nutritionFacts: component.inventoryItem.nutritionFacts,
				servings: component.servings,
				quantity
			}))
		];

		return components;
	},
	useSelectInitAndLinkProgress() {
		const initAndLinkProgress = useSelector<
			RootState,
			Pick<
				ProductDomain.State,
				'initAndLinkProgress' | 'initAndLinkProgressMessage'
			>
		>(
			({
				product: { initAndLinkProgress, initAndLinkProgressMessage }
			}) => ({
				initAndLinkProgress,
				initAndLinkProgressMessage
			})
		);

		return initAndLinkProgress;
	},
	useSelectNutrition(nutritionFacts = selectors.useSelectComponents()) {
		const reduceInventoryItemProperty =
			(property: ProductDomain.UI.NutritionFactsProperty) =>
			(
				acc: number,
				{
					nutritionFacts,
					servings,
					quantity
				}: ProductDomain.UI.IngredientNutritionFacts
			) =>
				acc + (nutritionFacts[property] ?? 0) * servings * quantity;

		const size = useSelector<
			RootState,
			Percomatic.Serialized.Size | undefined
		>(
			({
				product: {
					percomatic: { transItem }
				}
			}) => transItem?.item.size
		);

		// const nutritionFacts: ProductDomain.UI.IngredientNutritionFacts[] = transComponents.map(
		// 	transComponent => ({
		// 		nutritionFacts:
		// 			transComponent.component.inventoryItem.nutritionFacts,
		// 		component: transComponent.component,
		// 		servings: transComponent.component.servings,
		// 		quantity: 1
		// 	})
		// );

		const inventoryItemPropertiesAndLabels: {
			property: ProductDomain.UI.NutritionFactsProperty;
			label: string;
			child: boolean;
		}[] = [
			{ child: false, property: 'calories', label: 'Calories' },
			{
				child: true,
				property: 'caloriesFromFat',
				label: 'Calories from Fat'
			},
			{ child: false, property: 'fat', label: 'Total Fat (g)' },
			{
				child: true,
				property: 'saturatedFat',
				label: 'Saturated Fat (g)'
			},
			{ child: true, property: 'transFat', label: 'Trans Fat (g)' },
			{
				child: false,
				property: 'cholesterol',
				label: 'Cholesterol (mg)'
			},
			{ child: false, property: 'sodium', label: 'Sodium (mg)' },
			{
				child: false,
				property: 'carbohydrates',
				label: 'Total Carbohydrates (g)'
			},
			{ child: true, property: 'dietaryFiber', label: 'Dietary Fiber' },
			{ child: true, property: 'sugar', label: 'Sugar (g)' },
			{ child: false, property: 'protein', label: 'Protein (g)' },
			{ child: false, property: 'caffeine', label: 'Caffeine (mg)' }
		];

		const output: ProductDomain.UI.NutritionRow[] = [
			{
				child: false,
				value: size?.name ?? '',
				label: 'Serving Size'
			}
		];

		for (const {
			property,
			child,
			label
		} of inventoryItemPropertiesAndLabels) {
			output.push({
				label,
				child,
				value: round(
					nutritionFacts.reduce(
						reduceInventoryItemProperty(property),
						0
					)
				)
			});
		}

		return output;
	},
	useSelectHasModifierGroups() {
		const hasModifierGroups = useSelector<RootState, boolean>(
			state =>
				state.product.percomatic.modifierGroups !== null &&
				state.product.percomatic.modifierGroups.modifierGroups.length >
					0
		);

		return hasModifierGroups;
	},
	useSelectHasModifierScales() {
		const hasModifierScales = useSelector<RootState, boolean>(
			state =>
				state.product.percomatic.modifierScales !== null &&
				state.product.percomatic.modifierScales.modifierScales.length >
					0
		);

		return hasModifierScales;
	},
	useSelectHasProductVersions() {
		const hasProductVersions = useSelector<RootState, boolean>(
			state =>
				state.product.percomatic.productVersions.productVersions
					.length > 0
		);

		return hasProductVersions;
	},
	useSelectHasSizes() {
		const hasSizes = useSelector<RootState, boolean>(
			state => state.product.percomatic.sizes.sizes.length > 0
		);

		return hasSizes;
	},
	useSelectHasTransItem() {
		return false;
	},
	useSelectModifierGroups() {
		const modifierGroups = useSelector<
			RootState,
			ProductDomain.UI.ModifierGroups
		>(state => ({
			...state.product.percomatic.modifierGroups,
			activeModifierGroup: state.product.activeModifierGroup
		}));

		return modifierGroups;
	},
	useSelectModifierScales() {
		const modifierScales = useSelector<
			RootState,
			ProductDomain.UI.ModifierScales
		>(state => ({
			...state.product.percomatic.modifierScales,
			activeModifierScale: state.product.activeModifierScale
		}));

		return modifierScales;
	},
	useSelectProductVersions() {
		const productVersions = useSelector<
			RootState,
			ProductDomain.UI.ProductVersions
		>(state => ({
			...state.product.percomatic.productVersions,
			isProductVersionOptionActive:
				state.product.isProductVersionOptionActive
		}));

		return productVersions;
	},
	useSelectSizes() {
		const sizes = useSelector<RootState, ProductDomain.UI.Sizes>(state => ({
			...state.product.percomatic.sizes,
			isSizeOptionActive: state.product.isSizeOptionActive
		}));

		return sizes;
	},
	useSelectTransItem() {
		return null;
	},
	useSelectHasNutrition() {
		return true;
	}
};

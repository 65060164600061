import {
	GiftCardPaymentsFieldFragment,
	RefundFieldsFragment
} from '../../graphql';

export const gcRefundStatus = (
	orderGiftCardPayments: GiftCardPaymentsFieldFragment[],
	orderRefunds?: RefundFieldsFragment[]
): boolean =>
	orderGiftCardPayments.every(orderGcp => {
		const gcPaymentAmount = orderGcp.amount;
		const gcPaymentId = orderGcp.id;

		let amountAlreadyRefunded = 0;

		if (orderRefunds) {
			// Iterate over what has already been refunded
			for (const refund of orderRefunds) {
				// Look for destination with same Id in orderRefunds
				for (const destination of refund.destinations) {
					if (destination.paymentId === gcPaymentId) {
						amountAlreadyRefunded += destination.amount;
					}
				}
			}
		}

		return gcPaymentAmount === amountAlreadyRefunded;
	});

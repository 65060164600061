import moment from 'moment';
import { useMemo } from 'react';
import { ConnectionsQuery } from '../../graphql';

export type ConnectionProps = React.HTMLAttributes<HTMLDivElement> & {
	connection: ConnectionsQuery['connections'][number];
};

export const Connection: React.FC<ConnectionProps> = ({
	connection,
	...props
}) => {
	const { storeId, termId, disconnected, connectionId, time, value } =
		connection;

	const date = useMemo(() => moment(time), [time]);

	return (
		<div
			{...props}
			className="p-2 border border-light text-light overflow-hidden"
		>
			<small className="d-block text-muted">{connection.key}</small>

			<div className="d-flex justify-content-between align-items-center">
				<h5 className="m-0">
					<span className="text-primary">{storeId}</span>{' '}
					<span className="text-secondary">{termId}</span>{' '}
					<small className="text-muted">{connectionId}</small>
				</h5>

				{disconnected && (
					<h5 className="m-0 text-danger">DISCONNECTED</h5>
				)}
			</div>

			<h4 className="text-success m-0">{date.fromNow()}</h4>
			<small>{value}</small>
		</div>
	);
};

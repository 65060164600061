import React from 'react';

export const Table: React.FC = ({ children }) => {
	const childArray = React.Children.toArray(children);
	return (
		<div className="mt-2 table-container">
			<table className={`table table-striped table-hover`}>
				<tbody>
					{childArray?.length > 1 ? (
						childArray.map((child, key) => (
							<tr className={`text-primary border-0`} key={key}>
								{child}
							</tr>
						))
					) : (
						<tr className={`text-light border-0`}>{children}</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

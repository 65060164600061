import React, { useState } from 'react';

import { Table, TableTextArea, TextInput } from '../../..';

export const ProductDetails: React.FC = () => {
	const [description, setDescription] = useState('');
	const [product, setProduct] = useState('Physical Product');

	return (
		<>
			<h3 className="px-3 dp-h3">Product Details</h3>
			<div className="px-3">
				<Table>
					<TableTextArea
						label="Description"
						readOnly={false}
						state={description}
						setState={setDescription}
					/>
					<TextInput
						label="Type of Product"
						readOnly={true}
						state={product}
						setState={setProduct}
					/>
				</Table>
			</div>
		</>
	);
};

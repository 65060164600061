import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { LoginForm, Loading, QRCode } from '../components';

import Logo from '../assets/imgs/logo.png';

import Session from '../domains/Session';
import Network from '../domains/Network';
import UI from '../domains/UI';

import { useAuthenticateQrCodeMutation } from '../graphql';
import { useDispatch } from 'react-redux';

export default function SignIn(): JSX.Element {
	const dispatch = useDispatch();
	const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);
	const setOrientation = UI.actions.useSetOrientation();

	const { push } = useHistory();
	const loaded = Network.selectors.useSelectSessionLoaded();
	const authenticated = Session.selectors.useSelectAuthenticated();

	const [authenticateQRCode, { data }] = useAuthenticateQrCodeMutation();

	useEffect(() => {
		if (data) {
			const action: SessionDomain.Actions.SessionLoaded = {
				type: 'SESSION_LOADED',
				payload: data.authenticateQRCode
			};

			dispatch(action);
			push('/employee');
		}
	}, [data, dispatch, push]);

	useEffect(() => {
		if (authenticated) {
			push('/employee');
		}
	}, [authenticated, push]);

	return (
		<>
			{loaded ? (
				<>
					<div className="p-3">
						<div className="d-flex align-items-center justify-content-between mb-3">
							<img
								src={Logo}
								alt="BIGGBY COFFEE"
								className="d-block login-form-logo"
							/>

							<h5 className="text-light d-none d-sm-block mb-0">
								Please sign in
							</h5>
						</div>

						{isQRCodeVisible && (
							<QRCode
								successAction={result =>
									authenticateQRCode({
										variables: { qrcode: result }
									})
								}
								cancelAction={() => {
									setIsQRCodeVisible(false);
									setOrientation('any');
								}}
							/>
						)}
					</div>

					{!isQRCodeVisible && (
						<div className="position-absolute w-100 h-100">
							<LoginForm
								toggleQR={() => {
									setOrientation('portrait');
									setIsQRCodeVisible(true);
								}}
							/>
						</div>
					)}
				</>
			) : (
				<Loading />
			)}
		</>
	);
}

import { TextField } from './TextField';
import { formatCents } from '../utils';

export type CentsFieldProps = {
	label: string;
	value: number | undefined;
};

export const CentsField: React.FC<CentsFieldProps> = ({ label, value }) => (
	<TextField label={label} value={formatCents(value)} />
);

import React from 'react';
// import tooltipJSON from '../assets/tooltips.json';
import { Tooltip } from '../../Generic';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface TextInputProps {
	label: string;
	name?: string;
	readOnly?: boolean;
	placeholder?: string;
	state?: string | number;
	setState?: React.Dispatch<React.SetStateAction<string>>;
	content?: React.ReactNode;
	validated?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
	label,
	name,
	readOnly,
	placeholder,
	state,
	setState,
	content,
	validated
}) => {
	return (
		<>
			<div className="d-flex align-items-center">
				<th className="border-0 py-3">{label}</th>
				{content && (
					<Tooltip content={content}>
						{' '}
						<FontAwesomeIcon
							icon={faQuestionCircle}
							color="white"
						/>
					</Tooltip>
				)}
			</div>
			<td className="border-0">
				<input
					readOnly={readOnly ?? false}
					type="text"
					name={name || label}
					className={`form-control ${
						!validated && 'text-danger font-weight-bold'
					}`}
					value={state}
					onChange={e =>
						setState ? setState(e.target.value) : console.log()
					}
					placeholder={placeholder}
				></input>
			</td>
		</>
	);
};

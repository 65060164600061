import { Selector, Table } from '../../../components';
import { useReturnReasonsQuery } from '../../../graphql';
import Refund from '../../../domains/Refund';
import { Loading } from '../../Loading';
import { Error } from '../../Generic';

export const ReturnReasons: React.FC = () => {
	const setReturnReason = Refund.actions.useSetReturnReasonId();

	const { data, error } = useReturnReasonsQuery({
		onCompleted({ returnReasons }) {
			setReturnReason(returnReasons[0].returnReasonId);
		}
	});

	if (!data) return <Loading />;

	if (error) return <Error error={error.message} />;

	return (
		<div className="p-2 overflow-auto order-number">
			<Table>
				<Selector
					label="Return Reason"
					options={data.returnReasons.map(
						({ returnReasonId, name }): [string, string] => [
							String(returnReasonId),
							name ?? ''
						]
					)}
					setValue={value => setReturnReason(Number(value))}
				/>
			</Table>
		</div>
	);
};

import React from 'react';

import { useSelector } from 'react-redux';

import { Cart, SendOrder } from '../components';

const Checkout: React.FC = () => {
	const store = useSelector((state: RootState) => {
		if (state.network.session.loaded) {
			return state.session.store;
		} else {
			return false;
		}
	});

	if (store) {
		return (
			<div className="checkout h-100 overflow-auto">
				<Cart />

				<hr />

				<SendOrder />
			</div>
		);
	} else {
		return null;
	}
};

export default Checkout;

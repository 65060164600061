import {
	makeTableComponent,
	TextField,
	MoneyField,
	CentsField,
	DateField
} from '../../../components';
import { formatTime, formatMoney } from '../../../utils';
import { OLOItem, ModifierPair } from './types';
import { ModifierDetails } from './ModifierDetails';

const ModifierPricesTable = makeTableComponent<
	OLOItem['modifiers'][number]['modifier']['prices'][number]
>(['id', 'storeId', 'tierId', 'price', 'lastModified', 'serverLastModified'], {
	formatColumnFunctions: {
		price: formatMoney,
		lastModified: formatTime,
		serverLastModified: formatTime
	}
});

export type TransModifierComparisonProps = ModifierPair & {
	storeId: number;
	priceTierId: number;
};

export const TransModifierComparison: React.FC<TransModifierComparisonProps> =
	({ storeId, priceTierId, oloModifier, percModifier }) => (
		<div className="col-12">
			<div className="border border-light rounded py-2 mb-2">
				<div className="row">
					<div className="col-12 mb-2">
						<ModifierDetails modifier={oloModifier.modifier} />
					</div>

					<ModifierPricesTable
						data={oloModifier.modifier.prices.filter(
							price =>
								(price.storeId === storeId ||
									price.storeId === 0) &&
								(price.tierId === priceTierId ||
									price.tierId === 0)
						)}
					/>

					<div className="col-6">
						<h4 className="text-danger mb-0">OLO</h4>
					</div>

					<div className="col-6">
						<h4 className="text-danger mb-0">PERC</h4>
					</div>

					<div className="col-6">
						<TextField
							label="OLO Modifier ID"
							value={oloModifier.id}
						/>
					</div>

					<div className="col-6">
						<DateField
							label="PERC Modifier Add Time"
							value={percModifier.modifierAddTime}
						/>
					</div>

					<div className="col-6">
						<TextField
							label="Quantity"
							value={String(oloModifier.quantity)}
						/>
					</div>

					<div className="col-6">
						<TextField
							label="Quantity"
							value={String(percModifier.quantity)}
						/>
					</div>

					<div className="col-6">
						<CentsField label="Price" value={oloModifier.price} />
					</div>

					<div className="col-6">
						<div className="gap">
							<MoneyField
								label="Price"
								value={percModifier.price}
							/>

							<MoneyField
								label="Base Price"
								value={percModifier.basePrice}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

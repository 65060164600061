import { useSelector } from 'react-redux';

const selectors: UIDomain.UIDomainSelectors = {
	useSelectAlerts() {
		const alerts = useSelector((state: RootState) => state.ui.alertIds);

		return alerts;
	},
	useSelectAlert(id: string) {
		const alert = useSelector((state: RootState) => state.ui.alerts[id]);

		return alert;
	},
	useSelectOrientation() {
		const orientation = useSelector(
			(state: RootState) => state.ui.orientation
		);

		return orientation;
	},
	useSelectIsModalOpen() {
		const orientation = useSelector(
			(state: RootState) => state.ui.modalOpen
		);

		return orientation;
	}
};

export default selectors;

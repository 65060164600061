import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

//import { useAddProductMutation } from '../../../graphql';

import actions from '../../../../domains/UI/actions';

import {
	BooleanInput,
	ProductVersionSelector,
	Table,
	TableDropdown,
	TextInput
} from '../..';
import { Modal } from '../../../Generic';

import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import api from '../../../../worker';
import { PercomaticLoading, SaveButton } from '../../..';

// import {
// 	ticketNameTooltip,
// 	otherNamesTooltip,
// 	imageUrlTooltip,
// 	printableTooltip,
// 	autoModifyTooltip,
// 	includeInSalesTooltip,
// 	productVersionTooltip,
// 	defaultSizeTooltip,
// 	tokenProductTooltip,
// 	productGroupTooltip
// } from '../../tooltips';

function Body() {
	const params: { productId: string } = useParams();
	const history = useHistory();

	useEffect(() => {
		(async () => {
			const sizes = await api.getSizes();
			const productGroups = await api.getProductGroups();
			const products = await api.getProducts();

			setDefaultSize(sizes.filter(size => size.id === 3));
			setProductId(String(products[products.length - 2].id + 1));
			setProducts(products);

			setAllProductGroups(productGroups);
			setProductProductGroups(
				productGroups.filter(group => group.id === 1000)
			);

			if (params.productId) {
				const product = await api.getProduct(Number(params.productId));
				if (product && product.defaultSize) {
					setName(product.name);
					setTicketName(product.ticketName ?? '');
					setOtherNames(product.otherNames ?? '');
					setVersionId(product?.productVersion?.id ?? 1);
					setPrintable(product.printable);
					setAutoModify(product.autoModify);
					setDefaultSize([product.defaultSize]);
				}
			}

			setSizes(sizes);
		})();
	}, [params.productId]);

	const toggleModal = actions.useToggleModal();

	const [allProductGroups, setAllProductGroups] = useState<
		Percomatic.Serialized.ProductGroup[]
	>([]);

	const [productProductGroups, setProductProductGroups] = useState<
		Percomatic.Serialized.ProductGroup[]
	>([]);

	const [sizes, setSizes] = useState<Percomatic.Serialized.Size[]>([]);

	const [name, setName] = useState('');
	const [ticketName, setTicketName] = useState('');
	const [otherNames, setOtherNames] = useState('');
	const [defaultSize, setDefaultSize] = useState<
		Percomatic.Serialized.Size[]
	>([]);
	const [, setVersionId] = useState<number>(1);
	const [printable, setPrintable] = useState(true);
	const [autoModify, setAutoModify] = useState(true);
	const [includeInSales, setIncludeInSales] = useState(true);
	const [imageUrl, setImageUrl] = useState('');
	const [productId, setProductId] = useState('');

	const [products, setProducts] = useState<Percomatic.Serialized.Product[]>(
		[]
	);
	const [tokenProduct, setTokenProduct] = useState<
		Percomatic.Serialized.Product[]
	>([]);

	// const productGoupIds = productProductGroups.map(group => group.id);

	// const newProductData = {
	// 	productId: Number(productId),
	// 	name: name,
	// 	defaultSizeId: Number(defaultSize[0]?.id),
	// 	productVersionId: Number(versionId),
	// 	otherNames: otherNames,
	// 	ticketName: ticketName,
	// 	// image: imageUrl,
	// 	printable,
	// 	autoModify,
	// 	includeInSales,
	// 	tokenProductId: tokenProduct[0] ? tokenProduct[0].id : undefined,
	// 	productGroupIds: productGoupIds
	// };

	// const [addProduct] = useAddProductMutation({
	// 	variables: { product: newProductData }
	// });

	const submitNewProduct = () => {
		//addProduct();
		setName('');
		setDefaultSize([]);
		setVersionId(1);
		setImageUrl('');
		setPrintable(true);
		setAutoModify(true);
		setIncludeInSales(true);
		setTokenProduct([]);
		setOtherNames('');
		setTicketName('');
		history.push(
			`/product-management/new/item/product-id/${params.productId}`
		);
	};

	return (
		<>
			<Table>
				<TextInput
					label="Id"
					state={productId}
					setState={setProductId}
					readOnly={true}
				/>

				<TextInput label="Name" state={name} setState={setName} />
				<TextInput
					label="Ticket Name"
					state={ticketName}
					setState={setTicketName}
					content={'ticketNameTooltip'}
				/>
				<TextInput
					label="Other Names"
					state={otherNames}
					setState={setOtherNames}
					content={'otherNamesTooltip'}
				/>
				<TextInput
					label="Image URL"
					state={imageUrl}
					setState={setImageUrl}
					content={'imageUrlTooltip'}
				/>
				<BooleanInput
					label="Printable"
					selected={printable}
					setState={setPrintable}
					content={'printableTooltip'}
				/>
				<BooleanInput
					label="Auto Modify"
					selected={autoModify}
					setState={setAutoModify}
					content={'autoModifyTooltip'}
				/>
				<BooleanInput
					label="Include in Sales"
					setState={setIncludeInSales}
					selected={includeInSales}
					content={'includeInSalesTooltip'}
				/>
				<ProductVersionSelector
					setState={setVersionId}
					content={'productVersionTooltip'}
				/>
			</Table>

			<TableDropdown
				state={defaultSize}
				dropdownItems={sizes}
				label="Default Size"
				showDropdownButton={false}
				singleRelationship={true}
				onDelete={(_, key) =>
					setDefaultSize(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setDefaultSize([item])}
				content={'defaultSizeTooltip'}
			/>

			<TableDropdown
				state={tokenProduct}
				dropdownItems={products}
				label="Token Product"
				showDropdownButton={true}
				singleRelationship={true}
				onDelete={(_, key) =>
					setTokenProduct(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) => setTokenProduct([item])}
				content={'tokenProductTooltip'}
			/>

			<TableDropdown
				state={productProductGroups}
				dropdownItems={allProductGroups}
				label="Product Group"
				showDropdownButton={true}
				onDelete={(_, key) =>
					setProductProductGroups(items =>
						items.filter((_, index) => index !== key)
					)
				}
				onAdd={(item, key) =>
					setProductProductGroups(items => [...items, item])
				}
				content={'productGroupTooltip'}
			/>
			<Modal
				showConfirmationButton={true}
				confirmationMessage="Yes"
				confirmationButtonHandler={submitNewProduct}
			>
				{
					<>
						<h1>Are you sure?</h1>
						<h3 className="text-center text-primary font-weight-bold mt-5">
							Clicking yes will create this product and take you
							to the page to create a new item for this product.
						</h3>
					</>
				}
			</Modal>
			<SaveButton onClick={() => toggleModal()} />
		</>
	);
}

export const Product: React.FC = () => {
	return (
		<div>
			<PercomaticLoading loaded children={<Body />} />
		</div>
	);
};

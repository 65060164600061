/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useSelector } from 'react-redux';

const selectors: AdminDomain.AdminDomainSelectors = {
	useSelectTerminal(storeId, termId) {
		const terminal = useSelector(
			(state: RootState) => state.admin.terminals[storeId][termId]
		);

		return terminal;
	},
	useSelectTerminals(): AdminDomain.TerminalId[] {
		const terminalIds = useSelector(
			(state: RootState) => state.admin.terminalIds
		);

		return terminalIds;
	},
	useSelectOrders(): AdminDomain.OrderId[] {
		console.log('Admin: selectors: useSelectOrders');

		const orderIds = useSelector((state: RootState) => {
			console.log('Admin: selectors: useSelectOrders: useSelector');
			console.log(
				'Admin: selectors: useSelectOrders: useSelector: state.admin.orderIds',
				state.admin.orderIds
			);

			return state.admin.orderIds;
		});

		console.log('Admin: selectors: useSelectOrders: orderIds', orderIds);

		return orderIds;
	},
	useSelectOrder(storeId, termId, orderId) {
		const order = useSelector((state: RootState) => {
			console.log('Admin: selectors: useSelectOrder: useSelector');
			if (
				state.admin.orders[storeId] &&
				state.admin.orders[storeId]![termId] &&
				state.admin.orders[storeId]![termId]![orderId]
			) {
				return state.admin.orders[storeId]![termId]![orderId]!;
			} else {
				throw new Error(
					'store/term/order id does not exist in orders dictionary'
				);
			}
		});

		return order;
	},
	useSelectTerminalOrders(
		storeId: number,
		termId: number
	): AdminDomain.OrderId[] {
		const orderTerminalIds = useSelector((state: RootState) =>
			state.admin.orderIds.filter(
				orderId =>
					orderId.storeId === storeId && orderId.termId === termId
			)
		);

		return orderTerminalIds;
	},
	useSelectDisplayAllTerminals() {
		const displayAllTerminals = useSelector(
			(state: RootState) => state.admin.displayAllTerminals
		);

		return displayAllTerminals;
	},
	useSelectPendingOrderIds() {
		const pendingOrderIds = useSelector(
			(state: RootState) => state.admin.pendingOrders.ids
		);

		return pendingOrderIds;
	},

	useSelectFilterPendingOrders() {
		const filter = useSelector(
			(state: RootState) => state.admin.filterPendingOrders
		);
		return filter;
	}
};

export default selectors;

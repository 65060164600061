import { useState } from 'react';
import { useInterval } from 'react-use';

export const useNow = (poll = 1000): Date => {
	const [now, setNow] = useState(new Date());

	useInterval(() => {
		setNow(new Date());
	}, poll);

	return now;
};

import React from 'react';

export const SubmitEvidenceButton: React.FC = () => {
	return (
		<>
			<hr />
			<div className="d-flex justify-content-center px-3">
				<div className="w-50">
					<p>
						Once you submit your evidence for this dispute, we’ll
						send it to the customer’s bank for review. The bank will
						make a final decision within 3 months, and we’ll be in
						touch if they need any more information.
					</p>
					<div className="d-flex justify-content-center">
						<button className="btn btn-secondary">
							Submit Evidence
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

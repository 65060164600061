import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import React from 'react';

export interface PriorityProductsProps {
	priorityProducts: Percomatic.Serialized.Product[];
	selectProduct: (id: string | number) => void;
}

export const PriorityProducts: React.FC<PriorityProductsProps> = ({
	priorityProducts,
	selectProduct
}) => {
	return (
		<div className="priority-products grid-200">
			{priorityProducts.map((product, key) => (
				<button
					style={{ backgroundColor: '#648AC7' }}
					className="btn btn-block mt-0 rounded"
					key={key}
					onClick={() => selectProduct(product.id)}
				>
					{product.name}
				</button>
			))}
		</div>
	);
};

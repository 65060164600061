import {
	CreditCardPaymentsFieldFragment,
	GiftCardPaymentsFieldFragment,
	RefundDestinationInput
} from '../../graphql';

export const isFullyRefunded = (
	payment: GiftCardPaymentsFieldFragment | CreditCardPaymentsFieldFragment,
	orderRefundDestinations: RefundDestinationInput[]
): boolean => {
	const paymentAmount = payment.amount;
	let refundAmount = 0;

	for (let i = 0; i < orderRefundDestinations.length; i++) {
		if (orderRefundDestinations[i].paymentId === payment.id) {
			refundAmount += orderRefundDestinations[i].amount;
		}
	}

	return paymentAmount === refundAmount;
};

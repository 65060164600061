import React from 'react';

import { useAcceptDisputeMutation } from '../../../graphql';

export interface AcceptDisputeButtonProps {
	disputeId: string;
	accepted: () => void;
}

export const AcceptDisputeButton: React.FC<{
	disputeId: string;
	accepted: () => void;
}> = ({ disputeId, accepted }) => {
	const [acceptDispute] = useAcceptDisputeMutation();

	const acceptDisputeAction = () => {
		acceptDispute({
			variables: {
				disputeId
			}
		});
		accepted();
	};

	return (
		<button
			className="btn btn-secondary mr-3"
			onClick={acceptDisputeAction}
		>
			Accept Dispute
		</button>
	);
};

import { Link } from 'react-router-dom';
import { TableItemType as Item } from '../TableDropdown/TableDropdown';

export interface LinkedTableItemProps<Item> {
	key: number;
	val: Item;
	to: string;
}

export const LinkedTableItem = ({
	key,
	val,
	to
}: LinkedTableItemProps<Item>): React.ReactElement => {
	return (
		<Link to={to}>
			{' '}
			<td className="font-weight-bold" key={key}>
				{val}
			</td>
		</Link>
	);
};

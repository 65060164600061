import React, { useState, useEffect, useRef } from 'react';

//import { useEditComponentGroupMutation } from '../../graphql';

import actions from '../../../domains/UI/actions';

import { BooleanInput, Table, TableDropdown, TextInput } from '..';
import { Modal } from '../../Generic';
import { SaveButton } from '../..';
import TaxStateInput from '../TaxStateInput';

import { Percomatic, utils } from '@biggby-coffee/percomatic-typescript';
import api from '../../../worker';

// import {
// 	suppressNameFromRecipeTooltip,
// 	componentGroupTooltip
// } from '../tooltips';

const { defined } = utils;

export interface ComponentGroupFieldsProps {
	componentGroupData: Percomatic.Serialized.ComponentGroup;
}

export const ComponentGroupFields: React.FC<Percomatic.Serialized.ComponentGroup> =
	componentGroupData => {
		const toggleModal = actions.useToggleModal();

		useEffect(() => {
			(async () => {
				const componentIdPromises = componentGroupData.componentIds
					.filter(defined)
					.map(id => api.getComponent(id));
				const components = await Promise.all(componentIdPromises);
				const componentGroups = await api.getComponentGroups();

				setComponentGroups(componentGroups);
				setComponentGroupComponents(
					components.filter(defined).map(component => component)
				);
			})();
		}, [componentGroupData.componentIds]);

		const [componentGroupComponents, setComponentGroupComponents] =
			useState<Percomatic.Serialized.Component[]>([]);

		const [componentGroups, setComponentGroups] = useState<
			Percomatic.Serialized.ComponentGroup[]
		>([]);

		const [parentGroup, setParentGroup] = useState<
			Percomatic.Serialized.ComponentGroup[]
		>([]);

		const [suppressNameFromRecipe, setSuppressNameFromRecipe] = useState(
			componentGroupData.suppressNameFromRecipe ? true : false
		);

		const [components, setComponents] = useState<
			Percomatic.Serialized.Component[]
		>([]);

		// need to add percomatic later and to edit component group
		const [taxState, setTaxState] = useState('');
		const [taxRate, setTaxRate] = useState('');

		// const [editComponentGroup] = useEditComponentGroupMutation({
		// 	variables: {
		// 		componentGroup: {
		// 			...componentGroupData,
		// 			componentGroupId: Number(componentGroupData.id),
		// 			parentGroupId: parentGroup[0] ? parentGroup[0].id : undefined,
		// 			suppressNameFromRecipe,
		// 			unitId: Number(componentGroupData.unit?.id)
		// 		}
		// 	}
		// });

		const submitEditedComponentGroup = () => {
			//editComponentGroup();
		};

		const validated = useRef(false);

		useEffect(() => {
			(async () => {
				const components = await api.getComponents();

				setComponents(components);
			})();
		}, []);

		return (
			<>
				<Table>
					<TextInput
						label="Name"
						name="name"
						state={componentGroupData.name}
					/>

					<BooleanInput
						label="Suppress Name From Recipe"
						setState={setSuppressNameFromRecipe}
						selected={suppressNameFromRecipe}
						content={'suppressNameFromRecipeTooltip'}
					/>

					<TaxStateInput
						label="Tax State"
						state={taxState}
						setState={setTaxState}
					/>

					{taxState && (
						<TextInput
							label="Tax Rate"
							name="tax rate"
							state={taxRate}
							setState={setTaxRate}
							placeholder="0.00"
						/>
					)}

					<TextInput
						label="Id"
						name="id"
						state={componentGroupData.id}
						readOnly={true}
					/>
				</Table>

				<TableDropdown
					dropdownItems={components}
					state={componentGroupComponents}
					label="Component"
					showDropdownButton={true}
					onDelete={(_, key) =>
						setComponentGroupComponents(items =>
							items.filter((_, index) => index !== key)
						)
					}
					onAdd={(item, key) =>
						setComponentGroupComponents(items => [...items, item])
					}
					content={'componentGroupTooltip'}
				/>

				<TableDropdown
					dropdownItems={componentGroups}
					state={parentGroup}
					label="Parent Group"
					singleRelationship={true}
					showDropdownButton={false}
					onDelete={(_, key) =>
						setParentGroup(items =>
							items.filter((_, index) => index !== key)
						)
					}
					onAdd={(item, key) => setParentGroup([item])}
				/>

				<Modal
					showConfirmationButton={
						validated.current === false ? false : true
					}
					confirmationMessage="Yes"
					confirmationButtonHandler={submitEditedComponentGroup}
				>
					{validated.current === false ? (
						<>
							<h1 className="mb-4 text-danger">
								There was a problem
							</h1>
							<p>The following properties must be a number:</p>
							<ul>
								<li>Parent Group Id</li>
							</ul>
						</>
					) : (
						<>
							<h1>Are you sure?</h1>
							<p>
								This action will affect the{' '}
								{componentGroupData.name} component group.
							</p>
						</>
					)}
				</Modal>

				<SaveButton onClick={() => toggleModal()} />
			</>
		);
	};

import { useMemo } from 'react';
import { useParams } from 'react-router';

import { Error, Loading } from '../../../components';
import { useOrderQuery } from '../../../graphql';
import { getItemPairs } from './getItemPairs';
import { TransItemComparison } from './TransItemComparison';
import { Metadata } from './Metadata';

export const Order: React.FC = () => {
	const params = useParams<{ id: string }>();

	const { data, error } = useOrderQuery({ variables: { id: params.id } });

	const { itemPairs, orphans } = useMemo(() => {
		if (data && data.order.transaction) {
			return getItemPairs(data.order.items, data.order.transaction.items);
		}

		return {
			itemPairs: [],
			orphans: {
				oloItems: [],
				percItems: []
			}
		};
	}, [data]);

	if (error) return <Error error={error.message} />;
	if (!data) return <Loading />;

	return (
		<div className="order">
			<div className="row mt-3">
				<div className="col">
					<h1 className="text-light mb-0">
						Online Order{' '}
						<span className="font-weight-light">{params.id}</span>
					</h1>
				</div>
			</div>

			<Metadata {...data.order} />

			<div className="row mt-2">
				<div className="col-12">
					<h2 className="text-light p-0 mb-0">Order Items</h2>
				</div>

				<div className="col-12">
					{itemPairs.map(({ oloItem, percItem }, key) => (
						<TransItemComparison
							storeId={data.order.storeId}
							priceTierId={data.order.store.priceTierId ?? 0}
							oloItem={oloItem}
							percItem={percItem}
							key={key}
						/>
					))}
				</div>
			</div>

			{(orphans.oloItems.length > 0 || orphans.percItems.length > 0) && (
				<div className="row mb-2">
					<div className="col-12">
						<h2 className="text-light mb-0">Orphans</h2>
					</div>

					<div className="col-6">
						<h3 className="text-light mb-0">OLO</h3>

						<pre className="text-light">
							{JSON.stringify(orphans.oloItems, null, '\t')}
						</pre>
					</div>

					<div className="col-6">
						<h3 className="text-light mb-0">PERC</h3>

						<pre className="text-light">
							{JSON.stringify(orphans.percItems, null, '\t')}
						</pre>
					</div>
				</div>
			)}
		</div>
	);
};

import React from 'react';
import { Link } from 'react-router-dom';

import {
	BrandStandardEdgeFieldsFragment,
	BrandStandardsConnectionFieldsFragment
} from '../../../graphql';

export type BrandStandardsTableProps = {
	data: BrandStandardEdgeFieldsFragment[];
};

const BrandStandard: React.FC<
	BrandStandardsConnectionFieldsFragment['edges'][number]
> = ({ cursor, node: brandStandard }) => (
	<tr>
		<td>{brandStandard.brandStandardId}</td>
		<td>{brandStandard.description}</td>
		<td>{brandStandard.resolution}</td>
		<td>
			<Link to={`/brand-standards/${brandStandard.id}`}>Details</Link>
		</td>
	</tr>
);

export const BrandStandardsTable: React.FC<BrandStandardsTableProps> = ({
	data
}) => {
	return (
		<table className="table table-dark">
			<thead>
				<tr className="text-primary">
					<th>ID</th>
					<th>Description</th>
					<th>Resolution</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{data?.map(edge => (
					<BrandStandard {...edge} key={edge.node.id} />
				))}
			</tbody>
		</table>
	);
};

import React from 'react';
import { animated, useTransition } from 'react-spring';

import { Loading } from '..';

export interface Props {
	loaded: boolean;
	noMargin?: boolean;
	noContainer?: boolean;
	className?: string;
}

export const Loader: React.FC<Props> = (
	props: React.PropsWithChildren<Props>
) => {
	const loaded = props.loaded === undefined ? false : props.loaded;
	const transitions = useTransition(loaded, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 }
	});

	const className =
		props.className || (props.noContainer ? '' : 'loading-container');

	return (
		<>
			{transitions.map(({ item, key, props: styles }) =>
				item ? (
					<animated.div key={key} style={styles}>
						{props.children}
					</animated.div>
				) : (
					<animated.div key={key} style={styles}>
						<div className={className}>
							{props.noMargin ? (
								<Loading />
							) : (
								<div className="mt-5">
									<Loading />
								</div>
							)}
						</div>
					</animated.div>
				)
			)}
		</>
	);
};

import { useSelector } from 'react-redux';

const selectors: NetworkDomain.NetworkDomainSelectors = {
	useSelectSessionLoaded: (): boolean => {
		const session = useSelector(
			(state: RootState) => state.network.session.loaded
		);

		return session;
	},
	useSelectAllProductDataLoaded: (): boolean => {
		const allProductData = useSelector(
			(state: RootState) => state.network.allProductData.loaded
		);

		return allProductData;
	},
	useSelectSettingStoreId: (): boolean => {
		const settingStoreStoreId = useSelector(
			(state: RootState) => state.network.settingStoreId.loading
		);

		return settingStoreStoreId;
	}
};

export default selectors;

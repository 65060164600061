import React from 'react';

import '../../../sass/Toggle.scss';

export interface ToggleProps {
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	checked: boolean;
	label: string;
	inline?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
	onChange,
	label,
	checked,
	inline = false
}) => (
	<div className={`toggle${inline ? ' d-flex align-items-center' : ''}`}>
		<label className="switch">
			<input onChange={onChange} type="checkbox" checked={checked} />

			<span className="slider round" />
		</label>

		<div className={inline ? 'ml-2' : ''}>{label}</div>
	</div>
);

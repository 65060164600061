const initialState: SessionDomain.State = {
	cart: { id: '', items: [] },
	authenticated: null
};

const reducer = (
	state: SessionDomain.State = initialState,
	action: SessionDomain.Action
): SessionDomain.State => {
	switch (action.type) {
		case 'STORE_SET':
			return {
				...state,
				store: action.payload.store
			};
		case 'STARTED_LOGGING_OUT':
			return {
				...initialState
			};
		case 'AUTHENTICATED':
			return {
				...state,
				authenticated: true,
				employee: action.payload
			};
		case 'SESSION_LOADED':
			return {
				...action.payload
			};
		default:
			return state;
	}
};

export default reducer;

import React from 'react';
import FadeIn from 'react-fade-in';

import Product from '../../domains/Product';

export const Size: React.FC = () => {
	const changeSize = Product.actions.useChangeSize();
	const modifying = Product.selectors.useSelectModifying();

	const { sizes, selectedIndex } = Product.selectors.useSelectSizes();

	let body = [];

	body = sizes.map((size, index) => (
		<FadeIn
			className={`col-3 p-0 m-0 border border-dark rounded`}
			key={index}
		>
			<button
				className={`btn rounded text-dark btn-block w-100 ${
					selectedIndex === index
						? 'product-size-modifier-selected'
						: 'product-size-modifier'
				}`}
				onClick={() => {
					if (!modifying) {
						changeSize(index);
					}
				}}
			>
				{size.name}
			</button>
		</FadeIn>
	));
	return (
		<div className="option option-active sizes">
			<div className="modifier-group row">{body}</div>
		</div>
	);
};

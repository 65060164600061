import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Tooltip } from '../..';

export interface ProductVersionSelectorProps {
	setState: React.Dispatch<React.SetStateAction<number>>;
	value?: string;
	content?: React.ReactNode;
}

export const ProductVersionSelector: React.FC<ProductVersionSelectorProps> = ({
	setState,
	value,
	content
}) => {
	const [defaultVersion, setDefaultVersion] = useState<string | undefined>(
		undefined
	);

	useEffect(() => {
		switch (value) {
			case 'Hot':
				setDefaultVersion('Hot');
				break;
			case 'Iced':
				setDefaultVersion('Iced');
				break;
			case 'Frozen':
				setDefaultVersion('Frozen');
				break;
			default:
				setDefaultVersion(undefined);
		}
	}, [value]);

	return (
		<>
			<div className="d-flex align-items-center">
				<th className="border-0">Version</th>
				{content && (
					<Tooltip content={content}>
						{' '}
						<FontAwesomeIcon
							icon={faQuestionCircle}
							color="white"
						/>
					</Tooltip>
				)}
			</div>
			<td className="border-0">
				<select
					onChange={e => setState(Number(e.target.value))}
					className="form-control"
					defaultValue="-- select an option --"
				>
					<option disabled selected={defaultVersion === undefined}>
						-- select an option --
					</option>
					<option value={1} selected={defaultVersion === 'Hot'}>
						Hot
					</option>
					<option value={2} selected={defaultVersion === 'Iced'}>
						Iced
					</option>
					<option value={3} selected={defaultVersion === 'Frozen'}>
						Frozen
					</option>
				</select>
			</td>
		</>
	);
};

const initialState: ProductManagementDomain.State = {
	header: {
		label: '',
		showButton: false
	}
};

const reducer = (
	state: ProductManagementDomain.State = initialState,
	action: ProductManagementDomain.Action
): ProductManagementDomain.State => {
	switch (action.type) {
		case 'UPDATE_HEADER':
			return {
				...state,
				header: action.payload
			};
		default:
			return state;
	}
};

export default reducer;

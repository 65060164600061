import { TableDropdown } from '..';

// import { allergenTooltip } from '../tooltips';

export interface AllergenFieldsProps {
	allergens: Record<number, string>[];
}
export const AllergenFields: React.FC<AllergenFieldsProps> = ({
	allergens
}: AllergenFieldsProps) => {
	//TODO change to correct type when added to perc
	const allergensList = [
		{ id: 5, name: 'nut' },
		{ id: 6, name: 'nut' },
		{ id: 7, name: 'nut' },
		{ id: 8, name: 'nut' },
		{ id: 9, name: 'nut' }
	];

	return (
		<>
			<TableDropdown
				label="Allergen"
				dropdownItems={allergensList}
				state={allergens}
				onAdd={console.log}
				onDelete={console.log}
				content={'result'}
			/>
		</>
	);
};

import React from 'react';
import { Loading } from '..';

export interface OrderStatusProps {
	title: string;
	body: string;
	id: string;
	showLoading: boolean;
}

export const OrderStatus: React.FC<OrderStatusProps> = ({
	title,
	body,
	id,
	showLoading
}) => {
	return (
		<div className="text-center">
			<h1 className="text-secondary font-weight-bold">{title}</h1>

			{showLoading && (
				<div className="my-3 d-flex justify-content-center">
					<Loading />
				</div>
			)}

			<p className="text-center mb-3 text-light">{body}</p>

			<h6 className="text-success mb-0">Order Id</h6>
			<small className="text-primary">{id}</small>
		</div>
	);
};

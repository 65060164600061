import {
	createStore,
	applyMiddleware,
	Store,
	MiddlewareAPI,
	Dispatch,
	Middleware,
	compose
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import LogRocket from 'logrocket';
import {
	addBreadcrumb,
	captureException,
	Severity,
	createReduxEnhancer
} from '@sentry/react';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from './reducer';

const initialState = {};

const monitoring: Middleware<
	MiddlewareAPI<Dispatch<AllActions>, RootState>,
	RootState,
	Dispatch<AllActions>
> = store => next => action => {
	try {
		addBreadcrumb({
			level: 'debug' as Severity.Debug,
			timestamp: Date.now(),
			category: 'redux',
			message: action.type,
			data: action
		});

		next(action);
	} catch (err) {
		const eventId = captureException(err);

		store.dispatch({
			type: 'ADD_ALERT',
			payload: {
				autoDismiss: false,
				type: 'danger',
				message: 'There was an error! Code: ' + eventId,
				id: eventId
			}
		});
	}
};

const sentryReduxEnhancer = createReduxEnhancer();

let store: Store<RootState, AllActions>;

if (process.env.NODE_ENV === 'production') {
	store = createStore(
		rootReducer(),
		initialState,
		compose(
			applyMiddleware(monitoring, LogRocket.reduxMiddleware(), thunk),
			sentryReduxEnhancer
		)
	);
} else {
	const middleware = [monitoring, LogRocket.reduxMiddleware(), thunk, logger];

	const composeEnhancers = composeWithDevTools({
		latency: 0
	});

	store = createStore(
		rootReducer(),
		initialState,
		composeEnhancers(applyMiddleware(...middleware))
	);
}

export default store;

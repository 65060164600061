import { StoresList } from '../components';

export const Stores: React.FC = () => {
	return (
		<div className="stores overflow-auto h-100">
			<div className="px-2 pt-3">
				<div className="d-flex align-items-center">
					<h1 className="text-light">Choose Store</h1>
				</div>

				<StoresList />
			</div>
		</div>
	);
};

export default Stores;

import React, { useEffect, useState } from 'react';
import Session from '../../domains/Session';
import { useAuthenticateEmployeeMutation } from '../../graphql';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const authResultInitialState: SessionDomain.UI.LoginResponse = {
	loading: false,
	result: false,
	error: null
};

type ValidationResult = {
	field: 'employeeId' | 'password';
	message: string;
};

export type LoginFormProps = {
	toggleQR: React.MouseEventHandler<HTMLButtonElement>;
};

type ValidationProps = {
	results: ValidationResult[];
	authResult: SessionDomain.UI.LoginResponse;
};

const Validation: React.FC<ValidationProps> = ({ results, authResult }) => {
	return (
		<>
			{results.length > 0 && (
				<div className="validation-results mt-3">
					{results.map((result, index) => (
						<div
							className="alert alert-danger my-2 validation-message"
							key={index}
						>
							{result.message}
						</div>
					))}
				</div>
			)}

			{authResult.error && (
				<div className="validation-results mt-3">
					<div className="alert alert-danger my-2 validation-message">
						Login failed. Invalid username/password.
					</div>
				</div>
			)}
		</>
	);
};

export const LoginForm: React.FC<LoginFormProps> = ({ toggleQR }) => {
	const reloadSession = Session.actions.useUpdateSession();
	const [validationResults, setValidationResult] = useState<
		ValidationResult[]
	>([]);
	const [employeeId, setEmployeeId] = useState('');
	const [password, setPassword] = useState('');

	const [authResult, setAuthResult] = useState(authResultInitialState);
	const { push } = useHistory();
	const dispatch = useDispatch();
	const [debounce, setDebounce] = useState(false);

	const [login, { data, loading, error }] = useAuthenticateEmployeeMutation({
		errorPolicy: 'all',
		onError: error => {
			for (const err of error.graphQLErrors) {
				if (err.message !== 'Login failed') {
					reloadSession();
				}
			}
		}
	});

	useEffect(() => {
		if (data) {
			dispatch({
				type: 'AUTHENTICATED',
				payload: data.authenticateEmployee
			});

			push('/employee');
		}

		setAuthResult({
			loading,
			result: data?.authenticateEmployee ? true : false,
			error
		});
	}, [loading, data, error, dispatch, push]);

	const validate = () => {
		const isEmployeeIdValid =
			typeof employeeId === 'string' && employeeId !== '';

		const isPasswordValid = typeof password === 'string' && password !== '';

		const newValidationResult: ValidationResult[] = [];

		if (!isEmployeeIdValid) {
			newValidationResult.push({
				field: 'employeeId',
				message: 'Invalid employee id.'
			});
		}

		if (!isPasswordValid) {
			newValidationResult.push({
				field: 'password',
				message: 'Invalid password.'
			});
		}

		setValidationResult(newValidationResult);

		return isEmployeeIdValid && isPasswordValid;
	};

	const attemptLogin = (e: React.FormEvent | React.MouseEvent) => {
		e.preventDefault();

		if (!authResult.loading && validate()) {
			if (!debounce) {
				login({
					variables: {
						credentials: {
							employeeId,
							password
						}
					}
				});

				setDebounce(true);
				setTimeout(() => setDebounce(false), 1000);
			}
		}
	};

	const form = (
		<form className="needs-validation" onSubmit={attemptLogin} noValidate>
			<div className="row align-items-baseline mb-1">
				<label
					htmlFor="employeeId"
					className="col-12 col-sm-4 text-sm-right"
				>
					Employee Id
				</label>

				<div className="col-12 col-sm-8">
					<input
						type="text"
						className="form-control w-100 text-secondary bg-dark"
						placeholder="employee id"
						onChange={e => setEmployeeId(e.target.value)}
						value={employeeId}
						name="employeeId"
						required
					/>
				</div>
			</div>

			<div className="row align-items-baseline mb-3">
				<label
					htmlFor="password"
					className="col-12 col-sm-4 text-sm-right"
				>
					Password
				</label>

				<div className="col-12 col-sm-8">
					<input
						type="password"
						className="form-control text-secondary bg-dark"
						placeholder="password"
						onChange={e => setPassword(e.target.value)}
						value={password}
						name="password"
						required
					/>
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-sm-8 ml-auto d-flex flex-wrap flex-sm-nowrap align-items-center">
					<button
						className={`btn rounded text-dark btn-primary mb-1${
							authResult.loading ? ' disabled' : ''
						}`}
						onClick={attemptLogin}
					>
						Sign In
					</button>

					<div className="text-light mb-1 px-3">or</div>

					<button
						className="btn rounded text-dark btn-secondary mb-1"
						onClick={toggleQR}
					>
						Scan QR Code
					</button>
				</div>
			</div>
		</form>
	);

	return (
		<div className="login-form text-light">
			{form}

			<div className="row">
				<div className="col-12 col-sm-8 ml-auto">
					<Validation
						authResult={authResult}
						results={validationResults}
					/>
				</div>
			</div>
		</div>
	);
};

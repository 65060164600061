import { Percomatic } from '@biggby-coffee/percomatic-typescript';
import React from 'react';
export interface ProductsListProps {
	products: Percomatic.Serialized.Product[];
	selectProduct(id: string | number): void;
	productsListRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const MenuProductsList: React.FC<ProductsListProps> = ({
	products,
	selectProduct,
	productsListRef
}) => {
	return (
		<div ref={productsListRef} className="products-list grid-150">
			{products?.map(({ id, name }, key) => (
				<button
					onClick={() => selectProduct(id)}
					key={key}
					className="btn btn-block btn-secondary mt-0 rounded products-list-product"
				>
					{name}
				</button>
			))}
		</div>
	);
};

import React from 'react';
import { Link } from 'react-router-dom';

import Session from '../domains/Session';
import UI from '../domains/UI';

const managerRoles = [1, 2, 3, 5, 7, 9];
// settingPermissionRoles: to allow Home Office roles to view default notices
const settingPermissionRoles = [1, 5, 7, 8, 9, 10, 11, 12, 14, 15];
const homeOfficeRoles = [1, 7, 8, 9, 10, 11, 12, 14, 15];

export default function Employee(): JSX.Element {
	UI.actions.useSetOrientation('any');

	const employee = Session.selectors.useSelectEmployee();
	const store = Session.selectors.useSelectStore();
	const logout = Session.actions.useLogout();
	const authenticated = Session.selectors.useSelectAuthenticated();

	return (
		<div className="employee overflow-auto h-100 mx-auto mx-sm-0 d-sm-flex align-items-center justify-content-center">
			<div className="w-75">
				<div className="grid-200">
					<Link
						className="btn btn-success btn-lg p-sm-4 w-100 btn-block"
						to="/menu"
					>
						Menu
					</Link>

					{store && (
						<>
							<Link
								className="btn btn-info btn-lg p-sm-4"
								to="/orders"
							>
								Orders
							</Link>
						</>
					)}

					<Link
						className="btn btn-info btn-lg p-sm-4"
						to="/recipe-book"
					>
						Recipe Book
					</Link>

					<Link className="btn btn-info btn-lg p-sm-4" to="/stores">
						Stores
					</Link>

					<Link className="btn btn-info btn-lg p-sm-4" to="/e-ward">
						E-Ward
					</Link>

					<a
						className="btn btn-info btn-lg p-sm-4"
						href={process.env.REACT_APP_PERCOMANAGER_URL ?? '#'}
					>
						PERC-O-Manager
					</a>

					{employee && (
						<>
							{employee.roles.find(({ roleId }) =>
								homeOfficeRoles.includes(roleId)
							) && (
								<Link
									className="btn btn-info btn-lg p-sm-4"
									to="/home-office"
								>
									Home Office
								</Link>
							)}

							{employee.roles.find(({ roleId }) =>
								managerRoles.includes(roleId)
							) && (
								<Link
									className="btn btn-info btn-lg p-sm-4"
									to="/stripe"
								>
									Stripe
								</Link>
							)}

							{employee.roles.find(({ roleId }) =>
								settingPermissionRoles.includes(roleId)
							) && (
								<Link
									className="btn btn-info btn-lg p-sm-4"
									to="/store-settings"
								>
									Settings
								</Link>
							)}
						</>
					)}

					{authenticated && (
						<button
							className="btn btn-danger btn-lg p-sm-4 text-dark"
							onClick={logout}
						>
							Logout
						</button>
					)}
				</div>
			</div>
		</div>
	);
}

export const round = (x: number, precision = 2): string => {
	const factor = 10 ** precision;
	const scaled = x * factor;
	const int = Math.round(scaled);
	const rounded = int / factor;

	if (rounded - x !== 0) {
		return rounded.toFixed(precision);
	} else {
		return String(rounded);
	}
};

import React from 'react';

export interface NutritionRowProps {
	label: string;
	value: string;
	child: boolean;
}

export const NutritionRow: React.FC<ProductDomain.UI.NutritionRow> = ({
	label,
	value,
	child
}: NutritionRowProps) => (
	<div
		className={`nutrition-row d-flex align-items-between border-gray border-bottom py-1 pr-3 pl-${
			child ? '5' : '3'
		}`}
	>
		<p className={`mb-0 ${child ? '' : 'font-weight-bold'}`}>{label}</p>

		<p className="mb-0 ml-auto">{value}</p>
	</div>
);

import React, { useState } from 'react';
import { Modal } from '../../Generic/Modal';
import actions from '../../../domains/UI/actions';
import { useEditBrandStandardMutation } from '../../../graphql';

export type BrandStandardEditFormProps = {
	id: string;
	brandStandardId: number;
	description?: string | undefined;
	resolution?: string | undefined;
	onSave: () => void;
};

export const BrandStandardEditForm: React.FC<BrandStandardEditFormProps> = ({
	id,
	brandStandardId,
	description,
	resolution,
	onSave
}) => {
	const toggleModal = actions.useToggleModal();

	const [brandStandard, setBrandStandard] = useState<{
		description: string;
		resolution: string;
	}>({
		description: description ?? '',
		resolution: resolution ?? ''
	});

	const [saveModal, setSaveModal] = useState<boolean>(false);

	const [editBrandStandard] = useEditBrandStandardMutation();

	const handleChange = (e: { target: { name: string; value: string } }) => {
		const { name, value } = e.target;
		setBrandStandard({
			...brandStandard,
			[name]: value
		});
	};

	const handleSaveClick = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		toggleModal();
		setSaveModal(true);
	};

	const handleClose = () => {
		if (saveModal) {
			setSaveModal(false);
		}
	};

	const handleSave = async () => {
		const response = await editBrandStandard({
			variables: {
				brandStandard: {
					brandStandardId: Number(brandStandardId),
					description: brandStandard.description,
					resolution: brandStandard.resolution
				}
			}
		});

		if (response) {
			onSave();
		}
	};

	return (
		<>
			<form className="brand-standard-form">
				<div className="p-3">
					<label className="text-primary font-weight-bold">
						Brand Standard Description
					</label>
					<textarea
						className="w-100 overflow-auto"
						name="description"
						rows={3}
						value={brandStandard.description}
						onChange={handleChange}
					/>
				</div>
				<div className="p-3">
					<label className="text-primary font-weight-bold">
						Brand Standard Resolution
					</label>
					<textarea
						className="w-100 overflow-auto"
						rows={5}
						name="resolution"
						value={brandStandard.resolution}
						onChange={handleChange}
					/>
				</div>
				<div className="d-flex justify-content-center">
					<button
						className="btn btn-secondary"
						onClick={handleSaveClick}
					>
						Save
					</button>
				</div>
			</form>

			{saveModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Save"
					confirmationButtonHandler={handleSave}
					closeMessage="Cancel"
					closeButtonHandler={handleClose}
				>
					<>
						<h1 className="text-dark text-center">
							Are you sure you want to save?
						</h1>
						<p className="text-dark">
							The brand standard for{' '}
							<em>'{brandStandard.description}'</em>&nbsp; will be
							saved.
						</p>
					</>
				</Modal>
			)}
		</>
	);
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronDown,
	faChevronUp,
	faAngleDoubleUp,
	faAngleDoubleDown
} from '@fortawesome/free-solid-svg-icons';

export interface MenuSideProps {
	scrollRef: React.MutableRefObject<HTMLDivElement | null>;
}

const className = (direction: string) =>
	'btn btn-sm border border-dark bg-light-gray text-light rounded text-center side-button ' +
	direction;

export const ProductSide: React.FC<MenuSideProps> = ({ scrollRef }) => {
	return (
		<>
			<button
				onClick={() =>
					scrollRef.current !== null
						? (scrollRef.current.scrollTop = 0)
						: null
				}
				className={className('top-arrow')}
			>
				<FontAwesomeIcon icon={faAngleDoubleUp} />
			</button>

			<button
				onClick={() =>
					scrollRef.current !== null
						? (scrollRef.current.scrollTop -=
								scrollRef.current.clientHeight)
						: null
				}
				className={className('up')}
			>
				<FontAwesomeIcon icon={faChevronUp} />
			</button>

			<button
				onClick={() =>
					scrollRef.current !== null
						? (scrollRef.current.scrollTop +=
								scrollRef.current.clientHeight)
						: null
				}
				className={className('down')}
			>
				<FontAwesomeIcon icon={faChevronDown} />
			</button>

			<button
				onClick={() =>
					scrollRef.current !== null
						? (scrollRef.current.scrollTop =
								scrollRef.current.scrollHeight -
								scrollRef.current.clientHeight)
						: null
				}
				className={className('bottom-arrow')}
			>
				<FontAwesomeIcon icon={faAngleDoubleDown} />
			</button>
		</>
	);
};

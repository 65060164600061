import React from 'react';

export interface TableTextAreaProps {
	label: string;
	name?: string;
	readOnly?: boolean;
	state?: string | number;
	setState?: React.Dispatch<React.SetStateAction<string>>;
}

export const TableTextArea: React.FC<TableTextAreaProps> = ({
	label,
	name,
	readOnly,
	state,
	setState
}: TableTextAreaProps) => {
	return (
		<>
			<th className="border-0">{label}</th>
			<td className="border-0">
				<textarea
					readOnly={readOnly ?? false}
					name={name || label}
					className="form-control"
					value={state}
					onChange={e =>
						setState ? setState(e.target.value) : console.log()
					}
				></textarea>
			</td>
		</>
	);
};

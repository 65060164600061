import { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

import { Error, Loading, Toggle } from '../../components';
import {
	CreateCardsInSeriesInput,
	useCreateCardsInSeriesMutation,
	useCreateCardsInSeriesUpdatesSubscription
} from '../../graphql';
import { defined } from '../../utils';

type FormProps = {
	onSubmit(data: CreateCardsInSeriesInput): void;
	disabled?: boolean;
};

const Form: React.FC<FormProps> = ({ onSubmit, disabled }) => {
	const [cardSeries, setCardSeries] = useState<number>();
	const [numberOfCards, setNumberOfCards] = useState(100);
	const [sequential, setSequential] = useState(false);
	const [initialCash, setInitialCash] = useState(10);
	// const [batchSize, setBatchSize] = useState(1000);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();

				if (defined(cardSeries)) {
					onSubmit({
						cardSeries,
						initialCash,
						numberOfCards,
						sequential
						// batchSize
					});
				}
			}}
		>
			<div className="form-group">
				<label htmlFor="cardSeries">Card Series</label>

				<input
					className="form-control"
					type="number"
					name="cardSeries"
					inputMode="numeric"
					value={cardSeries}
					onChange={e => {
						const val = Number(e.target.value);

						if (!isNaN(val)) {
							setCardSeries(val);
						}
					}}
					required
					disabled={disabled}
				/>
			</div>

			<div className="form-group">
				<label htmlFor="numberOfCards">Number of Cards</label>

				<input
					className="form-control"
					type="number"
					name="numberOfCards"
					inputMode="numeric"
					value={numberOfCards}
					onChange={e => {
						const val = Number(e.target.value);

						if (!isNaN(val)) {
							setNumberOfCards(val);
						}
					}}
					disabled={disabled}
				/>
			</div>

			<div className="form-group">
				<label htmlFor="initialCash">Initial Cash</label>

				<input
					className="form-control"
					type="number"
					name="initialCash"
					inputMode="decimal"
					value={initialCash.toFixed(2)}
					onChange={e => {
						const val = Number(e.target.value);

						if (!isNaN(val)) {
							setInitialCash(val);
						}
					}}
					disabled={disabled}
				/>
			</div>

			{/* <div className="form-group">
				<label htmlFor="initialCash">Batch Size</label>

				<input
					className="form-control"
					type="number"
					name="batchSize"
					inputMode="decimal"
					value={batchSize}
					onChange={e => {
						const val = Number(e.target.value);

						if (!isNaN(val)) {
							setBatchSize(val);
						}
					}}
					disabled={disabled}
				/>
			</div> */}

			<Toggle
				checked={sequential}
				label="Sequential?"
				onChange={() => {
					if (disabled) return;

					setSequential(!sequential);
				}}
				inline
			/>

			<button
				type="submit"
				className="btn btn-primary mt-3"
				disabled={disabled}
			>
				Submit
			</button>
		</form>
	);
};

type UpdatesProps = {
	arn: string;
};

const Updates: React.FC<UpdatesProps> = ({ arn }) => {
	const [totalBatches, setTotalBatches] = useState<number>();
	const [currentBatch, setCurrentBatch] = useState<number>();
	const [errorMessage, setErrorMessage] = useState<string>();
	const [cards, setCards] = useState<string[]>([]);
	const [ok, setOk] = useState(true);

	// should be something like [['card number'], [12354657], [1235623456]]
	const csvData = useMemo(
		() => [['card number'], ...cards.map(card => [card])],
		[cards]
	);

	useCreateCardsInSeriesUpdatesSubscription({
		variables: { executionArn: arn },
		onSubscriptionData({ subscriptionData: { data, error } }) {
			if (error) {
				setErrorMessage(error.message);
			}

			if (data) {
				setCurrentBatch(data.createCardsInSeriesUpdates.batchIndex + 1);

				setCards(cards => [
					...cards,
					...data.createCardsInSeriesUpdates.cardNumbers
				]);

				if (
					!defined(totalBatches) &&
					data.createCardsInSeriesUpdates.totalBatches > 0
				) {
					setTotalBatches(
						data.createCardsInSeriesUpdates.totalBatches
					);
				}

				if (!data.createCardsInSeriesUpdates.ok) {
					setOk(false);

					setErrorMessage(
						`${data.createCardsInSeriesUpdates.errorMessage} - Code: ${data.createCardsInSeriesUpdates.errorCode}`
					);
				}
			}
		}
	});

	const progress = useMemo(() => {
		if (!defined(currentBatch) || !defined(totalBatches)) return 0;

		return (currentBatch / totalBatches) * 100;
	}, [currentBatch, totalBatches]);

	return (
		<div className="card border-gray">
			<h4 className="card-header border-dark-gray d-flex align-items-center justify-content-between">
				{!ok && 'Error!'}

				{ok && (
					<>
						<div>
							{progress < 100 ? (
								'Creating cards...'
							) : (
								<>
									Cards Created!{' '}
									<CSVLink data={csvData}>
										Download Card Numbers
									</CSVLink>
								</>
							)}
						</div>

						<div className="text-primary">
							{progress.toFixed(2)}%
						</div>
					</>
				)}
			</h4>

			<div className="card-body">
				{ok && progress < 100 && (
					<>
						<div className="progress bg-dark">
							<div
								className="progress-bar progress-bar-striped progress-bar-animated ml-auto bg-primary"
								role="progressbar"
								style={{
									width: `${progress === 0 ? 1 : progress}%`
								}}
							/>

							<div
								className="progress-bar progress-bar-striped progress-bar-animated ml-auto bg-gray"
								role="progressbar"
								style={{
									width: `${
										100 - (progress === 0 ? 1 : progress)
									}%`
								}}
							/>
						</div>

						{cards.length > 0 && <hr />}
					</>
				)}

				{cards.length > 0 && (
					<div className="list-group list-group-flush">
						{cards.map(card => (
							<li className="list-group-item" key={card}>
								{card}
							</li>
						))}
					</div>
				)}
			</div>

			<div className="card-footer">
				<div className="card-text text-nowrap overflow-auto">
					<small className="text-muted">{arn}</small>
				</div>

				{errorMessage && (
					<div className="card-text mt-3">
						<Error error={errorMessage} />
					</div>
				)}
			</div>
		</div>
	);
};

export type CreateCardsProps = React.HTMLAttributes<HTMLDivElement>;

export const CreateCards: React.FC<CreateCardsProps> = ({ ...props }) => {
	const [arn, setArn] = useState<string>();

	const [createCardsInSeries, { error, loading }] =
		useCreateCardsInSeriesMutation({
			onCompleted({ createCardsInSeries }) {
				setArn(createCardsInSeries);
			}
		});

	return (
		<div {...props} className="overflow-auto">
			<div className="text-light pt-3 px-3">
				<div className="row">
					<div className="col-6">
						<div className="card border-gray">
							<h4 className="card-header border-dark-gray">
								Create Cards
							</h4>

							<div className="card-body">
								<Form
									onSubmit={data =>
										createCardsInSeries({
											variables: { data }
										})
									}
									disabled={defined(arn) || loading}
								/>
							</div>

							{error && (
								<div className="card-footer">
									<div className="card-text">
										<Error error={error.message} />
									</div>
								</div>
							)}
						</div>
					</div>

					<div className="col-6">
						{loading && (
							<div className="card border-light">
								<h4 className="card-header border-light">
									<Loading />
								</h4>

								<div className="card-body">
									<div className="card-text">
										<Loading />
									</div>
								</div>
							</div>
						)}

						{arn && <Updates arn={arn} />}
					</div>
				</div>
			</div>
		</div>
	);
};

import * as actions from './actions';
import reducer from './reducer';
import selectors from './selectors';

const Data: NetworkDomain.NetworkDomain = {
	actions: actions,
	selectors: selectors,
	reducer
};

export default Data;

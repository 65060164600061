import React from 'react';
import { TableItemType } from '../TableDropdown/TableDropdown';

export interface DropDownItemProps<T> {
	label: string;
	key: number;
	item: T;
	linkedItems: boolean | undefined;
	clickHandler: (item: T, key: number) => void;
}

export const DropdownItem = <T extends TableItemType>({
	label,
	key,
	item,
	linkedItems,
	clickHandler,
	...props
}: DropDownItemProps<T>): React.ReactElement => {
	return (
		<>
			<option
				className="text-left text-primary my-auto dropdown-option"
				onClick={() => clickHandler(item, key)}
				value={item.id}
				style={{ cursor: 'pointer' }}
			>
				{label}
			</option>
		</>
	);
};

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../Generic';
import actions from '../../../domains/UI/actions';
import { useCreateBrandStandardMutation } from '../../../graphql';

export const AddBrandStandard: React.FC = () => {
	const toggleModal = actions.useToggleModal();
	const history = useHistory();

	const [createBrandStandard] = useCreateBrandStandardMutation();

	const [brandStandard, setBrandStandard] = useState<{
		description: string;
		resolution: string;
	}>({
		description: '',
		resolution: ''
	});

	const [saveModal, setSaveModal] = useState<boolean>(false);

	const [cancelModal, setCancelModal] = useState<boolean>(false);

	const [resetModal, setResetModal] = useState<boolean>(false);

	const handleChange = (e: { target: { name: string; value: string } }) => {
		const { name, value } = e.target;

		setBrandStandard({
			...brandStandard,
			[name]: value
		});
	};

	const handleSaveClick = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		toggleModal();
		setSaveModal(true);
	};

	const handleCancelClick = () => {
		toggleModal();
		setCancelModal(true);
	};

	const handleClose = () => {
		if (saveModal) {
			setSaveModal(false);
		}

		if (cancelModal) {
			setCancelModal(false);
		}

		if (resetModal) {
			setResetModal(false);
		}
	};

	const handleSave = async () => {
		const response = await createBrandStandard({
			variables: {
				brandStandard: {
					description: brandStandard.description,
					resolution: brandStandard.resolution
				}
			}
		});

		if (response) {
			history.push('/brand-standards');
		}
	};

	const handleCancel = () => {
		history.goBack();
	};

	const handleReset = () => {
		setBrandStandard({
			description: '',
			resolution: ''
		});
	};

	return (
		<div className="text-light p-2">
			<div className="d-flex justify-content-between px-3">
				<h1 className="m-0">Add Brand Standard</h1>
				<div>
					<button
						className="btn btn-danger"
						onClick={handleCancelClick}
					>
						Cancel
					</button>
				</div>
			</div>
			<hr className="m-0 my-2" />
			<form className="brand-standard-form" onSubmit={handleSaveClick}>
				<div className="p-3">
					<label className="text-primary font-weight-bold">
						Brand Standard Description
					</label>
					<textarea
						className="w-100 overflow-auto"
						name="description"
						rows={3}
						value={brandStandard.description}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="p-3">
					<label className="text-primary font-weight-bold">
						Brand Standard Resolution
					</label>
					<textarea
						className="w-100 overflow-auto"
						rows={5}
						name="resolution"
						value={brandStandard.resolution}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="d-flex justify-content-center">
					<button
						type="reset"
						className="btn btn-warning mr-5"
						onClick={() => {
							toggleModal();
							setResetModal(true);
						}}
					>
						Reset
					</button>
					<button type="submit" className="btn btn-secondary">
						Save
					</button>
				</div>
			</form>

			{saveModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Save"
					confirmationButtonHandler={handleSave}
					closeMessage="Cancel"
					closeButtonHandler={handleClose}
				>
					<>
						<h1 className="text-dark text-center">
							Are you sure you want to save?
						</h1>
						<p className="text-dark">
							The brand standard for{' '}
							<em>'{brandStandard.description}'</em>&nbsp; will be
							saved.
						</p>
					</>
				</Modal>
			)}

			{cancelModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Yes"
					confirmationButtonHandler={handleCancel}
					closeMessage="No"
					closeButtonHandler={handleClose}
				>
					<>
						<h1 className="text-dark text-center">
							Are you sure you want to cancel?
						</h1>
						<p className="text-dark">
							The brand standard will not be saved.
						</p>
					</>
				</Modal>
			)}

			{resetModal && (
				<Modal
					showConfirmationButton={true}
					confirmationMessage="Reset"
					confirmationButtonHandler={handleReset}
					closeMessage="Cancel"
					closeButtonHandler={handleClose}
				>
					<>
						<h1 className="text-dark text-center">
							Are you sure you want to reset?
						</h1>
						<p className="text-dark">
							The brand standard form will not be reset.
						</p>
					</>
				</Modal>
			)}
		</div>
	);
};

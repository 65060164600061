import moment from 'moment';

import { TextField } from './TextField';
import { formatTime } from '../utils';

export type DateFieldProps = {
	label: string;
	value: moment.MomentInput;
};

export const DateField: React.FC<DateFieldProps> = ({ label, value }) => (
	<TextField label={label} value={formatTime(value)} />
);

import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { useMenuQuery } from '../graphql';

import {
	Cart,
	MenuSide,
	TopButtons as MenuTopButtons,
	MenuUtilityButtons,
	PriorityProducts,
	MenuProductGroupsList,
	MenuProductsList,
	PercomaticLoading
} from '../components';

import Product from '../domains/Product';
import UI from '../domains/UI';
import api from '../worker';
import { Percomatic, utils } from '@biggby-coffee/percomatic-typescript';

export type MenuQueryProductGroup = {
	id: string;
	name: string;
};

export default function Menu(): JSX.Element {
	UI.actions.useSetOrientation('landscape');
	const params = useParams<{ groupId?: string; productId?: string }>();
	const location = useLocation();
	const history = useHistory();
	const productsListRef = useRef<HTMLDivElement>(null);

	console.debug('location', location);

	const { data: menuData } = useMenuQuery({
		variables: {
			productGroupId: Number(params.groupId)
		}
	});

	const { defined } = utils;

	const [productGroups, setProductGroups] = useState<
		| Percomatic.Serialized.ProductGroup[]
		| MenuQueryProductGroup[]
		| undefined
	>([]);
	// .filter(
	// 	product => !currentProductGroupPriorityProductsIds?.includes(product.id)
	// );

	// const sizeModel = Product.selectors.useSelectSizes();
	// const productGroupsList: Percomatic.Serialized.ProductGroup[] = useMemo(
	// 	() => [],
	// 	[]
	// );

	const [products, setProducts] = useState<
		Percomatic.Serialized.Product[] | undefined
	>([]);

	const [priorityProducts, setPriorityProducts] = useState<
		Percomatic.Serialized.Product[]
	>([]);

	useEffect(() => {
		(async () => {
			if (params.groupId !== undefined) {
				const group = await api.getProductGroup(Number(params.groupId));
				setProducts(group?.nonPriorityProducts);

				if (group) {
					const childGroupsPromises =
						group?.visibleChildProductGroupIds
							.filter(defined)
							.map(id => api.getProductGroup(id));

					const childGroups = await Promise.all(childGroupsPromises);
					setProductGroups(childGroups.filter(defined));

					setPriorityProducts(group.priorityProducts);
				}
			} else {
				setProductGroups(menuData?.menu.productGroups);
			}
		})();
	}, [defined, params.groupId, menuData?.menu.productGroups]);

	const resetProduct = Product.actions.useResetProduct();

	const selectProductGroup = (id: string | number) => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		history.push('/menu/' + id);
	};

	const selectProduct = (id: string | number) => {
		resetProduct();
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		history.push('/menu/' + params.groupId + '/' + id);
	};

	return (
		<PercomaticLoading redirect={location.pathname}>
			<div className="menu px-3">
				<MenuTopButtons />

				<div className="menu-main left">
					<Cart />

					<MenuUtilityButtons />
				</div>

				<div className="menu-main right">
					<MenuProductGroupsList
						// allProductGroups={productGroups}
						productGroups={productGroups}
						selectProductGroup={selectProductGroup}
						// currentProductGroups={productGroupsList}
					/>

					{/* <div className="sizes grid-100">
					{sizeModel.sizes.map((size, size

							key={sizeIndex}
							style={{ backgroundColor: '#F69CA0' }}
							className="btn btn-block mt-0 rounded border border-dark"
						>
							{size.name}
						</button>
					))}
				</div> */}

					{priorityProducts.length > 0 && (
						<PriorityProducts
							priorityProducts={priorityProducts}
							selectProduct={selectProduct}
						/>
					)}

					<div className="products">
						{products && (
							<MenuProductsList
								productsListRef={productsListRef}
								products={products}
								selectProduct={selectProduct}
								// allProducts={products}
							/>
						)}

						{(products?.length ?? 0) > 0 && (
							<MenuSide productsListRef={productsListRef} />
						)}
					</div>
				</div>
			</div>
		</PercomaticLoading>
	);
}

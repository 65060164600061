import React, { useState, useRef, useEffect } from 'react';

// import { useEditInventoryItemMutation } from '../../graphql';

import actions from '../../../domains/UI/actions';

import { AllergenFields, Table, TableDropdown, TextInput } from '..';
import { Modal } from '../../Generic';
import { SaveButton } from '../..';
import api from '../../../worker';

import { Percomatic } from '@biggby-coffee/percomatic-typescript';

// import { unitTooltip, containerSizeTooltip } from '../tooltips';

export interface InventoryItemFieldsProps {
	currentInventoryItem: Percomatic.LinkedInventoryItem;
}

export const InventoryItemFields: React.FC<Percomatic.LinkedInventoryItem> =
	currentInventoryItem => {
		const toggleModal = actions.useToggleModal();

		useEffect(() => {
			(async () => {
				const units = await api.getUnits();

				setUnits(units);
				setUnit(
					units.filter(
						unit => unit.id === currentInventoryItem.inventoryItemId
					)
				);
			})();
		}, [currentInventoryItem.inventoryItemId]);

		const [id, setId] = useState(String(currentInventoryItem.id) ?? '');
		const [name, setName] = useState(currentInventoryItem.name ?? '');

		const [units, setUnits] = useState<Percomatic.Serialized.Unit[]>([]);

		const [unit, setUnit] = useState<Percomatic.Serialized.Unit[]>([]);

		const [containerName, setContainerName] = useState(
			currentInventoryItem.containerName ?? ''
		);

		const [containerNamePlural, setContainerNamePlural] = useState(
			currentInventoryItem.containerNamePlural ?? ''
		);

		const [containerSize, setContainerSize] = useState(
			String(currentInventoryItem.containerSize) ?? ''
		);
		const [calories, setCalories] = useState(
			String(currentInventoryItem.nutritionFacts.calories) ?? '0'
		);
		const [caloriesFromFat, setCaloriesFromFat] = useState(
			String(currentInventoryItem.nutritionFacts.caloriesFromFat) ?? '0'
		);
		const [fat, setFat] = useState(
			String(currentInventoryItem.nutritionFacts.fat) ?? '0'
		);
		const [saturatedFat, setSaturatedFat] = useState(
			String(currentInventoryItem.nutritionFacts.saturatedFat) ?? '0'
		);
		const [transFat, setTransFat] = useState(
			String(currentInventoryItem.nutritionFacts.transFat) ?? '0'
		);
		const [cholesterol, setCholesterol] = useState(
			String(currentInventoryItem.nutritionFacts.cholesterol) ?? '0'
		);
		const [sodium, setSodium] = useState(
			String(currentInventoryItem.nutritionFacts.sodium) ?? '0'
		);
		const [carbohydrates, setCarbohydrates] = useState(
			String(currentInventoryItem.nutritionFacts.carbohydrates) ?? '0'
		);
		const [dietaryFiber, setDietaryFiber] = useState(
			String(currentInventoryItem.nutritionFacts.dietaryFiber) ?? '0'
		);
		const [sugar, setSugar] = useState(
			String(currentInventoryItem.nutritionFacts.sugar) ?? '0'
		);
		const [protein, setProtein] = useState(
			String(currentInventoryItem.nutritionFacts.protein) ?? '0'
		);
		const [caffeine, setCaffeine] = useState(
			String(currentInventoryItem.nutritionFacts.caffeine) ?? '0'
		);

		const [allergens] = useState<Record<number, string>[]>([]);

		// const editedInventoryItemData = {
		// 	name: name,
		// 	containerName,
		// 	containerNamePlural,
		// 	units: unit[0]?.unitName,
		// 	unitsPlural: unit[0]?.unitNamePlural,
		// 	containerSize: Number(containerSize),
		// 	calories: Number(calories),
		// 	caloriesFromFat: Number(caloriesFromFat),
		// 	fat: Number(fat),
		// 	saturatedFat: Number(saturatedFat),
		// 	transFat: Number(transFat),
		// 	cholesterol: Number(cholesterol),
		// 	carbohydrates: Number(carbohydrates),
		// 	sodium: Number(sodium),
		// 	dietaryFiber: Number(dietaryFiber),
		// 	sugar: Number(sugar),
		// 	protein: Number(protein),
		// 	caffeine: Number(caffeine),
		// 	inventoryItemId: Number(id)
		// };

		const validated = useRef(false);

		useEffect(() => {
			isNaN(Number(calories)) ||
			isNaN(Number(caloriesFromFat)) ||
			isNaN(Number(fat)) ||
			isNaN(Number(saturatedFat)) ||
			isNaN(Number(transFat)) ||
			isNaN(Number(cholesterol)) ||
			isNaN(Number(sodium)) ||
			isNaN(Number(carbohydrates)) ||
			isNaN(Number(dietaryFiber)) ||
			isNaN(Number(sugar)) ||
			isNaN(Number(protein)) ||
			isNaN(Number(caffeine)) ||
			isNaN(Number(containerSize))
				? (validated.current = false)
				: (validated.current = true);
		}, [
			calories,
			caloriesFromFat,
			fat,
			saturatedFat,
			transFat,
			cholesterol,
			sodium,
			carbohydrates,
			dietaryFiber,
			sugar,
			protein,
			caffeine,
			containerSize
		]);

		// const [editInventoryItem] = useEditInventoryItemMutation({
		// 	variables: { inventoryItem: editedInventoryItemData }
		// });
		const submitEditedInventoryItem = () => {
			// editInventoryItem();
		};

		return (
			<>
				<h2 className="text-light font-weight-bold">
					Inventory Item Fields
				</h2>

				<Table>
					<TextInput label="Name" state={name} setState={setName} />
					<TextInput
						label="Container Name"
						state={containerName}
						setState={setContainerName}
					/>
					<TextInput
						label="Container Name Plural"
						state={containerNamePlural}
						setState={setContainerNamePlural}
					/>
					<TextInput
						label="Container Size"
						state={containerSize}
						setState={setContainerSize}
						content={'containerSizeTooltip'}
					/>
					<TextInput
						label="Calories"
						state={calories}
						setState={setCalories}
					/>
					<TextInput
						label="Calories From Fat"
						state={caloriesFromFat}
						setState={setCaloriesFromFat}
					/>
					<TextInput label="Fat" state={fat} setState={setFat} />
					<TextInput
						label="Saturated Fat"
						state={saturatedFat}
						setState={setSaturatedFat}
					/>
					<TextInput
						label="Trans Fat"
						state={transFat}
						setState={setTransFat}
					/>
					<TextInput
						label="Cholesterol"
						state={cholesterol}
						setState={setCholesterol}
					/>
					<TextInput
						label="Carbohydrates"
						state={carbohydrates}
						setState={setCarbohydrates}
					/>
					<TextInput
						label="Sodium"
						state={sodium}
						setState={setSodium}
					/>
					<TextInput
						label="Dietary Fiber"
						state={dietaryFiber}
						setState={setDietaryFiber}
					/>
					<TextInput
						label="Sugar"
						state={sugar}
						setState={setSugar}
					/>
					<TextInput
						label="Protein"
						state={protein}
						setState={setProtein}
					/>
					<TextInput
						label="Caffeine"
						state={caffeine}
						setState={setCaffeine}
					/>
					<TextInput
						label="Id"
						state={id}
						setState={setId}
						readOnly={true}
					/>
				</Table>
				<TableDropdown
					state={unit}
					dropdownItems={units}
					label="Unit"
					singleRelationship={true}
					onDelete={(_, key) =>
						setUnit(items =>
							items.filter((_, index) => index !== key)
						)
					}
					onAdd={(item, key) => setUnit([item])}
					content={'unitTooltip'}
				/>

				<AllergenFields allergens={allergens} />

				<Modal
					showConfirmationButton={validated.current ? true : false}
					confirmationMessage="Yes"
					confirmationButtonHandler={submitEditedInventoryItem}
				>
					{validated.current === false ? (
						<>
							<h1 className="mb-4 text-danger">
								There was a problem
							</h1>
							<p>The following properties must be a number:</p>
							<ul>
								<li>Calories</li>
								<li>Calories From Fat</li>
								<li>Fat</li>
								<li>Saturated Fat</li>
								<li>Trans Fat</li>
								<li>Cholesterol</li>
								<li>Sodium</li>
								<li>Carbohydrates</li>
								<li>Dietary Fiber</li>
								<li>Sugar</li>
								<li>Protein</li>
								<li>Caffeine</li>
								<li>Container Sze</li>
							</ul>
						</>
					) : (
						<>
							<h1>Are you sure?</h1>
							<p>
								This action will affect the{' '}
								{currentInventoryItem.name} inventory item.
							</p>
						</>
					)}
				</Modal>
				<SaveButton onClick={() => toggleModal()} />
			</>
		);
	};

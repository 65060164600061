import React from 'react';

export interface TextAreaProps {
	classname?: string;
	label: string;
	name?: string;
	readOnly?: boolean;
	value: string | number;
	change: React.ChangeEventHandler<HTMLTextAreaElement>;
	required?: boolean;
}

export const TextArea: React.FC<TextAreaProps> = ({
	classname = '',
	label,
	name = '',
	readOnly = false,
	value,
	change,
	required = false
}: TextAreaProps) => {
	return (
		<div className={classname}>
			<label>{label}</label>
			<textarea
				readOnly={readOnly}
				name={name}
				value={value}
				onChange={change}
				required={required}
			/>
		</div>
	);
};

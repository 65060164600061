import React from 'react';

import Admin from '../../domains/Admin';

import '../../sass/AllOrders.scss';

interface OrderProps {
	orderId: string;
	storeId: number;
	termId: number;
	terminalStyle?: string;
	displayAllTerminals: boolean;
}

const formatMoney = (x: number) => (x / 100).toFixed(2);

const DUMMY_ITEM_PRICE = 199;

const Order = ({
	orderId,
	storeId,
	termId,
	displayAllTerminals,
	terminalStyle
}: OrderProps) => {
	const order = Admin.selectors.useSelectOrder(storeId, termId, orderId);

	console.log(order.items);

	return (
		<li className="list-group-item">
			<h5 className="text-secondary">
				{new Date(order.timePlaced).toDateString()}{' '}
				{new Date(order.timePlaced).toLocaleTimeString()}
			</h5>

			<h6 className="text-primary">{order.id}</h6>

			<div className="row">
				<div className="col pl-0">Store {order.storeId}</div>

				<div className="col">Term {order.termId}</div>

				<div className="col">
					{order.user?.type ?? 'CUSTOMER'} {order.user?.id ?? 'Guest'}
				</div>

				<div className="col pr-0">
					$
					{formatMoney(
						order.items.reduce(
							(acc, item) =>
								acc + item.quantity * DUMMY_ITEM_PRICE,
							0
						)
					)}
				</div>
			</div>

			<div className="row font-weight-bold">
				<div className="col pl-0">ItemId</div>
				<div className="col">Qty.</div>
				<div className="col pr-0">Price</div>
			</div>

			{order.items.map((item, key) => (
				<div className="row" key={key}>
					<div className="col pl-0">{item.id}</div>

					<div className="col">{item.quantity}</div>

					<div className="col pr-0">
						${formatMoney(DUMMY_ITEM_PRICE)}
					</div>
				</div>
			))}
		</li>
	);
};

export default function AllOrders(): JSX.Element {
	const allOrders = Admin.selectors.useSelectOrders();

	console.log('AllOrders: allOrders', allOrders);

	return (
		<div className="all-orders">
			<h2 className="text-primary font-weight-bold mt-3 mb-0">
				All Orders
			</h2>

			<ul className="list-group mt-3">
				{allOrders.map((order: AdminDomain.OrderId, key: number) => (
					<Order
						orderId={order.orderId}
						storeId={order.storeId}
						termId={order.termId}
						displayAllTerminals={true}
						key={key}
					/>
				))}
			</ul>
		</div>
	);
}

import { useRef, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
	Loader,
	PercomaticLoading,
	ProductGroupsList,
	ProductsList
} from '../../components';

import { useMenuQuery } from '../../graphql';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import api from '../../worker';
import ProductManagement from '../../domains/ProductManagement';

function Body() {
	const params = useParams<{ groupId?: string }>();
	const { push } = useHistory();
	const productsListRef = useRef<HTMLDivElement>(null);

	const { data } = useMenuQuery({
		variables: { productGroupId: Number(params.groupId) }
	});

	const selectProductGroup = (id: string) => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		push('/product-management/product-group/' + id);
	};

	const selectProduct = (id: string) => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		push(
			'/product-management/product-group/' +
				params.groupId +
				'/product/' +
				id
		);
	};

	const updateHeader = ProductManagement.actions.useUpdateHeader();

	useEffect(() => {
		updateHeader({
			label: '',
			showButton: false
		});
	}, [updateHeader]);

	const [allProductGroups, setAllProductGroups] = useState<
		Percomatic.Serialized.ProductGroup[]
	>([]);

	const [childProductGroups, setChildProductGroups] =
		useState<(Percomatic.Serialized.ProductGroup | undefined)[]>();

	const [allProducts, setAllProducts] = useState<
		Percomatic.Serialized.Product[]
	>([]);

	useEffect(() => {
		(async () => {
			const productGroups = await api.getProductGroups();
			const products = await api.getProducts();
			const currentGroup = productGroups.find(
				group => group.id === Number(params.groupId)
			);
			if (currentGroup !== undefined) {
				const childrenIds = currentGroup?.childProductGroupIds;

				const childrenPromises = childrenIds.map(id =>
					api.getProductGroup(id)
				);

				const children = await Promise.all(childrenPromises);

				setChildProductGroups(children);
			} else {
				setChildProductGroups(
					productGroups.filter(group => group.priority === 1)
				);
			}
			setAllProductGroups(productGroups);
			setAllProducts(products);
		})();
	}, [params]);

	const childProductGroupList: Percomatic.Serialized.ProductGroup[] = [];

	if (childProductGroups) {
		childProductGroups?.forEach(
			group => group !== undefined && childProductGroupList.push(group)
		);
	}

	return (
		<Loader loaded={data !== undefined}>
			<ProductGroupsList
				currentProductGroups={childProductGroupList}
				allProductGroups={allProductGroups}
				selectProductGroup={selectProductGroup}
			/>

			{data && data.menu.products.length > 0 && (
				<ProductsList
					products={data?.menu.products}
					selectProduct={selectProduct}
					productsListRef={productsListRef}
					allProducts={allProducts}
				/>
			)}
		</Loader>
	);
}

export default function Menu(): JSX.Element {
	return (
		<div className="overflow-auto pb-3 h-100 text-light">
			<PercomaticLoading
				redirect="/product-management"
				loaded
				children={<Body />}
			/>
		</div>
	);
}
// function useProductsQuery(): void {
// 	throw new Error('Function not implemented.');
// }

import { useHistory, useParams } from 'react-router';

import selectors from '../domains/Session/selectors';
import { StoresList } from '../components';

const approvedRoles = [1, 5, 7, 8, 9, 10, 11, 12, 14, 15];
const approvedLylaRoles = [1, 15]; // Admins & Boost

export default function StoreSettings(): JSX.Element {
	const { push } = useHistory();
	const params = useParams<{ storeId?: string }>();

	const roles = selectors.useSelectRoles();

	const canViewSettings = roles.some(role =>
		approvedRoles.includes(role.roleId)
	);

	const canViewLyla = roles.some(role =>
		approvedLylaRoles.includes(role.roleId)
	);

	if (!params.storeId) {
		return (
			<StoresList
				onClick={store => push(`/store-settings/${store.storeId}`)}
			/>
		);
	}

	if (!canViewSettings) {
		return (
			<h1 className="text-light text-center">
				Not Authorized to View Store Settings
			</h1>
		);
	}

	if (!canViewLyla) {
		return (
			<div className="d-flex align-items-center justify-content-center">
				{/* grid-200 w-75 when more settings added*/}
				<div className="grid-200 w-75">
					<button
						className="btn btn-info btn-lg"
						onClick={() =>
							push(
								`/store-settings/${params.storeId}/default-notices`
							)
						}
					>
						Default Notices
					</button>
				</div>
			</div>
		);
	}

	return (
		<div className="d-flex align-items-center justify-content-center">
			{/* grid-200 w-75 when more settings added*/}
			<div className="grid-200 w-75">
				<button
					className="btn btn-info btn-lg"
					onClick={() =>
						push(
							`/store-settings/${params.storeId}/default-notices`
						)
					}
				>
					Default Notices
				</button>
			</div>
		</div>
	);
}

import React from 'react';
import { StripeOrderQuery } from '../../../graphql';

export const OrderInfo: React.FC<StripeOrderQuery> = (
	props: StripeOrderQuery
) => {
	const order = props?.order;

	return (
		<>
			<h3 className="px-3">ORDER ITEMS</h3>

			<p className="px-3 order-number">
				{order?.items.length} Item
				{order && order.items.length <= 1 ? '' : 's'}
			</p>

			<hr />

			<table className="table table-dark">
				<thead>
					<tr>
						<th>Product Name</th>

						<th>Price</th>
					</tr>
				</thead>

				<tbody>
					{order?.items.map((item, key) => (
						<tr key={key}>
							<td>{item.item.product?.name}</td>

							<td>${(item.price / 100).toFixed(2)}</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
};

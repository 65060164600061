import React from 'react';
import { animated, useTransition } from 'react-spring';

import Product from '../../domains/Product';
import Session from '../../domains/Session';

import { StoresList } from '..';

import '../../sass/PercomaticLoading.scss';

interface LoadingBarProps {
	progress: number;
	message: string;
}

const LoadingBar: React.FC<LoadingBarProps> = ({ progress, message }) => (
	<div className="loading-container mt-5 mx-3">
		<div className="progress mx-auto">
			<div
				className="progress-bar"
				role="progressbar"
				style={{ width: progress + '%' }}
			></div>

			<div className="text">{progress}%</div>
		</div>

		<small className="mt-2 d-block text-center text-muted font-weight-bold">
			{message}
		</small>
	</div>
);

export interface PercomaticLoadingProps {
	loaded?: boolean;
	redirect?: string;
}

export const PercomaticLoading: React.FC<
	PercomaticLoadingProps & React.HTMLAttributes<HTMLOrSVGElement>
> = ({ children, redirect, loaded = true, ...otherProps }) => {
	const store = Session.selectors.useSelectStore();
	const progress = Product.selectors.useSelectInitAndLinkProgress();

	const transitions = useTransition(
		loaded && progress.initAndLinkProgress === 100,
		null,
		{
			from: { opacity: 0 },
			enter: { opacity: 1 },
			leave: { opacity: 0 }
		}
	);

	if (!store) {
		return <StoresList redirect={redirect} />;
	}

	const output = transitions.map(({ item, key, props: styles }) => (
		<animated.div
			{...otherProps}
			className={`${item ? '' : 'percomatic-loading'}${
				otherProps.className ? ' ' + otherProps.className : ''
			}`}
			key={key}
			style={styles}
		>
			{item ? (
				children
			) : (
				<LoadingBar
					progress={progress.initAndLinkProgress}
					message={progress.initAndLinkProgressMessage}
				/>
			)}
		</animated.div>
	));

	return <>{output}</>;
};

import React from 'react';
import Order from '../../domains/Order';

export const SendOrder: React.FC = () => {
	const sendOrder = Order.actions.useSendOrder();

	return (
		<div className="row">
			<div className="col">
				<div className="mt-3 pb-3">
					<button
						className="btn btn-block btn-primary"
						onClick={() => sendOrder(2)}
					>
						Send Order To Counter
					</button>
				</div>
			</div>

			<div className="col">
				<div className="mt-3 pb-3">
					<button
						className="btn btn-block btn-primary"
						onClick={() => sendOrder(3)}
					>
						Send Order To Drive-Thru
					</button>
				</div>
			</div>
		</div>
	);
};

import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Toggle } from '../../components';

const Link: React.FC<{
	to: string;
	exact?: boolean;
}> = ({ to, exact, children }) => (
	<NavLink
		exact={exact}
		to={`/admin/${to}`}
		className="mr-2"
		activeClassName="text-secondary"
	>
		{children}
	</NavLink>
);

const Nav: React.FC<{ height: number }> = ({ height }) => {
	const [autoHide, setAutoHide] = useState(true);

	return (
		<div
			className="fixed-top overflow-hidden w-100 bg-gray px-3 d-flex align-items-center justify-content-between"
			style={{ height: autoHide ? height : 50 }}
		>
			<div className="gap">
				<Link to="stores">Stores</Link>
				<Link to="terminals">Terminals</Link>
				<Link to="orders">Orders</Link>
				<Link to="live">Live Orders</Link>
				<Link to="logs">Logs</Link>
				<Link to="connections">Redis DB Connections</Link>
			</div>

			<div className="text-primary" style={{ fontSize: '75%' }}>
				<Toggle
					checked={autoHide}
					label="Auto Hide"
					onChange={() => setAutoHide(!autoHide)}
					inline
				/>
			</div>
		</div>
	);
};

export default Nav;

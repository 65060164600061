import React, { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { Percomatic } from '@biggby-coffee/percomatic-typescript';

import { Tooltip } from '../..';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import api from '../../../worker';

// import { itemRecipeTooltip } from '../tooltips';

interface ParamProps {
	groupId: string;
	productId: string;
	itemId: string;
}

export interface productProps {
	product: Percomatic.Serialized.Product;
}

export const ProductItems: React.FC<productProps> = product => {
	const params: ParamProps = useParams();

	const [productItems, setProductItems] = useState<
		(Percomatic.Serialized.Item | undefined)[]
	>([]);

	useEffect(() => {
		(async () => {
			const itemPromises: Promise<
				Percomatic.Serialized.Item | undefined
			>[] = product.product.itemIds.map(id => api.getItem(id));
			const items = await Promise.all(itemPromises);
			setProductItems(items);
		})();
	}, [product.product.itemIds]);

	const roundNumber = (number: number) => {
		return Math.round((number + Number.EPSILON) * 100) / 100;
	};

	return product.product.itemIds.length === 0 ? (
		<h2 className="text-left font-weight-bold text-light py-3">
			{' '}
			There are no items for this product.
		</h2>
	) : (
		<div className="my-5">
			<div className="d-flex align-items-center">
				<h2 className="text-left font-weight-bold text-light pr-3">
					Item Recipes
				</h2>
				{
					<Tooltip content={'itemRecipeTooltip'}>
						{' '}
						<FontAwesomeIcon
							icon={faQuestionCircle}
							color="white"
						/>
					</Tooltip>
				}
			</div>
			<div className="row justify-content-center">
				{productItems.map((item, key) => (
					<div
						key={key}
						className={`rounded d-flex flex-column col-12 col-sm-12 col-md-5 col-lg-3 mx-4 my-5 px-0 bg-dark border border-light`}
					>
						<h3
							className={`bg-dark rounded text-center font-weight-bold p-3 text-light`}
						>
							{item?.size ? item.size?.name : item?.product?.name}
						</h3>{' '}
						<ul className="list-group-flush px-0">
							{item?.recipe.map((item, key) => (
								<li
									key={key}
									className="list-group-item text-dark d-flex justify-content-between align-items-center bg-light-gray"
								>
									{item.component?.name}
									<span className="badge badge-primary badge-pill ml-5">
										{roundNumber(item.quantity)}
										{` ${
											roundNumber(item.quantity) === 1
												? item.component?.recipeUnits
												: item.component
														?.recipeUnitsPlural
										}`}
									</span>
								</li>
							))}
						</ul>
						<Link
							to={
								'/product-management/product-group/' +
								params.groupId +
								'/product/' +
								params.productId +
								'/item/' +
								item?.id
							}
							className="text-decoration-none align-self-end mt-auto"
						>
							<button className="btn bg-success text-white rounded m-3">
								Edit Item
							</button>
						</Link>
					</div>
				))}
			</div>
		</div>
	);
};

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { AddLylaQuestionButton } from '../../components/LylaQuestions';
import { Dropdown } from '../../components/LylaQuestions';
import { Loading } from '../../components/Loading';

import { useLylaQuestionsQuery } from '../../graphql';
import { useWhereToShowOptions } from '../../hooks';

export type LylaQuestionsProps = React.HTMLAttributes<HTMLDivElement> & {
	prop?: string;
};

export const LylaQuestions: React.FC<LylaQuestionsProps> = () => {
	const { data, loading, error } = useLylaQuestionsQuery();

	const getWhereToShowOptions = useWhereToShowOptions();

	// Passed to children to reload component on change
	const [lylaQuestions, setLylaQuestions] = useState(data?.lylaQuestions);
	const [isLoading, setIsLoading] = useState(false);
	const [whereToShowOptions, setWhereToShowOptions] = useState<
		{ value: string; label: string }[]
	>([...getWhereToShowOptions]);

	useEffect(() => {
		if (data) {
			setLylaQuestions(data.lylaQuestions);
		}
	}, [data]);

	useEffect(() => {
		setWhereToShowOptions(getWhereToShowOptions);
	}, [getWhereToShowOptions]);

	if (loading) {
		return <Loading />;
	}

	if (!data || error) {
		return (
			<div className="p-3">
				<div className="alert alert-danger">
					Please select your store to see order information.
					<Link to="/" className="text-danger">
						{' '}
						Go back.
					</Link>
				</div>
			</div>
		);
	}

	return (
		<div className="lyla-questions text-light py-3 overflow-auto">
			<div className="lyla-questions-header">
				<h1 className="px-3">Lyla Questions: </h1>
				{isLoading && (
					<div className="lyla-questions-loader px-3">
						<Loading />
					</div>
				)}
				{lylaQuestions && (
					<AddLylaQuestionButton
						largestId={
							lylaQuestions.length > 0
								? lylaQuestions[lylaQuestions.length - 1]
										?.questionId || ''
								: ''
						}
						lylaQuestions={lylaQuestions}
						setLylaQuestions={setLylaQuestions}
						setIsLoading={setIsLoading}
						whereToShowOptions={whereToShowOptions}
					/>
				)}
			</div>
			<Dropdown
				activeQuestions={true}
				lylaQuestions={lylaQuestions}
				setLylaQuestions={setLylaQuestions}
				setIsLoading={setIsLoading}
				whereToShowOptions={whereToShowOptions}
			/>
			<Dropdown
				activeQuestions={false}
				lylaQuestions={lylaQuestions}
				setLylaQuestions={setLylaQuestions}
				setIsLoading={setIsLoading}
				whereToShowOptions={whereToShowOptions}
			/>
			<div className="pull-left text-danger px-3">
				<p>Note: These values are global for all stores.</p>
			</div>
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import UI from '../../domains/UI';

import {
	Error,
	Loading,
	makeTableComponent,
	WrapApollo
} from '../../components';

import {
	useStripeOrdersQuery,
	useStripeOrdersLazyQuery,
	OrdersConnectionFieldsFragment,
	OrderEdgeFieldsFragment
} from '../../graphql';
import { formatCents } from '../../utils';

const OrdersTable = makeTableComponent<
	OrdersConnectionFieldsFragment['edges'][number]['node']
>(
	[
		'id',
		'timePlaced',
		'profileId',
		'profile',
		'pickupName',
		'status',
		'subTotal',
		'taxAmount',
		'total',
		'storeId'
	],
	{
		columnNamesMap: {
			id: 'Order ID',
			profileId: 'Loyalty Member?',
			profile: 'Email',
			subTotal: 'Subtotal',
			taxAmount: 'Tax',
			storeId: ''
		},
		formatColumnFunctions: {
			timePlaced: v => moment(v).fromNow(),
			profileId: v =>
				v === null ? (
					<FontAwesomeIcon className="text-danger" icon={faTimes} />
				) : (
					<FontAwesomeIcon className="text-success" icon={faCheck} />
				),
			profile: (profile, row) => profile?.email ?? row.guestEmail,
			status: v => v.status.substr(6),
			subTotal: formatCents,
			taxAmount: formatCents,
			total: formatCents,
			storeId: (storeId, { id }) => (
				<Link to={`/stripe/store/${storeId}/${id}`}>Details</Link>
			)
		}
	}
);

const Body: React.FC<{ data?: OrderEdgeFieldsFragment[] }> = ({ data }) => (
	<OrdersTable data={data?.map(({ node }) => node) ?? []} />
);

export const Store: React.FC = () => {
	UI.actions.useSetOrientation('landscape');
	const { storeId } = useParams<{ storeId: string }>();

	const [orders, setOrders] = useState<OrderEdgeFieldsFragment[]>([]);

	const { data, loading, error } = useStripeOrdersQuery({
		variables: {
			storeId: Number(storeId),
			first: 20,
			filter: { type: 'BHLOUNGE' },
			orderBy: 'created',
			orderDirection: 'desc'
		}
	});

	const [
		stripeQuery,
		{ data: lazyData, loading: lazyLoading, error: lazyError }
	] = useStripeOrdersLazyQuery({ errorPolicy: 'all' });

	const [currentOffset, setCurrentOffset] = useState<string>();

	useEffect(() => {
		setCurrentOffset(data?.store?.orders.pageInfo.endCursor);
		if (data?.store) {
			setOrders(data.store.orders.edges);
		}
	}, [data]);

	useEffect(() => {
		setCurrentOffset(lazyData?.store?.orders.pageInfo.endCursor);
		setOrders(orders => {
			if (lazyData?.store) {
				return [...orders, ...lazyData.store.orders.edges];
			}
			return orders;
		});
	}, [lazyData]);

	if (error) {
		return <Error error={error.message} />;
	}

	if (lazyError) {
		return <Error error={lazyError.message} />;
	}

	return (
		<div className="text-light py-3">
			<h1 className="px-3">Store {storeId} Orders</h1>

			<WrapApollo
				data={orders}
				loading={loading}
				error={error}
				component={Body}
			/>
			{lazyLoading && <Loading />}
			{!lazyLoading && !loading && (
				<div className="d-flex justify-content-center">
					<button
						className="btn btn-lg bg-primary text-white"
						onClick={() =>
							stripeQuery({
								variables: {
									storeId: Number(storeId),
									first: 10,
									after: currentOffset,
									filter: { type: 'BHLOUNGE' },
									orderBy: 'created',
									orderDirection: 'desc'
								}
							})
						}
					>
						Load More
					</button>
				</div>
			)}
		</div>
	);
};

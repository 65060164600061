import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useMeasure, useThrottleFn } from 'react-use';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface HeaderProps {
	isActive: boolean;
	onClick: React.EventHandler<React.MouseEvent>;
	color: 'primary' | 'secondary' | 'light' | 'dark';
	tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	label: string;
	padding?: 0 | 1 | 2 | 3 | 4 | 5;
	noHr?: boolean;
}

export const Header: React.FC<
	HeaderProps & React.HTMLAttributes<HTMLDivElement>
> = ({
	color,
	tag: Tag,
	label,
	padding = 2,
	onClick,
	isActive,
	noHr = false,
	...otherProps
}) => {
	return (
		<>
			{!noHr && <hr className="m-0 p-0" />}

			<div
				{...otherProps}
				className={`text-${color} d-flex align-items-center px-3 py-${padding}`}
				onClick={onClick}
			>
				<Tag className="mb-0 mr-auto">{label}</Tag>

				<FontAwesomeIcon
					icon={isActive ? faChevronUp : faChevronDown}
				/>
			</div>
		</>
	);
};

export interface GenericDrawerProps {
	isActive: boolean;
	expandDuration?: number;
	tag?: keyof JSX.IntrinsicElements;
	showOverflow?: boolean;
	onRest?: (...args: unknown[]) => unknown;
	overflow?: string;
}

export const GenericDrawer: React.FC<
	GenericDrawerProps & React.HTMLAttributes<HTMLOrSVGElement>
> = ({
	children,
	tag: Tag = 'div',
	isActive,
	expandDuration = 200,
	showOverflow,
	onRest,
	...otherProps
}) => {
	const [contentHeight, setContentHeight] = useState(0);
	const [ref, { height }] = useMeasure<HTMLDivElement>();
	const throttledIsActive = useThrottleFn<boolean, [boolean]>(
		isActive => isActive,
		expandDuration,
		[isActive]
	);
	const expand = useSpring({
		height: throttledIsActive ? `${contentHeight}px` : '0px',
		onRest
	});

	useEffect(() => {
		const resizeAction = () => setContentHeight(height);

		// Sets initial height
		setContentHeight(height);

		// Adds resize event listener
		window.addEventListener('resize', resizeAction);

		// Clean-up
		return window.removeEventListener('resize', resizeAction);
	}, [height]);

	const [header, ...others] = React.Children.toArray(children);

	return (
		<Tag
			{...otherProps}
			style={{
				...otherProps.style,
				overflow: showOverflow ? 'auto' : 'hidden',
				paddingBottom: 0
			}}
		>
			{header}

			<animated.div style={expand}>
				<div ref={ref}>{others}</div>
			</animated.div>
		</Tag>
	);
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { Error, Loading, WrapApollo } from '../../components';

import UI from '../../domains/UI';

import {
	useStripeDisputesQuery,
	DisputeFieldFragment,
	DisputeOrderFieldsFragment,
	useStripeDisputesLazyQuery
} from '../../graphql';

/// need to write order status query

const Order: React.FC<DisputeOrderFieldsFragment> = order => (
	<tr>
		<td>{order.id}</td>
		<td>{moment(order.timePlaced).fromNow()}</td>
		<td>
			{order.profileId === null ? (
				<FontAwesomeIcon className="text-danger" icon={faTimes} />
			) : (
				<FontAwesomeIcon className="text-success" icon={faCheck} />
			)}
		</td>
		<td>{order.profile?.email || order.guestEmail}</td>
		<td>{order.pickupName}</td>
		<td>{order.status.status.substr(6)}</td>
		<td>${(order.subTotal / 100).toFixed(2)}</td>
		<td>${(order.taxAmount / 100).toFixed(2)}</td>
		<td>${(order.total / 100).toFixed(2)}</td>
		<td>
			<Link to={`/stripe/store/${order.storeId}/${order.id}`}>
				Details
			</Link>
		</td>
	</tr>
);

const Body: React.FC<{ data: DisputeFieldFragment[] }> = ({ data }) => (
	<table className="table table-dark">
		<thead>
			<tr>
				<th>Order ID</th>
				<th>Time Placed</th>
				<th>Loyalty Member?</th>
				<th>Email</th>
				<th>Pickup Name</th>
				<th>Status</th>
				<th>Subtotal</th>
				<th>Tax</th>
				<th>Total</th>
				<th></th>
			</tr>
		</thead>

		<tbody>
			{data?.map(
				(element, key) =>
					element?.charge?.payment?.order && (
						<Order {...element.charge.payment.order} key={key} />
					)
			)}
		</tbody>
	</table>
);

export default function Disputes(): JSX.Element {
	UI.actions.useSetOrientation('landscape');

	const { storeId } = useParams<{ storeId: string }>();

	const [disputes, setDisputes] = useState<DisputeFieldFragment[]>([]);

	const { data, loading, error } = useStripeDisputesQuery({
		variables: {
			storeId: Number(storeId)
		}
	});

	const [
		disputesQuery,
		{ data: lazyData, loading: lazyLoading, error: lazyError }
	] = useStripeDisputesLazyQuery();

	const disputeData = data?.store?.disputes;

	useEffect(() => {
		if (disputeData) {
			setDisputes(disputeData);
		}
	}, [disputeData]);

	useEffect(() => {
		const lazyDisputes = lazyData?.store?.disputes;

		if (lazyDisputes) {
			setDisputes(disputes => [...disputes, ...lazyDisputes]);
		}
	}, [lazyData]);

	if (error || lazyError) {
		return (
			<Error
				error={error?.message ?? lazyError?.message ?? 'Unknown error.'}
			/>
		);
	}

	return (
		<div className="text-light py-3">
			<h1 className="px-3">Store {storeId} Orders with Dispute</h1>

			<WrapApollo
				data={disputes}
				loading={loading}
				error={error}
				component={Body}
			/>

			{lazyLoading && <Loading />}

			{!lazyLoading && !loading && (
				<div className="d-flex justify-content-center">
					<button
						className="btn btn-lg bg-primary text-white"
						onClick={() =>
							disputesQuery({
								variables: {
									storeId: Number(storeId),
									startingAfter:
										disputes[disputes.length - 1]?.id
								}
							})
						}
					>
						Load More
					</button>
				</div>
			)}
		</div>
	);
}

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export interface QuantityControlProps {
	quantity: string | number;
	increment: (e: unknown) => void;
	decrement: (e: unknown) => void;
	margins?: number;
	color?: string;
	size?: string;
	fontColor?: string;
	modifier?: boolean;
}

export const QuantityControl: React.FC<QuantityControlProps> = ({
	quantity,
	increment,
	decrement,
	margins,
	color,
	size,
	fontColor,
	modifier
}: QuantityControlProps) => {
	if (!margins) {
		margins = 3;
	}

	if (!color) {
		color = 'dark';
	}

	if (!size) {
		size = 'medium';
	}

	if (!fontColor) {
		fontColor = 'white';
	}

	return (
		<div
			className={
				`w-100 d-flex align-items-center justify-content-between quantity-control quantity-control-` +
				size
			}
		>
			<div onClick={decrement} className="hitbox ">
				{!modifier ? (
					<FontAwesomeIcon
						icon={faMinusCircle}
						color={'white'}
						size="lg"
						border={false}
						className={`m-${margins} text-primary bg-light rounded-circle border border-primary`}
					/>
				) : (
					<h5 className="text-black rounded px-3 py-2 h-100 quantity-control-icon border border-dark m-0">
						-
					</h5>
				)}
			</div>

			<span
				className={`cart-item-quantity mb-0 ${
					!modifier && 'flex-fill'
				}  ${
					modifier &&
					'text-center w-100 p-0 border border-dark rounded'
				}`}
			>
				<h5
					className={` w-100 badge-${color} text-${fontColor}
					 ${modifier && 'p-2 mb-0 rounded'}
					${!modifier && 'badge badge-pill'}`}
				>
					{quantity}
				</h5>
			</span>
			<div onClick={increment} className="hitbox">
				{!modifier ? (
					<FontAwesomeIcon
						icon={faPlusCircle}
						size="lg"
						border={false}
						className={`m-${margins} text-primary bg-light rounded-circle border border-primary`}
					/>
				) : (
					<h5 className="text-black rounded  px-3 py-2 h-100 quantity-control-icon border border-dark m-0">
						+
					</h5>
				)}
			</div>
		</div>
	);
};

import { TextField } from './TextField';
import { formatPercent } from '../utils';

export type PercentFieldProps = {
	label: string;
	value: number | undefined;
};

export const PercentField: React.FC<PercentFieldProps> = ({ label, value }) => (
	<TextField label={label} value={formatPercent(value)} />
);

import { TextField } from './TextField';
import { formatMoney } from '../utils';

export type MoneyFieldProps = {
	label: string;
	value: number | undefined;
};

export const MoneyField: React.FC<MoneyFieldProps> = ({ label, value }) => (
	<TextField label={label} value={formatMoney(value)} />
);

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Tooltip } from '../..';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Product from '../../../domains/Product';

export const PageHeader: React.FC = () => {
	const { label, showButton, linkTo, buttonTitle } = useSelector(
		(state: RootState) => state.productManagement.header
	);

	const { initAndLinkProgress } =
		Product.selectors.useSelectInitAndLinkProgress();

	const content = (
		<>
			<h1>Header</h1>
			<p>
				This is a page to{' '}
				{label.includes('New') ? label.toLowerCase() : 'edit ' + label}s
			</p>
		</>
	);

	return (
		<>
			<div className="d-flex flex-wrap justify-content-between align-items-center border-bottom border-light pb-2 mb-2">
				{initAndLinkProgress === 100 && (
					<div className="d-flex align-items-center">
						<h1 className="text-center font-weight-bold text-primary mb-0 pr-3">
							{label}
						</h1>
						{content && label && (
							<Tooltip content={content} direction="bottom">
								{' '}
								<FontAwesomeIcon
									icon={faQuestionCircle}
									color="white"
								/>
							</Tooltip>
						)}
					</div>
				)}

				{showButton && linkTo && (
					<Link to={linkTo} className="text-decoration-none">
						<button className="btn bg-success text-light rounded">
							{buttonTitle}
						</button>
					</Link>
				)}
			</div>
		</>
	);
};

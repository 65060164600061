import React, { useState, useEffect } from 'react';
import { CaptureOuterClick } from '../../..';
import { DropdownButton } from '../..';
import { DropdownItem } from '..';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { TableItemType } from '../TableDropdown/TableDropdown';

export interface DropdownProps<T> {
	label: string;
	items: T[];
	showDropdownButton?: boolean;
	alignLeft?: boolean;
	linkedItems?: boolean;
	clickHandler: (item: T, key: number) => void;
	state: T[];
}

export const Dropdown = <T extends TableItemType>({
	items,
	label,
	showDropdownButton,
	alignLeft,
	linkedItems,
	clickHandler,
	state,
	...props
}: DropdownProps<T>): React.ReactElement => {
	const [searchValue, setSearchValue] = useState('');
	const [filteredData, setFilteredData] = useState(items);
	const [showDropdown, setShowDropdown] = useState(false);

	useEffect(() => {
		const stateNames = state.map(item => item.name ?? item.unitName);
		setSearchValue(searchValue);
		setFilteredData(
			items?.filter(item =>
				item.unitName
					? item.unitName
							.toLowerCase()
							.includes(searchValue.toLowerCase())
					: item.name
							?.toLowerCase()
							.includes(searchValue.toLowerCase()) &&
					  !stateNames.includes(item.name ?? item.unitName)
			)
		);
	}, [searchValue, items, state]);

	return (
		<CaptureOuterClick handler={() => setShowDropdown(false)}>
			<div className="dropdown h-100">
				<button
					className="h-100 btn rounded btn-success bg-success border-0 text-white dropdown-toggle w-100 text-wrap"
					type="button"
					onClick={() => setShowDropdown(!showDropdown)}
				>
					{`${label}s`}
				</button>
				{showDropdown && (
					<datalist
						className={`border border-dark mt-3 p-3 d-flex flex-column bg-light dropdown-list rounded ${
							alignLeft ? 'align-left' : 'align-right'
						}`}
					>
						<input
							type="text"
							placeholder="Search..."
							value={searchValue}
							onChange={e => setSearchValue(e.target.value)}
							className=" mb-3"
						/>
						{showDropdownButton && (
							<>
								<DropdownButton label={label} />
								<h5 className="text-left text-primary font-weight-bold">{`Existing ${label}s`}</h5>
							</>
						)}
						<div className="d-flex flex-column ml-1">
							{filteredData ? (
								filteredData?.map((item, key) => (
									<DropdownItem
										key={key}
										label={item.name ?? item.unitName ?? ''}
										item={item}
										clickHandler={clickHandler}
										linkedItems={linkedItems}
									/>
								))
							) : (
								<span>
									Loading...
									<FontAwesomeIcon
										icon={faCircleNotch}
										spin
									/>{' '}
								</span>
							)}
						</div>
					</datalist>
				)}
			</div>
		</CaptureOuterClick>
	);
};

import React from 'react';
import { ApolloError } from 'apollo-client';
import { Link } from 'react-router-dom';

import { Loading } from '../..';

export interface WrapApolloProps<TData, TProps = Record<string, unknown>> {
	error?: ApolloError;
	loading: boolean;
	data?: TData;
	props?: TProps;
	component: React.FC<{ data: TData } & TProps>;
}

interface ErrorProps {
	error: string;
}

const Error: React.FC<ErrorProps> = ({ error }) => (
	<div className="p-3">
		<div className="alert alert-danger">
			{error}{' '}
			<Link to="/" className="text-danger">
				Go back.
			</Link>
		</div>
	</div>
);

export function WrapApollo<TData>({
	data,
	loading,
	error,
	props,
	component
}: WrapApolloProps<TData>): JSX.Element | null {
	if (error) {
		return <Error error={error.message} />;
	}

	if (loading) {
		return <Loading />;
	}

	if (data) {
		return component({ data, ...props });
	}

	return null;
}
